var render = function render(){var _vm=this,_c=_vm._self._c;return _c('o-dialog',{ref:"dialog",attrs:{"title":_vm.$t('components.auth.actions.changePin.title'),"info":_vm.info,"options":[
    { text: _vm.$t('general.cancel'), type: 'cancel' },
    { text: _vm.$t('general.save'), type: 'confirm', callback: _vm.confirm },
  ],"portal":_vm.portal},scopedSlots:_vm._u([{key:"heading",fn:function(){return [(_vm.account.employee.image)?_c('img',{staticClass:"mx-auto h-12 w-12 rounded-full mt-3",attrs:{"src":_vm.account.employee.image}}):_vm._e()]},proxy:true}])},[_vm._v(" "),_c('div',{staticClass:"pt-4 text-left"},[_c('o-form',{ref:"form"},[_c('segment-field',{staticClass:"block mb-6",attrs:{"options":[
          { label: _vm.$t('auth.pin.change'), value: false },
          { label: _vm.$t('auth.pin.remove'), value: true }
        ]},model:{value:(_vm.none),callback:function ($$v) {_vm.none=$$v},expression:"none"}}),_vm._v(" "),_c('o-field-group',{staticClass:"block pb-3",attrs:{"label":_vm.$t('auth.password.label'),"name":_vm.$t('auth.password.label'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ status }){return [_c('password-field',{attrs:{"status":status},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}])}),_vm._v(" "),(!_vm.none)?_c('o-field-group',{staticClass:"block pb-3",attrs:{"label":_vm.$t('auth.pin.new'),"name":_vm.$t('auth.pin.new'),"rules":"integer|required|pin:@Confirm"},scopedSlots:_vm._u([{key:"default",fn:function({ status }){return [_c('password-field',{attrs:{"status":status},model:{value:(_vm.newPin),callback:function ($$v) {_vm.newPin=$$v},expression:"newPin"}})]}}],null,false,1371200966)}):_vm._e(),_vm._v(" "),(!_vm.none)?_c('o-field-group',{staticClass:"block pb-3",attrs:{"label":_vm.$t('auth.pin.confirm'),"name":_vm.$t('auth.pin.confirm'),"rules":"integer|required"},scopedSlots:_vm._u([{key:"default",fn:function({ status }){return [_c('password-field',{attrs:{"status":status},model:{value:(_vm.confirmPin),callback:function ($$v) {_vm.confirmPin=$$v},expression:"confirmPin"}})]}}],null,false,4196069576)}):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }