<template>
  <div
    id="notification-list"
    class="z-80000"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <transition-group
      tag="div"
      name="list-complete"
      class="pointer-events-none fixed flex items-start top-0 w-full p-1 md:p-6 flex-col z-80000"
    >
      <div
        v-for="item in items"
        :key="item.key"
        class="list-complete-item pointer-events-auto rounded-md shadow-md z-80000 max-w-full relative mb-4 mx-auto bg-white bg-opacity-85 backdrop-blur border dark:bg-gray-900"
        :class="item.width"
      >
        <div class="flex items-center justify-between w-full">
          <!--<div v-if="item.icon" class="p-4 pr-0">
            <o-icon :icon="item.icon" :class="item.variant" :size="22" />
          </div>-->

          <div class="p-4 flex flex-col space-y-1 pr-8">
            <div
              :class="[
                'text-base font-medium leading-none',
                item.variant
              ]"
            >
              {{ item.title }}
            </div>
            <div v-if="item.text" class="text-sm text-gray-700 leading-tight dark:text-gray-200">
              {{ item.text }}
            </div>

            <div v-if="item.content" class="mt-3">
              <component :is="item.content" />
            </div>
          </div>

          <div class="flex items-center space-x-2 pr-2">
            <div v-if="item.action">
              <o-button flat size="xs" @click="handleActionClick(item)">
                {{ item.action.label }}
              </o-button>
            </div>

            <button
              class="text-black dark:text-white opacity-70 p-1 bg-transparent"
              @click="close(item)"
            >
              <o-icon icon="x" size="16" />
            </button>
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import {
  OInternalLink,
} from '@/components/elements'

const key = () => `${Date.now()}-${Math.random()}`

export default {
  components: {
    OInternalLink,
  },
  data () {
    return {
      items: [],
      parent: false,
    }
  },
  methods: {
    open ({ title, text, icon, variant, action, duration, content, width = 'w-100' }) {
      if (!this.parent) {
        this.$mount()
        document.body.appendChild(this.$el)
        this.parent = true
      }
      return new Promise((resolve) => {
        const item = {
          key: key(),
          show: true,
          text,
          title,
          action,
          icon, // : icon || this.icon(variant),
          variant: this.variant(variant),
          duration,
          content,
          width,
          resolve,
          timer: new this.timer(() => {
            this.close(item)
          }, duration),
        }
        this.items.push(item)
      })
    },
    toast (options = {}) {
      const { title, text, icon, variant, action, duration = 5000, content, width = 'w-100' } = options
      return this.open({ title, text, icon, variant, action, duration, content, width })
    },
    error (options = {}) {
      const { title = window.$nuxt.$t('components.elements.notification.error'), text = window.$nuxt.$t('components.elements.notification.errorInfo'), icon, errors, variant = 'danger', duration = 10000 } = options
      return this.open({ title, text: errors ? Object.values(errors).join(', ') : text, icon, variant, duration })
    },
    remove (item) {
      const i = this.items.indexOf(item)
      if (i >= 0) {
        this.items.splice(i, 1)
      }
    },
    close (item, ok) {
      item.resolve()
      item.show = false
      this.remove(item)
    },
    icon (variant) {
      switch (variant) {
        case 'success':
          return 'success'
        case 'danger':
          return 'danger'
        case 'warning':
          return 'warning'
        case 'info':
          return 'info'
        default:
          return 'notification'
      }
    },
    variant (variant) {
      switch (variant) {
        case 'success':
          return 'text-green-500 dark:text-green-600'
        case 'danger':
          return 'text-red-500 dark:text-red-500'
        case 'warning':
          return 'text-yellow-500 dark:text-yellow-500'
        case 'info':
          return 'text-blue-500 dark:text-blue-500'
        default:
          return 'text-gray-800 dark:text-gray-100'
      }
    },
    timer (callback, delay) {
      let timerId
      let start
      let remaining = delay

      this.pause = function () {
        window.clearTimeout(timerId)
        remaining -= Date.now() - start
      }

      this.resume = function () {
        start = Date.now()
        window.clearTimeout(timerId)
        timerId = window.setTimeout(callback, remaining)
      }

      this.resume()
    },
    handleMouseEnter () {
      const items = this.items

      items.forEach((notification) => {
        notification.timer.pause()
      })
    },
    handleMouseLeave () {
      const items = this.items

      items.forEach((notification) => {
        notification.timer.resume()
      })
    },
    handleActionClick (item) {
      item.action.click()

      this.close(item, true)
    },
  },
}
</script>

<style lang="scss">
  .list-complete-item {
    transition: all 0.5s;
    display: inline-flex;
    margin-right: 10px;
  }
  .list-complete-enter, .list-complete-leave-to {
    opacity: 0;
    transform: translateY(-40px);
  }
  .list-complete-leave-active {
    position: absolute;
  }
</style>
