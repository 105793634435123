<template>
  <div class="flex items-center space-x-2">
    <div :class="[ containerClass, statusClasses, { 'border-none' : group } ]">
      <div class="w-1/3 flex-shrink flex-grow pr-3 v-80000">
        <v-date-picker
          ref="start-date"
          :value="date.start"
          :max-date="limit ? date.finish : null"
          :first-day-of-week="firstDayOfWeek"
          :popover="{
            visibility: 'focus'
          }"
          :is-dark="$colorMode.value === 'dark'"
          :locale="$i18n.locale"
          @input="handleStartChange"
        >
          <template #default="{ inputValue, inputEvents }">
            <div class="relative group flex items-center justify-between">
              <input
                type="text"
                :placeholder="$t('general.from')"
                class="w-full bg-transparent text-gray-800 text-sm dark:text-gray-100"
                :value="inputValue"
                v-on="inputEvents"
              >
              <div class="absolute right-0 transition duration-200 opacity-0 h-4 text-red-600" :class="{ 'group-hover:opacity-100 cursor-pointer' : date.start }" @click="_value.start = null">
                <o-icon icon="x" />
              </div>
            </div>
          </template>
        </v-date-picker>
      </div>
      <div class="pr-3 -ml-3 opacity-50">
        <o-icon icon="chevronRight" :size="10" />
      </div>
      <div class="w-1/3 flex-shrink flex-grow">
        <v-date-picker
          ref="finish-date"
          :value="date.finish"
          :min-date="limit ? date.start : null"
          :first-day-of-week="firstDayOfWeek"
          :popover="{
            visibility: 'focus'
          }"
          :is-dark="$colorMode.value === 'dark'"
          :locale="$i18n.locale"
          @input="handleFinishChange"
        >
          <template #default="{ inputValue, inputEvents }">
            <div class="relative group flex items-center justify-between">
              <input
                type="text"
                :placeholder="$t('general.to')"
                class="w-full bg-transparent text-gray-800 text-sm dark:text-gray-100"
                :value="inputValue"
                v-on="inputEvents"
              >
              <div class="absolute right-0 transition duration-200 opacity-0 h-4 text-red-600" :class="{ 'group-hover:opacity-100 cursor-pointer' : date.finish }" @click="_value.finish = null">
                <o-icon icon="x" />
              </div>
            </div>
          </template>
        </v-date-picker>
      </div>
    </div>

    <o-dropdown v-if="options.length">
      <o-button icon="preset" flat size="sm" />

      <template #content>
        <o-dropdown-item
          v-for="(preset, index) in options"
          :key="index"
          @click="() => set(preset)"
        >
          {{ $t(preset.name) }}
        </o-dropdown-item>
      </template>
    </o-dropdown>
  </div>
</template>

<script>
import classes from '@/mixins/fields/classes.js'
import augmenters from '@/mixins/fields/augmenters'

import ODropdown from '@/components/elements/dropdown/Dropdown'
import ODropdownItem from '@/components/elements/dropdown/DropdownItem'

export default {
  components: {
    ODropdown,
    ODropdownItem,
  },
  mixins: [classes],
  props: {
    value: {
      type: Object,
      default: null,
    },
    format: {
      type: String,
      default: null,
    },
    augmenter: {
      type: Object,
      required: false,
      default: () => augmenters.timestamp,
    },
    group: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: true,
    },
    limit: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Array,
      default () {
        return []
      },
    },
    containerClass: {
      type: String,
      default: 'bg-white dark:bg-gray-900 dark:border-gray-800 flex items-center p-2 border rounded-md',
    },
    defaultStatusClass: {
      type: [String, Object, Array],
      default: 'bg-white dark:bg-gray-900',
    },
    warningStatusClass: {
      type: [String, Object, Array],
      default: 'border-yellow-400 bg-yellow-100 dark:bg-yellow-400/20',
    },
    errorStatusClass: {
      type: [String, Object, Array],
      default: 'border-red-300 bg-red-100 dark:bg-red-400/20',
    },
    successStatusClass: {
      type: [String, Object, Array],
      default: 'border-green-300 bg-green-100 dark:bg-green-400/20',
    },
  },
  data () {
    return {
      date: {
        start: null,
        finish: null,
      },
    }
  },
  computed: {
    _value: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },
    firstDayOfWeek () {
      const day = this.$auth?.info?.location?.startDayOfWeek?.value + 1

      return day === 8 ? 1 : day
    },
  },
  watch: {
    '_value.start' () {
      this.date.start = (this.augmenter) ? this.augmenter.input(this._value.start) : this._value.start
    },
    '_value.finish' () {
      this.date.finish = (this.augmenter) ? this.augmenter.input(this._value.finish) : this._value.finish
    },
  },
  mounted () {

  },
  methods: {
    handleStartChange (val) {
      const date = (this.augmenter) ? this.augmenter.output(val) : val
      const params = {
        old: this._value.start,
        new: date,
      }

      this._value.start = date
      this.$emit('change', params)
      this.$emit('changeStart', params)
    },
    handleFinishChange (val) {
      const date = (this.augmenter) ? this.augmenter.output(val) : val
      const params = {
        old: this._value.finish,
        new: date,
      }

      this._value.finish = date
      this.$emit('change', params)
      this.$emit('changeFinish', params)
    },
    set (preset) {
      if (preset) {
        this.handleStartChange(preset.value.start)
        this.handleFinishChange(preset.value.finish)
      }
    },
  },
}
</script>
