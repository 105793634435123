<template>
  <div class="relative max-w-xl w-full py-2 group ">
    <div>
      <input
        v-model="currentValue"
        type="range"
        :step="step"
        :min="min"
        :max="max"
        class="absolute pointer-events-none appearance-none z-20 h-2 w-full opacity-0 cursor-pointer"
      >

      <div class="relative z-10 h-2">
        <div class="absolute z-10 left-0 right-0 bottom-0 top-0 rounded-md bg-gray-100 dark:bg-gray-800" />

        <div
          class="absolute z-20 top-0 left-0 bottom-0 rounded-md bg-blue-300"
          :style="{
            width: `calc(${thumb}% + 4px) !important`
          }"
        />

        <div
          class="absolute z-30 w-4 h-4 top-1/2 transform -translate-y-1/2 -translate-x-1/2 left-0 bg-blue-400 rounded-full"
          :style="{
            left: `${thumb}% !important`
          }"
        >
          <div class="bg-gray-800 opacity-0 group-hover:opacity-85 py-1 px-2 text-white text-sm rounded absolute bottom-full transform left-1/2 -translate-x-1/2 mb-2 transition-opacity">
            {{ label }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
      required: true,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 10000,
    },
    step: {
      type: Number,
      default: 1,
    },
    labels: {
      type: Object,
      default: null,
    },
  },
  computed: {
    currentValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', Number(value))
      },
    },
    thumb () {
      return ((this.currentValue - this.min) / (this.max - this.min)) * 100
    },
    label () {
      const labels = this.labels
      const value = this.currentValue

      return labels ? labels[value] : value
    },
  },
}
</script>

<style>
input[type=range]::-webkit-slider-thumb {
  pointer-events: all;
  width: 24px;
  height: 24px;
  -webkit-appearance: none;
}
</style>
