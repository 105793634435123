import Model, { Request } from '../model'
import APIObject from '../object'

import Order from './order'
import ReservationCustomer from './reservationCustomer'

export default class FrontDesk extends Model {
  static modelName () {
    return 'frontDesk'
  }

  static reservationCustomerCheckinBuySingle (reservationCustomer, price) {
    const url = this.modelBaseURL() + '/reservationCustomerCheckinBuySingle?reservationCustomer=' + reservationCustomer.idReservationCustomer + '&price=' + price
    return this.requestItem(Request.get(url), FrontDeskReservationCustomerCheckin)
  }

  static reservationCustomerCheckinBuyPass (reservationCustomer, pass, price) {
    const url = this.modelBaseURL() + '/reservationCustomerCheckinBuyPass?reservationCustomer=' + reservationCustomer.idReservationCustomer + '&pass=' + pass.idPass + '&price=' + price
    return this.requestItem(Request.get(url), FrontDeskReservationCustomerCheckin)
  }

  static reservationAddCustomer (reservation, customer) {
    const url = this.modelBaseURL() + '/reservationAddCustomer?reservation=' + reservation.idReservation + '&date=' + reservation.date + '&customer=' + customer.idCustomer
    return this.requestItem(Request.get(url), ReservationCustomer)
  }
}

export class FrontDeskReservationCustomerCheckin extends APIObject {
  relations () {
    return {
      order: { type: Order },
      reservationCustomer: { type: ReservationCustomer },
    }
  }
}
