import Model, { Request } from '../model'

export default class CustomRole extends Model {
  static modelName () {
    return 'customRole'
  }

  objectID () {
    return this.name
  }

  relations () {
    return {
    }
  }

  static create ({ name, baseRole }) {
    const url = this.modelBaseURL() + '/create'
    const request = {
      name,
      baseRole,
    }
    return this.requestItem(Request.post(url, JSON.stringify(request)), this)
  }
}
