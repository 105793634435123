<template>
  <div class="absolute bottom-0 right-8 z-80000">
    <div
      class="w-72 h-12 bg-white border border-b-none rounded-t-lg shadow transition-all"
      :class="{ 'h-[70vh]': active }"
    >
      <div class="flex items-center justify-between px-4 h-12">
        <div class="flex items-center space-x-2">
          <p class="text-sm font-medium text-gray-800">
            {{ $t('general.messages') }}
          </p>
          <!-- eslint-disable-next-line vue/no-bare-strings-in-template -->
          <o-chip size="sm" variant="info" flat>
            20
          </o-chip>
        </div>
        <div class="flex items-center space-x-1">
          <o-button transparent icon="newChat" @click="handleNewChat" />
          <o-button transparent :icon="active ? 'chevronDown' : 'chevronUp'" :class="{ active : 'rotate-180' }" @click="handleToggle" />
        </div>
      </div>
      <div class="border-t" />
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      active: false,
    }
  },
  methods: {
    handleToggle () {
      this.active = !this.active
    },
  },
}
</script>
