<template>
  <div class="h-full flex flex-col">
    <div
      class="flex-shrink h-10 w-full dark:bg-gray-900 dark:text-white"
      :class="[
        'flex items-center flex-row',
        navClass
      ]"
    >
      <slot name="leading" />
      <template v-if="showArrows">
        <o-button flat size="sm" icon="chevronLeft" @click="handleMoveScroll(-1)" />
        <div class="py-2 pl-2 h-full">
          <span class="border-r h-full dark:border-gray-800" />
        </div>
      </template>
      <div class="flex-grow flex-shrink w-10 h-10">
        <o-scroll
          ref="scroll"
          x-axis
          :y-axis="false"
          :options="{
            visibility: 'hidden'
          }"
          draggable
        >
          <div
            class="flex relative h-full items-center px-2"
          >
            <template v-for="(tab, i) in tabs">
              <button
                v-if="tab.isVisible"
                :key="i"
                :ref="tab.hash"
                class="z-10 flex h-8 flex-shrink-0 items-center text-sm -mb-px leading-none hover:text-primary-600 focus:outline-none relative"
                :class="[
                  pill ? 'px-4 mr-px' : 'px-2 mr-2 md:mr-4',
                  tab.isActive ? (pill ? 'opacity-80 font-medium' : 'text-primary-600 font-medium dark:text-primary-400') : 'text-gray-600 dark:text-gray-300',
                  tab.tabClass
                ]"
                @click="selectTab(tab.hash, $event)"
              >
                <o-icon v-if="tab.icon" :icon="tab.icon" :size="16" class="lg:mr-3 p-2 md:p-0 hidden lg:block" />
                <span class="">{{ tab.name }}</span>

                <span v-if="tab.alert" class="block mb-px ml-2 bg-gray-300 dark:bg-white/10 text-gray-700 dark:text-gray-200 p-1 leading-none rounded text-xs font-medium">
                  {{ tab.alert }}
                </span>
              </button>
            </template>
            <span
              :class="[
                'absolute transition-all ease-in-out duration-200',
                pill ? 'h-8 bg-gray-100 rounded-md top-1/2 transform -translate-y-1/2 dark:bg-gray-900' : 'h-2px bottom-0 bg-primary-600 shadow'
              ]"
              :style="underline"
            />
          </div>
        </o-scroll>
      </div>
      <template v-if="showArrows">
        <div class="py-2 pr-2 h-full">
          <span class="border-r h-full dark:border-gray-800" />
        </div>
        <o-button flat size="sm" icon="chevronRight" @click="handleMoveScroll(1)" />
      </template>
      <slot name="trailing" />
    </div>

    <o-scroll
      :y-axis="scrollable"
      :class="[
        containerClass
      ]"
    >
      <slot ref="content" />
    </o-scroll>
  </div>
</template>

<script>
export default {
  props: {
    navClass: {
      type: String,
      default: 'border-b border-gray-200 px-5 dark:border-gray-800',
    },
    containerClass: {
      type: String,
      default: 'pt-4 pb-4',
    },
    scroll:{
      type: Boolean,
      default: false,
    },
    pill: {
      type: Boolean,
      default: false,
    },
    showArrows: {
      type: Boolean,
      default: true,
    },
    showHeader: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      children: [],
      activeTabHash: '',
      activeTabIndex: 0,
      lastActiveTabHash: '',
      firstTabIndex: 0,
      scrollable: false,
    }
  },
  computed: {
    tabs () {
      return this.getChildTabs(this.children)
    },
    underline () {
      const active = this.$refs[this.activeTabHash]

      let left = '0px'
      let width = '0px'

      if (active) {
        left = active[0].offsetLeft + 'px'
        width = active[0].getBoundingClientRect().width + 'px'
      }

      return {
        left,
        width,
      }
    },
    activeTab () {
      return this.findTab(this.activeTabHash)
    },
  },
  created () {
    this.children = this.$children
  },
  mounted () {
    if (this.tabs.length) {
      this.$nextTick(() => {
        let initial = 0

        // for (const tab of this.tabs) {
        for (const index in this.tabs) {
          const tab = this.tabs[index]
          tab.showHeader = this.showHeader

          if (tab.initial) {
            initial = index
          }
        }

        this.selectTab(this.tabs[initial].hash)

        if (this.$route?.query?.tab) {
          const query = this.$route?.query?.tab
          const hash = '#' + query

          if (hash) {
            this.selectTab(hash)
          }
        }
      })
    }
  },
  methods: {
    getChildTabs (children) {
      const tabs = []

      for (const child of children) {
        if (child._isTab) {
          tabs.push(child)
        }

        tabs.push(...this.getChildTabs(child.$children))
      }

      return tabs
    },
    findTab (hash) {
      return this.tabs.find(tab => tab.hash === hash)
    },
    selectTab (selectedTabHash, event) {
      // See if we should store the hash in the url fragment.
      const selectedTab = this.findTab(selectedTabHash)
      if (!selectedTab) {
        return
      }
      if (event && selectedTab.isDisabled) {
        event.preventDefault()
        return
      }
      if (this.lastActiveTabHash === selectedTab.hash) {
        this.$emit('clicked', {
          tab: selectedTab,
        })
        return
      }
      this.tabs.forEach((tab) => {
        tab.isActive = (tab.hash === selectedTab.hash)
      })
      this.$emit('changed', {
        tab: selectedTab,
      })
      this.activeTabHash = selectedTab.hash
      this.activeTabIndex = this.getTabIndex(selectedTabHash)
      this.lastActiveTabHash = this.activeTabHash = selectedTab.hash

      this.$nextTick(() => {
        this.scrollable = this.scroll || selectedTab?.scroll || false
      })
    },
    selectTabByIndex (index) {
      const hash = this.getTabHash(index)

      this.selectTab(hash)
    },
    setTabVisible (hash, visible) {
      const tab = this.findTab(hash)
      if (!tab) {
        return
      }
      tab.isVisible = visible
      if (tab.isActive) {
        // If tab is active, set a different one as active.
        tab.isActive = visible
        this.tabs.every((tab, index, array) => {
          if (tab.isVisible) {
            tab.isActive = true
            return false
          }
          return true
        })
      }
    },
    getTabIndex (hash) {
      const tab = this.findTab(hash)

      return this.tabs.indexOf(tab)
    },
    getTabHash (index) {
      const tab = this.tabs.find(tab => this.tabs.indexOf(tab) === index)

      if (!tab) {
        return
      }

      return tab.hash
    },
    getActiveTab () {
      return this.findTab(this.activeTabHash)
    },
    getActiveTabIndex () {
      return this.getTabIndex(this.activeTabHash)
    },
    handleMoveScroll (position = 1) {
      const element = this.$refs.scroll
      const current = this.activeTabIndex
      const tabs = this.tabs

      const index = current + position

      if (index >= 0 && index < tabs.length) {
        const hash = tabs[index].hash
        const tab = this.$refs[hash]

        if (tab) {
          element.scroll(tab[0], 500)
          this.selectTab(hash)
          this.activeTabIndex = index
        }
      }
    },
  },
}
</script>
