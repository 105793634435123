<template>
  <div class="pager-list">
    <div>
      <div v-if="header" :class="headerClass">
        <div class="flex items-center">
          <h4 class="text-lg mr-4 leading-none font-medium opacity-75">
            {{ header }}
          </h4>
          <o-chip v-if="list.loading || list.total" class="mb-0 flex items-center space-x-2">
            <o-loader v-if="list.loading" size="16px" />
            <template v-if="list.total">
              {{ list.total }}
            </template>
          </o-chip>
        </div>
        <slot name="action" />
      </div>
      <template v-if="list.items">
        <p v-if="list.items.length === 0" class="mb-8 text-gray-600 text-sm dark:text-gray-300 text-center mt-2">
          {{ list.loading ? $t('general.loadingEllipses') : empty }}
        </p>
        <div v-else>
          <slot :data="list.visibleItems" />

          <div class="text-center">
            <p class="mb-3">
              <small class="font-weight-light">{{ $t('components.elements.pager.showing', { count: list.visibleItems.length, total: list.total }) }}</small>
            </p>

            <o-button
              v-if="list.hasMore"
              flat
              outline
              size="sm"
              :loading="list.loading"
              @click="list.loadMore()"
            >
              {{ button }}
            </o-button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import * as API from '~/api'

export default {
  props: {
    list: {
      type: [Array, Object],
      default: null,
    },
    header: {
      type: String,
      default: null,
    },
    empty: {
      type: String,
      default () {
        return this.$t('components.elements.pager.empty')
      },
    },
    button: {
      type: String,
      default () {
        return this.$t('general.showMore')
      },
    },
    headerClass: {
      type: String,
      default: 'flex justify-between items-center mb-4',
    },
  },
}
</script>
