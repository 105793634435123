import Vue from 'vue'
import '@/static/imperavi/redactor/js/redactorx.js'
import '@/static/imperavi/redactor/js/definedlinks.js'
import '@/static/imperavi/redactor/js/clips.min.js'
import '@/static/imperavi/redactor/js/counter.min.js'
import '@/static/imperavi/redactor/css/redactorx.min.css'

Vue.component('Redactor', {
  props: {
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    config: {
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      redactorx: null,
    }
  },
  watch: {
    value (newValue, oldValue) {
      if (!this.redactorx.editor.isFocus()) {
        this.redactorx.app.$element.val(newValue)
      }
    },
  },
  mounted () {
    this.init()
  },
  beforeDestroy () {
    this.destroy()
  },
  methods: {
    destroy () {
      /// Call destroy on redactor to cleanup event handlers
      // eslint-disable-next-line no-undef
      RedactorX(this.$refs.redactorx, 'destroy')

      // unset instance for garbage collection
      this.redactorx = null
      this.$parent.redactorx = null
    },
    init () {
      const that = this
      const subscribe = {
        'editor.change' (event) {
          const html = event.get('html')
          that.handleInput(html)
          return html
        },
      }

      // extend config
      if (typeof this.config.subscribe === 'undefined') {
        Vue.set(this.config, 'subscribe', subscribe)
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.config.subscribe['editor.change'] = subscribe['editor.change']
      }

      // call Redactor X
      // eslint-disable-next-line no-undef
      const app = RedactorX(this.$refs.redactorx, this.config)

      // set instance
      this.redactorx = app
      this.$parent.redactorx = app
    },
    handleInput (val) {
      this.$emit('input', val)
    },
    insertImage (image) {
      this.redactorx.image.insert(image)
    },
  },
  template: '<textarea ref="redactorx" :name="name" :placeholder="placeholder" :value="value" />',
})
