<template>
  <div class="custom-fields">
    <slot :fields="fields" />
  </div>
</template>


<script>
import * as Fields from '@/components/fields'
import * as API from '@/api'
import augmenters from '@/mixins/fields/augmenters'

export default {
  components: {
    DateField: Fields.DateField,
    SelectField: Fields.SelectField,
    CheckboxField: Fields.CheckboxField,
    CheckboxGroupField: Fields.CheckboxGroupField,
    NumberField: Fields.NumberField,
    RadioGroupField: Fields.RadioGroupField,
    TextField: Fields.TextField,
    TextAreaField: Fields.TextAreaField,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  computed: {
    fields () {
      return this.data.map((field) => {
        return {
          component: this.resolve(field.dataType),
          label: field.name,
          props: {
            id: field.idCustomField,
            // label: field.name,
            value: this.value(field),
            // uncheckedValue: '0',
            model: field.value,
            options: this.options(field),
            augmenter: augmenters.timestamp,
          },
          events: {
            input: (e) => {
              field.value = e
            },
          },
        }
      })
    },
  },
  methods: {
    resolve (dataType) {
      switch (dataType) {
        case API.CustomFieldDataType.date:
          return Fields.DateField
        case API.CustomFieldDataType.bool:
          return Fields.CheckboxField
        case API.CustomFieldDataType.number:
          return Fields.NumberField
        case API.CustomFieldDataType.longtext:
          return Fields.TextAreaField
        case API.CustomFieldDataType.multiple:
          return Fields.SelectField
        default:
          return Fields.TextField
      }
    },
    value (field) {
      if (field.dataType === API.CustomFieldDataType.bool) {
        return true
      }

      if (!field.value && field.defaultValue) {
        field.value = field.defaultValue
      }

      return field.value
    },
    options (field) {
      if (field.dataType === API.CustomFieldDataType.multiple) {
        return field.multipleValues.map((value) => { return { value, label: value } })
      }

      return null
    },
  },
}

</script>
