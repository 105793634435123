<template>
  <o-dialog
    ref="dialog"
    :title="$t('components.auth.actions.changePin.title')"
    :info="info"
    :options="[
      { text: $t('general.cancel'), type: 'cancel' },
      { text: $t('general.save'), type: 'confirm', callback: confirm },
    ]"
    :portal="portal"
  >
    <template #heading>
      <img
        v-if="account.employee.image"
        :src="account.employee.image"
        class="mx-auto h-12 w-12 rounded-full mt-3"
      >
    </template>

    <div class="pt-4 text-left">
      <o-form ref="form">
        <segment-field
          v-model="none"
          :options="[
            { label: $t('auth.pin.change'), value: false },
            { label: $t('auth.pin.remove'), value: true }
          ]"
          class="block mb-6"
        />

        <o-field-group v-slot="{ status }" :label="$t('auth.password.label')" :name="$t('auth.password.label')" rules="required" class="block pb-3">
          <password-field v-model="password" :status="status" />
        </o-field-group>

        <o-field-group
          v-if="!none"
          v-slot="{ status }"
          :label="$t('auth.pin.new')"
          :name="$t('auth.pin.new')"
          rules="integer|required|pin:@Confirm"
          class="block pb-3"
        >
          <password-field v-model="newPin" :status="status" />
        </o-field-group>

        <o-field-group
          v-if="!none"
          v-slot="{ status }"
          :label="$t('auth.pin.confirm')"
          :name="$t('auth.pin.confirm')"
          rules="integer|required"
          class="block pb-3"
        >
          <password-field v-model="confirmPin" :status="status" />
        </o-field-group>
      </o-form>
    </div>
  </o-dialog>
</template>

<script>
import * as API from '~/api'

import {
  PasswordField,
  ToggleField,
  SegmentField,
} from '@/components/fields'

export default {
  components: {
    PasswordField,
    ToggleField,
    SegmentField,
  },
  props: {
    portal: {
      type: String,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      none: false,
      password: '',
      newPin: '',
      confirmPin: '',
    }
  },
  computed: {
    account () {
      return this.$auth.info
    },
    info () {
      const employee = this.account?.employee
      return `${employee?.firstName} ${employee?.lastName}`
    },
  },
  methods: {
    open () {
      this.none = !this.account?.employee?.pinHash
      this.password = ''
      this.newPin = ''
      this.confirmPin = ''

      this.$refs.dialog.open()
    },
    async confirm () {
      this.$emit('update:loading', true)

      try {
        const { none, password, newPin, confirmPin } = this
        const data = {
          password,
          newPin: none ? null : newPin,
          confirmPin: none ? null : confirmPin,
        }

        const success = await API.Auth.changePin(data)
        this.$emit('update:loading', false)

        if (success) {
          this.$notification.toast({
            title: this.$t('auth.pin.changeSuccess'),
            text: this.$t('components.auth.actions.changePin.successInfo'),
            variant: 'success',
          })

          this.$emit('success')

          return true
        }
      } catch (e) {
        this.$notification.error({ errors: e?.errors })

        this.$emit('error')
      }
    },
  },
}
</script>
