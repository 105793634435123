import Fuse from 'fuse.js'

import Model from '../model'
import Result from '../result'

import Component from '@/components/search/elements/Customer'

class Customer extends Model {
  constructor (options = {}) {
    super(options)

    this.name = 'Customer'
    this.component = Component

    this.items = []
  }

  async init (instance) {
    this.syncing = true
    await instance.$store.dispatch('customer/sync')
    const items = instance.$store.getters['customer/all']

    this.items = items
  }

  search (query) {
    const items = this.items

    const fuse = new Fuse(items, {
      keys: [
        {
          name: 'displayName',
          weight: 2,
        },
        'email',
        'phone',
      ],
      threshold: 0.3,
    })

    const results = fuse.search(query)
    const filtered = results.map(({ item }) => new CustomerResult({ data: item }))

    filtered.length = Math.min(filtered.length, 5)

    this.results = filtered
  }
}

class CustomerResult extends Result {
  get path () {
    return '/customer/' + this.data.idCustomer
  }
}

export default new Customer()
