<template>
  <nuxt-link :to="result.path" class="flex items-center leading-none py-1 text-gray-800 dark:text-gray-100">
    <o-avatar :items="[result.data]" class="mr-3" />
    <div>
      <p class="my-1">
        {{ result.data.displayName }}
      </p>
      <p class="text-gray-600 text-sm dark:text-gray-300">
        {{ result.data.email }}
      </p>
    </div>
  </nuxt-link>
</template>

<script>
import {
  OAvatar,
} from '@/components/elements'

export default {
  components: {
    OAvatar,
  },
  props: {
    result: Object,
  },
}
</script>
