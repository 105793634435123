<template>
  <div class="custom-field">
    <component
      :is="component"
      :id="custom.idCustomField"
      v-model="_value"
      :label="custom.label"
      :augmenters="augmenters.timestamp"
      :options="options"
    />
  </div>
</template>


<script>
import * as Fields from '@/components/fields'
import * as API from '@/api'
import augmenters from '@/mixins/fields/augmenters'

export default {
  name: 'OCustomField',
  components: {
    DateField: Fields.DateField,
    SelectField: Fields.SelectField,
    CheckboxField: Fields.CheckboxField,
    CheckboxGroupField: Fields.CheckboxGroupField,
    NumberField: Fields.NumberField,
    RadioGroupField: Fields.RadioGroupField,
    TextField: Fields.TextField,
    TextAreaField: Fields.TextAreaField,
  },
  props: {
    value: {
      type: [Object, Array, Boolean, String, Number],
    },
    custom: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      augmenters,
    }
  },
  computed: {
    _value: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },
    component () {
      switch (this.custom.dataType) {
        case API.CustomFieldDataType.date:
          return Fields.DateField
        case API.CustomFieldDataType.bool:
          return Fields.CheckboxField
        case API.CustomFieldDataType.number:
          return Fields.NumberField
        case API.CustomFieldDataType.longtext:
          return Fields.TextAreaField
        case API.CustomFieldDataType.multiple:
          return Fields.SelectField
        default:
          return Fields.TextField
      }
    },
    label () {
      return this.custom.name
    },
    options () {
      if (this.custom.dataType === API.CustomFieldDataType.multiple) {
        return this.custom.multipleValues
          .map((value) => {
            return { value, label: value }
          })
      }

      return null
    },
  },
}

</script>
