import Model, { Request } from '../model'
import Enum from '../enums'

export default class CustomPage extends Model {
  static modelName () {
    return 'customPage'
  }

  objectID () {
    return this.pageIdentifier
  }

  relations () {
    return {
      state: { type: CustomPageState },
    }
  }

  save ({ publish = false } = {}) {
    const wasNewRecord = this.isNewRecord

    let url
    let request

    if (wasNewRecord) {
      url = this.constructor.modelBaseURL() + '?publish=' + (publish ? 1 : 0)
      request = Request.jsonPost(url, this.requestJSON())
    } else {
      url = this.constructor.modelBaseURL() + '/' + this.objectID() + '?publish=' + (publish ? 1 : 0)
      request = Request.jsonPut(url, this.requestJSON())
    }

    const that = this
    return this.constructor.requestItem(request, this.constructor).catch((err) => {
      throw err
    }).then((item) => {
      that.saturated = new Date()

      for (const key in item) {
        that[key] = item[key]
      }

      return that
    })
  }

  get isStatusDraft () {
    return this.state === CustomPageState.draft
  }

  get isStatusPublished () {
    return this.state === CustomPageState.published
  }
}


export const CustomPageState = new Enum({
  draft: { value: 0, description: 'general.draft' },
  published: { value: 1, description: 'customPage.published' },
})
