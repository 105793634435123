<template>
  <container v-bind="container" :leading="_leading">
    <input
      v-model="currentValue"
      type="text"
      class="flex-grow px-2 h-full w-full bg-transparent text-sm"
      :max="max"
      :min="min"
      :step="step"
      v-bind="attributes"
      v-on="handlers"
      @keypress="handleKeyPress"
      @focusout="handleFocusOut"
    >

    <template #leading>
      <slot name="leading" />
    </template>

    <template #trailing>
      <slot name="trailing" />
    </template>
  </container>
</template>

<script>
import Container from './Container'

import classes from '@/mixins/fields/classes.js'
import attributes from '@/mixins/fields/attributes.js'
import methods from '@/mixins/fields/methods.js'
import input from '@/mixins/fields/input.js'

export default {
  components: {
    Container,
  },
  mixins: [attributes, classes, methods, input],
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
    increment: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 99999,
    },
    step: {
      type: String,
      default: 'any',
    },
    slim: {
      type: Boolean,
      default: false,
    },
    leading: {
      type: String,
      default: null,
    },
    defaultSizeClass: {
      type: [String, Object, Array],
      default: 'p-1',
    },
    largeSizeClass: {
      type: [String, Object, Array],
      default: 'p-4 text-lg',
    },
    smallSizeClass: {
      type: [String, Object, Array],
      default: 'p-px text-sm',
    },
  },
  computed: {
    currentValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },
    _leading () {
      if (this.leading) {
        return this.leading
      }

      return this.$auth.info.location.currencySymbol
    },
  },
  methods: {
    handleKeyPress (e) {
      const keyCode = (e.keyCode ? e.keyCode : e.which)
      const value = e.target.value
      const i = value.indexOf('.')

      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || i !== -1)) {
        // only allow numbers and one dot
        e.preventDefault()
      } else if (e.target.selectionStart === e.target.selectionEnd && (i > -1 || e.target.selectionEnd > i) && value.split('.')[1]?.length > 1) {
        // restrict to 2 decimal places
        e.preventDefault()
      }
    },
    handleFocusOut ($event) {
      $event.target.value = (Number($event.target.value) || 0).toFixed(2)
    },
  },
}
</script>

<style>
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
