<template>
  <li class="my-px">
    <o-panel-link
      class="w-full flex flex-row flex-grow items-center h-8 rounded-lg  relative hover:bg-white/10 transition-colors"
      :class="[
        active ? `bg-white/10 dark:bg-primary-${$auth.theme.dark.shade} dark:text-white/80` : '',
        collapse ? 'px-2 text-white/90 dark:text-white/90' : 'px-3 text-white/70 dark:text-white/70',
      ]"
      path="/alerts"
    >
      <div class="w-full flex items-center">
        <span v-if="!collapse && active" class="block h-full w-3 left-0 absolute p-1">
          <span class="block w-full h-full bg-primary rounded-full" />
        </span>

        <span
          class="flex items-center justify-center text-lg "
          :class="[
            collapse ? '-ml-px' : '',
            active ? '' : ''
          ]"
        >
          <o-icon
            icon="menuAlerts"
            :size="18"
          />
        </span>
        <span class="ml-4 text-base" :class="[ collapse? 'sm:hidden' : '']">{{ $t('general.alerts') }}</span>
      </div>

      <div
        class="flex items-center space-x-1"
        :class="[
          collapse ? 'absolute top-0 right-0' : '',
        ]"
      >
        <div v-if="counts && counts.unread" class="-mr-1 px-2 py-1 bg-primary-600 text-white rounded-lg text-xs">
          {{ Number(counts.unread) }}
        </div>
      </div>
    </o-panel-link>
  </li>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import * as API from '~/api'

export default {
  computed: {
    ...mapGetters({
      collapse: 'menu',
      counts: 'alert/counts',
    }),
    active () {
      return this.$route.hash === '#/alerts'
    },
    notifications () {
      return this.$auth.info?.panels || []
    },
  },
  mounted () {
    this.fetchAlertCounts()
  },
  methods: {
    ...mapActions({
      fetchAlertCounts: 'alert/fetchAlertCounts',
    }),
  },
}

</script>
