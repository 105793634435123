import localforage from 'localforage'
import * as API from '~/api'

export const state = () => ({
  customFields: [],
  sync: {
    running: false,
    progress: null,
    date: null,
    error: false,
  },
  hydrated: false,
  count: null,
})

export const mutations = {
  setSync (state, { running, progress, date }) {
    if (!(running === undefined)) {
      state.sync.running = running
    }
    if (!(progress === undefined)) {
      state.sync.progress = progress
    }
    if (!(date === undefined)) {
      state.sync.date = date
    }
  },
  updateCustomFields (state, customFields) {
    state.customFields = Object.freeze(API.CustomField.ensure(customFields))
  },
  setHydrated (state, hydrated) {
    state.hydrated = hydrated
  },
  setCount (state, count) {
    state.count = count
  },
}

export const getters = {
  isSyncing (state) {
    return state.sync.running
  },
  syncProgress (state) {
    return state.sync.progress
  },
  syncDate (state) {
    return state.sync.date
  },
  all (state) {
    return state.customFields
  },
  count (state) {
    return state.customFields.length
  },
  type: state => (modelType) => {
    return state.customFields
      .filter(customField => customField.modelType === modelType)
      .map(customField => API.CustomField.ensure({ ...customField, value: null }))
  },
  customer (state, getters) {
    return getters.type(API.CustomFieldModelType.customer)
  },
  reservation (state, getters) {
    return getters.type(API.CustomFieldModelType.reservation)
  },
  order (state, getters) {
    return getters.type(API.CustomFieldModelType.order)
  },
  employee (state, getters) {
    return getters.type(API.CustomFieldModelType.employee)
  },
  service (state, getters) {
    return getters.type(API.CustomFieldModelType.service)
  },
  product (state, getters) {
    return getters.type(API.CustomFieldModelType.product)
  },
  reservationCustomer (state, getters) {
    return getters.type(API.CustomFieldModelType.reservationCustomer)
  },
}

export const actions = {
  async sync ({ commit, state, rootState, rootGetters }, { force = false, change = false } = {}) {
    const guid = rootState.auth.active
    const info = rootGetters['auth/info']
    const features = info?.features

    if (features && !features.includes('custom-fields')) {
      return
    }

    // Check permission
    if (this.$grants && !this.$grants.all({ customField: ['view'] })) {
      console.log('no custom fields permissions')
      return
    }

    if (!state.hydrated || change) {
      const customFields = await localforage.getItem(guid + '.customFields.customFields')
      const sync = await localforage.getItem(guid + '.customFields.sync')

      if (customFields) {
        commit('updateCustomFields', customFields)
      } else {
        commit('updateCustomFields', [])
      }

      if (sync) {
        commit('setSync', sync)
      } else {
        commit('setSync', { date: null, progress: null, running: false })
      }

      commit('setHydrated', true)
    }

    const date = new Date()

    commit('setSync', { running: true, progress: 0 })

    const sinceDate = (force) ? null : state.sync.date

    const existing = {}

    if (!force) {
      for (let i = 0; i < state.customFields.length; ++i) {
        const customFields = state.customFields[i]
        existing[customFields.idCustomField] = customFields
      }
    }

    const errors = []

    try {
      await API.CustomField.listAll(sinceDate, (page, pageCount, list) => {
        if (list.items) {
          for (let i = 0; i < list.items.length; ++i) {
            const customFields = list.items[i]
            existing[customFields.idCustomField] = customFields
          }
        }

        if (list.deleted) {
          for (let i = 0; i < list.deleted.length; ++i) {
            const id = list.deleted[i]
            delete existing[id]
          }
        }

        commit('setSync', { running: true, progress: (page / pageCount) })
      })
    } catch (e) {
      if (e?.status === 304) {
        console.log('Custom field data is up to date')
      } else {
        errors.push(e)
      }
    } finally {
      commit('updateCustomFields', Object.values(existing))

      if (errors.length) {
        commit('setSync', { running: false, progress: null, date: null, error: true })
      } else {
        commit('setSync', { running: false, progress: null, date })
      }

      localforage.setItem(guid + '.customFields.customFields', state.customFields)
      localforage.setItem(guid + '.customFields.sync', state.sync)
    }

    /* await API.CustomField.listAll(sinceDate, (page, pageCount, list) => {
      if (list.items) {
        for (let i = 0; i < list.items.length; ++i) {
          const customFields = list.items[i]
          existing[customFields.idCustomField] = customFields
        }
      }

      if (list.deleted) {
        for (let i = 0; i < list.deleted.length; ++i) {
          const id = list.deleted[i]
          delete existing[id]
        }
      }

      commit('setSync', { running: true, progress: (page / pageCount) })
    }).catch((err) => {
      console.log('customFields.' +  err)
    }).then(() => {
      commit('updateCustomFields', Object.values(existing))

      commit('setSync', { running: false, progress: null, date })

      localforage.setItem(guid + '.customFields.customFields', state.customFields)
      localforage.setItem(guid + '.customFields.sync', state.sync)
    }) */
  },
}
