<template>
  <div class="o-button-group flex">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'OButtonGroup',
}
</script>
