<template>
  <component
    :is="componentToRender"
    class="w-full py-2 px-3 flex items-center justify-between transition duration-200 ease-in-out cursor-pointer"
    :class="[ disabled ? 'cursor-default' : 'hover:bg-gray-100 dark:hover:bg-gray-950/20' ]"
    v-bind="$attrs"
    :disabled="disabled"
    :href="href"
    @click.stop.prevent="click"
  >
    <div
      class="flex items-center w-full"
      :class="{
        'opacity-50' : disabled,
        'py-2px' : !icon
      }"
    >
      <div v-if="icon" class="pr-3 leading-none" :class="colour">
        <o-icon :icon="icon" :size="iconSize" />
      </div>
      <slot name="label">
        <div class="text-left pr-2">
          <p class="text-sm leading-none" :class="colour">
            <slot>
              {{ name }}
            </slot>
          </p>
          <p v-if="description" class="text-sm opacity-60 leading-none mt-1">
            {{ description }}
          </p>
        </div>
      </slot>
    </div>
    <o-icon v-if="external" icon="externalLink" :size="12" class="opacity-60" />
  </component>
</template>

<script>
import componentType from '~/mixins/elements/type'

export default {
  name: 'ODropdownItem',
  mixins: [componentType],
  inject: ['dropdown'],
  props: {
    name: {
      type: String,
      default: null,
    },
    description: {
      type: [Number, String],
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    iconSize: {
      type: Number,
      default: 14,
    },
    href: {
      type: String,
      default: null,
    },
    external: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: null,
    },
    preventDefault: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    colour () {
      const variant = this.variant

      switch (variant) {
        case 'info':
          return 'text-blue-700'
        case 'success':
          return 'text-green-700'
        case 'danger':
          return 'text-red-700'
        case 'warning':
          return 'text-yellow-700'
        default:
          return ''
      }
    },
  },
  methods: {
    click (e) {
      if (!this.disabled) {
        this.$emit('click')

        if (this.componentToRender === 'a' && !this.preventDefault) {
          window.open(this.href, this.target)
        }

        if (this.dropdown.autoHide) {
          this.dropdown.close()
        }
      }
    },
  },
}
</script>
