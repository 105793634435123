import APIObject from '../object'
import Enum from '../enums'
import { DateYMD } from '../dates'

import EmployeeBreak from './employeeBreak'
import Reservation from './reservation'
import ReservationService from './reservationService'
import ServiceResource from './serviceResource'

export default class OverviewBlock extends APIObject {
  relations () {
    return {
      date: { type: DateYMD },
      type: { type: OverviewBlockType },
      employeeFunction: { type: OverviewBlockEmployeeFunction },
      employeeBreak: { type: EmployeeBreak },
      reservation: { type: Reservation },
      reservationService: { type: ReservationService },
      serviceResources: { type: ServiceResource },
    }
  }
}

export const OverviewBlockEmployeeFunction = new Enum({
  0: { value: 0, description: 'api.models.overviewBlock.notSure' },
  primary: { value: 1, description: 'general.primary' },
  resource: { value: 2, description: 'general.resource' },
})

export const OverviewBlockType = new Enum({
  single: { value: 0, description: 'general.single' },
  singleFinishing: { value: 1, description: 'api.models.overviewBlock.singleFinishing' },
  multi: { value: 2, description: 'api.models.overviewBlock.multi' },
  multiFinishing: { value: 3, description: 'api.models.overviewBlock.multiFinishing' },
  // I thought I saw a 5 too but haven't been able to find it again - ?
})

