import Model, { Request } from '../model'
import Enum from '../enums'
import APIObject from '../object'

import Customer from './customer'
import Order from './order'

export default class GiftCard extends Model {
  static modelName () {
    return 'giftCard'
  }

  objectID () {
    return this.idGiftCard
  }

  relations () {
    return {
      customer: { type: Customer },
      order: { type: Order },
      // NOTE: Not sure if transactions comes through only for giftcards that have had transactions or if it isn't working
      transactions: { type: GiftCardTransaction },
      gift: { type: GiftCardGift },
    }
  }

  /* get displayNumber () {
    return (this.customNumber && this.customNumber.length > 0) ? this.customNumber : this.number
  } */

  matchesQuery (query) {
    if (this.displayNumber().includes(query)) {
      return true
    }

    if (this.customer && this.customer.displayName.includes(query)) {
      return true
    }

    return false
  }

  email ({ fromName, toName, toEmail, body } = {}) {
    const url = this.constructor.modelBaseURL() + '/email?id=' + this.objectID()
    const data = {
      fromName,
      toName,
      toEmail,
      body,
    }

    return this.constructor.requestSuccess(Request.post(url, JSON.stringify(data)))
  }

  static search ({ query } = {}) {
    const url = this.modelBaseURL() + '/search'
    const params = {
      query,
    }

    return this.requestList(Request.post(url, JSON.stringify(params)), GiftCard)
  }

  static outstanding () {
    const url = this.modelBaseURL() + '/outstanding'
    return this.requestData(Request.get(url))
  }

  get isGift () {
    return !!this.gift
  }

  get isGiftDelivered () {
    return !!this.gift && this.gift.delivered
  }
}

export class GiftCardTransaction extends APIObject {
  relations () {
    return {
      order: { type: Order },
    }
  }
}

export class GiftCardGift extends APIObject {
  relations () {
    return {
      // delivered: { type: GiftCardGiftDeliveredType }
    }
  }
}

export const GiftCardGiftDeliveredType = new Enum({
  undelivered: { value: 0, description: 'giftCard.undelivered' },
  delivered: { value: 1, description: 'general.delivered' },
  failed: { value: 2, description: 'giftCard.deliveryFailed' },
})
