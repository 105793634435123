export default {
  ovatu: {
    label: 'Ovatu (Default)',
    light: {
      primary: 'ovatugreen',
      gray: 'zinc',
      shade: 900,
      gradient: 'bg-gradient-to-tr from-ovatublue-900 to-ovatublue-900',
    },
    dark: {
      primary: 'ovatublue',
      gray: 'zinc',
      shade: 900,
      // gradient: 'bg-gradient-to-tr from-ovatu-blue-600 to-ovatu-blue-800'
    },
  },
  blush: {
    label: 'Blush',
    light: {
      primary: 'blush',
      gray: 'zinc',
      shade: 500,
    },
    dark: {
      primary: 'blush',
      gray: 'zinc',
      shade: 700,
    },
  },
  horizon: {
    label: 'Horizon',
    light: {
      primary: 'horizon',
      gray: 'zinc',
      shade: 700,
    },
    dark: {
      primary: 'horizon',
      gray: 'zinc',
      shade: 700,
    },
  },
  candyCrush: {
    label: 'Candy Crush',
    light: {
      primary: 'fuchsia',
      gray: 'neutral',
      shade: 500,
      gradient: 'bg-gradient-to-tr from-fuchsia-600 to-pink-600',
    },
    dark: {
      primary: 'fuchsia',
      gray: 'neutral',
      shade: 700,
    },
  },
  midnight: {
    label: 'Midnight',
    light: {
      primary: 'blue',
      gray: 'slate',
      shade: 950,
    },
    dark: {
      primary: 'sky',
      gray: 'slate',
      shade: 950,
    },
  },
  forest: {
    label: 'Forest',
    light: {
      primary: 'emerald',
      gray: 'stone',
      shade: 800,
    },
    dark: {
      primary: 'emerald',
      gray: 'stone',
      shade: 900,
    },
  },
  lipStick: {
    label: 'Lipstick',
    light: {
      primary: 'pink',
      gray: 'zinc',
      shade: 600,
    },
    dark: {
      primary: 'pink',
      gray: 'zinc',
      shade: 600,
    },
  },

  summerVibes: {
    label: 'Summer Vibes',
    light: {
      primary: 'orange',
      gray: 'zinc',
      shade: 500,
      gradient: 'bg-gradient-to-r from-orange-400 to-rose-400',
    },
    dark: {
      primary: 'orange',
      gray: 'slate',
      shade: 600,
    },
  },

  grape: {
    label: 'Grape',
    light: {
      primary: 'fuchsia',
      gray: 'zinc',
      shade: 950,
    },
    dark: {
      primary: 'fuchsia',
      gray: 'slate',
      shade: 950,
    },
  },


  mist: {
    label: 'Mist',
    light: {
      primary: 'mist',
      gray: 'zinc',
      shade: 500,
    },
    dark: {
      primary: 'mist',
      gray: 'slate',
      shade: 800,
    },
  },

  earth: {
    label: 'Earth',
    light: {
      primary: 'green',
      gray: 'stone',
      shade: 950,
    },
    dark: {
      primary: 'emerald',
      gray: 'neutral',
      shade: 800,
    },
  },

  hotStuff: {
    label: 'Hot Stuff',
    light: {
      primary: 'hot',
      gray: 'zinc',
      shade: 600,
    },
    dark: {
      primary: 'hot',
      gray: 'slate',
      shade: 800,
    },
  },

  sunDowners: {
    label: 'Sun Downers',
    light: {
      primary: 'sun',
      gray: 'zinc',
      shade: 500,
      gradient: 'bg-gradient-to-tr from-sun-800 to-sun-500',
    },
    dark: {
      primary: 'sun',
      gray: 'slate',
      shade: 800,
    },
  },

  surf: {
    label: 'Surf',
    light: {
      primary: 'surf',
      gray: 'zinc',
      shade: 600,
    },
    dark: {
      primary: 'surf',
      gray: 'slate',
      shade: 700,
    },
  },

  echo: {
    label: 'Echo',
    light: {
      primary: 'zinc',
      gray: 'zinc',
      shade: 950,
    },
    dark: {
      primary: 'zinc',
      gray: 'zinc',
      shade: 800,
    },
  },

  // contrast: {
  //   label: 'High Contrast',
  //   light: {
  //     primary: 'contrastPrimaryLight',
  //     gray: 'contrastGrayLight',
  //     shade: 950
  //   },
  //   dark: {
  //     primary: 'contrastPrimaryDark',
  //     gray: 'contrastGrayDark',
  //     shade: 950
  //   }
  // }

  christmas: {
    label: 'Christmas',
    light: {
      primary: 'green',
      gray: 'neutral',
      shade: 800,
      gradient: 'bg-gradient-to-bl from-[#B32C2C] to-[#512626]'
    },
    dark: {
      primary: 'merlot',
      gray: 'stone',
      shade: 900,
    }
  },

}
