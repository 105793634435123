import * as API from '~/api'

export const state = () => ({
  counts: {},
})

export const mutations = {
  setCounts (state, counts) {
    state.counts = counts
  },
}

export const getters = {
  counts (state) {
    return state.counts
  },
}

export const actions = {
  fetchCounts ({ commit }) {
    return API.CustomerMessage.counts().then((counts) => {
      commit('setCounts', counts)
    })
  },
}
