import Model from '../model'
import Enum from '../enums'

export default class Segment extends Model {
  static modelName () {
    return 'segment'
  }

  objectID () {
    return this.idSegment
  }

  relations () {
    return {
      onlinePaymentCaptureRequired: { type: SegmentOnlinePaymentCaptureRequired },
      onlineBookingAllowed: { type: SegmentOnlineBookingAllowed },
    }
  }

  get variant () {
    switch (this.colour) {
      case 1:
        return 'default'
      case 2:
        return 'success'
      case 3:
        return 'warning'
      case 4:
        return 'danger'
      case 5:
        return 'info'
      case 6:
        return 'dark'
      default:
        return 'default'
    }
  }
}

export const SegmentOnlinePaymentCaptureRequired = new Enum({
  system: { value: null, description: 'api.models.segment.systemDefault' },
  required: { value: 0, description: 'api.models.segment.notRequired' },
  notRequired: { value: 1, description: 'general.required' },
})

export const SegmentOnlineBookingAllowed = new Enum({
  allowed: { value: 1, description: 'general.allowed' },
  notAllowed: { value: 0, description: 'general.notAllowed' },
})
