import APIObject from '../object'
import Enum from '../enums'

import Employee from './employee'

export default class ServiceResource extends APIObject {
  objectID () {
    return this.idServiceResource
  }

  relations () {
    return {
      assignment: { type: ServiceResourceAssignment },
      type: { type: ServiceResourceType },
      defaultSelection: { type: ServiceResourceDefaultSelection },
      resources: { type: Employee },
    }
  }

  get isDefaultSelectionNone () {
    return this.defaultSelection === ServiceResourceDefaultSelection.none
  }

  get isDefaultSelectionFirstAvailable () {
    return this.defaultSelection === ServiceResourceDefaultSelection.firstAvailable
  }

  get isAssignmentReservation () {
    return this.assignment === ServiceResourceAssignment.reservation
  }

  get isAssignmentCustomer () {
    return this.assignment === ServiceResourceAssignment.customer
  }
}
export const ServiceResourceAssignment = new Enum({
  reservation: { value: 0, description: 'general.reservation' },
  customer: { value: 1, description: 'general.customer' },
})

export const ServiceResourceType = new Enum({
  resource: { value: 0, description: 'general.resource' },
  resourceType: { value: 1, description: 'general.group' },
})

export const ServiceResourceDefaultSelection = new Enum({
  none: { value: -1, description: 'resource.none' },
  firstAvailable: { value: 0, description: 'resource.firstAvailable' },
})
