import Model, { Request } from '../model'
import { FilterController, RemoteFilter } from '../filter'
import Customer from './customer'


export default class Review extends Model {
  static modelName () {
    return 'review'
  }

  objectID () {
    return this.idLocationReview
  }

  relations () {
    return {
      customer: { type: Customer },
    }
  }

  static filter ({ page, filters = {}, limit = 20 } = {}) {
    const url = this.modelBaseURL() + '/list?page=' + page + '&limit=' + limit
    const request = {
      ...filters,
    }

    return this.requestList(Request.post(url, JSON.stringify(request)), this)
  }

  approve () {
    const url = this.constructor.modelBaseURL() + '/approve?id=' + this.objectID()
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  unapprove () {
    const url = this.constructor.modelBaseURL() + '/unapprove?id=' + this.objectID()
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  feature () {
    const url = this.constructor.modelBaseURL() + '/feature?id=' + this.objectID()
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  unfeature () {
    const url = this.constructor.modelBaseURL() + '/unfeature?id=' + this.objectID()
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  respond (reply) {
    const url = this.constructor.modelBaseURL() + '/reply?id=' + this.objectID()

    const data = {
      reply,
    }

    return this.constructor.requestItem(Request.post(url, JSON.stringify(data)), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }
}

export class ReviewFilterController extends FilterController {
  constructor (context) {
    super(Review, { context })

    const { $i18n } = context

    this.query = new RemoteFilter('search', {
      label: $i18n.t('general.search'),
      info: $i18n.t('review.search'),
      component: 'TextField',
      getParams () {
        return this.value
      },
    })

    this.filters = []
  }
}
