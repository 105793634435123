<template>
  <portal :to="portal">
    <div>
      <transition name="fade" appear>
        <div
          v-if="_active"
          key="lightbox-overlay"
          class="fixed inset-0 w-full h-full z-80000 pointer-events-none"
        >
          <div
            class="absolute inset-0 w-full h-full bg-gray-900 opacity-75 pointer-events-auto"
            @click="close()"
          />

          <o-button
            class="pointer-events-auto float-right m-3"
            variant="dark"
            transparent
            icon="x"
            :icon-size="22"
            @click="close()"
          />

          <div class="flex items-center justify-center w-full h-full absolute inset-0">
            <slot item="current">
              <div class="rounded overflow-hidden max-h-90vh max-w-90vw">
                <img
                  v-show="!isLoading"
                  :src="current.url"
                  @load="loaded"
                >
                <div v-if="isLoading" class="min-w-140 min-h-100 bg-gray-800 flex items-center justify-center">
                  <o-loader />
                </div>
                <div class="bg-gray-900 text-white p-2">
                  <p v-if="current.title">
                    {{ current.title }}
                  </p>
                  <p v-if="current.created" class="text-sm">
                    {{ current.created }}
                  </p>
                </div>
              </div>
            </slot>
          </div>

          <div class="flex items-center justify-between h-full w-full inset-0 absolute p-2">
            <o-button class="pointer-events-auto py-6" variant="dark" icon="chevronLeft" :icon-size="18" @click="previous()" />
            <o-button class="pointer-events-auto py-6" variant="dark" icon="chevronRight" :icon-size="18" @click="next(1)" />
          </div>
        </div>
      </transition>
    </div>
  </portal>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    index: {
      type: Number,
      default: 1,
    },
    items: {
      type: Array,
      required: true,
    },
    portal: {
      type: String,
      default: 'dialog-container',
    },
  },
  data () {
    return {
      isLoading: true,
    }
  },
  computed: {
    _active: {
      get () {
        return this.active
      },
      set (value) {
        this.$emit('update:active', value)
      },
    },
    _index: {
      get () {
        return this.index
      },
      set (value) {
        this.$emit('update:index', value)
      },
    },
    current () {
      return this.items[this.index] || this.items[0]
    },
  },
  watch: {
    index () {
      this.isLoading = true
    },
  },
  methods: {
    next () {
      const index = this._index + 1

      if (index > this.items.length - 1) {
        this._index = 0
      } else {
        this._index = index
      }
    },
    previous () {
      const index = this._index - 1

      if (index < 0) {
        this._index = this.items.length - 1
      } else {
        this._index = index
      }
    },
    close () {
      this._active = false
    },
    loaded () {
      this.isLoaded = true
      this.isLoading = false
    },
  },
}
</script>
