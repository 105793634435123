<template>
  <div class="flex items-center">
    <template v-for="(avatar, index) in avatars">
      <img
        v-if="avatar.image"
        :key="index"
        :class="classes"
        :src="avatar.image"
      >
      <div
        v-else
        :key="index"
        class="pointer-events-none"
        :class="classes"
      >
        <p>{{ avatar.initials }}</p>
      </div>
    </template>

    <template v-if="limit < items.length">
      <o-tooltip trigger="click" base-class="o-tooltip p-0 bg-white">
        <div class="ml-2 leading-none text-sm text-gray-500 dark:text-gray-400">
          + {{ items.length - limit }}
        </div>

        <template #content>
          <ul class="bg-white text-gray-700 text-sm -m-3 rounded-md overflow-hidden block p-4">
            <li
              v-for="(avatar, index) in items"
              :key="index"
              class="flex items-center space-x-3 my-1"
            >
              <img
                v-if="avatar.image"
                :key="index"
                :class="classes"
                :src="avatar.image"
              >
              <div
                v-else
                :key="index"
                class="pointer-events-none"
                :class="classes"
              >
                <p>{{ avatar.initials }}</p>
              </div>

              <span>{{ avatar.label }}</span>
            </li>
          </ul>
        </template>
      </o-tooltip>
    </template>
  </div>
</template>

<script>
import colours from '~/mixins/elements/colours'

export default {
  name: 'OAvatar',
  mixins: [colours],
  props: {
    items: {
      type: Array,
      required: true,
    },
    size: {
      type: [String, Boolean],
      default: 'md',
    },
    baseClass: {
      type: String,
      default: 'first:ml-0 border-white flex items-center justify-center flex-shrink-0',
    },
    rounded: {
      type: String,
      default: 'rounded-full',
    },
    border: {
      type: Boolean,
      default: true,
    },
    variant: {
      type: String,
      default: 'default',
    },
    flat: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 5,
    },
  },
  computed: {
    avatars () {
      const items = this.items
      const images = items
        .filter(item => item)
        .map((item) => {
          return {
            image: item.image,
            initials: item.initials,
          }
        })

      images.length = Math.min(images.length, this.limit)

      return [...new Set(images)]
    },
    classes () {
      const classes = [
        this.baseClass,
        this.rounded,
      ]

      if (this.size === 'sm') {
        classes.push('w-6 h-6 -ml-2 text-xs')
      } else if (this.size === 'md') {
        classes.push('w-7 h-7 -ml-3 text-xs')
      } else if (this.size === 'lg') {
        classes.push('w-14 h-14 -ml-6 text-base')
      } else if (this.size === 'xl') {
        classes.push('w-18 h-18 -ml-9 text-lg')
      } else if (this.size === '2xl') {
        classes.push('w-24 h-24 -ml-9 text-lg')
      }

      if (this.border) {
        if (this.size === 'sm') {
          classes.push('border')
        } else if (this.size === 'md') {
          classes.push('border')
        } else if (this.size === 'lg') {
          classes.push('border-2')
        } else if (this.size === 'xl') {
          classes.push('border-2')
        }
      }

      return this.variants(classes)
    },
  },
}
</script>
