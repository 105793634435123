<template>
  <div>
    <slot v-if="unlocked" />

    <div v-if="!unlocked && owner" :class="baseClass">
      <div class="text-center">
        <h2 class="font-bold text-secondary text-xl tracking-tight mb-2">
          {{ $t('components.elements.paywall.notAvailable', { label: label || metaName }) }}
        </h2>
        <p class="opacity-70 mb-8">
          {{ $t('components.elements.paywall.upgrade', { label: label || metaName }) }}
        </p>
        <o-button to="/account/billing">
          {{ $t('components.elements.paywall.changePlan') }}
        </o-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    features: {
      type: Array,
      default: null,
    },
    baseClass: {
      type: String,
      default: 'h-full w-full absolute inset-0 flex items-center justify-center bg-white dark:bg-gray-900',
    },
  },
  computed: {
    unlocked () {
      let features = null

      if (this.features) {
        features = this.features
      } else if (this.$route.path) {
        const path = this.$route.path
        features = this.$features.route(path)
      }

      if (!features) {
        return true
      }

      const allowed = this.$features.all(features)

      return allowed
    },
    owner () {
      const employee = this.$auth.info.employee
      return employee.role.isRoleTypeOwner
    },
    metaName () {
      return this.$route?.meta?.name || this.$t('components.elements.paywall.thisFeature')
    },
  },
}
</script>
