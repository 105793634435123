import Model, { Request } from '../model'
import Enum from '../enums'

import date, { plugin as $date } from '../../plugins/date'

export default class Voucher extends Model {
  static modelName () {
    return 'voucher'
  }

  objectID () {
    return this.idVoucher
  }

  relations () {
    return {
      customerType: { type: VoucherCustomerType },
      discountType: { type: VoucherDiscountType },
      discountTaxType: { type: VoucherTaxType },
      multipleMode: { type: VoucherMultipleMode },
    }
  }

  static filter (page = 1, filter) {
    const url = this.modelBaseURL() + '/?page=' + page + '&filter=' + filter
    return this.requestList(Request.get(url), this)
  }

  static batchCreate (data) {
    const url = this.modelBaseURL() + '/batch'

    const payload = []

    for (const item of data) {
      payload.push(item.requestJSON())
    }

    return this.requestList(Request.post(url, payload), this)
  }

  get validityLabel () {
    const { validStartTime, validEndTime, validStartDate, validEndDate, validMonday, validTuesday, validWednesday, validThursday, validFriday, validSaturday, validSunday } = this

    let validityTimesLabel = ''

    if (validStartTime && validEndTime) {
      validityTimesLabel = window.$nuxt.$t('api.models.voucher.betweenTime', { start: $date.minutes(validStartTime), end: $date.minutes(validEndTime) })
    } else if (validStartTime) {
      validityTimesLabel = window.$nuxt.$t('api.models.voucher.afterTime', { time: $date.minutes(validStartTime) })
    } else if (validEndTime) {
      validityTimesLabel = window.$nuxt.$t('api.models.voucher.beforeTime', { time: $date.minutes(validEndTime) })
    }

    let validityDaysLabel = ''

    if (validMonday && validTuesday && validWednesday && validThursday && validFriday && validSaturday && validSunday) {
      // all days
      validityDaysLabel = window.$nuxt.$t('api.models.voucher.allDays')
    } else if (validMonday && validTuesday && validWednesday && validThursday && validFriday && !validSaturday && !validSunday) {
      // week days
      validityDaysLabel = window.$nuxt.$t('api.models.voucher.weekdays')
    } else if (!validMonday && !validTuesday && !validWednesday && !validThursday && !validFriday && validSaturday && validSunday) {
      // weekends
      validityDaysLabel = window.$nuxt.$t('api.models.voucher.weekends')
    } else {
      const daysNames = []
      if (validMonday) { daysNames.push(window.$nuxt.$t('datetime.days.monday.plural')) }
      if (validTuesday) { daysNames.push(window.$nuxt.$t('datetime.days.tuesday.plural')) }
      if (validWednesday) { daysNames.push(window.$nuxt.$t('datetime.days.wednesday.plural')) }
      if (validThursday) { daysNames.push(window.$nuxt.$t('datetime.days.thursday.plural')) }
      if (validFriday) { daysNames.push(window.$nuxt.$t('datetime.days.friday.plural')) }
      if (validSaturday) { daysNames.push(window.$nuxt.$t('datetime.days.saturday.plural')) }
      if (validSunday) { daysNames.push(window.$nuxt.$t('datetime.days.sunday.plural')) }

      validityDaysLabel = daysNames.join(window.$nuxt.$t('general.listSeparator'))
    }

    let validityDatesLabel = ''

    if (validStartDate && validEndDate) {
      validityDatesLabel = window.$nuxt.$t('api.models.voucher.fromDate', { start: $date.format(validStartDate * 1000, $date.presets.short), end: $date.format(validEndDate * 1000, $date.presets.short) })
    } else if (validStartDate) {
      validityDatesLabel = window.$nuxt.$t('api.models.voucher.afterDate', { date: $date.format(validStartDate * 1000, $date.presets.short) })
    } else if (validEndDate) {
      validityDatesLabel = window.$nuxt.$t('api.models.voucher.beforeDate', { date: $date.format(validEndDate * 1000, $date.presets.short) })
    }

    if (validityTimesLabel && validityDatesLabel) {
      return window.$nuxt.$t('api.models.voucher.validTimesDaysDates', { validityTimesLabel, validityDaysLabel, validityDatesLabel })
    } else if (validityTimesLabel) {
      return window.$nuxt.$t('api.models.voucher.validTimesDays', { validityTimesLabel, validityDaysLabel })
    } else if (validityDatesLabel) {
      return window.$nuxt.$t('api.models.voucher.validDaysDates', { validityDaysLabel, validityDatesLabel })
    } else {
      return window.$nuxt.$t('api.models.voucher.validDays', { validityDaysLabel })
    }
  }

  get publishedLabel () {
    let publishedDatesLabel = ''
    const { startDate, endDate } = this

    if (startDate && endDate) {
      publishedDatesLabel = window.$nuxt.$t('api.models.voucher.fromDate2', { start: $date.format(startDate * 1000, $date.presets.short), end: $date.format(endDate * 1000, $date.presets.short) })
    } else if (startDate) {
      publishedDatesLabel = window.$nuxt.$t('api.models.voucher.after2', { date: $date.format(startDate * 1000, $date.presets.short) })
    } else if (endDate) {
      publishedDatesLabel = window.$nuxt.$t('api.models.voucher.before2', { date: $date.format(endDate * 1000, $date.presets.short) })
    } else {
      publishedDatesLabel = window.$nuxt.$t('api.models.voucher.ongoing')
    }

    return publishedDatesLabel
  }

  get isDiscountTypeFixed () {
    return this.discountType === VoucherDiscountType.fixed
  }

  get isDiscountTypePercentage () {
    return this.discountType === VoucherDiscountType.percentage
  }

  get isCustomerTypeAll () {
    return this.customerType === VoucherCustomerType.all
  }

  get isCustomerTypeNew () {
    return this.customerType === VoucherCustomerType.new
  }

  get isCustomerTypeExisting () {
    return this.customerType === VoucherCustomerType.existing
  }

  get isCustomerTypeSegments () {
    return this.customerType === VoucherCustomerType.segments
  }
}

export const VoucherDiscountType = new Enum({
  fixed: { value: 0, description: 'general.fixed' },
  percentage: { value: 1, description: 'general.percentage' },
})

export const VoucherTaxType = new Enum({
  post: { value: 0, description: 'general.post' },
  pre: { value: 1, description: 'general.pre' },
})

export const VoucherCustomerType = new Enum({
  all: { value: 0, description: 'general.all' },
  new: { value: 1, description: 'general.new' },
  existing: { value: 2, description: 'api.models.voucher.existing' },
  segments: { value: 3, description: 'general.segments' },
})

export const VoucherMultipleMode = new Enum({
  any: { value: 0, description: 'api.models.voucher.any' },
  singleFirst: { value: 1, description: 'api.models.voucher.singleFirst' },
  singleLowest: { value: 2, description: 'api.models.voucher.singleLowest' },
  singleHighest: { value: 3, description: 'Highest value service only' },
})
