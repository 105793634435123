import cookie from 'cookie'

export default (context, inject) => {
  const plugin = {
    all () {
      const cookieStr = process.client
        ? document.cookie
        : context.req.headers.cookie

      return cookie.parse(cookieStr || '') || {}
    },
    set (key, value) {
      if (process.server && !context.res) {
        return
      }

      const _key = key
      const _value = value
      const _options = {
        path: '/',
      }

      if (typeof _value === 'undefined' || _value === null) {
        _options.maxAge = -1
      }

      const serializedCookie = cookie.serialize(_key, _value, _options)

      if (process.client) {
        // Set in browser
        document.cookie = serializedCookie
      } else if (process.server && context.res && !context.res.headersSent) {
        // Send Set-Cookie header from server side
        const cookies = (context.res.getHeader('Set-Cookie')) || []
        cookies.unshift(serializedCookie)
        context.res.setHeader(
          'Set-Cookie',
          cookies.filter(
            (v, i, arr) =>
              arr.findIndex(val =>
                val.startsWith(v.substr(0, v.indexOf('='))),
              ) === i,
          ),
        )
      }

      return value
    },
    get (key) {
      if (process.server && !context.req) {
        return
      }

      const _key = key
      const cookies = this.all()

      const value = cookies[_key]
        ? decodeURIComponent(cookies[_key])
        : undefined

      return value
    },
    remove (key) {
      this.set(key, undefined)
    },
  }

  inject('cookies', plugin)
}
