const formats = {
  ja_jp: {
    locale: 'ja-JP',
    code: 'JPY',
  },
  id_id: {
    locale: 'id-ID',
    code: 'IDR',
  },
}

export default (context, inject) => {
  const plugin = {
    format (amount = 0) {
      console.log(context.$auth.info.location)
      const symbol = this.symbol
      const value = Number(amount)

      const locale = context.$auth.info.location.locale
      const format = formats[locale]

      if (format) {
        const currencyFractionDigits = new Intl.NumberFormat(format.locale, {
          style: 'currency',
          currency: format.code
        }).resolvedOptions().maximumFractionDigits;

        const number = (value).toLocaleString(format.locale, {
          minimumFractionDigits: currencyFractionDigits 
        });

        return `${symbol}${number}`
      }

      const number = (value || 0).toFixed(2)
      const negative = number < 0

      if (negative) {
        return `-${symbol}${Math.abs(number)}`
      }

      return `${symbol}${number}`
    },
    get symbol () {
      return context.$auth.info.location.currencySymbol
    },
  }

  inject('currency', plugin)
}
