export default {
  api: {
    models: {
      audit: {
        event: 'Event',
      },
      billing: {
        creditCard: 'Credit card',
        card: 'Card',
        prepaid: 'Pre-paid',
        subscriptionPayment: 'Subscription Payment',
        smsCredit: 'SMS Credit',
        smsCreditAuto: 'SMS Credit (auto-topup)',
        braintree: 'Braintree',
        classic: 'Classic',
        next: 'Next',
      },
      customerMessage: {
        order: 'Order',
        customerForm: 'Customer form',
        customerCourse: 'Customer course',
        customerPass: 'Customer pass',
        failed: 'Failed',
        doNotSend: 'Do not send',
        origin: 'Origin',
        newReservation: 'New reservation',
        newReservationConfirmed: 'New reservation confirmed',
        newReservationRejected: 'New reservation rejected',
        newReservationCancelled: 'New reservation cancelled',
        newReservationReminder: 'New reservation reminder',
        reservationMoved: 'Reservation moved',
        reservationCancelled: 'Reservation cancelled',
        reservationReminder: 'Reservation reminder',
        passwordReset: 'Password reset',
        receivedCampaign: 'Received campaign',
        general: 'General',
        loginCode: 'Login code',
        newOrder: 'New order',
      },
      customerNote: {
        label: 'Customer Note',
        formula: 'Formula',
        alert: 'Alert',
      },
      customerPass: {
        onlyLatest: 'Only latest',
        onlyLatestInfo: 'Include only the latest pass for each customer',
        latestPerCustomer: 'Latest per customer',
        passes: 'Customer passes using the selected passes',
        allPasses: 'All passes',
        expired: 'Include customer passes that are active/expired',
        usesRemaining: 'Customer passes that have uses remaining',
        hasUses: 'Has uses',
        hasNoUses: 'Has no uses',
        deleted: 'Include customer passes that have been deleted',
      },
      customField: {
        groupCustomer: 'Group customer',
      },
      employeeHour: {
        a: 'A',
        b: 'B',
        c: 'C',
        d: 'D',
      },
      overviewBlock: {
        notSure: 'Not sure',
        singleFinishing: 'SingleFinishing',
        multi: 'Multi',
        multiFinishing: 'MultiFinishing',
      },
      recurrence: {
        dayOfWeek: 'On day of week',
        dayOfMonth: 'On day of month',
        lastDayOfMonth: 'On last day of month',
        onDate: 'On date',
        first: '1st',
        second: '2nd',
        third: '3rd',
        fourth: '4th',
        skip: 'Skip',
        doubleBook: 'Double book',
        book: 'Book',
        onDay: 'on {day}',
        onOrdinalDay: 'on the {ordinal} {day}',
        onOrdinalDayOfTheMonth: 'on the {ordinal} day of the month',
        onLastDayOfTheMonth: 'on the last day of the month',
        onMonthOrdinal: 'on {month} {ordinal}',
        endingOnDate: 'ending on {date}',
        endingAfterRepeats: 'ending after {repeats} repeat | ending after {repeats} repeats',
        label: 'Repeats every {interval} {on}, {ending}',
      },
      referral: {
        invited: 'Invited',
        signedUp: 'Signed up!',
        validated: 'Validated!',
        paid: 'Paid!',
        invalid: 'Invalid',
        pending: 'Pending',
      },
      reservationCancellationType: {
        both: 'Both',
        internal: 'Internal',
      },
      segment: {
        systemDefault: 'System default',
        notRequired: 'Not required',
      },
      settings: {
        failing: 'Failing',
      },
      update: {
        news: 'News',
        article: 'Article',
      },
      upload: {
        document: 'Document',
      },
      voucher: {
        betweenTime: 'between {start} and {end}',
        afterTime: 'after {time}',
        beforeTime: 'before {time}',
        allDays: 'all days',
        weekdays: 'weekdays',
        weekends: 'weekends',
        fromDate: 'from {start} to {end}',
        afterDate: 'after {date}',
        beforeDate: 'before {date}',
        validTimesDaysDates: 'Valid {validityTimesLabel} on {validityDaysLabel} {validityDatesLabel}',
        validTimesDays: 'Valid {validityTimesLabel} on {validityDaysLabel}',
        validDaysDates: 'Valid on {validityDaysLabel} {validityDatesLabel}',
        validDays: 'Valid on {validityDaysLabel}',
        fromDate2: 'From {start} to {end}',
        after2: 'After {date}',
        before2: 'Before {date}',
        ongoing: 'Ongoing',
        existing: 'Existing',
        any: 'All valid services',
        singleFirst: 'First service only',
        singleLowest: 'Lowest value service only',
        singleHighest: 'Highest value service only',
      },
    },
    model: {
      created: '{model} Created',
      updated: '{model} Updated',
      deleted: '{model} Deleted',
      authenticationError: 'Authentication Error',
      authenticationErrorInfo: 'Your account details have timed out. Please log back in.',
      permissionError: 'Permission Error',
      permissionErrorInfo: 'You are not authorised to perform this action',
    },
  },
  components: {
    account: {
      actions: {
        changeOwner: {
          warning: 'This will transfer ownership of your Ovatu account. You will no longer be the owner of this account and any other changes will need to be made from the new owners account.',
          selectUser: 'Select the user you would like to transfer ownership to',
          confirm: 'Yes, transfer account ownership',
          ownerChanged: 'Owner changed',
          accountOwnerUpdated: 'The owner of this Ovatu account has been updated',
        },
        payment: {
          select: 'Select payment method',
          save: 'Save payment method',
          success: 'Updated billing details',
        },
        plan: {
          showingPrices: 'Showing prices for {employees} employees',
          firstEmployee: 'First employee is {price}',
          additionalEmployee: 'Each additional employee is {price}',
          success: 'Changed account plan',
        },
        planDetails: {
          planDetails: 'Plan details',
          planPrice: 'Plan price',
          includesEmployee: 'includes 1 employee',
          employeesAtPriceEach: '+ {count} Employees @ {currencySymbol}{price} each',
          discountedPrice: 'Discounted price',
        },
        policy: {
          accept: 'Accept {title}',
        },
      },
      elements: {
        billing: {
          taxInvoices: 'Tax invoices',
          previousPayments: 'Previous payments',
          refunded: 'Refunded: {currency} {refunded}',
          viewInvoice: 'View invoice',
          invoiceNumber: 'Invoice No.',
          dateIssued: 'Date Issued',
          discount: 'Discount: {name}',
          discounted: 'Discounted: {name}',
          paid: 'PAID',
          from: 'from',
          to: 'to',
          item: 'ITEM',
          amount: 'AMOUNT',
          payment: 'PAYMENT',
        },
        sync: {
          forceSync: 'Force sync',
        },
      },
      pages: {
        usersAndRoles: {
          label: 'Users & Roles',
          info: 'Manage admins users, employees and role permissions',
        },
        planAndBilling: {
          label: 'Plan & Billing',
          info: 'Manage your plan and subscription',
        },
        referral: {
          label: 'Referral',
          info: 'Invite your friends to Ovatu and receive a gift',
        },
        security: {
          label: 'Security',
          info: 'PIN Settings and IP Blacklists',
        },
        cachedData: {
          label: 'Cached data',
          info: 'View status of locally stored account data',
        },
        privacy: {
          label: 'Privacy',
          info: 'Privacy, marketing and EU cookie consent',
        },
        integrations: {
          label: 'Integrations',
          info: 'Connect your Ovatu account with other apps',
        },
        sessions: {
          label: 'Sessions',
          info: 'Account access activity',
        },
        calendarFeeds: {
          label: 'Calendar feeds',
          info: 'Add Ovatu appoints to your calendar',
        },
        policy: {
          label: 'Policy',
          info: 'Ovatu terms & conditions',
        },
        exports: {
          label: 'Exports',
          info: 'Download export file requests',
        },
      },
    },
    adminUser: {
      actions: {
        changeEmail: {
          info: 'Change the email address for this user',
          newUserEmail: 'The new email address for this user',
          successInfo: 'The details for this user has been updated',
        },
        changeRole: {
          userPermissionRole: 'User permission role',
        },
        delete: {
          title: 'Delete user',
          info: `
            Are you sure you want to delete user: <br>
            <strong>{name}</strong> (#{id})<br><br>
            The user will be removed permanently from your account.
          `,
          success: 'Deleted user',
          successInfo: 'Deleted user record for: {name}',
        },
        image: {
          title: 'Upload User Image',
          info: 'Edit user image for: <strong>{name}</strong>',
          infoWithName: 'Edit user image',
          updateSuccess: 'Added photo',
        },
        resetPassword: {
          info: `
            Reset password for: <br>
            <strong>{name}</strong> (#{id})<br>
          `,
        },
      },
      edit: {
        details: {
          selectRole: 'Set the users permissions by selecting a role',
        },
      },
    },
    asset: {
      elements: {
        info: {
          filename: 'Face_mask.png',
          beauty: 'Beauty',
          timestamp: '20 March 2023 at 14:57',
        },
        listItem: {
          filename: 'Beauty_facemask.png',
          timestamp: '20 March 2023 at 14:58',
        },
      },
    },
    auth: {
      actions: {
        changePin: {
          title: 'Set PIN',
          successInfo: 'Successfully changed your PIN',
        },
      },
      elements: {
        lock: {
          inactivitySessionLock: 'Session will lock in {seconds} due to inactivity',
          seconds: 's',
          unlockOrSwitchUser: 'Unlock to access user account or {switchUser}',
          unlock: 'Unlock',
        },
        userSettings: {
          title: 'User settings',
          homePage: 'Home page',
          homePageInfo: 'Which page should we use as your home page',
        },
      },
    },
    brand: {
      actions: {
        delete: {
          title: 'Delete brand',
          info: `
            Are you sure you want to delete brand: <br>
            <strong>{name}</strong> (#{id})<br><br>
            The brand will be removed permanently from your account.
          `,
          success: 'Deleted brand',
          successInfo: 'Deleted brand record for: {name}',
          error: 'Error deleting brand',
          errorInfo: 'Unable to delete brand: {name}',
        },
      },
    },
    calendarFeed: {
      actions: {
        create: {
          title: 'Create new {description} calendar feed',
          success: 'Created calendar feed',
          error: 'Error creating calendar feed',
        },
        delete: {
          title: 'Delete calendar feed',
          info: `
            Are you sure you want to delete this calendar feed: <br>
            <strong>{name}</strong>
          `,
          success: 'Deleted calendar feed',
          successInfo: 'Deleted calendar feed record for: {name}',
          error: 'Error deleting calendar feed',
          errorInfo: 'Unable to delete calendar feed: {name}',
        },
      },
    },
    campaign: {
      actions: {
        addAttachment: {
          fileNameInfo: 'A label for this file in your asset library',
          success: 'Uploaded attachment',
          successInfo: 'Uploaded email attachment to campaign',
        },
        cancelScheduled: {
          title: 'Cancel schedules campaign',
          info: 'Campaign will no longer be sent at scheduled time and will be moved to draft.',
          success: 'Cancelled scheduled campaign',
        },
        delete: {
          title: 'Delete draft campaign',
          info: 'Are you sure you want to delete this draft?',
          success: 'Deleted campaign',
          successInfo: 'Deleted draft campaign',
        },
        resend: {
          title: 'Resend campaign',
          info: 'Create a duplicate campaign to unopened customers. You can make changes before sending.',
          success: 'Created campaign',
          successInfo: 'Created draft campaign',
        },
        send: {
          titleEmail: 'Send campaign email',
          titleSMS: 'Send campaign SMS',
          infoEmail: `
            Campaign email will be sent to 
            <strong>{count} customers</strong>
          `,
          infoSMS: `
            Campaign SMS will be sent to 
            <strong>{count} customers</strong>
          `,
          messagesAvailable: 'This mass SMS will use approximately <strong>{count}</strong> messages. You currently have <strong>{balance}</strong> messages available.',
          insufficientMessages: 'You do not have enough messages to send this mass SMS. You can purchase additional messages below.',
          creditsAvailable: 'This mass SMS will use approximately <strong>{approximate}</strong> credits. You currently have <strong>{available}</strong> credits available.',
          insufficientCredit: 'You do not have enough credit to send this mass SMS. You can purchase additional credits below.',
          whenSend: 'When should this campaign be sent?',
          later: 'Later',
          scheduled: 'Scheduled date/time',
          error: 'Sorry, something went wrong while calculating required SMS credits',
          successInfoEmail: 'Sent campaign email',
          successInfoSMS: 'Sent campaign SMS',
        },
      },
      elements: {
        customer: {
          title: 'Select customers',
          customers: '{count} Customer | {count} Customers',
          show: 'Show only selected customers',
          noEmail: 'No email',
          noPhone: 'No phone',
          recentBounces: 'Recent bounces',
        },
      },
    },
    cancellationReason: {
      actions: {
        delete: {
          title: 'Delete cancellation reason',
          info: `
            Are you sure you want to delete cancellation reason: <br>
            <strong>{name}</strong> (#{type})<br><br>
            The cancellation reason will be removed permanently from your account.
          `,
          success: 'Deleted cancellation reason',
          successInfo: 'Deleted cancellation reason record for: {name}',
          error: 'Error deleting cancellation reason',
          errorInfo: 'Unable to delete cancellation reason: {name}',
        },
      },
    },
    chargeModel: {
      actions: {
        delete: {
          title: 'Delete charge',
          info: `
            Are you sure you want to delete charge: <br>
            <strong>{title}</strong> (#{id})<br><br>
            The charge will be removed permanently from your account.
          `,
          success: 'Deleted charge',
          successInfo: 'Deleted charge record for: {title}',
        },
        edit: {
          new: 'New Charge',
          edit: 'Edit Charge',
          lockedInfo: 'Locking a charge will prevent it from being edited from a sale"',
          success: 'Saved charge',
          successInfo: 'Saved charge: {title}',
        },
      },
      elements: {
        select: {
          placeholder: 'Select a charge',
        },
      },
    },
    customer: {
      actions: {
        archiveCustomer: {
          title: 'Archive customer',
          info: 'Are you sure you want to archive customer: <br><strong>{name}</strong> (#{id})',
          success: 'Archived customer',
          successInfo: 'Archived customer record for: {name}',
        },
        bulkArchiveCustomer: {
          title: 'Archive customers',
          info: 'Are you sure you want to archive the following customers:',
          success: 'Archived customers',
          successInfo: 'Archived customer records',
        },
        bulkRestoreCustomer: {
          title: 'Restore customers',
          info: 'Are you sure you want to restore the following customers:',
          success: 'Restored customers',
          successInfo: 'Restored customer records',
        },
        creditBalance: {
          title: 'Update credit balance',
          info: 'Edit credits for <strong>{name}</strong>',
          success: 'Updated credit balance',
        },
        creditCardAdd: {
          title: 'Add customer credit card',
          info: 'Add credit card to: <strong>{name}</strong>',
          add: 'Add credit card',
          noCompatible: 'No compatible payment integrations set up. Please connect a payment integration in Account > Integrations',
          success: 'Added customer credit card',
        },
        creditCardRemove: {
          title: 'Remove credit card',
          info: 'Are you sure you would like to remove the credit card ending in: {cardLast4}',
          success: 'Removed',
          successInfo: 'Removed credit card ending in: {cardLast4}',
          error: 'Error removing card',
          errorInfo: 'Unable to remove credit card ending in: {cardLast4}',
        },
        deleteCustomer: {
          title: 'Delete customer',
          info: `
            Are you sure you want to delete customer: <br>
            <strong>{name}</strong> (#{id})<br><br>
            The customer will be removed permanently from your account.
          `,
          warning: 'Warning. Deleting a customer is PERMANENT.',
          warningInfo: 'Due to privacy legislation, this action is permanent. It is not possible to recover a deleted customer or any associated data.',
          exportCustomerData: "If you would like to export a copy of this customer's data prior to deletion, please use the <strong>Export</strong> button on the Customer file.",
          archiveCustomer: 'If full deletion is not required for this customer, please use the <strong>Archive</strong> button on the Customer file instead.',
          deleted: 'The following data will be <strong>permanently deleted</strong>:',
          deletedInfo: `
            <li>Customer profile (name, contact details, address)</li>
            <li>Custom fields</li>
            <li>Notes (including notes made on appointments)</li>
            <li>Photos</li>
            <li>Saved Credit Cards</li>
            <li>Forms</li>
          `,
          delinked: 'The following data will be retained but <strong>permanently de-linked</strong> from the customer:',
          delinkedInfo: `
            <li>Gift Cards</li>
            <li>Passes</li>
            <li>Appointments</li>
            <li>Sales</li>
          `,
          success: 'Deleted customer',
          successInfo: 'Deleted customer record for: {name}',
          error: 'Error deleting customer',
          errorInfo: 'Unable to delete customer: {name}',
        },
        image: {
          title: 'Set customer profile photo',
          remove: 'Remove existing photo',
          success: 'Set photo',
          removeSuccess: 'Removed photo',
          removeSuccessInfo: 'Removed profile photo for {name}',
        },
        loyaltyPointBalance: {
          title: 'Update loyalty point balance',
          info: 'Edit loyalty points for <strong>{name}</strong>',
          success: 'Updated loyalty point balance',
        },
        loyaltyPointRecalculate: {
          title: 'Recalculate loyalty point balance',
          info: 'Recalculate loyalty points for <strong>{name}</strong>',
          recalculate: 'Recalculate',
          warning: 'For correcting loyalty balance errors',
          recalculateBalance: 'This will recalculate the loyalty point balance for this customer.',
          transactionsNotAffected: 'Loyalty point transactions will not be affected.',
          success: 'Recalculated loyalty point balance',
        },
        merge: {
          title: 'Merge customers',
          info: 'Merge multiple customer records into one',
          confirm: 'Confirm merge',
          primaryCustomer: 'Primary customer:',
          selectPrimaryCustomer: 'Select a primary customer',
          customersToMerge: 'Customers to merge:',
          accept: 'I understand that all selected customers will be merged into {name} (#{id})',
          success: 'Merged',
          successInfo: 'Successfully merged customers',
        },
        restoreCustomer: {
          title: 'Restore customer',
          info: `
            Are you sure you want to store customer: <br>
            <strong>{name}</strong> (#{id})<br>
            This customers profile and online account will be un-archived.
          `,
          success: 'Restored customer',
          successInfo: 'Restored customer record for: {name}',
        },
        segments: {
          title: 'Apply customer segments',
          success: 'Updated customer segments',
        },
        sendWelcomeEmail: {
          title: 'Resend welcome email',
          info: `
            Are you sure you want to resend the welcome email to: <br>
            <strong>{name}</strong> (#{id})<br><br>
            This will also reset their online booking password.
          `,
          success: 'Resent welcome email',
          successInfo: 'Resent welcome email to: {name}',
        },
      },
      edit: {
        address: {
          addressDetails: 'Address Details',
          postalAddressInfo: 'Postal address information',
        },
        custom: {
          info: 'Unique fields for your business',
          noFields: 'You do not have any customer custom fields set up',
        },
        information: {
          loyaltyPointBalance: 'Loyalty Point Balance',
          creditBalance: 'Credit Balance',
        },
        marketing: {
          title: 'Marketing preferences',
          info: 'Manage customers online booking & notification subscriptions',
          massEmailUnsubscribed: 'Mass Email Unsubscribed',
          massSMSUnsubscribed: 'Mass SMS Unsubscribed',
        },
        segments: {
          info: 'Group customers into segments to manage multiple customers at once',
        },
      },
      elements: {
        duplicate: {
          info: 'You already have a customer with those details: | You already have customers with those details:',
          matches: '{count} match | {count} matches',
        },
        preview: {
          openProfile: 'Open profile',
          addCompany: 'Add company',
          addPhoneNumber: 'Add phone number',
          addHomeNumber: 'Add home number',
          addEmailAddress: 'Add email address',
          addAddress: 'Add address',
        },
        select: {
          startTyping: 'Start typing customer name',
          openCustomerProfile: 'Open customer profile',
          new: '(New)',
        },
      },
      list: {
        dateOfBirth: {
          hasDOB: 'Has D.O.B.',
          hasNoDOB: 'Has no D.O.B.',
        },
        fieldsWithDateRange: {
          during: 'During',
        },
      },
      profile: {
        actions: {
          editRecord: 'Edit record',
          restoreCustomerAccount: 'Restore customer account',
          seeAllChanges: 'See all changes made to a customer record',
          resendWelcomeEmail: 'Resend welcome email',
          resetsOnlineBookingPassword: '(Also resets online booking password)',
          export: 'Export customer data',
          exportInfo: 'Export all customer data to JSON file',
          archive: 'Archive customer record',
          archiveInfo: 'Record will be archived',
          restore: 'Restore customer record',
          restoreInfo: 'Record will be un-archived',
          delete: 'Delete customer record',
          deleteInfo: 'Customer will be permanently deleted',
        },
        creditCard: {
          cardNumber: 'Card number',
          cardExpiration: 'Card expiration',
        },
        creditCards: {
          title: 'Credit cards',
          info: 'Stored credit cards linked to this customer. Requires integration with a Stripe or Square account.',
          add: 'Add Credit Card',
          noStoredCards: 'No stored credit cards',
        },
        customerForm: {
          reopen: 'Re-open',
          requestUpdate: 'Request Update',
        },
        customerForms: {
          sendRequest: 'Send the customer a request to complete a new form',
          requestForm: 'Request form',
          demo: 'Demo',
          anotherLine: 'Another one line here',
        },
        details: {
          title: 'Details & information',
          age: 'Age',
          checkinRate: 'Check-in rate',
          noShowRate: 'No-show rate',
          cancellationRate: 'Cancellation rate',
          customerSince: 'Customer since',
          totalAppointmentDuration: 'Total appointment duration',
          averageAppointmentDuration: 'Avg. appointment duration',
        },
        giftCards: {
          balances: 'Balances',
          updateCreditBalance: 'Update credit balance',
          empty: 'No gift cards...',
        },
        heading: {
          dataCollectionConsented: 'Data collection consented',
        },
        notes: {
          addAlert: 'Add alert',
          noAlerts: 'No customer alerts...',
          noNotes: 'No customer notes...',
          quickNotes: 'Quick notes',
        },
        passes: {
          empty: 'No passes to show...',
        },
        photo: {
          view: 'View photo',
          edit: 'Edit photo',
          set: 'Set as customer profile',
        },
        products: {
          history: 'Product history',
          empty: 'No products...',
        },
        reservations: {
          noWaitlistRequests: 'No waitlist requests...',
          upcoming: 'Upcoming',
          past: 'Past',
          noPast: 'no past appointments...',
        },
        sales: {
          revenue: 'Sales revenue',
          empty: 'No sales to show...',
        },
        tabs: {
          profile: 'Profile',
          giftCardsCredit: 'Gift Cards / Credit',
          credit: 'Credit',
          creditCards: 'Credit Cards',
        },
        timeline: {
          title: 'Timeline',
          empty: 'No timeline events',
          requestedForm: 'Requested Form',
          giftCardExpired: 'Gift Card expired',
        },
        uploads: {
          title: 'Files and images',
          info: 'Documents and photos relating to this customer',
        },
      },
    },
    customerForm: {
      actions: {
        delete: {
          title: 'Are you sure you want to delete this form?',
          successInfo: 'Deleted form {title} from {name}',
          errorInfo: 'Error while deleting form {title} from {name}',
        },
        email: {
          title: 'Are you sure you want to email this form?',
          info: 'Email {title} to {name}',
          emailed: 'Emailed form',
          emailedInfo: 'Emailed form {title} to {name}',
          errorInfo: 'Error while emailing form {title} to {name}',
        },
        reopen: {
          title: 'Are you sure you want to reopen this form?',
          info: 'Reopen {title} for {name}?',
          success: 'Reopened form',
          successInfo: 'Reopened form {title} for {name}',
          errorInfo: 'Error while reopening form {title} for {name}',
        },
        request: {
          title: 'Request a new form',
          info: 'Send a form request to {name}?',
          success: 'Requested form',
        },
        requestUpdate: {
          title: 'Are you sure you want to request an update for this form?',
          info: 'Request update for {title} from {name}?',
          success: 'Requested update',
          successInfo: 'Requested update for {title} from {name}',
          errorInfo: 'Error while requesting update for {title} from {name}',
        },
        schedule: {
          title: 'Schedule form',
          info: 'Schedule form for {name}?',
          success: 'Scheduled form',
        },
      },
    },
    customerNote: {
      delete: {
        title: 'Delete customer note',
        info: 'Are you sure you want to delete this note?',
        success: 'Deleted customer note',
        successInfo: 'Deleted customer note record',
        error: 'Error deleting customer note',
        errorInfo: 'Unable to delete customer note',
      },
      edit: {
        title: 'Edit note',
        info: 'Edit customer note',
        save: 'Save note',
        success: 'Edited note',
        successInfo: 'Edited customer note',
      },
    },
    customerPass: {
      actions: {
        add: {
          wontLink: 'The pass will not be linked to the recipient\'s profile until it is redeemed',
          willLink: 'The pass will be linked to the recipient\'s profile',
          addAndPay: 'Add pass & pay now',
          deliveryDateLabel: 'Customer pass will be sent immediately',
          deliveryDateLabelWithDate: 'Customer pass will be sent {date} @ 9AM',
          success: 'Created customer pass',
          successInfo: 'Successfully created new customer pass',
        },
        delete: {
          info: `
            Are you sure you want to delete this pass?<br><br>
            The pass will be removed permanently from the account.
          `,
          successInfo: 'Removed pass from account',
        },
        email: {
          info: 'Email customer pass: <strong>#{id}</strong>',
          successInfo: 'Sent customer customer pass email',
          errorInfo: 'Error while sending customer pass.',
        },
        restore: {
          title: 'Restore customer pass',
          info: 'Are you sure you want to restore this pass?',
          confirm: 'Restore pass',
          success: 'Restored pass',
          successInfo: 'Successfully restored pass',
          errorInfo: 'Error while restoring pass.',
        },
      },
    },
    customerPhoto: {
      actions: {
        delete: {
          title: 'Delete customer photo',
          info: `
            Are you sure you want to delete this photo?<br><br>
            The photo will be removed permanently from the account.
          `,
        },
        edit: {
          title: 'Edit photo details',
          customerVisibleInfo: 'Should this photo be visible in the customers account on your minisite',
          successInfo: 'Successfully saved changes to customer photo',
        },
        profile: {
          title: 'Edit photo caption',
          successInfo: 'Successfully updated customer photo caption',
        },
        upload: {
          info: 'Add photo to: <strong>{name}</strong>',
          successInfo: 'Added photo to {name}',
        },
      },
    },
    customField: {
      actions: {
        delete: {
          title: 'Delete custom field',
          info: `
            Are you sure you want to delete custom field: <br>
            <strong>{name}</strong> (#{id})<br><br>
            The custom field will be removed permanently from your account.
          `,
          showPastData: 'Show past data',
          showPastDataInfo: 'If enabled, existing fields that have values will still be shown.',
          success: 'Deleted custom field',
          successInfo: 'Deleted custom field record for: {name}',
          error: 'Error deleting custom field',
          errorInfo: 'Unable to delete custom field: {name}',
        },
      },
    },
    customPage: {
      actions: {
        create: {
          templates: 'Templates',
          template: 'Template',
          useTemplate: 'Use template',
          titleInfo: 'The page name',
          slugInfo: 'How to page will appear in the address bar',
          infoInfo: 'A short description for this page',
          success: 'Created page',
          successInfo: 'Your page has been created successfully',
        },
        delete: {
          title: 'Delete custom page',
          info: `
            Are you sure you want to delete custom page: <br>
            <strong>{title}</strong> (#{id})
          `,
          successInfo: 'Deleted custom page: {title}',
        },
        hero: {
          title: 'Page Hero',
        },
      },
      elements: {
        preview: {
          desktop: 'Desktop',
          mobile: 'Mobile',
        },
      },
      templates: {
        about: 'About',
        terms: 'Terms',
      },
    },
    dashboard: {
      elements: {
        banner: {
          referAndEarn: 'Refer & Earn',
          shareReferralLink: 'Refer a friend and get a free month',
          shareNow: 'Share now',
        },
        reservation: {
          note: '<span class="font-medium">Note: </span>{notes}',
        },
        waitlists: {
          noRequests: 'No {filter} waitlist requests',
          seeAll: 'See all waitlist items',
        },
        unconfirmed: {
          note: '<span class="font-medium">Note: </span>{notes}',
        },
        attendees: '{count} customers',
        inMins: 'in {count} mins',
      },
    },
    discountModel: {
      actions: {
        delete: {
          title: 'Delete discount',
          info: `
            Are you sure you want to delete discount: <br>
            <strong>{title}</strong> (#{id})<br><br>
            The discount will be removed permanently from your account.
          `,
          success: 'Deleted discount',
          successInfo: 'Deleted discount record for: {name}',
        },
        edit: {
          new: 'New Discount',
          edit: 'Edit Discount',
          lockedInfo: 'Locking a discount will prevent it from being edited from a sale',
          success: 'Saved discount',
          successInfo: 'Saved discount: {title}',
        },
      },
    },
    elements: {
      annotate: {
        index: {
          edit: 'Tap/click to edit',
        },
      },
      editor: {
        actions: {
          link: {
            add: 'Add link',
            edit: 'Change link',
            editInfo: 'Update link attributes and destination.',
            linkUrl: 'Link URL',
            linkText: 'Link Text',
            element: 'Element',
            button: 'Button',
            internal: 'Internal',
            external: 'External',
            bookNow: 'Book now',
          },
        },
        blocks: {
          columns: {
            component: {
              drag: 'Drag',
            },
          },
          employees: {
            addEmployees: {
              title: 'Add employees',
              info: 'Add employee to the employee block',
              success: 'Added employees',
              successInfo: 'Added employees to block',
            },
            component: {
              listInfo: 'The list of employees to show in this section. Start typing to add new employees and click-and-drag to reorder them.',
              add: 'Add employees',
              addInfo: 'Add a employee to the list or display all employees by leaving list empty.',
              edit: 'Edit employees',
            },
          },
          services: {
            addServices: {
              info: 'Add service to the service block',
            },
            component: {
              listInfo: 'The list of services to show in this section. Start typing to add new services and click-and-drag to reorder them.',
              add: 'Add services',
              addInfo: 'Add a service to the list or display all services by leaving list empty.',
              edit: 'Edit services',
            },
            add: 'Add services',
          },
        },
        block: {
          title: 'Block',
        },
        menus: {
          blocks: {
            insert: 'Insert a formatted block',
          },
        },
      },
      actionBar: {
        save: 'Save Changes',
        unsavedChanges: 'Unsaved changes...',
      },
      address: {
        noMatchingAddress: 'No matching address found...',
      },
      error: {
        somethingWentWrong: 'Sorry, something went wrong',
        helpGuides: 'Help Guides',
        helpGuidesInfo: 'Use our range of help guides to learn everything you need',
        tutorials: 'Tutorials',
        tutorialsInfo: 'Use our catalogue of video tutorials',
        message: 'Send us a message',
        messageInfo: 'Start a live chat with our support team',
      },
      fieldGroup: {
        required: 'Required field',
        modifiedMessage: 'This field has been modified from its original value',
      },
      filters: {
        title: 'Filters',
      },
      heading: {
        title: 'Page title',
        home: 'Home',
        breadcrumb: 'Breadcrumb',
      },
      insertImage: {
        title: 'Something',
        alt: 'Something else',
      },
      notification: {
        title: 'Notification',
        error: 'Something went wrong..',
        errorInfo: 'Sorry, there was an error while processing your request',
      },
      pager: {
        showing: 'Showing {count} of {total}',
        empty: 'No items to show...',
      },
      pagination: {
        showing: 'Showing <span class="font-medium">{from}</span> to <span class="font-medium">{to}</span> of <span class="font-medium">{total}</span>',
      },
      passwordStrength: {
        pwned: `
          This password has been been identified in a data breach. Please try another unique password <a
            href="https://haveibeenpwned.com/Passwords"
            target="_blank"
            class="text-blue-600 underline"
          >
            (more info)
          </a>
        `,
      },
      paywall: {
        notAvailable: '{label} are not available on your plan',
        upgrade: 'Upgrade to Ovatu Loaded to access {label}',
        changePlan: 'Change plan',
        thisFeature: 'This feature',
      },
      qrCode: {
        download: 'Download QR Code',
        copyLink: 'Copy Minisite Link',
        copied: 'Copied!',
      },
      sms: {
        insertPlaceholder: 'Insert placeholder',
      },
      socialShare: {
        shareTo: 'Share to {name}',
      },
      table: {
        cancelReorder: 'Cancel reorder',
        saveOrderChanges: 'Save order changes',
        showing: 'Showing <span class="font-medium">{from}</span> to <span class="font-medium">{to}</span> of <span class="font-medium">{total}</span>',
        noData: 'No data available',
      },
      upload: {
        uploadFile: 'Upload a file',
        label: 'Drag your file here or click to browse',
      },
    },
    employee: {
      actions: {
        changeEmail: {
          info: 'Change the email address for this employee',
          newUserEmail: 'The new email address for this employee',
          successInfo: 'The details for this employee has been updated',
        },
        deleteEmployee: {
          title: 'Delete employee',
          info: `
            Are you sure you want to delete employee: <br>
            <strong>{name}</strong> (#{id})<br><br>
            The employee will be removed permanently from your account.<br><br>
            Please re-schedule any future appointments prior to deleting the employee.<br><br>
          `,
          success: 'Deleted employee',
          successInfo: 'Deleted employee record for: {name}',
          error: 'Error deleting employee',
          errorInfo: 'Unable to delete employee: {name}',
        },
        image: {
          title: 'Upload Employee Image',
          info: 'Edit employee image',
          for: 'Edit employee image for: <strong>{name}</strong>',
        },
        resetPassword: {
          sendEmailInfo: 'Send an email to the employee letting them know their new password',
        },
        updateAvatar: {
          select: 'Select an Avatar',
          or: 'OR',
        },
        updatePrices: {
          info: 'Would you like to apply the service price to future appointments?',
        },
      },
      edit: {
        details: {
          avatarInfo: 'May be visible on minisite',
          role: 'Role: {name}',
          toChangeVisitAccountUsersAndRoles: "To change this employee's role, please visit Account > {usersAndRoles}.",
        },
        minimiseGaps: {
          info: 'Override system settings to reduce unwanted gaps and manage times between appointments created online for this employee',
          enabledInfo: 'Enable gap minimisation for this employee',
          showFirstAvailableAppointmentInfo: 'Display the first appointment time of the day, as per the hours set in the roster',
          showLastAvailableAppointmentInfo: 'Display the last appointment time of the day, as per the hours set in the roster',
          showAvailabilityBeforeAndAfter: 'Show availability before and after appointments / breaks',
          showAvailabilityBeforeAndAfterInfo: 'Show appointment times which join up with the start and end times of all existing breaks and appointments',
          showAvailabilityLeavingMinGapTimeInfo: 'Minimum allowable gap time before or after any existing breaks and appointments',
          showAllAvailabilityForEmptyDayInfo: 'Display all availability in the event that an Employee does not already have any appointments on a given date.',
        },
        roster: {
          normalHoursInfo: 'Set the normal working hours for this employee',
        },
        services: {
          enableServices: 'Enable/disable all services for with this employee',
          enableOnlineBooking: 'Enable/disable online booking with this employee for all services',
        },
      },
      elements: {
        select: {
          unavailable: 'Unavailable',
          selectItem: 'Select an item',
        },
      },
    },
    export: {
      actions: {
        download: {
          title: 'Download export',
          info: 'This export has been split into multiple files.',
          part: 'Part {count}',
        },
      },
    },
    fields: {
      avatar: {
        update: 'Update Image',
      },
      colour: {
        select: 'Select a colour',
      },
      dateRange: {
        oneWeek: '1 Week',
        twoWeeks: '2 Weeks',
        threeWeeks: '3 Weeks',
        thisMonth: 'This Month',
        lastMonth: 'Last Month',
        future: 'Future',
        past: 'Past',
      },
      list: {
        basicExample: 'Basic example',
        placeholder: 'Type and press enter to add items',
      },
      listBox: {
        noOptions: 'No options found.',
        clearSelected: 'Clear selected',
      },
      period: {
        first: 'First',
        second: 'Second',
        third: 'Third',
        fourth: 'Fourth',
      },
      typeahead: {
        noResults: 'No results were found matching your query',
        typeToSearch: 'Type in the field above to search',
        searching: 'searching results...',
      },
    },
    form: {
      actions: {
        create: {
          title: 'Create new form',
          id: '<span class="text-gray-700 dark:text-gray-200">Form ID:</span> {id}',
          success: 'Created form',
          successInfo: 'Your form has been created successfully',
        },
        delete: {
          successInfo: 'Deleted form record for: {title}',
          error: 'Error deleting form',
          errorInfo: 'Unable to delete form: {title}',
        },
        deleteFormItem: {
          title: 'Delete form item',
          info: `
            Remove form item: <br>
            <strong>{name}</strong> (#{id})
          `,
        },
        duplicate: {
          title: 'Duplicate form',
          info: 'Duplicate the form: <strong>{title}</strong>',
          success: 'Duplicated form',
          successInfo: 'Created new form: {title}',
          error: 'Error duplicating form',
          errorInfo: 'Unable to duplicate form: {title}',
        },
        editFormBuilderItem: {
          fieldTitle: '{description} Field',
          nameField: '{description} Field: Name',
          nameFieldInfo: 'Title of section or field label',
          contentField: '{description} Field: Content',
          contentFieldInfo: 'The paragraph text',
          dataTypeInfo: 'The type of form field',
          valuesField: '{description} Field: Values',
          valuesFieldInfo: 'Options for multiple field type',
          annotationInfo: 'The image being annotated',
          uploadImage: 'Upload Image',
          allowDrawing: 'Allow drawing',
          drawingColours: 'Drawing colours',
          drawingColoursInfo: 'Colours available when drawing',
          allowStamps: 'Allow stamps',
          availableStamps: 'Available stamps',
          availableStampsInfo: 'Stamps the user can select from',
          allowErasing: 'Allow erasing',
          defaultValueInfo: 'Pre-fill this field with a value',
          informationInfo: 'Information you would like to be displayed with this field',
          requiredInfo: 'This field must be completed',
          editItem: 'Edit form item: {name}',
        },
      },
      edit: {
        consent: {
          title: 'Data Consent',
          info: 'The consent field is mandatory when enabled.',
          titleName: 'Data Consent: Title',
          titleInfo: 'The name of the data consent section',
          contentInfo: "E.g. 'We collect your data in accordance with privacy legislation'",
          acceptLabel: 'Consent accept label',
          acceptLabelName: 'Data Consent: Accept Label',
          acceptLabelInfo: "E.g. 'I accept'",
        },
        details: {
          titleInfo: 'The form name',
          informationInfo: 'Form information for your own internal reference',
          instructions: 'Instructions',
          instructionsInfo: 'These will appear at the top of the Form and also in the Form request email',
          permission: 'Permission',
          permissionInfo: 'Can the form be opened and edited once submitted',
          prefill: 'Prefill form',
          prefillInfo: 'Would you like form to be pre-filled with the customers previous responses? (You can change this later when adding the form to the customer)',
          allowCustomerCopy: 'Allow Customer Copy',
          allowCustomerCopyInfo: 'When a Customer completes their own form via the mini site, they have the option to email themselves a copy',
          automationEnabled: 'Automation enabled',
          automationEnabledInfo: "Allows the form to be sent automatically when set to do so in a Service's settings",
          automationAlert: "Automation allows the form to be sent automatically when set to do so in a service's settings.",
          followingServiesHaveAutomation: 'The following services currently have automation enabled for this form:',
          noServicesHaveAutomation: 'No services currently have automation enabled for this form',
        },
        items: {
          layout: 'Layout',
          fields: 'Fields',
        },
      },
    },
    giftCard: {
      actions: {
        add: {
          wontLink: 'The gift card will not be linked to the recipient\'s profile until it is redeemed',
          willLink: 'The gift card will be linked to the recipient\'s profile',
          search: 'Search existing gift cards',
          deliveryDateLabelDate: 'Gift card will be sent {date} @ 9AM',
          deliveryDateLabelImmediate: 'Gift card will be sent immediately',
          defaultExpiryLabelExpires: 'Default setting: {expiry} days',
          defaultExpiryLabelDoesntExpire: 'Default setting:  doesn\'t expire',
          message: 'I hope you enjoy this Gift Card!',
          createdSuccess: 'Created gift card',
          createdSuccessInfo: 'Successfully created new gift card',
        },
        delete: {
          title: 'Delete customer gift card',
          info: `
            Are you sure you want to delete this gift card: <br>
            <strong>#{number}</strong><br><br>
            The gift card will be removed permanently from the account.
          `,
          success: 'Deleted gift card',
          successInfo: 'Removed gift card from account',
          errorInfo: 'Error while deleting gift card.',
        },
        email: {
          title: 'Email customer gift card',
          info: 'Email gift card: <strong>#{number}</strong>',
          successInfo: 'Sent customer gift card email',
          errorInfo: 'Error while sending gift card.',
        },
        printReceipt: {
          giftCard: 'Gift card: {number}',
          balance: 'Balance: {balance}',
          expires: 'Expires: {expiry}',
          customer: 'Customer: {name}',
          to: 'To {name}',
          from: 'From {name}',
        },
      },
      elements: {
        select: {
          placeholder: 'Select a gift card',
        },
      },
    },
    integration: {
      actions: {
        action: {
          errorInfo: 'Error while running action',
        },
        reconnect: {
          label: 'Reconnect',
          requires: '{name} requires you to reconnect',
        },
      },
      edit: {
        integrations: {
          square: {
            actions: {
              connectDevice: {
                info: 'Connect a Square Terminal to your account.',
                generateCode: 'Generate Code',
                pairingCode: 'Pairing Code',
                enterCode: 'Enter the following code into your Square Terminal.',
              },
              removeDevice: {
                title: 'Remove Terminal',
                info: 'Remove Square Terminal: {name}',
                successInfo: 'Removed Square terminal',
              },
              renameDevice: {
                title: 'Rename Terminal',
                info: 'Rename your Square Terminal',
                successInfo: 'Renamed Square terminal',
              },
            },
            tabs: {
              devices: {
                title: 'Connected Devices',
                noDevices: 'There are no connected devices.',
                rename: 'Rename',
              },
            },
            pairTerminal: 'Pair Terminal',
            terminalLabel: 'A label for your terminal (e.g. Front Desk)',
          },
        },
        helpGuide: {
          title: 'Help guide',
        },
        option: {
          stripeCurrency: 'Stripe Currency',
          requestCustomerAddress: 'Request Customer Address',
          paymentOptionCard: 'Enable card payments when customers are paying online',
          paymentOptionApplePay: 'Enable Apple Pay payments when customers are paying online',
          paymentOptionGooglePay: 'Enable Google Pay payments whencustomers are  paying online',
          paymentOptionAfterpay: 'Enable Afterpay/Clearpay payments when customers are paying online',
          allowTipping: 'Allow the customer to add a tip when checking out on the Square Terminal',
          skipReceiptScreen: 'Skip the receipt screen when checking out on the Square Terminal',
          tipPercentages: 'Add up to 3 pre-set amounts (0-100) as tip options. Defaults to 15, 20, and 25. Ignored if Smart Tipping is enabled',
          smartTipping: 'Enable "Smart Tip Amounts" features. Square Terminal will automatically calculate the tip options based on the total amount of the payment.',
          customTipField: 'Allow the customer to add a custom tip amount',
          seperateTipScreen: 'Show tip options before the customer signs for card payments',
        },
        overview: {
          platforms: 'Platforms',
          webDesktop: 'Web/Desktop',
          apple: 'iOS & iPadOS - iPhone/iPad',
          android: 'Android',
        },
      },
      elements: {
        integrationAlert: {
          goToSettings: 'Go to {name} settings',
          unableToConnect: 'Unable to connect',
          infoSuccess: 'Successfully connected {name} to Ovatu',
          infoError: 'There was an issue while connecting {name} to Ovatu',
        },
        integrationCard: {
        },
      },
    },
    layout: {
      account: {
        accountId: 'Account ID: #{id}',
        accountHistory: 'Account History',
        lockAccount: 'Lock account',
        switchUser: 'Switch user',
        manageAccountSettings: 'Manage account settings',
        guidesAndFAQs: 'Guides and FAQs',
        featuresAndChanges: 'Features and changes',
        systemDarkLightMode: 'System/Dark/Light Mode',
        system: 'System (Auto)',
        darkAuto: 'Dark (Auto)',
        remoteEvents: 'Remote Events',
        client: 'Client: {label}',
        subs: 'Subs: {keys}',
        notConnected: 'Not connected',
        logoutUser: 'Logout user',
        leaveDemo: 'Leave demo',
        plan: 'Plan',
      },
      banner: {
        ovatuNext: 'Ovatu Next',
        installApp: 'Install the Ovatu app on your device',
        install: 'Install',
      },
      loading: {
        syncing: 'Syncing {label}',
        justASec: 'Just a sec...',
        haveAQuestion: 'Have a question?',
        gettingThingsReady: 'Getting things ready...',
        ctrlS: 'You can press CTRL + S from anywhere in the app to quickly access search',
        createCustomPermissionRoles: 'You can create custom permission roles in your account settings',
        lickElbows: 'It\'s impossible for most people to lick their elbows.',
        carbonNeutral: 'Ovatu is proud to be 100% carbon neutral',
        plantsTree: 'For every new customer Ovatu plants a tree',
        useWidgets: 'You can use our widgets to add book.app to your own website',
        dataReady: 'We are getting your data ready',
        reticulatingSplines: 'Reticulating splines...',
        inQueue: 'You are number 2843684714 in the queue',
        covfefe: 'Despite the constant negative press covfefe',
        doesAnyoneRead: 'Does anyone actually read these?',
        clouds: 'Some people are like clouds. When they go away, it\'s a brighter day.',
        supportTeam: 'Our support team are always ready to help!',
        thanks: 'Thanks for using Ovatu! we appreciate it',
      },
      mobile: {
        menu: 'Menu',
      },
      notifications: {
        createAnAccount: 'Create an account',
      },
      update: {
        remindMeLater: 'Remind me later',
        dismiss: 'Dismiss',
      },
      support: {
        title: 'Support',
      },
      help: 'Help',
    },
    loyaltyProgram: {
      actions: {
        bulkSet: {
          title: 'Bulk set loyalty program',
          info: `
            Are you sure you would like to bulk {state} the loyalty program for all {type}?<br><br>
            This will override all existing settings for {type}.
          `,
          successInfo: 'Successfully {state} the loyalty program for all {type}',
        },
        loyaltyReset: {
          title: 'Loyalty Program balances reset',
          info: 'This will reset all customer loyalty points balances to 0. This action cannot be un-done. Are you sure you wish to continue?',
          success: 'Loyalty Program reset',
          successInfo: 'All customer loyalty points balances have been reset to 0',
        },
      },
    },
    manage: {
      elements: {
        logoImage: {
          businessLogo: 'Business Logo',
          success: 'Uploaded logo',
          successInfo: 'Uploaded new logo image',
          removeSuccess: 'Removed logo',
          removeSuccessInfo: 'Removed logo image',
        },
      },
      pages: {
        sections: {
          schedule: 'Manage default schedule/calendar settings',
          location: 'Manage location settings for your business',
          theme: 'Manage theme settings for your web app',
          employees: 'Manage employee details, services, roster and overrides',
          resources: 'Add, remove and edit resources',
          resourcesGroups: 'Add, remove and edit resource groups',
          services: 'Manage service details, employees and service defaults',
          serviceCategories: 'Create groups for sorting your services',
          statusTypes: 'Add, remove and edit booking status tags',
          cancellationReasons: 'Add, remove and edit booking cancellation reasons',
          products: 'Manage product details',
          productCategories: 'Create groups for sorting your products',
          brands: 'Add, remove and edit brands',
          suppliers: 'Add, remove and edit supplier',
          purchaseOrders: 'Create and manage product purchase orders',
          stockTake: 'Create and manage product inventory',
          taxes: 'Add, remove and edit taxes',
          details: 'Sales, receipt information and tax details',
          customFieldsCustomer: 'Add, remove and edit customer custom fields',
          customFieldsReservation: 'Add, remove and edit appointment custom fields',
          customFieldsReservationCustomer: 'Add, remove and edit group customer custom fields',
          customFieldsSale: 'Add, remove and edit sale custom fields',
          customFieldsEmployee: 'Add, remove and edit employee custom fields',
          customFieldsService: 'Add, remove and edit service custom fields',
          customFieldsProduct: 'Add, remove and edit product custom fields',
          forms: 'Manage customer form templates & scheduling',
          customerGiftCards: 'Manage active customer gift gards',
          passes: 'Manage available passes',
          customerPasses: 'Manage active customer passes',
          loyaltyProgram: 'Enable/disabled and set earn & spend amounts for loyalty program',
          activeVouchers: 'List active promo codes',
          expiredVouchers: 'List expired promo codes',
          discounts: 'Preset discount options',
          charges: 'Preset charge options',
          payments: 'Preset payment options',
          templates: 'Email notification templates',
          email: 'Email master template & internal email settings',
          sms: 'SMS credit and phone number settings',
          reminders: 'Appointment, review and birthday messages',
        },
        pages: {
          preferences: 'General settings',
          employees: 'Bookable employee settings',
          services: 'Services, appointment statuses & cancellation reasons',
          resources: 'Resources & resource groups',
          remindersAndNotifications: 'Reminder settings & email templates',
          forms: 'Customer form templates',
          products: 'Products & inventory control',
          pointOfSale: 'Taxes, discounts, charges & custom payments',
          customerGiftCards: 'Customer gift cards',
          passes: 'Available passes & customer passes',
          promoCodes: 'Promotional discount codes',
          loyaltyPoints: 'Loyalty points settings',
          customFields: 'Various customisable fields',
        },
      },
    },
    marketing: {
      pages: {
        campaigns: 'Send SMS & Email campaigns',
        sentSMSes: 'View a log of sent SMSes',
        sentEmails: 'View a log of sent Emails',
        customerReviews: 'Manage customer reviews',
        assetLibrary: 'Store images and files for use in marketing campaigns and your minisite',
      },
    },
    message: {
      actions: {
        new: {
          title: 'Message customer',
        },
      },
      elements: {
        attachments: {
          types: {
            customerForm: {
              action: {
                title: 'Select Form',
              },
            },
            giftCard: {
              action: {
                title: 'Select Gift Card',
              },
            },
            order: {
              action: {
                title: 'Select Sale',
              },
            },
            reservation: {
              action: {
                title: 'Select Appointment',
              },
            },
            upload: {
              action: {
                title: 'Select Attachment',
              },
            },
          },
        },
        send: {
          attach: {
            attachAsset: 'Attach Image or File',
          },
          emoji: {
            title: 'Emoji',
          },
        },
        chat: {
          noMoreMessages: 'No more messages',
          new: 'New messages',
        },
        infiniteScroll: {
          noMoreData: 'No more data',
        },
        message: {
          noContent: 'This message has no content',
          sentViaEmail: 'Sent via Email',
          sentViaSMS: 'Sent via SMS',
          sentViaApp: 'Sent via BookApp',
        },
        Send: {
          sendAsEmail: 'Send via email',
          sendAsSMS: 'Send via SMS',
          sendAsApp: 'Send via BookApp',
          sendAsEmailAndSMS: 'Send via Email & SMS',
          sendAsEmailAndApp: 'Send via Email & BookApp',
          sendAsSMSAndApp: 'Send via SMS & BookApp',
          sendAsEmailAndSMSAndApp: 'Send via Email, SMS & BookApp',
          noDeliveryChannelSelected: 'No delivery channel selected',
          sendingTo: 'Sending to {contact}',
          noEmail: 'Customer has no email address',
          noPhone: 'Customer has no phone number',
          noApp: 'Customer does not have BookApp installed',
          hasApp: 'Customer has BookApp installed',
        },
      },
    },
    minisite: {
      actions: {
        addMenuGroup: {
          info: 'Create a new menu group item.',
        },
        businessHoursOverridesModule: {
          title: 'Business Hours Overrides',
          dates: '{count} dates selected',
        },
        editMenuGroup: {
          title: 'Edit menu group',
          info: 'Edit an existing menu group item.',
        },
        removeDomain: {
          info: `
            Remove your minisite custom domain.<br>
            Your custom domain will no longer be used to serve your minisite. {slug}.{domain} will be re-enabled.
          `,
          confirm: 'Remove domain',
          successInfo: 'Successfully removed custom domain',
        },
        setDomain: {
          title: 'Change domain',
          info: `
            Set your minisite domain to a custom domain you already own.<br>
            Once your domain is successully setup, all visits to {slug}.{domain} will be redirected.
          `,
          setCustomDomain: 'Set a custom domain',
          setCustomDomainInfo: 'Set your minisite domain to a custom domain you already own.',
          customerAccess: 'Customers will access your minisite at your own domain instead of {slug}.{domain}',
          steps: `
            <li>Enter your custom domain below and click "Apply".</li>
            <li>Add a DNS record to your Host/Domain provider.</li>
            <li>We will monitor your DNS records and start redirecting your minisite once your domain is verified.</li>
          `,
          forMoreInformation: 'For more detailed information visit our help guide: <a class="font-medium underline">Set a custom domain</a>',
          onceDomainIsSet: 'Note: Once your domain is successully setup, all visits to  {slug}.{domain} will be redirected.',
          customDomainInfo: 'Your custom domain (e.g. ovatu.com)',
          successInfo: 'Successfully set custom domain',
        },
      },
      elements: {
        menu: {
          order: {
            title: 'Custom menu order',
            info: 'Manage your minisite menu manually. This will only apply if you have enabled custom menu above',
            primaryMenu: 'Primary menu',
            primaryMenuInfo: 'Menu items visible at the top of your minisite on desktop and navigation menu on mobile (limited to 5 items)',
            emptyMessage: 'You currently have no menu items. Drag items from the unlisted pages below to add them to your menu.',
            secondaryMenu: 'Secondary menu',
            secondaryMenuInfo: 'Items only visible in the overflow menu',
            unlistedPages: 'Unlisted pages',
            unlistedPagesInfo: 'Drag pages from here to the menu for them to appear in your minisite menu',
          },
          parent: {
            emptyMessage: 'No items',
          },
          payments: {
            processorOrder: {
              title: 'Payment processor order',
              info: 'Set the order that payment processors are displayed',
              successInfo: 'Updated payment processor order',
            },
          },
          tabs: {
            gallery: {
              deleteImage: {
                title: 'Delete gallery image',
              },
              uploadImage: {
                successInfo: 'Uploaded gallery image',
              },
            },
            giftCard: {
              deleteImage: {
                title: 'Delete gift card image',
              },
              uploadImage: {
                info: 'Upload gift card image',
                successInfo: 'Uploaded gift card image',
              },
            },
            pass: {
              deleteImage: {
                title: 'Delete pass image',
                errorInfo: 'Error while deleting photo.',
              },
              uploadImage: {
                title: 'Upload pass image',
                successInfo: 'Uploaded pass image',
              },
            },
          },
          theme: {
            emailPreview: {
              dave: 'Dave',
              emailCommunication: 'Email communication',
              bookedIn: "We've got you booked in!",
              seeYouSoon: 'See you soon!',
              haircutWithBarbara: `
                <strong>Service:</strong> Haircut with Barbara Walters<br>
                <strong>Date:</strong> Wednesday, 4th April, 2020<br>
                <strong>Time:</strong> 5:00pm<br>
              `,
              manageBooking: 'Manage my booking »',
            },
            heroImage: {
              info: 'The background image that shows at the top of your page. Should be at least 900x600px',
              selectImage: 'Select image',
              minisiteHero: 'Minisite Hero',
              selectAnImage: 'Select an image',
              successInfo: 'Uploaded new hero image',
              removeSuccess: 'Removed hero',
              removeSuccessInfo: 'Removed hero image',
            },
            iconImage: {
              info: 'An icon used on the web browser tab and as a shortcut icon on mobile and desktop. Should be square and JPG or PNG format.',
              minisiteIcon: 'Minisite Icon',
              success: 'Uploaded icon',
              successInfo: 'Uploaded new icon image',
              removeSuccess: 'Removed icon',
              removeSuccessInfo: 'Removed icon image',
            },
            logoImage: {
              info: 'Your businesses logo should be transparent and at least 600x80px',
              minisiteLogo: 'Minisite Logo',
              success: 'Uploaded logo',
              successInfo: 'Uploaded new logo image',
              removeSuccess: 'Removed logo',
              removeSuccessInfo: 'Removed logo image',
            },
            themeSelect: {
              bookNow: 'Book Now',
              lastMinute: 'Last Minute',
              customerLogin: 'Customer Login',
              whereToFindUs: 'Where to find us',
              funkiSalon: 'Funki Salon',
              funkiEmail: 'hi@funki.co',
              funkiPhone: '(02) 9203 6152',
              funkiAddress: '200 George Street, Sydney, NSW, 2000, Australia',
            },
            ovatuDemoLocation: 'Ovatu Demo Location',
            amazing: "Amazing, can't stop telling everyone!",
          },
        },
        theme: {
          heroImage: {
            uploadDifferent: 'Upload a different hero image',
            uploadCustom: 'Upload a custom hero image',
            uploadInfo: 'Minimum 900x600px (Only JPG or PNG files)',
          },
        },
        map: {
          findAddressOnMap: 'Find address on map',
          latLong: 'Lat: {lat} Long: {long}',
          alert: 'Geocode was not successful for the following reason: ',
        },
      },
      pages: {
        share: 'Share your online booking minisite with customers',
        preferences: 'Waitlist, customer access & booking limits',
        theme: 'Colours, images & logos',
        embed: 'Embed code for widgets and ovatu bot',
        booking: 'Online booking, timetable and last minute bookings',
        giftCards: 'Sell gift cards online',
        passes: 'Sell passes online',
        reviews: 'Reviews from your customers',
        customer: 'Customer account page',
        siteNotice: 'Include an alert at the top of your book.app site',
        gallery: 'Show a gallery of images on your book.app site',
        contact: 'Contact details and address',
        businessHours: 'Business hours and holidays',
        social: 'Social accounts',
        payments: 'Manage online payment and deposit options',
        newCustomers: 'What fields are required for new customer registrations',
        minimiseGaps: 'Reduce unwanted gaps and manage times between appointments created online.',
        terms: 'Online booking customer terms and conditions',
        domain: 'Set a custom domain for your minisite',
        script: 'Custom script for your minisite',
        seo: 'Search engine optimisation settings',
        pages: 'Enable pages on your book.app site',
        customPages: 'Create additional pages for your book.app site',
        menu: 'Set the order of items in the menu',
        businessInfo: 'Contact details, social accounts and business hours',
        advanced: 'Advanced minisite options',
      },
    },
    notification: {
      actions: {
        autoTopUp: {
          title: 'SMS Auto Top-up',
          enableInfo: 'Enable SMS auto top-up',
          smsBalance: 'SMS Balance',
          smsBalanceInfo: 'Automatically purchase more SMS when my balance drops below:',
          smsRefill: 'SMS Refill',
          smsRefillInfo: 'When my SMS balance drops below the above amount, automatically purchase:',
          alertTitle: 'Enabling auto top-up will immediately charge your account',
          currentBalance: 'Your current SMS credit balance is {balance}',
          autoPurchase: 'When your balance drops below {limit} credits, we will automatically purchase the {refill} credits pack',
          immediateCharge: 'Because your balance is currently below the refill amount, we will immediately charge your account for {count} x {refill} credit packs',
          success: 'Updated SMS auto top-up settings',
        },
        purchaseCredit: {
          info: 'SMS Pack will be purchased using your subscription payment method.',
          smsPack: 'SMS Pack',
          paymentDetails: 'Payment details',
          validDetails: 'Please ensure you have entered valid details to purchase SMS credits',
          addPaymentMethod: 'Add a payment method in {accountBilling}',
          accountBilling: 'Account Billing',
          packLabel: '{quantity} {type} pack ({currency} {price})',
          success: 'Purchased SMS Credits',
          successInfo: 'Thanks, We\'ve received your SMS credit purchase, they will show up in your account shortly.',
          error: 'There was an error processing your payment, please check your payment method in account settings and try again.',
        },
      },
    },
    onboarding: {
      elements: {
        complete: {
          title: 'Welcome to Ovatu 👋',
          info: 'Your account is now active and ready to use!',
          manage: `
            You can manage your account at <a href="https://app.ovatu.com" target="_blank" class="text-primary-500 dark:text-white bg-primary-50 dark:bg-primary-800 px-1 rounded inline-flex space-x-1 items-center"><span>{slug}.ovatu.app</span> <o-icon icon="externalLink" size="10" /></a> and your new online booking website is available at <a href="https://ovatu.com" target="_blank" class="text-primary-500 dark:text-white bg-primary-50 dark:bg-primary-800 px-1 rounded inline-flex space-x-1 items-center"><span>{slug}.book.app</span> <o-icon icon="externalLink" size="10" /></a>
          `,
          chat: 'Chat with support',
          getHelp: 'Get help setting up your account and importing data',
          helpGuides: 'Visit our help guides',
          setUp: 'See how to set up your first service and add your first employee',
          iOS: 'Download for iOS',
          howDoIAddAnEmployee: 'How do I add an employee?',
          bookingScheduleScreenOverview: 'Booking/schedule screen overview',
          whatIsMyMinisite: 'What is my minisite (book.app)',
          howDoIAddANewProduct: 'How do I add a new product?',
          minisiteDemo: 'Minisite demo',
          howToCreateAClass: 'How to create a class',
          howToManageCustomers: 'How to manage customers',
          howToCreateASale: 'How to create a sale',
        },
        roster: {
          title: 'Set your roster',
          info: `
            Enter your normal working hours in the fields below. Breaks can be added by clicking Edit.<br>
            You will have the flexibility to modify individual dates later by accessing the Roster tab in your account.
          `,
        },
        service: {
          info: 'Add your first service by filling in the field below. You will be able to add as many services as you like, using the Manage tab of your account.',
          durationInfo: 'How long does the service take?',
          identifyService: 'Identify this service in your schedule.',
        },
        settings: {
          info: 'Add an extra layer of security by adding a PIN to your account. This allows you to quickly lock and unlock your account without entering your password when on trusted devices.',
          addPin: 'Add PIN',
          noPin: 'No PIN',
        },
        welcome: {
          title: "Welcome to Ovatu, let's get you started! 🎉",
          addEssentials: "Let's add a few of the essentials to get your account running.",
          nextSteps: 'The next 4 steps will show you the basics of getting started with Ovatu. It takes only a couple of minutes and everything you do within this process will be added to your account.',
          forIOS: 'For iPhone, iPad and Apple Watch',
          downloadForAndroid: 'Download for Android',
          forAndroid: 'For android phones and tablets',
          helpGuides: 'Some useful help guides to get you started:',
          getStarted: 'Get started',
        },
      },
    },
    order: {
      actions: {
        addCharge: {
          info: 'Add a charge to the sale',
          confirm: 'Add Charge',
          charge: 'Charge',
          newCharge: 'New charge',
          editCharge: 'Edit charge',
          success: 'Added charge',
          successInfo: 'Successfully added charge to sale',
        },
        addCustomerCredit: {
          info: 'Add customer credit to the sale',
          success: 'Added customer credit',
          successInfo: 'Successfully added customer credit to sale',
        },
        addDiscount: {
          info: 'Select a discount from the dropdown OR click the + button to add a new discount',
          success: 'Added discount',
          successInfo: 'Successfully added discount to sale',
        },
        addGiftCards: {
          info: 'Add an unpaid gift card to the sale',
          confirm: 'Add gift card',
          customerInfo: 'Find gift cards for the selected customer',
          noUnpaid: '{name} has no unpaid gift cards',
          loadingUnpaid: 'Loading unpaid gift cards for {name}...',
          success: 'Added gift cards',
          successInfo: 'Successfully added gift cards to sale',
        },
        addPasses: {
          info: 'Pay for existing pass or create a new pass',
          customerInfo: 'Find passes for the selected customer',
          noUnpaid: '{name} has no unpaid passes',
          loadingUnpaid: 'Loading unpaid passes for {name}...',
          addSelectedPass: 'Add selected pass',
          createNewPass: 'Create a new pass',
          success: 'Added passes',
          successInfo: 'Successfully added passes to sale',
        },
        addPayment: {
          pay: 'Pay {amount}',
          newCustomerPayment: 'New customer payment',
          changeDue: 'Change due: {change}',
          paymentAmountMoreThanRemainder: 'Payment amount more than remainder',
          saveChangeAsTip: 'Save change as tip',
          insufficientFunds: '{label} has insufficient funds for transaction',
          paymentButBalance: 'Payment amount is set to <strong>{amount}</strong> but {label} only has <strong>{balance}</strong> available',
          changePayment: 'Change payment to {balance}',
          saleFinalised: 'Sale is finalised and there is no further action required.',
          keepAsOverpayment: 'Keep as overpayment',
          returnChange: 'Return change',
          customerPaid: 'Customer paid',
          remainingBalance: 'Remaining balance',
          changeRequired: 'Change required',
          processingPayment: 'Processing payment',
          success: 'Added payment',
          successInfo: 'Successfully added {amount} {label} payment to sale',
        },
        addProduct: {
          title: 'Add product',
          info: 'Add a product item to the sale',
          success: 'components.order.actions.addProduct.success',
          successInfo: 'Successfully added products to sale',
        },
        addReservations: {
          title: 'Add appointments',
          info: 'Add existing appointments to the sale',
          customerInfo: 'Find appointments for the selected customer',
          findingAppointments: 'Finding appointments',
          noAppointments: 'No appointments for this customer were found',
          success: 'Added appointments',
          successInfo: 'Successfully added selected appointments to the sale',
        },
        addService: {
          info: 'Add manual service to the sale',
          success: 'Added service',
          successInfo: 'Successfully added service to sale',
        },
        closeOrder: {
          info: 'Close the sale: {number}',
          confirm: 'Confirm close sale',
          sendEmail: 'Send Email',
          sendEmailInfo: 'Send an email receipt to the customer',
          printInfo: 'Print a copy of the receipt',
          success: 'Closed sale',
          successInfo: 'Closed sale: {number}',
        },
        createOrder: {
          info: 'Create and open a new sale',
          saleReportingDate: 'Sale reporting date',
          saleReportingDateInfo: 'What date should we use for reporting purposes',
          appointmentDate: 'Appointment date',
          success: 'Created sale',
          successInfo: 'Successfully created a new sale',
        },
        deleteItem: {
          confirm: 'Delete item',
          success: 'Deleted item',
          successInfo: 'Successfully removed items from sale',
        },
        deleteOrder: {
          title: 'Delete sale',
          info: `
            Are you sure you want to delete sale: <br>
            <strong>#{number}</strong><br><br>
            The sale will be removed permanently from your account.
          `,
          success: 'Deleted sale',
          successInfo: 'Remove sale from account',
          errorInfo: 'Error while deleting sale.',
        },
        editItem: {
          title: 'Edit sale item',
          discountTaxType: 'Discount tax type',
          afterTax: 'After tax',
          beforeTax: 'Before tax',
          success: 'Updated item',
          successInfo: 'Successfully edited sale item',
        },
        editOrder: {
          success: 'Saved changes',
          successInfo: 'Successfully saved changes to sale',
        },
        editTip: {
          info: 'Set the tip amount for the sale',
          confirm: 'Save tip',
          tipAllocation: 'Tip allocation',
          tipAmount: 'Tip amount',
          tip: 'Tip: {name}',
          perEmployee: 'Per Employee',
          noEmployeesAssociated: 'No employees are associated with this sale',
          success: 'Set tip',
          successInfo: 'Successfully set tip for sale',
        },
        printReceipt: {
          no: 'No: {number}',
          employee: 'Employee: {name}',
          customer: 'Customer: {name}',
          receiptNo: 'Receipt No.',
        },
        refundPayment: {
          amountToRefund: 'Amount to refund, up to {amount}',
          refundWith: 'Refund transaction with {name}',
          refundWithInfo: 'Process refund using payment provider',
          deletePayment: 'Delete payment',
          success: 'Refunded payment',
          successInfo: 'Successfully refunded payment {label}',
          deleteSuccess: 'Deleted payment',
          deleteSuccessInfo: 'Successfully deleted payment {label}',
        },
        requestPayment: {
          title: 'Request payment',
          info: 'Request online payment from customer',
          detailsInfo: 'Description of payment request sent to customer',
          presets: 'Presets',
          deposit: 'Deposit ({value}%)',
          success: 'Requested payment',
          successInfo: 'Sent online payment request to customer',
        },
      },
      edit: {
        info: {
          notPaid: 'Not paid',
        },
        totals: {
          loyaltyPointsEarned: 'Loyalty points earned',
        },
      },
      elements: {
        payments: {
          integration: {
            square: {
              manual: {
                label: 'Manual - Card Payment',
              },
              terminal: {
                label: 'Terminal - Card Payment',
                continue: 'Continue transaction on terminal',
                cancel: 'Cancel transaction',
                failed: 'Transaction failed',
                timedOut: 'Transaction timed out',
              },
            },
            stripe: {
              manual: {
                saveCardDetails: 'Save credit card details',
              },
            },
            sumUp: {
              loading: 'Loading SumUp',
              rememberCard: 'Store credit card',
              rememberCardInfo: 'Save credit card on customer profile for future payments',
            },
          },
          static: {
            creditCard: {
              visa: 'Visa ({cardLast4})',
              amex: 'American Express ({cardLast4})',
              mastercard: 'Mastercard ({cardLast4})',
              expires: 'Expires: {cardExpiryMonth}/{cardExpiryYear}',
            },
            giftCardOther: {
              search: 'Search gift cards',
              searchAll: 'Search all gift cards.',
              noMatching: 'No matching gift cards found...',
              searching: 'Searching...',
              searchAllCustomerGiftCards: 'Search all customer gift cards',
            },
          },
          paymentProvider: {
            customerCredit: 'Customer Credit',
          },
        },
        barcode: {
          ready: 'Ready for barcode scanner',
          success: 'Added product',
          successInfo: 'Successfully added product to sale',
        },
        orderItem: {
          terminalPayment: 'Terminal Payment -',
          edit: 'Edit item',
          voucher: 'Voucher: {code}',
          refundPayment: 'Refund payment',
          removeFromSale: 'Remove item from sale',
        },
        orderSection: {
          addExistingAppointment: 'Add an existing appointment',
          addManualService: 'Add a manual service',
          requestOnline: 'Request online',
          requestOnlineInfo: 'Send customer a request to pay online',
        },
        select: {
          title: 'Select a sale',
        },
        selectMulti: {
          selected: '{count} Sales selected',
          order: 'Order',
        },
      },
    },
    panel: {
      panelLayout: {
        moveToCentre: 'Move panel to centre',
        moveToRight: 'Move panel to right',
      },
    },
    pass: {
      actions: {
        delete: {
          info: `
            Are you sure you want to delete pass: <br>
            <strong>{name}</strong> (#{id})<br><br>
            The pass will be removed permanently from your account.
          `,
          successInfo: 'Deleted pass record for: {name}',
          error: 'Error deleting pass',
          errorInfo: 'Unable to delete pass: {name}',
        },
        image: {
          info: 'Edit pass image for: <strong>{name}</strong>',
        },
      },
      edit: {
        details: {
          info: 'The name of the Pass',
          infoInfo: 'A description of this Pass. This will appear when purchasing this Pass online',
          priceInfo: 'Cost of the pass to the customer',
          limitMessage: '0 for no limit',
          limit: 'Online limit per customer',
          limitInfo: 'Limit how many of this pass a customers can purchase online',
          allowOnlinePurchase: 'Allow online purchase',
          allowOnlinePurchaseInfo: 'This pass can be purchased online',
          allowGifting: 'Allow gifting',
          allowGiftingInfo: 'Show the pass on the gift cards page and allow pass to be giftable',
          loyaltyEnabledInfo: 'Customer gains loyalty points when purchasing this pass',
          notifyCustomer: 'Notify customer when pass runs out',
          notifyCustomerInfo: 'Send a notification to the Customer once the pass has expired or run out of uses.',
          applyPass: 'Apply pass to appointment at:',
          includesExpiry: 'Does this pass include an expiry date/time',
          expiryDaysInfo: 'Number of days the Pass is valid for ie 1 month = 30 days',
          expiryMode: 'Expiry mode',
          expiryModeInfo: 'When the appointment must be made',
          daysRemainingReminder: 'Days remaining reminder',
          daysRemainingReminderInfo: 'Send the customer a reminder email when this number of days remain before expiry (0 for no reminder)',
          limitedUse: 'Limited use',
          limitedUseInfo: 'Include a limit on the number of uses for this Pass',
          numberOfUses: 'Number of uses',
          numberOfUsesInfo: 'Limit on the number of uses this pass provides',
          usesRemainingReminder: 'Uses remaining reminder',
          usesReminaingReminderInfo: 'Send the customer a reminder email when this number of uses remain (0 for no reminder)',
          cancelAppointmentBehaviour: 'Cancel appointment behaviour',
          cancelAppointmentBehaviourInfo: 'When an appointment using the pass has been cancelled',
          deleteAppointmentBehaviour: 'Delete appointment behaviour',
          deleteAppointmentBehaviourInfo: 'When an appointment using the pass has been deleted',
        },
        services: {
          info: 'Enable/Disable services for this pass',
          servicePrice: 'Service price (for reports)',
          usesDeducted: 'Uses deducted per service',
        },
        taxes: {
          info: 'Include {name} ({amount}) in pass cost',
        },
      },
    },
    paymentModel: {
      actions: {
        delete: {
          title: 'Delete payment',
          info: `
            Are you sure you want to delete payment: <br>
            <strong>{title}</strong> (#{id})<br><br>
            The payment will be removed permanently from your account.
          `,
          success: 'Deleted payment',
          successInfo: 'Deleted payment record for: {name}',
        },
        edit: {
          new: 'New payment',
          success: 'Saved payment',
          successInfo: 'Saved payment: {title}',
        },
      },
    },
    product: {
      actions: {
        addInventory: {
          title: 'Add inventory',
          info: 'Add inventory items to this product',
          amountInfo: 'Amount to add',
          reasonInfo: 'Reason for inventory change',
          success: 'Added inventory',
          successInfo: 'Added inventory to product: {title}',
          error: 'Error adding inventory',
          errorInfo: 'Unable to add inventory from product: {title}',
        },
        delete: {
          title: 'Delete product',
          info: `
            Are you sure you want to delete product: <br>
            <strong>{name}</strong> (#{id})<br><br>
            The product will be removed permanently from your account.
          `,
          success: 'Deleted product',
          successInfo: 'Deleted product record for: {name}',
          error: 'Error deleting product',
          errorInfo: 'Unable to delete product: {name}',
        },
        duplicate: {
          title: 'Duplicate product',
          info: 'Duplicate the product: <strong>{name}</strong>',
          success: 'Duplicated product',
          successInfo: 'Created new product: {name}',
          error: 'Error duplicating product',
          errorInfo: 'Unable to duplicate product: {name}',
        },
        image: {
          info: 'Edit product image for: <strong>{name}</strong>',
        },
        removeInventory: {
          title: 'Remove inventory',
          info: 'Remove inventory items to this product',
          amountInfo: 'Amount to remove',
          reasonInfo: 'Reason for inventory change',
          success: 'Removed inventory',
          successInfo: 'Removed inventory to product: {title}',
          error: 'Error removing inventory',
          errorInfo: 'Unable to remove inventory from product: {title}',
        },
        otherInfo: 'Other label',
      },
      edit: {
        details: {
          label: 'Product details',
          priceInfo: 'Cost of the product to customers',
          wholesalePrice: 'Wholesale price',
          wholesalePriceInfo: 'Cost of the product to your business',
          categoryInfo: 'Sort the product into is associated category',
          brandInfo: 'The product brand name',
          sku: 'SKU',
          skuInfo: 'Product stock keeping unit number',
          barcode: 'Barcode',
          barcodeInfo: 'Product barcode number',
          loyaltyEnabledInfo: 'Customers collect loyalty points when purchasing this item',
          taxesInfo: 'Taxes that should be applied to this product',
        },
        inventory: {
          info: 'Number of this product in stock',
          saleOrder: 'Sale/Order',
          added: 'Added',
          removed: 'Removed',
          supplierCode: 'Supplier code',
          reorderLevel: 'Re-order level',
          reorderLevelInfo: 'The level of stock at which more stock is to be ordered',
          minimumOrderQuantity: 'Minimum order quantity',
          minimumOrderQuantityInfo: 'The minimum amount to be re-ordered',
        },
      },
    },
    productCategory: {
      actions: {
        delete: {
          title: 'Delete product category',
          info: `
            Are you sure you want to delete product category: <br>
            <strong>{name}</strong> (#{id})<br><br>
            The product category will be removed permanently from your account.
          `,
          whatShouldWeDo: "What should we do with the products in this category<br> once it's deleted?",
          confirmRemove: 'Confirm remove products',
          confirmRemoveInfo: 'Delete all products in this category permanently',
          deleteProducts: 'Delete products',
          success: 'Deleted product category',
          successInfo: 'Deleted product category record for: {name}',
          error: 'Error deleting product category',
          errorInfo: 'Unable to delete product category: {name}',
        },
        reorder: {
          title: 'Reorder product categories',
          success: 'Save product category order',
        },
      },
    },
    purchaseOrder: {
      actions: {
        add: {
          info: 'Add products to purchase order',
          outOfStock: '- Out of stock ({inventory})',
          inStock: '- {inventory} in stock',
          auto: 'Auto ({inventory})',
          successInfo: 'Added item(s) to purchase order',
        },
        complete: {
          title: 'Complete order',
          info: `
            Are you ready to finalise this order? <br>
            When you finalise an order, all stock items will be added to inventory and you will no longer be able to edit the order.
          `,
          success: 'Completed purchase order',
          successInfo: 'Completed purchase order: {number}',
        },
        create: {
          info: 'Create a new purchase order',
          autoOrder: 'Auto order',
          autoOrderInfo: "'Auto Order' will automaticaly add all products for this supplier which are below the re-order levels.",
          success: 'Created purchase order',
          successInfo: 'Created new purchase order',
        },
        delete: {
          title: 'Delete purchase order',
          info: `
            Are you sure you want to delete purchase order: <br>
            <strong>{number}</strong> (#{id})<br><br>
            The purchase order will be removed permanently from your account.
          `,
          success: 'Deleted purchase order',
          successInfo: 'Deleted purchase order record for: {number}',
          error: 'Error deleting purchase order',
          errorInfo: 'Unable to delete purchase order: {number}',
        },
        details: {
          successInfo: 'Saved changes to purchase order',
        },
        edit: {
          priceInfo: 'Individual cost of each item',
          orderQuantity: 'Order quantity',
          orderQuantityInfo: 'Number of items to order',
          taxesInfo: 'Taxes that should be applied to this item',
          successInfo: 'Saved changes to purchase order item',
        },
        print: {
          title: 'Print purchase order',
          info: '{name} (#{number})',
          portrait: 'Portrait',
          landscape: 'Landscape',
          number: 'Number: ',
          shipTo: 'Ship to',
          unitPrice: 'Unit Price (ex Tax)',
          total: 'Total (ex Tax)',
        },
        receive: {
          title: 'Receive order',
          info: 'Check the received quantities before completing the order.',
          success: 'Received purchase order',
          successInfo: 'Received purchase order: {number}',
        },
        receiveAll: {
          title: 'Received all items',
          info: 'Mark all products as received in full',
          success: 'Received all items',
          successInfo: 'Received all items for: {number}',
        },
        receiveItem: {
          title: 'Received item(s)',
          success: 'Set received amount',
          quantity: 'Received quantity',
        },
        remove: {
          title: 'Remove purchase order item',
          info: `
            Are you sure you want to remove purchase order item: <br>
            <strong>{title}</strong> (#{id})
          `,
          successInfo: 'Deleted purchase order item record for: {title}',
        },
        send: {
          info: `
            Are you ready to send this order? <br>
            Mark this purchase order as being sent to the supplier.
          `,
          success: 'Marked as sent',
        },
      },
    },
    recurrence: {
      elements: {
        customer: {
          loading: 'Loading class recurrences',
          alreadyAttending: 'Already attending',
        },
        reservation: {
          repeatEvery: 'Repeat every',
          repeatOn: 'Repeat on',
          monthlyOnThe: 'Monthly on the',
          ending: 'Ending',
          endingDate: 'Ending date',
          recurrenceConflicts: 'Recurrence conflicts',
          recurrenceConflictsInfo: 'The recurrence options you have selected may cause conflicts with existing appointments',
          showOnlyConflicts: 'Show only conflicts',
          showAllRecurrenceDates: 'Show all recurrence dates',
          hasConflictsTooltip: 'To change recurrence options for this appointment, use the recurrence options in the appointment menu',
          hasConflicts: 'Has conflicts',
          noConflicts: 'No conflicts',
          recurrenceDateNoConflicts: 'Recurrence dates have no conflicts',
        },
      },
    },
    report: {
      actions: {
        filters: {
          title: '{title} filters',
          apply: 'Apply filters',
        },
      },
      elements: {
        filters: {
          date: '<span class="text-opacity-75 mr-2 font-normal">Date:</span> {scope}',
          nameTitle: '<span class="font-normal text-opacity-75 mr-2">{name}:</span> {title}',
          edit: 'Edit filters',
        },
        print: {
          reportSummary: 'Report summary',
        },
        section: {
          exportToCSV: 'Export to CSV',
          success: 'Exported {title} report',
          successInfo: 'Exported {title} to CSV file. Please check your downloads folder',
        },
        summary: {
          rawValue: 'Raw Value',
        },
      },
    },
    reservation: {
      actions: {
        attendeeAdd: {
          info: 'Add customer to appointment: {label}',
          enableRecurrence: 'Enable recurrence',
          success: 'Added customer',
          successInfo: 'Successfully added customer to reservation: {label}',
        },
        cancel: {
          confirm: 'Confirm cancellation',
          reason: 'Cancellation reason',
          notes: 'Cancellation notes',
          cancelFuture: 'Cancel future appointments',
          cancelFutureInfo: 'Cancel the current appointment, as well as all recurrences of this appointment',
          sendEmailInfo: 'Send an email to the customer letting them know the appointment has been cancelled',
          areYouSureClass: 'Are you sure you want to cancel the class?',
          areYouSure: 'Are you sure you want to cancel?',
          cancelViaCustomersTab: 'You can cancel individual customers via the Customers tab on the class appointment panel',
          success: 'Cancelled appointment',
          successInfo: 'Cancelled appointment for {name}',
        },
        checkIn: {
          info: 'Check {name} into reservation: {label}',
          successInfo: 'Checked {name} into reservation',
        },
        confirm: {
          title: 'Confirm appointment?',
          info: 'Confirm reservation: {label}',
        },
        delete: {
          title: 'Are you sure you want to delete this appointment?',
          removeCustomerFromClass: 'Are you sure you want to remove this customer from this class?',
          deleteFuture: 'Delete future appointments',
          deleteFutureInfo: 'Delete the current appointment, as well as all recurrences of this appointment',
          success: 'Deleted appointment',
          successInfo: 'Successfully deleted appointment',
          errorInfo: 'Error while deleting appointment',
        },
        discount: {
          title: 'Passes & Promo Codes',
          info: 'Apply passes and promo codes for {name} in reservation: {label}',
          alert: 'Unable to apply Pass because this service has already been added to a sale',
          selectPass: 'Select a promo code',
          selectPassOrPromo: 'Select a pass or promo code',
          passPromoSuccess: 'Applied pass & promo code',
          passPromoSuccessInfo: 'Applied pass & promo code for {name} in reservation: {label}',
          passSuccess: 'Applied pass',
          passSuccessInfo: 'Applied pass for {name} in reservation: {label}',
          promoSuccess: 'Applied promo code',
          promoSuccessInfo: 'Applied promo code for {name} in reservation: {label}',
        },
        editService: {
          info: 'Edit an existing service',
          processing: 'Processing (minutes)',
          finishing: 'Finishing (minutes)',
        },
        noShow: {
          title: 'No show',
          info: 'Flag customer as no show',
          confirm: 'Confirm no show',
          success: 'Mark as no show',
          successInfo: 'Recorded {name} as a no show for this appointment',
        },
        print: {
          label: '{time} - {title} with {name}',
        },
        rebook: {
          title: 'Rebook appointment',
          info: 'Pick a date to rebook the appointment',
          twentyFourWeeks: '24 Weeks',
          week: '{count} Week | {count} Weeks',
          keepNotes: 'Keep notes',
          keepNotesInfo: 'Copy notes from the original appointment to the new appointment',
        },
        recurrence: {
          title: 'Reservation Recurrence',
          confirm: 'Save recurring reservation',
          success: 'Recurrence created successfully',
          successInfo: 'Created new recurring appointment that {readable}',
          error: 'Please select an option for each conflicting date.',
        },
        sendEmail: {
          received: 'Send appointment received email to {recipient}.',
          confirmation: 'Send appointment confirmed email to {recipient}.',
          reminderEmail: 'Send appointment reminder email to {recipient}.',
          reminderSMS: 'Send appointment reminder SMS to {recipient}.',
          reviewRequest: 'Send appointment review request to {recipient}.',
          default: 'Send email to {recipient}.',
          successInfo: '{description} sent to {recipient}.',
        },
        undoCancel: {
          title: 'Are you sure you want to uncancel?',
          info: 'Undo cancel appointment: {label}',
          confirm: 'Confirm undo cancellation',
          success: 'Undo appointment cancellation',
        },
        undoCheckIn: {
          info: 'Undo check {name} into reservation: {label}. This action will remove applied passes from the appointment.',
          confirm: 'Confirm undo check-in',
        },
        undoNoShow: {
          title: 'Undo flag customer as no show',
          info: 'Undo flag {name} as no show.',
          confirm: 'Undo flag as no show',
        },
        unsavedChanged: {
          info: 'You have unsaved changes, would you like to save?',
        },
        undoCheckInSuccess: 'Undo check-in',
        undoCheckInSuccessInfo: 'Undo check customer {name} into reservation',
      },
      availability: {
        auto: {
          controller: {
            for: 'Availability for {date}',
            no: 'No availability for {date}',
            other: 'Other employees available',
            show: 'Show availability for all employees',
            next: 'Next available',
            loading: 'Loading availability',
            wrong: 'Something went wrong while finding availability...',
            error: 'There was an error checking for availability',
            success: 'No availability found',
            successInfo: 'No available slots were found for the next 90 days',
          },
          slot: {
            totalDelay: '{delay} mins waiting',
            service: '<span class="text-sm text-gray-500 dark:text-gray-400">Service:</span> {title}',
            employee: '<span class="text-sm text-gray-500 dark:text-gray-400">Employee:</span> {name}',
            includesResources: '(includes resources)',
          },
        },
        date: {
          waitlist: {
            requested: 'Requested dates:',
          },
        },
        manual: {
          controller: {
            alertTitle: 'Appointment may contain services which conflict with other items in the schedule or the employee may not be rostered.',
            ignoreConflict: 'Ignore conflict and double book appointment',
          },
        },
      },
      create: {
        availability: {
          recurrenceEnabled: 'Recurrence enabled',
          recurrenceDisabled: 'Recurrence disabled',
          repeat: 'Regularly repeat this appointment',
          change: 'Change future recurrences',
          dontChange: "Don't change future recurrences",
          affectFuture: 'This appointment is part of a recurrence group. Should these changes affect future recurrences?',
          oneServiceComplete: 'At least one service must be complete before selecting availability',
          waitlistTooltip: 'Add this appointment request to the waitlist',
          waitlist: 'Waitlist appointment',
        },
        confirm: {
          sendMovedEmail: 'Send the customer an email letting them know their appointment has been moved',
          sendConfirmationEmail: 'Send the customer an email confirming their appointment',
          full: 'This class is full',
          fullInfo: 'Please note that class has reached the maximum number of customers, confirming this customer will override the maximum allowed customers.',
        },
        discounts: {
          select: 'Please select an availability slot to see customer passes and promo codes',
        },
        discountService: {
          select: 'Select pass/promo code',
          noDiscounts: 'No discounts available',
        },
        move: {
          title: 'Moving appointment',
        },
        notes: {
          fieldInfo: 'Customer notes for this appointment',
          copyPrevious: 'Copy previous notes',
        },
        recents: {
          label: 'Recent appointments',
        },
        recurrence: {
          select: 'Please select an availability slot to set recurrence options',
          loading: 'Loading recurrence preview...',
        },
        service: {
          timings: 'Timings',
          timingInfo: 'Timing has been changed from its default value for this service',
          durationProcessingFinishing: '{duration}m ({processing}m processing, {finishing}m finishing)',
        },
        services: {
          noServices: 'You have not added any services',
        },
        waitlist: {
          content: '{services} with {employees} on {dates}',
        },
      },
      edit: {
        attendee: {
          discount: '-{label} discount',
          edit: 'Edit class customer',
          remove: 'Remove customer from class',
          noShow: "Customer didn't show up",
        },
        attendees: {
          noAttendees: 'This reservation has no customers',
          addACustomer: 'Add a customer',
          addCustomer: 'Add customer',
          search: 'Search customers',
        },
        communications: {
          emailReminder: 'Email reminder',
          date: '4:24 PM on Monday, October 24th 2020',
        },
        forms: {
          title: 'Requested forms',
          noForms: 'No forms have been added to this appointment',
          related: 'Related forms',
        },
        info: {
          recurrenceOf: 'Recurrence {number} of {count}',
          maxCustomers: 'Max Customers',
          attendees: '{count} customers',
          spotsLeft: '{count} spots left',
        },
      },
      elements: {
        availability: {
          showAllDates: 'Show all dates',
          showWaitlistDates: 'Show waitlist dates',
        },
        service: {
          remove: 'Remove service',
          name: '<span class="font-medium mr-1">{name}:</span> {displayName}',
        },
        statusRequestType: {
          label: 'Request Type: {description}',
        },
      },
      sendEmailInfo: 'Send the customer an email confirming their appointment',
    },
    reservationCustomer: {
      actions: {
        checkIn: {
          info: 'Check {name} into group appointment',
          successInfo: 'Checked {name} into group appointment',
        },
        confirm: {
          info: 'Confirm {name} for group appointment',
        },
        discount: {
          info: 'Apply passes and promo codes for {name} in reservation: {label}',
        },
        editService: {
          info: 'Edit service',
        },
        recurrence: {
          title: 'Class Customer Recurrence',
          success: 'Saved customer recurrence',
        },
      },
      edit: {
        info: {
          recurrenceEnabled: 'Recurrence enabled',
          fillingIn: '(Filling in for {name})',
        },
      },
    },
    reservationStatusType: {
      actions: {
        delete: {
          title: 'Delete status type',
          info: `
            Are you sure you want to delete status type: <br>
            <strong>{name}</strong> (#{id})<br><br>
            The status type will be removed permanently from your account.
          `,
          success: 'Deleted status type',
          successInfo: 'Deleted status type record for: {name}',
          error: 'Error deleting status type',
          errorInfo: 'Unable to delete status type: {name}',
        },
      },
    },
    resource: {
      actions: {
        deleteResource: {
          info: `
            Are you sure you want to delete resource: <br>
            <strong>{name}</strong> (#{id})<br><br>
            The resource will be removed permanently from your account.
          `,
          success: 'Deleted resource',
          successInfo: 'Deleted resource record for: {name}',
          error: 'Error deleting resource',
          errorInfo: 'Unable to delete resource: {name}',
        },
        removeOrDelete: {
          title: 'Remove or delete resource',
          info: 'Do you want to remove this resource from this resource group or delete the resource entirely?',
          remove: 'Remove resource from group',
        },
        remove: {
          info: `
            Are you sure you want to remove this resource: <br>
            <strong>{resourceName}</strong> (#{idEmployee})<br>
            From this resource group?: <br>
            <strong>{resourceTypeName}</strong> (#{idResourceType})
          `,
          success: 'Resource removed',
          successInfo: 'Successfully removed resource from resource group',
          error: 'Error removing resource',
          errorInfo: 'Unable to remove resource: {name}',
        },
      },
      edit: {
        details: {
          enabledInfo: 'Allow bookings with this resource',
          allowOnlingBookingInfo: 'Allow customers to book services with this resource online',
          rosteredInfo: 'Resource can be rostered to be available during specific times',
        },
        resourceTypes: {
          title: 'Resource options',
          info: 'Set which resource groups this resource is available for',
        },
        roster: {
          info: 'Set the normal hours for this resource',
        },
      },
    },
    resourceType: {
      actions: {
        delete: {
          title: 'Delete resource group',
          info: `
            Are you sure you want to delete resource group: <br>
            <strong>{name}</strong> (#{id})<br><br>
            The resource group will be removed permanently from your account.
          `,
          success: 'Deleted resource group',
          successInfo: 'Deleted resource group record for: {name}',
          error: 'Error deleting resource group',
          errorInfo: 'Unable to delete resource group: {name}',
        },
      },
    },
    review: {
      actions: {
        approve: {
          title: 'Approve review',
          info: 'Review will be displayed on your book.app minisite',
          success: 'Approved review',
          successInfo: 'Successfully approved review',
        },
        delete: {
          title: 'Are you sure you want to delete this review?',
          info: 'Review will be deleted permanently',
          success: 'Deleted review',
          successInfo: 'Successfully deleted review',
        },
        feature: {
          title: 'Feature review',
          info: 'Review will be pinned to the top of your book.app minisite reviews',
          success: 'Featured review',
          successInfo: 'Successfully featured review',
        },
        reply: {
          title: 'Reply to review',
          info: 'Reply will be visible on your book.app minisite',
          replyInfo: 'Reply to the customers review',
          success: 'Replied to review',
          successInfo: 'Successfully replied to review',
        },
        unapprove: {
          title: 'Undo approve review',
          info: 'Review will be removed on your book.app minisite',
          confirm: 'Undo approve',
          successInfo: 'Successfully unapproved review',
        },
        unfeature: {
          title: 'Undo feature review',
          info: 'Review will no longer be pinned to the top of your book.app minisite reviews',
          confirm: 'Undo feature',
          success: 'Undo featured review',
          successInfo: 'Successfully un-featured review',
        },
      },
    },
    roles: {
      actions: {
        create: {
          info: 'Create a new role based on an existing one',
          name: 'Role name',
          nameInfo: 'Enter a label name for the new role',
          existing: 'Existing role',
          existingInfo: 'Select an existing role to base the new one on',
          success: 'Created role',
          successInfo: 'Created new role: {name}',
        },
        delete: {
          title: 'Delete role',
          info: `
            Are you sure you want to delete the custom role: <br>
            <strong>{name}</strong> (#{id})<br><br>
            The role will be removed permanently from your account.
          `,
          success: 'Deleted role',
          successInfo: 'Deleted role record for: {name}',
          error: 'Error deleting role',
        },
      },
      elements: {
        checkbox: {
          dependenciesLabel: 'This permission also requires {dependencies}',
          listSeparator: ', ',
        },
        group: {
          permissions: '{count} permission | {count} permissions',
          description: 'Description of permission',
        },
        role: {
          deleteTitle: 'You cannot delete a role with employees assigned to it.',
          deleteInfo: 'Please remove the employees from the role before deleting it.',
        },
        select: {
          title: 'This employee is currently using a legacy role.',
          info: 'If you change the role, you can not change back to the legacy role. Are you sure you want to change the role?',
        },
      },
    },
    roster: {
      actions: {
        edit: {
          businessHours: 'Edit Business Hours',
          overrideStart: 'Override existing start times',
          overrideStartInfo: 'The selected days already have varying start times',
          overrideFinish: 'Override existing finish times',
          overrideFinishInfo: 'The selected days already have varying finish times',
          leaveType: 'Leave type',
          success: 'Roster saved',
          successInfo: 'Roster was successfully updated',
        },
        print: {
          title: 'Print Roster',
          info: 'Print a formatted view of the roster.',
          weekLabel: 'Week {count} - {startLabel} to {finishLabel}',
        },
        revert: {
          title: 'Revert roster dates',
          info: 'This will revert all selected roster dates to their default settings.',
          success: 'Roster reverted',
          successInfo: 'Roster was successfully reverted to normal working hours',
          error: 'Error during save',
          errorInfo: 'Some roster dates were unable to be saved',
        },
      },
      elements: {
        cells: {
          rosterCellWorking: {
            breaksLabel: '({count} breaks)',
          },
        },
        edit: {
          rosterEditBreaks: {
            breakOutsideHours: 'This break is outside of the employees working hours',
          },
        },
        headers: {
          employeeHeader: {
            viewMonth: 'View month',
            setNormalHours: 'Set normal hours',
          },
        },
      },
    },
    schedule: {
      actions: {
        create: {
          title: 'Create New',
          groupClass: 'Create a group class',
          employeeBreak: 'Create a employee break',
        },
        createBreak: {
          info: 'roster.addBreak',
        },
        editBreak: {
          title: 'Edit break',
          info: 'Change an employee break',
          delete: 'Delete break',
          save: 'Save break',
          removeSuccess: 'Removed break',
          removeSuccessInfo: 'Successfully removed break for {name}',
          editSuccess: 'Updated break',
          editSuccessInfo: 'Edited break on {date} at {time} for {name}',
        },
        moveBreak: {
          title: 'Confirm move break',
          areYouSure: 'Are you sure you want to move this break?',
          ogDetails: 'Original break details',
          newDetails: 'New break details',
        },
        moveReservation: {
          title: 'Confirm reservation move',
          info: 'Are you sure you want to move this reservation?',
          conflicts: 'Move will cause conflicts',
          conflictsInfo: 'This appointment contains services which conflict with other reservations or the employee is not available at the selected time.',
          with: '{title} with {name}',
          ogDetails: 'Original reservation details',
          newDetails: 'New reservation details',
          email: 'Email customer',
          emailInfo: 'Email customer',
          successInfo: 'Successfully moved appointment to: {label}',
        },
        settings: {
          title: 'Schedule preferences',
          resourcesInfo: 'Hide/show active resources in schedule day view',
          columnSize: 'Column size',
          columnSizeInfo: 'Width of the schedule columns',
        },
      },
      blocks: {
        reservation: {
          finishing: '(Finishing)',
          price: 'Price: {label}',
        },
      },
      elements: {
        container: {
          noEmployees: 'No employees rostered',
          noEmployeesInfo: 'There are no employees rostered. Please use the roster to set your employees availability.',
        },
        controller: {
          bookings: 'Bookings',
          disablePrivacyMode: 'Disable Privacy Mode',
          enablePrivacyMode: 'Enable Privacy Mode',
        },
        modifier: {
          movingAppointment: 'Moving appointment',
          movingBreak: 'Moving break',
        },
        preview: {
          otherServices: 'Other services:',
        },
        reservation: {
          with: '{services} with {employees}',
        },
        viewControls: {
          grid: 'Grid',
        },
      },
    },
    search: {
      search: {
        noResults: 'No results found.',
        toSelect: 'to select',
        toNavigate: 'to navigate',
        esc: 'Esc',
        toClose: 'to close',
      },
    },
    segments: {
      actions: {
        delete: {
          title: 'Delete segment',
          info: `
            Are you sure you want to delete segment: <br>
            <strong>{name}</strong> (#{id})<br><br>
            The segment will be removed permanently from your account.
          `,
          success: 'Deleted segment',
          successInfo: 'Deleted segment: {name}',
        },
      },
      elements: {
        select: {
          placeholder: 'Add segment',
        },
      },
    },
    service: {
      actions: {
        addForm: {
          addInfo: 'Add form requirement to service',
          formInfo: 'The form you would like to add',
          success: 'Added form',
          successInfo: 'Added form to service: {title}',
          error: 'Error adding form',
          errorInfo: 'Unable to add form to service: {title}',
        },
        addResource: {
          title: 'Add resource',
          info: 'Add a resource or group of resources to this service',
          resourceInfo: 'Select the resource or group of resources you would like to add',
          useCustomLabel: 'Use custom label',
          labelInfo: 'A custom name for this service resource',
          defaultInfo: 'The default resource selection when booking this service',
          assignment: 'Assignment',
          assignmentInfo: 'Assign the resource per customer or per class',
          quantityInfo: 'The number of resources required from this group',
          customerVisibleInfo: "Allow your customer to see which Resource or Group they've been allocated for this service",
          assignAutomatically: 'Assign automatically',
          assignManually: 'Assign manually',
          success: 'Added resource',
          successInfo: 'Added resource to service: {name}',
          error: 'Error adding resource',
          errorInfo: 'Unable to add resource to service: {name}',
        },
        delete: {
          title: 'Delete service',
          info: `
            Are you sure you want to delete service: <br>
            <strong>{title}</strong> (#{id})<br><br>
            The service will be removed permanently from your account.
          `,
          success: 'Deleted service',
          successInfo: 'Deleted service record for: {title}',
          error: 'Error deleting service',
          errorInfo: 'Unable to delete service: {title}',
        },
        duplicate: {
          title: 'Duplicate service',
          info: 'Duplicate the service: <strong>{title}</strong>',
          success: 'Duplicated service',
          successInfo: 'Created new service: {title}',
          error: 'Error duplicating service',
          errorInfo: 'Unable to duplicate service: {title}',
        },
        image: {
          info: 'Edit service image for: <strong>{title}</strong>',
        },
        removeForm: {
          title: 'Remove form',
          info: `
            Are you sure you want to remove this form?: <br>
            <strong>{title}</strong> (#{id})<br><br>
            This will prevent the form from being automatically sent to customers for all future appointments.
          `,
          success: 'Removed service form',
          successInfo: 'Removed service form: {title}',
          error: 'Error removing service form',
          errorInfo: 'Unable to remove service form: {title}',
        },
        removeResource: {
          info: `
            Are you sure you want to remove this resource?: <br>
            <strong>{name}</strong> (#{id})<br><br>
          `,
          success: 'Removed service resource',
          successInfo: 'Removed service resource: {name}',
        },
        updatePrices: {
          info: 'Would you like to apply the service price to all employees and future appointments?',
          priceChangedInfo: 'Would you like to apply the service price to future appointments?',
          updateAllEmployees: 'Update all employees',
          updateAllEmployeesInfo: 'All employee prices for this service will be overridden',
        },
      },
      edit: {
        details: {
          create: 'Create a new category',
          newCategory: 'New Category',
          newCategoryInfo: 'Create a new category for this service. This will be available for other services.',
          categoryTitle: 'Category title',
          cateogryTitleInfo: 'The category name',
          categoryInformation: 'Category Information',
          select: 'Select an existing category',
          advancedTimingOptions: 'Advanced timing options',
          apply: 'Apply this price to all employees and future appointments',
          reset: 'Reset',
          costPrice: 'Cost price',
          costPriceInfo: 'Cost price of the service, used for reporting',
          colourInfo: 'Colour to identify this service in your schedule.',
          loyaltyEnabledInfo: 'Allow customers to earn loyalty points from this service',
          taxesInfo: 'Taxes that should be applied to this service',
        },
        forms: {
          title: 'Service forms',
          info: 'Manage and automate forms for this service',
          requirement: 'Requirement',
          hours: '({value} hours {mode})',
        },
        online: {
          title: 'Online booking enabled',
          info: 'Allow service to be bookable using book.app',
          deposit: 'Deposit',
          depositInfo: 'Require a deposit for this service when booking online',
          usingDefault: 'Using default online booking settings',
          cardCapture: 'Card capture',
          cardCaptureInfo: 'Require credit card capture during book.app payments',
          cardCaptureRequired: 'Card capture required',
        },
        reminders: {
          title: 'Rebooking reminder',
          info: 'Send the customer a rebooking reminder for this service. Please note: If a future appointment is already booked for this service, the rebooking reminder will not be sent.',
          days: 'Rebooking reminder days',
          daysInfo: "Number of days after a customer's appointment to send the rebooking reminder",
          mode: 'Review reminder mode',
          modeInfo: 'When to send a review request to customers for this service',
        },
        resources: {
          info: 'Manage resource and resource groups for this service',
          noResources: 'There are no resources set up for this service.',
          add: 'Add resource/group',
          resourceGroup: 'Resource/Group',
          defaultSelection: 'Default selection',
          perCustomer: '(per customer)',
          first: 'First available (Automatic)',
          processingInfo: 'A gap between Duration and Finishing blocks',
          finishingInfo: 'Final service block',
          customerTimeMode: 'Customer time mode',
          customerTimeModeInfo: 'Which times (Duration, Processing & Finishing) are displayed to the Customer',
          bufferTimes: 'Buffer Times',
          bufferTimesInfo: "Add 'padding' time to your appointments, for events such as travel or cleaning.",
          preService: 'Pre-service buffer time',
          preServiceInfo: 'Default buffer time for before this service',
          postService: 'Post-service buffer time',
          postServiceInfo: 'Default buffer time for after this service',
          bufferMode: 'Buffer time mode',
          bufferModeInfo: 'How the buffer times should behave in a multi-service appointment',
        },
      },
      elements: {
        resource: {
          groups: 'Groups',
        },
        select: {
          title: 'Select a service',
        },
      },
    },
    serviceCategory: {
      actions: {
        delete: {
          title: 'Delete service category',
          info: `
            Are you sure you want to delete service category: <br>
            <strong>{name}</strong> (#{id})<br><br>
            The service category will be removed permanently from your account.
          `,
          whatDo: "What should we do with the services in this category<br> once it's deleted?",
          confirm: 'Confirm remove services',
          confirmInfo: 'Delete all services in this category permanently',
          services: 'Delete services',
          success: 'Deleted service category',
          successInfo: 'Deleted service category record for: {name}',
          error: 'Error deleting service category',
          errorInfo: 'Unable to delete service category: {name}',
        },
        image: {
          info: 'Edit service category image for: <strong>{title}</strong>',
        },
        reorder: {
          title: 'Reorder service categories',
          success: 'Save service category order',
        },
      },
    },
    session: {
      actions: {
        revoke: {
          title: 'Revoke Session',
          info: 'This session will be revoked immediately',
          successInfo: 'Successfully revoked session',
        },
        revokeEmployee: {
          info: 'This session for the selected employee will be revoked immediately',
          successInfo: 'Successfully revoked employee sessions',
        },
      },
    },
    stockTake: {
      actions: {
        apply: {
          title: 'Apply stock take',
          info: 'Finalise stock take. All stock inventory will be adjusted and you will no longer be able toe sit the stock take.',
          successInfo: 'Successfully applied stock take: {description}',
        },
        delete: {
          title: 'Delete stock take',
          info: `
            Are you sure you want to delete stock take: <br>
            <strong>{description}</strong> (#{id})<br><br>
            The stock take will be removed permanently from your account.
          `,
          success: 'Deleted stock take',
          successInfo: 'Deleted stock take record for: {description}',
          error: 'Error deleting stock take',
          errorInfo: 'Unable to delete stock take: {description}',
        },
        inventoryReset: {
          title: 'Inventory reset',
          warningInfo: 'This will reset all product inventory to 0. This action cannot be un-done. Are you sure you wish to continue?',
          success: 'Product inventory reset',
          successInfo: 'All products inventory has been reset to 0',
        },
      },
    },
    supplier: {
      actions: {
        delete: {
          title: 'Delete supplier',
          info: `
            Are you sure you want to delete supplier: <br>
            <strong>{name}</strong> (#{id})<br><br>
            The supplier will be removed permanently from your account.
          `,
          success: 'Deleted supplier',
          successInfo: 'Deleted supplier record for: {name}',
          error: 'Error deleting supplier',
          errorInfo: 'Unable to delete supplier: {name}',
        },
      },
      elements: {
        select: {
          title: 'Select a supplier',
          noProducts: 'No products',
        },
      },
    },
    tax: {
      actions: {
        bulkSet: {
          enableInfo: `
            Are you sure you would like to bulk enable {name} for all {type}?<br><br>
            This will override all existing settings for {type}.
          `,
          disableInfo: `
            Are you sure you would like to bulk enable {name} for all {type}?<br><br>
            This will override all existing settings for {type}.
          `,
          enableSuccess: 'Successfully enabled {name} for all {type}',
          disableSuccess: 'Successfully disabled {name} for all {type}',
        },
        delete: {
          title: 'Delete tax',
          info: `
            Are you sure you want to delete tax: <br>
            <strong>{name}</strong> (#{id})<br><br>
            The tax will be removed permanently from your account.
          `,
          success: 'Deleted tax',
          successInfo: 'Deleted tax record for: {name}',
          error: 'Error deleting tax',
          errorInfo: 'Unable to delete tax: {name}',
        },
      },
    },
    upload: {
      actions: {
        delete: {
          title: 'Delete uploaded file',
          info: `
            Are you sure you want to delete this upload?<br><br>
            The file will be removed permanently from the account.
          `,
          success: 'Deleted uploaded',
          successInfo: 'Removed file from account',
          errorInfo: 'Error while deleting upload.',
        },
      },
      elements: {
        list: {
          file: 'FILE',
          dateUploaded: 'Date uploaded',
        },
        preview: {
          notSupported: 'Your browser does not support the video tag.',
          download: 'Download file',
          name: 'Name of the file',
          success: 'Saved upload',
          successInfo: 'Successfully saved changes to file',
        },
        upload: {
          label: 'Click to upload or drag and drop your file(s)',
          info: 'Max 5MB per file',
          tooLarge: 'File too large',
          complete: 'Complete!',
        },
      },
    },
    voucher: {
      actions: {
        delete: {
          title: 'Delete promo code',
          info: `
            Are you sure you want to delete promo code: <br>
            <strong>{name}</strong> (#{code})<br><br>
            The promo code will be removed permanently from your account.
          `,
          success: 'Deleted promo code',
          successInfo: 'Deleted promo code record for: {name}',
          error: 'Error deleting promo code',
          errorInfo: 'Unable to delete promo code: {name}',
        },
      },
      edit: {
        details: {
          info: "The code used by customers to redeem (ie 'XMAS20')",
          createMultiple: 'Create multiple promo codes',
          codeList: 'Code list',
          codeListInfo: "A list of codes used by customers to redeem (ie 'XMAS20')",
          createSingle: 'Create single promo code',
          voucherTitle: 'The title of the voucher',
          infoInfo: 'A description of the promo code',
          discountAmount: 'Discount amount',
          customerType: 'Customer type',
          customerTypeInfo: 'Who this promo code is valid for',
          multipleServiceMode: 'Multiple service mode',
          multipleServiceModeInfo: 'How should a promo code be applied to multi service bookings',
          leaveBlankNoLimit: 'Leave blank for no limit',
          appointmentLimit: 'Appointment limit',
          appointmentLimitInfo: 'The total number of appointments this promo code can be used for',
          appointmentLimitPerCustomer: 'Appointment limit per customer',
          appointmentLimitPerCustomerInfo: 'The total number of appointments one customer can use this promo code for',
          leaveBlankMinSpend: 'Leave blank for no minimum spend',
          minimumSpend: 'Minimum spend',
          minimumSpendInfo: 'The minimum value of one appointment, before this promo code can be applied',
          codesUnique: 'Codes must be unique.',
          codeLimit: '100 code limit.',
        },
        employee: {
          enabled: 'Enabled (on/off)',
          enableDisable: 'Enable/disable all employees',
          onlineBookingURL: 'Online Booking URL',
          urlInfo: 'Share this URL with your customers, this will add the promo code automatically and only show availability which is valid for the promo code.',
          onlineBookingTimetableURL: 'Online Booking Timetable URL',
          onlineBookingLastMinuteURL: 'Online Booking Last-Minute URL',
        },
        services: {
          enableDisable: 'Enable/Disable services for this promo code',
        },
        validity: {
          title: 'Appointment date range',
          info: 'The date range for appointments to be booked using this promo code. You can also limit this to specific days of the week and times.',
          startDate: 'Appointment start date',
          endDate: 'Appointment end date',
          startTime: 'Appointment start time',
          endTime: 'Appointment end time',
          activeDateRange: 'Active date range',
          activeDateRangeInfo: 'The date range during which the promo code can be used online (not the appointment date)',
          activeStartDate: 'Active start date',
          activeEndDate: 'Active end date',
          limit: 'Limit the days this promo code can be used',
        },
      },
    },
    waitlist: {
      actions: {
        cancel: {
          info: 'Cancel this waitlist request',
          confirm: 'Cancel waitlist',
          successInfo: 'Waitlist request has been cancelled',
        },
        create: {
          title: 'Add appointment to waitlist',
          with: 'with {name}',
          withAnyone: 'with anyone',
          dateTimes: 'Date/times:',
          timeToTime: '{from} to {to}',
          anyTime: 'any time',
          noDates: 'You have not added any dates',
          fromTime: 'From time',
          toTime: 'To time',
          error: 'Finish time must be after start time',
          success: 'Created waitlist',
        },
        delete: {
          info: 'Delete this waitlist request',
          successInfo: 'Waitlist request has been deleted',
        },
        email: {
          info: 'Send an email to the customer to confirm you have received their waitlist request',
          successInfo: 'Email sent to customer.',
        },
        notify: {
          info: 'Let the customer know you have availability for them',
          successInfo: 'Availability notification sent to customer.',
        },
      },
    },
  },
  pages: {
    account: {
      index: {
        users: {
          roles: {
            title: 'Built-in roles',
            customRoles: 'Custom roles',
            noCustomRoles: 'No custom roles',
          },
          users: {
            bookableEmployees: 'Bookable Employees',
            editEmployees: 'Edit bookable employee',
            usersInfo: "If you require users that do not need to be scheduled and won't add to your billable employees, you can create them here",
            newUser: 'New user',
            edit: 'Edit user',
            employeesInfo: 'All employees are users of your account and can login to apps. If you need to edit additional Employee details, please use the Employees section',
          },
        },
        billing: {
          name: 'Plan',
          trialAccount: 'Trial account',
          trialPlan: 'Click below to subscribe to a plan (You will not be charged until your trial period is complete).',
          trialDaysRemaining: 'You are currently on a FREE trial, with {count} days remaining.',
          trialInfo: 'Your account is currently in trial mode. In order to continue using Ovatu, please click below to subscribe',
          endTrial: 'Want to end your trial early? Click here',
          confirmEndTrialTitle: 'Are you sure?',
          confirmEndTrialInfo: 'Your account will be deactivated immediately',
          confirmEndTrialButton: 'Yes, cancel my trial',
          planDetails: 'Plan Details',
          currentPlan: 'Current Plan',
          billableEmployees: 'Billable employees: {employees}',
          cancelPlan: 'Cancel plan',
          perMonth: 'per month',
          moreDetails: 'More details',
          nextBillingDate: 'Next billing date',
          ends: 'Ends {date}',
          onTheHouse: 'On the house',
          employees: '{employees} employees',
          paymentMethod: 'Payment Method',
          card: '{type} ending in {last4}',
          expiry: 'Expiry',
          noPaymentMethod: 'No payment method',
          noPaymentMethodInfo: 'You do not have an active payment method.',
          addPaymentMethod: 'Add payment method',
        },
        calendar: {
          name: 'Calendar feeds',
          location: 'Location calendar feeds',
          info: 'Includes appointments for all employees',
          new: 'New calendar feed',
          copy: 'Copy calendar feed URL to clipboard',
          copyURL: 'Copy URL',
          delete: 'Delete calendar token',
          employee: 'Employee calendar feeds',
          newEmployee: 'New employee calendar feed',
        },
        devices: {
          title: 'Devices',
        },
        exports: {
          name: 'Exports',
          new: 'New export',
          invalid: 'Invalid',
          rerun: 'Re-run',
        },
        index: {
          info: 'Manage your Ovatu subscription, account security and access, customer consent and privacy settings, and integrations with third party apps and hardware.',
        },
        integrations: {
          marketingInfo: 'Communicate with customers and sync mailing lists',
          onlinePayments: 'Online Payments',
          onlinePaymentsInfo: 'Accept payments on your book.app minisite',
          inStorePayments: 'In-store Payments',
          inStorePaymentsInfo: 'Accept payments using 3rd party devices',
          messaging: 'Messaging',
          messagingInfo: 'Send automated business alerts',
          conferencing: 'Conferencing',
          conferencingInfo: 'Take bookings online with video calls',
          paymentStorage: 'Payment Storage',
          paymentStorageInfo: 'Monitor sales and payments',
          social: 'Social',
          socialInfo: 'Connect with social accounts to share updates',
        },
        policy: {
          requiresAgreement: 'Requires agreement',
        },
        privacy: {
          name: 'Privacy',
          title: 'Privacy consent',
          info: 'The privacy consent when enabled is mandatory. This means that it will not be possible to create an account for a customer if enabled.',
          titleInfo: 'The title (heading) of your Privacy consent',
          content: 'Privacy consent content',
          contentInfo: 'The Privacy consent customers must agree to',
          acceptLabelInfo: "The label for the Privacy consent acceptance checkbox, e.g. 'I accept the privacy consent'",
          marketingConsent: 'Marketing Consent',
          marketingConsentInfo: 'The Marketing Consent is optional. This means that if a Customer has this checkbox de-selected, an account can still be created, but this customer will be unsubscribed from all marketing emails and SMS.',
          marketingConsentTitleInfo: 'The title (heading) of your Marketing consent',
          marketingConsentContent: 'Marketing consent content',
          marketingConsentContentInfo: 'The Marketing consent customers must agree to',
          marketingConsentAcceptLabelInfo: "The label for the Marketing consent acceptance checkbox, e.g. 'I accept the Marketing consent'",
          euCookieLaw: 'EU Cookie Law',
          euCookieLawInfo: `
            The Cookie Law is a piece of privacy legislation that requires websites to get consent from visitors to store or retrieve any information on a computer, smartphone or tablet.
            It was designed to protect online privacy, by making consumers aware of how information about them is collected and used online, and give them a choice to allow it or not.
          `,
          successInfo: 'Saved privacy preferences',
        },
        referral: {
          name: 'Referrals',
          step1: '1. Share your referral link!',
          step1Info: `
            Simply share your special referral link below with your friend OR enter their details into the 'Invite your friends' area.
          `,
          step2: '2. Your friend gets a 30% discount for 3 months!',
          step2Info: 'If your friend signs up using your referral link, they will automatically receive a 30% discount for the first 3 months of their subscription.',
          step3: '3. You get one month FREE!',
          step3Info: 'When your friend becomes a paying Ovatu customer, we will apply a ‘One month FREE’ discount to your account as a thank you.',
          share: 'Share your referral link',
          shareInfo: `This is your special referral link. Simply copy and paste it and send it to a friend who needs a little help with their scheduling.
            Any friend who uses this link to sign up to an Ovatu trial will:
            <ul class="list-disc list-inside pl-4">
              <li>Appear in the "Existing referrals" area</li>
              <li>Automatically receive a "30% for 3 months" discount</li>
            </ul>
            `,
          invite: 'Invite your friends',
          inviteInfo: "We can email your referral link to your friend for you. Simply enter your pals details below and we'll email them on your behalf. If they sign up, you'll see their details appear below.",
          existingReferrals: 'Existing referrals',
          existingReferralsInfo: 'Your friends\' progress, as well as your gift, will be tracked below.',
          noExistingReferrals: 'No existing referrals...',
          unknownAccount: 'Unknown Account - <span class="font-normal">#{id}</span>',
          termsAndConditions: 'Ovatu Referral Program Terms & Conditions',
          termsAndConditionsInfo: `
            <li>A referral is considered successful when the referred customer signs up for a paid subscription and completes 2 monthly payment cycles.</li>
            <li>A "One month free" discount will be applied to the referring account, valid for the next consecutive monthly subscription payment only.</li>
            <li>A referring account is eligible for a maximum of 12 free months in a calendar year.</li>
            <li>The "One month free" discount is valid for the referring account only</li>
            <li>Only paying Ovatu customers are eligible for the referral program.</li>
            <li>Only referrals entered in using this dashboard, or made via the referral link on this dashboard are eligible for the free month reward.</li>
            <li>Ovatu reserves the right to change or withdraw the referral program at our discretion.</li>
            <li>Ovatu reserves the right to withhold the referral program from an individual user/account at our discretion if we suspect misuse</li>
            <li>Ovatu reserves the right to change these Terms & Conditions at any time</li>
            <li>Referred customers who have NOT signed up using the above referral link process will not be eligible for the 30% for 3 months discount for the referee or the one month free for the referrer.</li>
          `,
          facebook: '{url} is the best way to schedule your appointments. Join me and get 30% off for 3 months!',
          twitter: '{url} is the best way to schedule your appointments. Join me and get 30% off for 3 months! #OVATU',
          instagram: '{url} is the best way to schedule your appointments. Join me and get 30% off for 3 months! #OVATU',
          copied: 'Copied!',
          copyLink: 'Copy link',
          success: 'Referral sent',
          successInfo: 'Successfully sent referral to {firstName} {lastName} ({email})',
        },
        security: {
          name: 'Security',
          app: 'App Auto-lock',
          appInfo: 'Automatically lock the Ovatu App (iOS, Android) when inactive',
          appTimeout: 'App PIN timeout',
          appTimeoutInfo: 'Idle time before app requires PIN',
          web: 'Web Auto-lock',
          webInfo: 'Automatically lock the web app when inactive',
          webTimeout: 'Web App PIN timeout',
          webTimeoutInfo: 'Idle time before web app requires PIN',
          restrict: 'Restrict employee login IPs',
          restrictInfo: 'Your employees will only be able to login from the following IP addresses',
          message: 'One IP address per line.',
          employeeBlacklist: 'Employee IP Blacklist',
          employeeBlacklistInfo: 'Prevent logging in from the following IP addresses',
          onlineBlacklist: 'Online Booking IP Blacklist',
          onlineBlacklistInfo: 'Prevent online booking (book.app) from the following IP addresses',
          successInfo: 'Saved security preferences',
        },
        sessions: {
          title: 'Sessions',
          info: 'Currently logged in devices and access history',
          client: 'Client',
          device: 'Device',
          deviceIP: 'Device IP',
          loggedIn: 'Logged in',
          lastAccessed: 'Last accessed',
          revoked: 'Revoked',
          revoke: 'Revoke session',
        },
        storage: {
          title: 'Cache state',
          info: 'Force refresh locally stored account data',
          force: 'Force sync all',
          name: 'Sync',
        },
      },
      inactive: {
        title: 'Inactive account',
        noSub: 'Your account does not have an active subscription.',
        pricePerEmployee: `
          First employee is {currency}{price}<br>
          Each additional employee is {currency}{pricePerEmployee}
        `,
        reactivate: 'Reactivate account',
      },
      Index: {
        search: 'Search account options',
      },
      subscribe: {
        usingTrial: 'You are currently using a trial of Ovatu. This trial will end in {days} days.',
        processedImmediately: '(Payment will be processed immediately)',
      },
      unsubscribe: {
        sorryToSeeYouGoName: "{name}, we're sorry to see you go",
        sorryToSeeYouGo: "We're sorry to see you go",
        tellUsWhy: 'Would you like to tell us why?',
        anythingElse: 'Is there anything else you wish to tell us?',
        thanks: 'Thanks for letting us know!',
        perhaps: 'Before you go... Perhaps we can help?',
        request: "Request an Ovatu expert to review your account and ensure you're getting the most value from it.",
        yesPlease: "Yes please, I'd like to chat with someone.",
        noThanks: "No thanks, I'd just like to cancel.",
        iUnderstand: 'I understand that this will cancel my Ovatu subscription.',
        confirm: 'Confirm cancellation',
        great: 'Great! Please let us know what day of the week and time we can call you',
        requestCallback: 'Request callback',
        cancelled: 'Your Ovatu subscription has been cancelled',
        back: 'Back to account',
        thankYou: 'Thank you',
        wellCall: 'We will call you at your requested time',
        name: 'Unsubscribe',
        competitorLabel: 'I\'m moving to another provider',
        competitorInfo: 'Which provider are you moving to and why?',
        featuresLabel: 'Ovatu doesn\'t do what I need',
        featuresInfo: 'Let us know what you need, we\'re always adding new features!',
        derp: 'I don\'t understand how to use Ovatu',
        valueLabel: 'I don\'t see the value in using booking software',
        valueInfo: 'Let us know what you\'re having trouble with',
        closing: 'My business is closing',
        price: 'It\'s too expensive',
        other: 'Other - please tell us more',
      },
      verify: {
        title: 'Verify account',
        info: 'Please verify your address to activate email sending.',
        resend: 'Resend verification email',
        success: 'Resent',
      },
    },
    adminUser: {
      edit: {
        newUser: 'New user',
        userID: '<span class="text-gray-700 dark:text-gray-200">User ID:</span> {id}',
        success: 'User details saved',
        successInfo: 'The user details were successfully saved',
      },
    },
    alerts: {
      index: {
        unreadAlerts: '{count} Unread alerts',
        total: '{count} total',
        markAllAsRead: 'Mark all as read',
        deleteAll: 'Delete all alerts',
        createAnAccount: 'Create an account',
        noAlerts: 'No alerts...',
        unread: 'Unread',
        read: 'Read',
        history: '{label} History',
        action: 'Action',
        changed: 'Changed',
        success: 'Alerts marked as read',
        successInfo: 'All of your unread alerts have been marked as read.',
      },
    },
    appointment: {
      attendee: {
        id: {
          move: 'Move customer',
        },
      },
      id: {
        customEmail: 'Create & send a custom email',
        emailAppointmentCustomers: 'Email appointment customers',
        customSMS: 'Create & send a custom SMS',
        smsAppointmentCustomers: 'SMS appointment customers',
        appointmentReceived: 'Appointment received',
        justThis: 'Just this appointment',
        allFuture: 'All future appointments',
        attendeeUnavailable: 'Customer unavailable',
        attendeeUnavailableInfo: 'The customer you were editing is no longer on this appointment. Would you like to open the new appointment?',
        success: 'Reservation saved',
        successInfo: 'The reservation details were successfully saved',
      },
      create: {
        servicesInfo: 'Add services to the appointment',
        availabilityInfo: 'Find an available slot',
        futureRecurring: 'Future recurring appointments',
        setToRepeat: 'Set this appointment to repeat',
        passesPromoCodes: 'Passes and promo codes',
        passesPromoCodesInfo: 'Apply available customer passes and promo codes to this appointment',
        notesInfo: 'Appointment and customer notes',
        confirmInfo: 'Validate and confirm appointment details',
        addClass: 'Add Class',
        addFromWaitlist: 'Add Appointment from waitlist request',
        moveExisting: 'Move existing appointment',
        add: 'Add Appointment',
        selectCustomer: 'Select a customer',
        selectService: 'Select at least one service',
        selectTime: 'Select a time',
        selectRecurrence: 'Select recurrence',
        confirm: 'Confirm appointment',
        success: 'Created appointment',
        successInfo: 'Successfully created new appointment: {label}',
        movedSuccessInfo: 'Successfully moved existing appointment: {label}',
      },
      list: {
        export: 'Export to CSV',
        attendees: 'Customers',
      },
    },
    appointmentStatusType: {
      id: {
        id: '<span class="text-gray-700 dark:text-gray-200">Status Type ID:</span> {id}',
        label: 'The status label',
        shortNameInfo: 'A label used to identify this status on the booking schedule',
        colourInfo: 'Colour of the status label tag on appointments',
        success: 'Status type details saved',
        successInfo: 'The status details were successfully saved',
      },
    },
    auth: {
      index: {
        find: {
          index: {
            accounts: {
              welcomeBack: 'Welcome back!',
              info: 'Select the account you would like to log in to',
              dontSeeBusiness: "Don't see your business here? {tryDifferentEmail}",
              tryDifferentEmail: 'Try a different email address',
            },
            domain: {
              title: 'Sign in to your business',
              info: 'Enter your businesses Ovatu domain name',
              loginNext: 'This login is for Ovatu Next',
              loginClassic: 'If you use Ovatu Classic, please <a href="https://ovatu.com/location" class="underline">login here</a>.',
              moveToNext: 'If you are ready to move over to Ovatu Next, please contact <a href="mailto:hello@ovatu.com" class="underline">hello@ovatu.com</a>',
            },
            email: {
              title: 'Welcome back!',
              info: 'Enter your email address to log into your account.',
              ifOldOvatu: 'If you use the old version of Ovatu, please {loginHere}.',
              loginHere: 'login here',
            },
          },
        },
        integration: {
          index: {
            guid: {
              connect: {
                info: 'Please enter your businesses Ovatu domain name to continue',
                continueWithClassic: 'Continue with classic account',
              },
            },
          },
        },
        login: {
          index: {
            code: {
              usePassword: 'I would like to use my {passwordInstead}',
              passwordInstead: 'password instead',
            },
            email: {
              title: 'Sign In',
              enterPassword: 'Enter password',
              or: 'Or',
              sendCode: 'Send me a code',
              alreadyLogged: 'Already logged in? {selectAccount}',
              selectAccount: 'Select an existing account',
            },
            password: {
              title: 'Enter your password',
            },
          },
        },
        reset: {
          index: {
            confirm: {
              chooseNew: 'Choose a new password',
            },
            email: {
              set: "Enter your email address and we'll send you a code to set your password",
              reset: "Enter your email address and we'll send you a code to reset your password",
              request: 'Request code',
              added: "You've been added to <br> {title}",
              resetPassword: 'Reset your password',
            },
            success: {
              set: 'The password for {email} has been set.',
              updated: 'The password for {email} has been updated.',
            },
          },
        },
        switch: {
          title: 'Select user',
          info: 'Select your account from the list below',
          dontSee: "Don't see your account above? {authorise}",
          authorise: 'Authorise a new user on this device',
        },
        token: {
          info: 'Logging into your account',
        },
        verify: {
          info: 'Verifying your email',
          error: 'Sorry, something went wrong while verifying your account.',
        },
      },
    },
    brand: {
      edit: {
        id: '<span class="text-gray-700 dark:text-gray-200">Brand ID:</span> {id}',
        name: 'The brand company name',
        success: 'Brand details saved',
        successInfo: 'The brand details were successfully saved',
      },
    },
    cancellationReason: {
      edit: {
        id: '<span class="text-gray-700 dark:text-gray-200">Cancellation Reason ID:</span> {id}',
        name: 'The cancellation reason name',
        shortNameInfo: 'This will display in the cancelled tag in the Reservation window and list of Appointments in the Customer Profile',
        displayModeInfo: 'Where this cancellation reason can be selected from',
        includeInHighCancellation: 'Select this option if you wish this reason to be included in High Cancellation stats',
        noteRequired: 'Note Required',
        noteRequiredInfo: 'Select this option to require the Customer to add a note to their online cancellation reason. Please note: Selecting this option does NOT require a note to be added for internal cancellations',
        enabledInfo: 'Allow this cancellation to be used',
        success: 'Cancellation reason saved',
        successInfo: 'The cancellation reason details were successfully saved',
      },
    },
    customPage: {
      id: {
        edit: 'Edit page: {title}',
        saveDraft: 'Save Draft',
        notInMenu: 'This page is not in the menu. You can add it to the menu in the {menuSettings}.',
        menuSettings: 'menu settings',
        lastPublished: 'Last published',
        never: 'Never',
        titleInfo: 'Used at the top of the page and in page info',
        slugInfo: 'How the page will appear in the browser address bar',
        menuLabel: 'Menu label',
        menuLabelInfo: 'A short name for this page used in the menu',
        infoInfo: 'A description for this page used in the menu and feature blocks',
        iconInfo: 'Used in the menu',
        titleColour: 'Title colour',
        titleColourInfo: 'The colour used for the main title at the top of the page',
        heroInfo: 'The main image used at the top of the page',
        editHero: 'Edit hero',
        addHero: 'Add hero',
        deletePage: 'Delete page',
        advanced: 'Advanced',
        successInfo: 'Changes to custom page have been saved',
      },
    },
    customer: {
      credit: {
        id: {
          creditTransactions: 'Credit Transactions',
        },
      },
      edit: {
        index: {
          privacyConsentError: 'Privacy Consent required',
          privacyConsentErrorInfo: 'Privacy consent must be accepted before continuing',
          created: '<span class="opacity-80">Created:</span> {label}',
          success: 'Customer details saved',
          successInfo: 'The customer details were successfully saved',
        },
      },
      form: {
        id: {
          incomplete: 'Incomplete form',
          success: 'Customer form saved',
          successInfo: 'The customer form was successfully saved',
        },
      },
      giftCard: {
        id: {
          giftDetails: 'Gift Details',
          deliveryDateInfo: 'Changing this will reschedule the delivery date.',
          fullPageGiftCertificate: 'Full page gift certificate',
          thermalPrinterReceipt: 'Thermal printer receipt',
          info: '<span class="font-medium mr-1">Info: </span>{info}',
          giftCardSaveSuccess: 'Gift card saved',
          giftCardSaveSuccessInfo: 'The customer gift card changes were successfully saved',
        },
      },
      loyalty: {
        id: {
          edit: 'Edit loyalty points balance',
          recalculate: 'Re-calculate loyalty points balance',
          transactions: 'Loyalty Points Transactions',
        },
      },
      pass: {
        id: {
          noReservations: 'There are no reservations for this pass',
          viewReservation: 'View reservation',
          info: '<span class="font-medium mr-1">Info: </span>{info}',
          success: 'Customer pass saved',
          successInfo: 'The customer pass changes were successfully saved',
        },
      },
      index: {
        match: 'Match',
        matchCustomers: 'Match customers to all applied filters',
        matchFilters: 'Match filters',
        customersInSystem: `
          You currently have {count} customers in your
          system but you can store and manage an Unlimited number.
        `,
        export: 'Export the selected customers',
        selected: '({count} selected)',
        merge: 'Merge selected customer records',
        setSegments: 'Set customer segments',
        composeAndSendSMS: 'Compose & send an SMS message to the selected customers',
        composeAndSendEmail: 'Compose & send an Email to the selected customers',
        createMarketingCampaign: 'Create a new marketing campaign for the selected customers',
        archive: 'Archive the selected customers',
        restore: 'Restore the selected customers',
        highCheckin: 'High checkin',
      },
      list: {
        createMarketingCampaign: 'Create a new marketing campaign for these customers',
        marketingConsent: 'Marketing Consent',
        massEmail: 'Mass Email',
        massSMS: 'Mass SMS',
      },
    },
    customField: {
      edit: {
        id: '<span class="text-gray-700 dark:text-gray-200">Custom Field ID:</span> {id}',
        name: 'Label for the custom field',
        code: 'Internal code used for data export and message templates',
        type: 'The type of field',
        history: 'Record changes to the value entered in this field',
        customerVisible: 'Show the customer this field online and in messages',
        customerEditable: 'Customer editable',
        customerEditableInfo: 'The customer can edit this field online',
        values: 'Options for multiple field type',
        defaultValue: 'The value this field should be pre-filled with',
        success: 'Custom field details saved',
        successInfo: 'The custom field details were successfully saved',
      },
    },
    demo: {
      index: {
        title1: 'Try Ovatu!',
        title2: 'with an instant demo.',
        info: "It's faster, more powerful and more intuitive.<br> Try a fully loaded instant demo of Ovatu. No account details or credit card required.",
        email: 'Email address (required)',
        industry: 'Select an industry to generate a live demo (required)',
        creatingAccount: 'Creating <strong>{title}</strong><br>demo account',
        building: 'Building',
        hairAndBeauty: 'Hair & Beauty',
        spaAndMassage: 'Spa & Massage',
        alliedHealth: 'Allied Health',
        tattoo: 'Tattoo',
        fitness: 'Fitness',
      },
    },
    employee: {
      create: {
        title: 'Add a New Employee',
        password: 'Setting a password',
        passwordInfo: 'Once saved, we will send an email to your new employee account to confirm their email address and create a password',
        managingAccess: 'Managing access',
        managingAccessInfo: 'You can reset employee passwords, set PINs and changes roles from Account -> Users & Roles',
        subscriptionCost: 'Subscription cost',
        subscriptionCostInfo: 'Adding a new employee will increase your monthly subscription cost. You will be charged the cost increase for the remainder of your billing month.',
        acceptPriceChange: 'I understand my subscription cost will increase',
        success: 'Created new employee',
      },
      edit: {
        id: '<span class="opacity-80">Employee ID:</span> {id}',
        adding: 'Adding a new employee',
        addingInfo: 'Adding a new employee will increase your monthly subscription cost. You will be charged the cost increase for the remainder of your billing month.',
        serviceOptions: 'Service options',
        success: 'Employee details saved',
        createSuccess: 'Create new employee',
      },
    },
    export: {
      index: {
        heading: 'Create a new export',
        info: 'Export your account data',
        type: 'Export type',
        create: 'Create export',
        successInfo: 'We are currently processing your request. It will be available on the Account Exports page when complete.',
      },
    },
    form: {
      id: {
        edit: 'Edit form: ',
        editDetails: 'Edit details',
        details: 'Form details',
        retainResponses: 'Retain responses',
        removedAlert: 'You have removed the following field(s) from the form:',
        retainResponsesInfo: 'Would you like to retain previous customer responses?',
        editFormDetails: 'Edit form details',
        success: 'Form details saved',
        successInfo: 'The form details were successfully saved',
      },
    },
    helpGuide: {
      index: {
        title: 'Help Guide',
        openInNewTab: 'Open in a new tab',
      },
    },
    history: {
      index: {
        filterAttribute: 'Filter attribute',
        action: 'Action',
        changed: 'Changed',
        heading: '{model} History',
      },
    },
    integration: {
      id: {
        connect: {
          to: 'Connect to {name}',
        },
        index: {
          needsReenabling: 'Needs re-enabling',
          needsReconnecting: 'Needs re-connecting',
          notVerified: 'Your account has not yet been verified',
          onlyVerified: 'Only verified accounts can enable integrations.',
          ifAlreadyVerified: "If you've already verified your email address, please check back here later.",
          overview: 'Overview',
          notSetUp: 'This integration has not been set up',
          disconnect: 'Disconnect',
          connect: 'Connect',
          reenable: 'Re-enable',
          reconnect: 'Re-connect',
          disconnectSuccess: 'Disconnected',
          disconnectSuccessInfo: 'Disconnected {name}',
          savedSuccessInfo: 'Saved integration settings for {name}',
        },
      },
    },
    manage: {
      index: {
        appointmentStatusTypes: {
          tag: 'Tag',
        },
        brands: {
          edit: 'Edit brand',
        },
        cancellationReasons: {
          noteRequired: 'Note required',
          edit: 'Edit cancellation reason',
        },
        charges: {
          successInfo: 'Your changes to the order of charges has been saved',
        },
        customFields: {
          edit: 'Edit custom field',
        },
        customerGiftCards: {
          outstandingValue: 'Outstanding value',
          activeGiftCards: 'Active gift cards',
          loading: 'Loading Gift Cards...',
          notLinked: 'Not yet linked to customer file',
          delivers: 'Delivers: {label}',
          createSale: 'Create sale',
        },
        customerPass: {
          loading: 'Loading customer passes...',
          count: '{count} customer passes',
          delivers: 'Delivers: {label}',
        },
        details: {
          title: 'Sales details',
          info: 'Sales, receipt information and tax details',
          orderCreationDate: 'Order creation date',
          receiptNumberType: 'Receipt number type',
          adminOnly: 'Admin only',
          receiptHeader: 'Receipt header',
          receiptFooter: 'Receipt footer',
          taxDetails: 'Sales tax details',
          taxDetailsInfo: 'Tax information included on sales receipt',
        },
        discounts: {
          new: 'New discount',
          successInfo: 'Your changes to the order of discounts has been saved',
        },
        email: {
          masterTemplate: 'Master template (Admin only)',
          masterTemplateInfo: 'Which master template should your emails use?',
          passbook: 'Passbook integration',
          passbookInfo: 'Attach passes for use with Apple Wallet (iOS devices only) to appointment emails and allow downloading passes from minisite',
          gmail: 'Schema.org (GMail) Integration',
          gmailInfo: 'Include code in appointment emails for Gmail allowing appointments to automatically appear in customer calendars (Google/Gmail accounts only)',
          internalEmailDefaults: 'Internal email defaults',
          internatlEmailDefaultsInfo: 'Set which emails are sent automatically by default or require manual sending. These settings do not affect appointments booked or modified online.',
          sendNewEmail: 'Send Customer New Appointment Email',
          sendNewEmailInfo: 'The default setting to send the customer an email when a new appointment is booked internally. This can be overridden when creating a Appointment',
          sendMoveEmail: 'Send Customer Move Appointment Email',
          sendMoveEmailInfo: 'The default setting to send the customer an email when an appointment is moved internally. This can be overridden when moving an appointment',
          sendConfirmEmail: 'Send Customer Confirm Appointment Email',
          sendConfirmEmailInfo: 'The default setting to send the customer an email when a appointment is confirmed (online bookings only)',
          sendCancelEmail: 'Send Customer Cancel Appointment Email',
          sendCancelEmailInfo: 'The default setting to send the customer an email when a appointment is cancelled internally. This can be overridden when cancelling a Appointment',
          sendCheckinEmail: 'Send Customer Check-in Appointment Email',
          sendCheckinEmailInfo: 'The default setting to send the customer an email when they are checked-in for their appointment',
          sendNoShowEmail: 'Send Customer No-show Appointment Email',
          sendNoShowEmailInfo: 'The default setting to send the customer an email when their appointment is marked as a no-show',
          sendWelcomeEmail: 'Send Customer Welcome Email',
          sendWelcomeEmailInfo: 'The default setting to send the customer a welcome email when they are added to your Ovatu account',
          sendCloseEmail: 'Send Customer Close Sale Email',
          sendCloseEmailInfo: 'The default setting to send the customer an email receipt when closing a sale. This can be overridden when closing a Sale',
          successInfo: 'Saved email settings',
        },
        forms: {
          automation: 'Automation',
          automationDisabled: 'Automation: Disabled',
          editForm: 'Edit form',
          exportCustomerForms: 'Export customer forms',
        },
        index: {
          title: 'Manage your account',
          info: 'Manage your preferences for the features and components of your Ovatu account.',
          search: 'Use the search box to quickly find a particular feature.',
        },
        location: {
          title: 'Location details',
          info: 'Business information',
          businessName: 'The public name of your business',
          email: 'Where default Ovatu notifications and customer replies are sent',
          timeZone: 'Time zone',
          timeZoneInfo: 'The timezone where your business operates',
          locale: 'Locale',
          localeInfo: 'Language, time, date and currency formatting',
          typeInfo: 'Your businesses primary service offer',
          logoInfo: 'Your businesses logo. Used on business receipts.',
          business: 'Business address & phone',
          businessInfo: 'Registered address and contact number for business',
          customerNotesTemplate: 'Customer notes template',
          notesFormula: 'Notes formula',
        },
        loyaltyProgram: {
          inSummary: 'In Summary...',
          eachSpentPointEarned: 'Each {currencySymbol}1.00 spent = {earnLabel} point earned,',
          toReedemPointsRequired: 'To redeem {currencySymbol}1.00 = {buyLabel} points are required,',
          onePointEquals: 'So 1 point = {valueLabel}',
          equivalentDiscount: 'Equivalent discount: {discountLabel}',
          settings: 'Loyalty program settings',
          settingsInfo: 'Customise the loyalty program settings',
          reset: 'Reset loyalty balances',
          loyaltyEnabled: 'Activate your Loyalty Program',
          includeTax: 'Loyalty Include Tax',
          includeTaxInfo: 'Customer earns loyalty points on tax paid',
          pointsEarned: 'Points earned',
          pointsRedeemed: 'Points redeemed',
          bulkSet: 'Bulk set loyalty',
          bulkSetInfo: 'Apply the Loyalty Program to your Products, Services and Employees in bulk',
          overrideService: 'Override custom service loyalty settings',
          overrideProduct: 'Override custom product loyalty settings',
          overrideEmployee: 'Override custom employee loyalty settings',
        },
        payments: {
          title: 'Add and manage custom payment types for your sales.',
          info: 'Integrate a compatible payment provider with Ovatu in account settings',
          manage: 'Manage payment integrations',
          newPaymentType: 'New payment type',
          successInfo: 'Your changes to the order of payments has been saved',
        },
        productCategories: {
          uncategorised: '({count} Uncategorised Product) | ({count} Uncategorised Products)',
          reorderUncategorised: 'Reorder uncategorised products',
          edit: 'Edit product category',
        },
        products: {
          loading: 'Loading products...',
          count: '{count} Product | {count} Products',
          export: 'Export products',
          search: 'Search products',
          onOrder: '{count} on order',
          edit: 'Edit product',
        },
        promoCodes: {
          appointmentRange: 'Appointment range',
          activeRange: 'Active range',
          new: 'New promo code',
          edit: 'Edit voucher',
        },
        purchaseOrders: {
          loading: 'Loading purchase orders...',
          count: '{count} Purchase order | {count} Purchase orders',
          view: 'View purchase order',
        },
        reminders: {
          title: 'Appointment reminders',
          info: 'Send your customers automated messages to remind them of upcoming appointments.',
          time: 'Reminder time',
          timeInfo: 'How long before the appointment should we send a reminder?',
          confirmations: 'Reminder confirmations enabled',
          confirmationsInfo: 'Allow customers to confirm they are attending an appointment',
          email: 'Email reminders',
          emailInfo: 'Send customers an email reminder of their upcoming appointment',
          sms: 'SMS reminders',
          smsInfo: 'Send customers a SMS reminder of their upcoming appointment',
          smsMessage: 'SMS message',
          smsMessageInfo: 'Body of the SMS message',
          placeholders: 'Placeholders are available to include',
          customerName: 'Customer name: {{customer.displayName}}, {{customer.firstName}}, {{customer.lastName}}',
          reservationDate: 'Reservation date:&nbsp;{{reservation.date}}',
          reservationTime: 'Reservation time:&nbsp;{{reservation.startTime}}',
          services: 'Services within the Reservation:&nbsp;{{reservation.services}}',
          name: 'Name of your business:&nbsp;{{location.title}}',
          smsNote: 'Please note: One SMS contains 160 characters. If your SMS body contains more than 160 characters, it will count as 2 or more SMSs',
          emojis: "Emoji's are supported, so feel free to add some fun to your message.&nbsp;However please note that if you include one or more emoji's, the character limit per message is reduced to 70.",
          review: 'Review reminders',
          reviewInfo: 'Send your customers automated messages asking them to leave a review.',
          reviewTime: 'Review reminder time',
          reviewTimeInfo: 'How long after the appointment should we send a review request?',
          reviewAlertEmployees: 'Review alert employees',
          reviewAlertEmployeesInfo: 'Notify employees when they receive a review',
          birthday: 'Birthday messages',
          birthdayInfo: 'Send your customers automated birthday messages.',
          birthdayTime: 'Birthday message time',
          birthdayTimeInfo: 'When should we send a birthday message to your customers?',
          successInfo: 'Saved reminder settings',
        },
        resourceGroups: {
          export: 'Export resources',
        },
        resources: {
          filter: 'Filter resources',
          editGroup: 'Edit resource group',
          removeOrDelete: 'Remove or delete',
          noneYet: "You haven't created any resources yet",
          definition: 'A resource is something that needs to be booked together with a service (ie a room or equipment).',
          linked: 'A resource can be linked to a service:',
          linkedInfo: `
            <li>On it's own (ie book 1 room along with a massage)</li>
            <li>As part of a group (ie book 1 of 3 available rooms with a massage)</li>
          `,
          create: 'Create resource',
          createGroup: 'Create group',
          results: 'Results',
        },
        schedule: {
          title: 'Date & time',
          info: 'Custom date and time formats',
          weekStart: 'Start day of week',
          weekStartInfo: 'Set which day the week starts on',
          timeFormat: 'Time format',
          timeFormatInfo: 'Set how the time should be displayed in Ovatuop',
          options: 'Schedule options',
          optionsInfo: 'Bookings schedule size and notifications',
          slotSize: 'Slot size',
          slotSizeInfo: 'Increments in which availability is offered',
          setDisplaySize: 'Set schedule display size',
          displaySize: 'Schedule display size',
          displaySizeInfo: 'Spacing of the timeline on the Schedule page (does not affect availability)',
          appointmentRate: 'Appointment rate warning threshold',
          appointmentRateInfo: "% of cancelled or no-show appointments before a customer's name is flagged",
          prices: 'Prices in Schedule',
          pricesInfo: 'Show prices on booking tiles',
          timeLine: 'Current time line',
          timeLineInfo: 'Show current time indication line on booking schedule',
          successInfo: 'Saved schedule preferences',
        },
        segments: {
          edit: 'Edit segment',
        },
        serviceCategories: {
          uncategorised: '({count} Uncategorised Service) | ({count} Uncategorised Services)',
          reorder: 'Reorder uncategorised services',
          edit: 'Edit service category',
        },
        services: {
          loading: 'Loading services...',
          count: '{count} Services',
          export: 'Export services',
          search: 'Search services',
          associatedForms: 'Service has associated forms',
          from: 'From {price}',
        },
        sms: {
          title: 'SMS number',
          info: 'Set where your SMS messages should come from.',
          default: 'When using the Ovatu system number, SMSs will be sent from a local number in your country managed by Ovatu. Replies to these messages will come back to Ovatu and you will be notified with the content of the reply.',
          default2: 'Using the Ovatu system number is the only way to enable appointment reminder confirmations (reply Y to confirm).',
          custom: 'It is possible in your country to send from your own number. All messages will still be sent from our servers and SMS costs apply in the same way, but messages will appear to come from your own number and all replies will be sent straight to your own mobile device.',
          custom2: 'Please note that appointment reminder confirmations (reply Y to confirm) are not possible using your own number as Ovatu no longer receives replies to the SMS reminders.',
          country: 'Which country should your SMS messages be sent from?',
          smsInfo: 'How should we send your SMS messages',
          from: 'From number',
          sendTest: 'Send test message',
          providerInfo: 'SMS Provider Information (Admin Only)',
          systemNumbers: 'System Numbers',
          alternativeCode: 'Alternative Sending Country Code',
          numberFormat: 'Number Format',
          messages: 'SMS messages',
          autoTopUpSettings: 'Auto top-up settings',
          autoTopUpStatus: 'Auto top-up status',
          creditsUsed: 'SMS credits used this month',
          purchased: 'Purchased SMS credits',
          included: 'SMS credits included in your plan',
          reminders: 'Average SMS reminders sent per month',
          creditsPerReminder: 'Average credits per SMS reminder',
          averageCreditsUsed: 'Average credits used per month',
          additionalRequired: 'Estimated additional credits required per month',
          usedThisMonth: 'SMS messages used this month',
          balance: 'SMS Message balance',
          sentPerMonth: 'Average SMS reminders sent per month',
          useSystemNumber: 'Use system (Ovatu) number',
          useOwnNumber: 'Use my own number',
          successInfo: 'Saved SMS settings',
        },
        stockTake: {
          reset: 'Reset inventory',
          edit: 'Edit stock take',
          view: 'View stock take',
        },
        suppliers: {
          edit: 'Edit supplier',
        },
        templates: {
          enabled: 'Custom template: Enabled',
          disabled: 'Custom template: Disabled (using system)',
          system: 'System template',
          enabledSuccess: 'Enabled email template',
          disabledSuccess: 'Disabled email template',
          revertedSuccess: 'Reverted email template',
        },
        theme: {
          info: 'Account theme',
        },
      },
      Index: {
        search: 'Search account settings',
      },
    },
    marketing: {
      campaigns: {
        edit: {
          title: 'Create campaign',
          saveDraft: 'Save draft',
          transactional: 'Campaign type: Transactional',
          transactionalInfo: 'This email will be sent to <strong>all</strong> selected customers. \'Unsubscribe\' settings will be ignored. To be used for non-promotional emails <strong>only</strong>.',
          changeType: 'Change type',
          emailBody: 'Email body',
          smsBody: 'SMS body',
          createSMSCampaign: 'Create new SMS campaign',
          createEmailCampaign: 'Create new email campaign',
          scheduleEmail: 'Schedule email',
          scheduleSMS: 'Schedule SMS',
          scheduleForLater: 'Schedule for later',
          sendNow: 'Send now',
          previewSuccess: 'Campaign preview',
          previewSentToEmail: 'Campaign preview has been sent to your email address ({email})',
          previewSentToPhone: 'Campaign preview has been sent to your phone ({phone})',
          removedSuccess: 'Attachment removed',
          removedSuccessInfo: 'Remove attachment {name}',
          saveSuccess: 'Campaign saved',
          saveSuccessInfo: 'Campaign draft has been saved.',
        },
        view: {
          title: 'Viewing sent campaign',
          customerCount: 'Customer count',
          attachments: 'Attachments',
          noAttachments: 'No attachments',
        },
      },
      index: {
        assets: {
          index: {
            maxPerFile: 'Max 5MB (10MB on Loaded plan) per file',
          },
        },
        campaigns: {
          report: {
            id: {
              title: 'Campaign report',
              linkClicks: 'Link clicks',
              linkClicksInfo: 'The number of recipients who have clicked links included in the email.',
              viewAll: 'View all',
              opens: 'Recent opens',
              opensInfo: 'Recipients who have recently opened the email.',
              bounces: 'Recent bounces',
              bouncesInfo: 'An may be caused by an invalid email address, or a full inbox, among other possible reasons.',
              complaints: 'Recent complaints',
              complaintsInfo: 'Complaints are logged when a recipient flags the email as spam. This does no include recipients who have used the "Unsubscribe" link.',
              unsubscribes: 'Recent unsubscribes',
              unsubscribesInfo: 'Customers who have unsubscribed from marketing emails using the "Unsubscribe" link.',
            },
          },
          index: {
            states: 'States',
            types: 'Types',
            newCampaign: 'New campaign',
            empty: 'No campaigns...',
            viewReport: 'View report',
            editDraft: 'Edit draft',
            deleteDraft: 'Delete draft',
            cancelScheduled: 'Cancel scheduled campaign',
          },
        },
        messages: {
          channel: {
            loadingSMS: 'Loading SMS messages',
            loadingEmail: 'Loading email messages',
            smsCount: '{count} SMS messages',
            emailCount: '{count} email messages',
            typeSubject: 'Type / Subject',
          },
        },
        reviews: {
          index: {
            loading: 'Loading reviews...',
            count: '{count} reviews',
            comment: 'Comment',
          },
        },
        index: {
          info: 'Manage your marketing campaigns, send emails and SMS, and track your marketing performance.',
        },
        massEmail: {
          respect: 'Respect mass email unsubscription',
        },
        massSMS: {
          calculateCredits: 'Calculate credits',
        },
      },
      reviews: {
        review: {
          title: 'Customer review',
          dateTime: 'Date/time',
          label: 'Review',
        },
      },
      Index: {
        search: 'Search marketing',
      },
      sentMessages: {
        title: 'Sent message',
        channel: 'Channel',
        cost: 'Cost',
        smsInfo: 'SMS Info',
        provider: 'Provider: {provider}',
        message: 'Message: {message}',
      },
    },
    message: {
      index: {
        id: {
          lastMessage: 'Last message {date} ago',
          noContactInfo: 'Add an email or phone number to begin messaging this customer',
        },
      },
      send: {
        email: {
          heading: 'Compose Email',
        },
        sms: {
          heading: 'Compose SMS',
          content: 'SMS message content',
          success: 'SMS sent',
        },
      },
      Index: {
        newChat: 'Start new chat',
        selectCustomer: 'Select a customer to start a conversation',
      },
    },
    minisite: {
      index: {
        booking: {
          bookTabName: 'Book tab name',
          bookTabNameInfo: 'Custom name for the booking page',
          bookTabInformation: 'Book tab information',
          bookTabInformationInfo: 'Custom description for the booking page',
          timetableTabName: 'Timetable tab name',
          timetableTabNameInfo: 'Custom name for the timetable page',
          timetableTabInformation: 'Timetable tab information',
          timetableTabInformationInfo: 'Custom description for the timetable page',
          lastMinuteTabName: 'Last minute tab name',
          lastMinuteTabInformation: 'Last minute tab information',
          lastMinuteTabInformationInfo: 'Custom description for the last minute page',
        },
        contact: {
          title: 'Contact details',
          info: 'Show contact information on your book.app site',
          name: 'Contact name',
          streetAddress: 'Street address',
          stateRegion: 'State/Region',
          zipPostcode: 'ZIP/Postcode',
          businessDescription: 'Business description',
          businessDescriptionInfo: 'A short description about your business. Only used in your site description for SEO purposes.',
          showOnMap: 'Show on map',
          showOnMapInfo: 'Show a mini map of your business location',
          manualMapLocation: 'Manual map location (Admin only)',
          latitude: 'Latitude',
          longitude: 'Longitude',
        },
        customPages: {
          loading: 'Loading pages...',
          count: '{count} page | {count} pages',
        },
        customer: {
          info: 'Allow customers to log in and view account',
          tabName: 'Customer tab name',
          tabNameInfo: 'Custom name for the customer page',
          tabInformation: 'Customer tab information',
          tabInformationInfo: 'Custom description for the customer page',
          showUpcomingAppointments: 'Show Upcoming Appointments',
          showPastAppointments: 'Show Past Appointments',
          showSales: 'Show Sales (invoices)',
          showGiftCards: 'Show Gift Cards',
          showPasses: 'Show Passes',
        },
        domain: {
          title: 'Request domain change',
          info: 'Change the domain provided by Ovatu.',
          requestChangeInfo: 'Requesting a change to your domain',
          updateUrl: 'If you have published or shared your old mini site URL anywhere (e.g. in social media posts or other marketing material), you will need to update it to the new URL.',
          requestCheck: 'Once you have submitted your request, we will check that the domain is available and then contact you to confirm change.<br><strong>Requests may take up to 3 business days to be approved.</strong>',
          afterChange: 'After your domain has changed:',
          changeInfo: `
            <li>
              You will need to change it everywhere you've noted the old mini site URL
            </li>
            <li>
              Your mini site URL is also your account Domain, so you and your team will need to enter the new domain name in the 'Domain' field when logging in.
            </li>
            <li>
              If you have online booking on your Facebook and Instagram
              pages, please log into the web app and go to the
              <strong>Account > Integrations</strong> page. On that page
              disconnect the
              <strong>Meta (Facebook & Instagram)</strong> integration, and
              then re-connect it. This will connect online booking to your
              new mini site URL.
            </li>
            <li>
              Links in emails sent from your account, and your old mini site domain will redirect to your new mini site for 30 days. After that the redirection will no longer work.
              This includes links to leave reviews, complete forms, and claim gift cards etc., so please keep in mind you may need to send new links if customers contact you about that.
              Links in emails sent after the change will work as normal.
            </li>
          `,
          currentDomains: 'Your current domains are:',
          newDomain: 'New domain',
          newDomainInfo: 'The domain name you want to use for your minisite and web app',
          requestChange: 'Request change',
          yourDomain: 'Your domain:',
          yourDomainInfo: `
            <li>Must be at least 8 characters long</li>
            <li>Can include letters, numbers and hyphens</li>
            <li>
              Must NOT include any special characters (e.g. !, @ etc)
            </li>
          `,
          fixErrors: 'Please fix the following error(s):',
          requestSent: 'Your request has been sent',
          requestSentInfo: 'Your domain change request has been received. We will check that the domain is available and then contact you to confirm the change.<br><strong>Requests may take up to 3 business days to be approved.</strong>',
          afterChangeInfo: `
            <li class="font-semibold">
              If you have published or shared your old mini site URL anywhere (e.g. in social media posts or other marketing material), you will need to update it to the new URL.
            </li>
            <li class="font-semibold">
              Your mini site URL is also your account Domain, so you and your team will need to enter the new domain name in the 'Domain' field when logging in.
            </li>
            <li class="font-semibold">
              If you have online booking on your Facebook and Instagram
              pages, please log into the web app and go to the
              <strong>Account > Integrations</strong> page. On that page
              disconnect the
              <strong>Meta (Facebook & Instagram)</strong> integration, and
              then re-connect it. This will connect online booking to your
              new mini site URL.
            </li>
            <li class="font-semibold">
              Links in emails sent from your account, and your old mini site domain will redirect to your new mini site for 30 days. After that the redirection will no longer work.
              This includes links to leave reviews, complete forms, and claim gift cards etc., so please keep in mind you may need to send new links if customers contact you about that.
              Links in emails sent after the change will work as normal.
            </li>
          `,
          requestedDomains: 'Your requested domains are:',
          customMinisiteDomain: 'Custom minisite domain',
          changeToCustom: 'Change your minisite domain to a custom domain.',
          customDomains: 'Custom domains',
          customDomainActive: 'Custom domain active',
          activeAndReady: 'Your custom domain is active and ready to use.',
          availableAt: 'Your minisite is available at <strong>{domain}</strong>.',
          noLongerAccess: 'You can no longer access your minisite at <strong>{slug}.{domain}</strong>.',
          customDomainInfo: 'Set a custom domain to serve your Ovatu minisite at another address',
          checkStatus: 'Check Status',
          setCustomDomain: 'Set custom domain',
          failing: 'Failing (Using book.app)',
          registerDomainDetails: 'Register domain details',
          createDNSRecords: 'To use <strong>{domain}</strong> with your minisite, create two new DNS records in your domain registrar/web host using the following settings:',
          valueIP: 'Value / IPv4 Address',
          aRecord: 'A (Record)',
        },
        embed: {
          chooseEmbedType: 'Choose embed type',
          chooseWidgetType: 'Choose widget type',
          limitReviews: 'Limit reviews',
          limitReviewsInfo: 'Limits number of reviews visible',
          filter: 'Filter by category or service',
          filterInfo: 'Only show selected services or services in selected categories',
          promoCode: 'Apply promo code',
          promoCodeInfo: 'Automatically apply a promo code and filter the available services when using the widget',
          siteNotice: 'Show site notice',
          siteNoticeInfo: 'If enabled - show/hide the site notice in your widget',
          scrollToTop: 'Scroll to top of page',
          scrollToTopInfo: 'Scroll to the top of your website when the page changes in the widget',
          placement: 'Placement',
          placementInfo: 'Where the trigger/button should appear on the page',
          offset: 'Offset',
          offsetInfo: 'Offset of widget trigger/button from edge',
          top: 'top',
          right: 'right',
          bottom: 'bottom',
          left: 'left',
          embedCode: 'Embed Code',
          copyCodeInfo: 'Copy and paste this code in your HTML where you want to embed your Ovatu widget.',
          ensureCode: 'Ensure the code is copied exactly as you see it here.',
          previewWidget: 'Preview widget',
          previewBeacon: 'Preview beacon',
          codeCopied: 'Code copied',
          copyCode: 'Copy code',
          widgetPreview: 'Widget preview',
          widget: 'Widget',
          beacon: 'Beacon',
          customerLogin: 'Customer login',
          rightDefault: 'Right (default)',
          leftPlacement: 'Left',
          topRight: 'Top right',
          topLeft: 'Top left',
        },
        gallery: {
          info: 'Show a page for gallery images online',
          tabName: 'Gallery tab name',
          tabNameInfo: 'Custom name for the gallery page',
          tabInformation: 'Gallery tab information',
          tabInformationInfo: 'Custom description for the gallery page',
          images: 'Gallery images',
          imagesInfo: 'Photos to show on your minisite gallery page',
        },
        giftCards: {
          info: 'Allow customers to purchase gift cards online',
          tabName: 'Gift card tab name',
          tabNameInfo: 'Custom name for the gift card page',
          tabInformation: 'Gift card tab information',
          tabInformationInfo: 'Custom description for the gift card page',
          termsAgreement: 'Gift Card Terms Require Agreement',
          termsTitle: 'Terms and conditions title for gift cards',
          termsAndConditions: 'Terms and conditions for gift cards',
          expiryDays: 'Number of days until gift card expires. 0 for no expiry.',
          presetAmounts: 'Preset amounts',
          presetAmountsInfo: 'Preset values for gift cards',
          customAmounts: 'Allow custom amounts',
          customAmountsInfo: 'Allow customers to enter custom amounts for gift cards',
          customAmountLimit: 'Custom amount limit',
          customAmountLimitInfo: 'Limit the gift card value when custom amounts are allowed',
          giftCardImage: 'Gift card image',
          giftCardImageInfo: 'Default image for gift cards',
          uploadDefaultImage: 'Upload default image',
          noDefault: 'No default gift card image uploaded',
          additionalImages: 'Additional card images',
          additionalImagesInfo: 'Alterntive image options for gift cards',
          uploadAdditionalImage: 'Upload additional image',
        },
        hours: {
          info: 'Display your opening hours on your book.app site',
          converting: 'Converting business hours',
          override: 'Override business hours',
          overrideInfo: 'Override your business hours for specific days',
          editOverrides: 'Edit overrides',
          noUpcomingOverrides: 'No upcoming overrides',
          overridesInfo: 'You can override your business hours for specific days using the Edit Overrides button',
          open: '<span class="font-semibold">Open</span> - {start} to {finish}',
          closed: '<span class="font-semibold">Closed</span> - {label}',
          improved: "We've improved how you can manage your business hours.",
          improvedInfo: 'You can now set your business hours for each day of the week, and override them for specific days. Click enable to get started.',
        },
        index: {
          title: 'Online booking is enabled',
          info: 'Use the left hand menu to customize your online booking experience.',
          bookingDisabledInfo: 'Your customers can still access your minisite at <a href="//{slug}.{domain}" target="_blank" class="text-primary-600 font-bold">{slug}.{domain}</a> but will not be able to book online.',
          success: 'Saved online booking',
          successInfo: 'Saved online booking preferences',
        },
        menu: {
          title: 'Menu type',
          default: 'Default menu',
          defaultInfo: 'Use the default menu. We will automatically order your menu based on your page preferences.',
          custom: 'Custom menu',
          customInfo: 'Customise your minsite menu using the options below',
          noItems: 'No menu items',
          noItemsInfo: 'The custom menu has no items. Would you like to save the menu anyway?',
          successInfo: 'Saved book.app menu',
        },
        minimiseGaps: {
          title: 'Minimise gaps',
          info: 'Enable the Minimise Gaps feature on for all Employees',
          showAvailableAppointment: 'This will either be the start of the day, or immediately after any bookings/breaks already set at the start of the day.',
          showLastAvailableAppointment: 'This will be either the end of the day, or immediately before any bookings/breaks already set at the end of the day.',
          showAvailabilityImmediately: 'Show availability immediately before and after all existing breaks and bookings',
          showAllAvailability: "Display all availability if the employee doesn't have any appointments on the selected date.",
          showAvailabilityLeavingMinGapTime: 'Set a minimum gap time between bookings by entering the minimum number of minutes the gap should be before or after any existing break and appointment.',
          successInfo: 'Saved minisite minimise gaps settings',
        },
        passes: {
          info: 'Allow customers to purchase passes online',
          tabName: 'Pass tab name',
          tabNameInfo: 'Custom name for the pass page',
          tabInformation: 'Pass tab information',
          tabInformationInfo: 'Custom description for the pass page',
          termsAgreement: 'Pass Terms Require Agreement',
          termsTitle: 'Terms and conditions title for passes',
          termsContent: 'Terms and conditions for passes',
          passImage: 'Pass image',
          passImageInfo: 'Default image for customer passes',
          noImage: 'No pass image uploaded',
        },
        payments: {
          noProcessor: 'You do not yet have a payment processor connected.',
          noProcessorInfo: 'Online payments can be accepted by connecting a Stripe, Square or PayPal account to Ovatu. They can be connected on the Integrations page',
          enable: 'Enable online payments',
          depositAmount: 'Deposit amount',
          depositAmountInfo: 'Fixed deposit is applied per service. Where a service price is lower than the fixed deposit, the fixed deposit will be applied.',
          payInFull: 'Allow Pay In Full Option',
          payInFullInfo: 'Give Customers the option to pay in full',
          payLater: 'Allow Pay Later Option',
          payLaterInfo: 'Give Customers the option of not paying at the time of booking online',
          depositPolicy: 'Deposit policy content',
          depositPolicyInfo: 'Online deposit policy information',
          cardCapture: 'Card capture is only available via Stripe or Square.',
          cardCaptureInfo: `
            <li><strong>Stripe</strong>: Card details can be captured with or without a payment.</li>
            <li><strong>Square</strong>: Card details can only be captured without a payment.</li>
          `,
          enableCardCapture: 'Enable card capture',
          enableCardCaptureInfo: 'Capture customer credit card details when they book online',
          cardCapturePolicy: 'Card capture policy content',
          cardCapturePolicyInfo: 'Card capture policy information',
          paymentReminder: 'Enable Payment Remainder ',
          paymentReminderInfo: 'Include an option in emails to the Customer, for them to pay any unpaid amount noted in the Sale',
        },
        preferences: {
          ovatuYou: 'Enable Ovatu You',
          ovatuYouInfo: 'Show your business in the Ovatu You app',
          servicePrices: 'Show service prices',
          servicePricesInfo: 'Display service prices on minisite',
          onlineAppointments: 'Confirm online appointments',
          onlineAppointmentsInfo: 'Online appointments require manual confirmation',
          customers: 'Control who can book online',
          forCustomers: 'Allow for Customers',
          forCustomersInfo: 'Limit customers who can book online by segment',
          newRegistrations: 'Allow new registrations',
          newRegistrationsInfo: 'Allow new customers to register and book online',
          availability: 'Control how your schedule availability is offered',
          bookingThreshold: 'Booking Threshold',
          bookingThresholdInfo: 'The time period before which the first availability is offered',
          bookingFutureLimit: 'Booking Future Limit',
          bookingFutureLimitInfo: 'How far into the future availability is offered',
          waitTime: 'Allow wait time in appointments',
          waitTimeInfo: 'Allow multi Service appointments with a wait time between Services',
          serviceSelection: 'Service Selection',
          serviceSelectionInfo: 'Control how customers can book appointments',
          employeeSelection: 'Allow employee selection',
          employeeSelectionInfo: 'Allow customers to select which employee for a service',
          firstAvailable: "Show 'First Available' option",
          firstAvailableInfo: "Allow customers to select 'First Available' for a service instead of a specific employee",
          multiService: 'Allow Multi Service',
          multiServiceInfo: 'Allow Customers to book multiple Services in one appointment',
          analyticsConversionCode: 'Analytics conversion code',
          analyticsConversionCodeInfo: 'Add the analytics code from your analytics system, such as Facebook Pixel.',
          analyticsConversionCodeMessage: 'Only confirmed bookings are tracked. No other individual clicks or events are tracked.',
          waitlist: 'Allow customers to add themselves to your waitlist.',
          learnWaitlist: 'Learn how to use the waitlist',
          waitlistNotificationMode: 'Waitlist notification mode',
          waitlistNotificationModeInfo: 'Select how customers will be notified when a waitlist time slot becomes available',
          customerSelfCancel: 'Customer self cancel',
          customerSelfCancelInfo: 'Allow Customers to cancel their own bookings online',
          selfCancelWindow: 'Self cancel window',
          selfCancelWindowInfo: 'The minimum number of hours before a booking a customer can cancel it online.',
          selfCancelNotice: 'Self cancel notice',
          selfCancelNoticeInfo: 'Text shown to a customer upon cancelling a reservation online, prior to confirmation.',
          selfCancelNoticeMessage: "If left blank, the following default text will be displayed: 'Are you sure you want to cancel this appointment?'",
          customerSelfMove: 'Customer self move',
          customerSelfMoveInfo: 'Allow Customers to move their own bookings online',
          selfMoveWindow: 'Self move window',
          selfMoveWindowInfo: 'The minimum number of hours before a booking a customer can move it online.',
          noBookings: 'No online bookings before midnight',
          noLimit: 'No limit',
          noBookingsBefore: 'No online bookings before: {message}',
          notAllowed: 'Not Allowed',
          notAllowedInfo: 'The first available employee will be automatically selected',
          allowedDropdown: 'Allowed (Dropdown)',
          allowedDropdownInfo: 'Customers will be able to select an employee from a dropdown',
          allowedList: 'Allowed (List)',
          allowedListInfo: 'Customers will be able to select an employee from a list',
        },
        reviews: {
          info: 'Show a page for reviews online',
          tabName: 'Review tab name',
          tabNameInfo: 'Custom name for the reviews page',
          showService: 'Show service',
          showServiceInfo: 'Show the service for each review',
          showReplies: 'Show replies',
          showRepliesInfo: 'Show replies to reviews',
        },
        script: {
          javascript: 'Javascript - Head',
          javascriptInfo: 'Custom javascript to be added to the head of every page',
          html: 'HTML - Body',
          htmlInfo: 'Custom HTML to be added to the body of every page',
          successInfo: 'Saved book.app custom script settings',
        },
        seo: {
          show: 'Show in search engines',
          showInfo: 'If disabled, search engines will not index your site',
          successInfo: 'Saved book.app SEO settings',
        },
        share: {
          foundAt: 'Your minisite can be found at<br><a href="//{slug}.domain" target="_blank" class="text-primary-600 font-bold">{slug}.{domain}</a>',
          accessAt: 'Your customers can still access your minisite at <a href="//{slug}.{domain}" target="_blank" class="text-primary-600 font-bold">{slug}.{domain}</a> but will not be able to book online.',
        },
        social: {
          facebook: 'Facebook account (page username/ID)',
          facebookInfo: 'Show link to Facebook account on your book.app minisite',
          instagram: 'Instagram username',
          instagramInfo: 'Show link to Instagram account on your book.app minisite',
          twitter: 'Twitter handle',
          twitterInfo: 'Show link to Twitter account on your book.app minisite',
          pinterest: 'Pinterest username',
          pinterestInfo: 'Show link to Pinterest account on your book.app minisite',
          tiktok: 'TikTok username',
          tiktokInfo: 'Show link to TikTok account on your book.app minisite',
          whatsapp: 'WhatsApp channel (ID)',
          whatsappInfo: 'Show link to WhatsApp channel on your book.app minisite',
          youtube: 'YouTube channel (handle)',
          youtubeInfo: 'Show link to YouTube channel on your book.app minisite',
          linkedIn: 'LinkedIn page',
          linkedInInfo: 'Show link to LinkedIn page on your book.app minisite',
          reddit: 'Reddit username',
          redditInfo: 'Show link to Reddit account on your book.app minisite',
          threads: 'Threads username',
          threadsInfo: 'Show link to Threads account on your book.app minisite',
          linktree: 'Linktree username',
          linktreeInfo: 'Show link to Linktree on your book.app minisite',
        },
        terms: {
          title: 'Booking terms',
          info: 'Require customers agree to booking terms',
          titleInfo: 'The title (heading) of your Booking Terms',
          bookingTerms: 'Booking terms content',
          bookingTermsInfo: 'The booking terms customers must agree to',
          acceptLabel: "The label for the Booking Terms acceptance checkbox, e.g. 'I accept the Booking terms'",
        },
        theme: {
          info: 'Select a preset theme for your minisite.',
          colours: 'Theme colours',
          actionBackground: 'Action Background',
          actionBackgroundInfo: 'The colour used for buttons and links.',
          titleInfo: 'The primary title colour used for page titles and section headers.',
          actionText: 'Action Text',
          actionTextInfo: 'The colour used for button text',
          headerBackground: 'Header Background',
          headerBackgroundInfo: 'Overlayed on header image (adjust opacity using slider below)',
          headerText: 'Header Text',
          headerTextInfo: 'The primary text colour used in the header',
          contentBackground1: 'Content Background 1',
          contentBackground1Info: 'Primary background colour used for page sections',
          contentBackground2: 'Content Background 2',
          contentBackground2Info: 'Secondary background colour used for page sections',
          contentText: 'Content Text',
          contentTextInfo: 'The primary colour used for plain text',
          border: 'Border',
          borderInfo: 'Primary colour used for container borders',
          hero: 'Hero image menu',
          heroInfo: 'Show hero image in navigation menu',
          headerOpacity: 'Header Opacity',
          headerOpacityInfo: 'Opacity of the header overlay colour',
          borderRadius: 'Border radius',
          borderRadiusInfo: 'Roundness of border and button corners corners',
          map: 'Map',
          mapInfo: 'Location map theme (if enabled)',
          email: 'Email settings',
          emailHeader: 'Show hero in email header',
          emailHeaderInfo: 'Include the hero header image in the email template',
          emailHeaderBackground: 'Email Header Background',
          emailHeaderBackgroundInfo: 'The colour of the email header (if hero image is disabled)',
          accessibilityIssues: 'Accessibility issues with some colours',
          xl: 'XL',
          standard: 'Standard',
          successInfo: 'Saved minisite theme',
        },
      },
      Index: {
        search: 'Search minisite settings',
        foundAt: 'Your minisite can be found at <a :href="//{slug}.{bookappDomain}" target="_blank" class="text-primary-600 font-bold">{slug}.{domain}</a>',
      },
    },
    onboarding: {
      index: {
        welcome: 'Welcome',
        welcomeInfo: 'We\'re excited to have you here. Let\'s get you started.',
        serviceInfo: 'You\'ve got awesome services, let\'s create your first one!',
        roster: 'Set your roster hours',
        rosterInfo: 'Set up up your working hours to manage availability.',
        pinInfo: 'Quickly unlock your account without entering a password',
        complete: 'You\'re all set!',
        completeInfo: '...but its only the beginning',
      },
    },
    order: {
      id: {
        open: 'Open sale',
        print: 'Print sale',
        email: 'Email sale to customer',
        viewCustomer: 'View customer profile',
        label: 'Sale: {customerLabel}',
        paid: 'Amount paid',
        due: 'Amount due',
        payNow: 'Pay now',
        notes: 'Sale notes',
        saveNotes: 'Save notes',
        unpaidCredit: 'Unpaid Customer Credit',
        unpaidCreditInfo: 'This sale has customer credit which has not been paid for and can be spent. Proceed with closing sale?',
        proceed: 'Proceed',
        openSuccess: 'Opened order',
        openSuccessInfo: 'Opened order: {number}',
        sentSuccessInfo: 'Sent email to customer for sale: {number}',
        saveSuccess: 'Sale saved',
        saveSuccessInfo: 'Sale changes were successfully saved',
      },
      index: {
        count: '{count} Sales',
        selected: '({count} selected)',
        browseHelpGuides: "For everything you need to know about setting up Sales, browse our <a href='https://ovatu.com/help/guides/sales'>Sales help guides</a>",
        exportSalesList: 'Export this sales list',
        exportSales: 'Export sales',
        exportItemisedList: 'Export an itemised list',
        exportItems: 'Export items',
        search: 'Search sales',
      },
    },
    pass: {
      edit: {
        success: 'Pass details saved',
        successInfo: 'The pass details were successfully saved',
      },
    },
    product: {
      edit: {
        id: '<span class="text-gray-700 dark:text-gray-200">Product ID:</span> {id}',
        success: 'Product details saved',
        succeessInfo: 'The product details were successfully saved',
      },
    },
    productCategory: {
      edit: {
        id: '<span class="text-gray-700 dark:text-gray-200">Product Category ID:</span> {id}',
        informationInfo: 'Product category details',
        productsInfo: 'Re-order products in this category',
        noProducts: 'No products in this category',
        addProducts: 'Add products by selecting a category in the Product Edit page',
        success: 'Product category saved',
        successInfo: 'The product category details were successfully saved',
      },
    },
    promoCode: {
      edit: {
        new: 'New promo code',
        code: 'Code: <span class="text-gray-700 dark:text-gray-200">{code}</span>',
        validity: 'Validity',
        confirmCreation: 'Confirm Creation',
        creationInfo: 'You are about to create {count} promo codes.',
        saveSuccess: 'Promo code details saved',
        saveSuccessInfo: 'The promo code details were successfully saved',
        saveBatchSuccess: 'Promo codes saved',
        saveBatchSuccessInfo: 'The promo codes were successfully saved',
      },
    },
    purchaseOrder: {
      edit: {
        markAsSent: 'Mark purchase order as sent to the supplier',
        pdf: 'Print / Create PDF',
        print: 'Print / PDF',
        receive: 'Receive',
        markAsReceiving: 'Mark purchase order as receiving',
        markAsComplete: 'Mark purchase order as complete',
        label: 'Purchase Order: {name} (#{number})',
        items: 'Items',
        products: 'Products to be purchased',
        addItems: 'Add items',
        receiveAll: 'Receive all',
        notes: 'Note: {notes}',
        setQuantity: 'Set the received quantity and add a note',
        totals: 'Totals',
        success: 'Purchase order details saved',
        successInfo: 'The purchase order details were successfully saved',
      },
    },
    report: {
      index: {
        index: {
          info: 'Generate business reports and manage exported data',
        },
      },
      Index: {
        appointments: 'Reporting on all appointments',
        sales: 'Reporting on all sales',
        payments: 'Reporting on payment transactions',
        businessSummary: 'Business Summary',
        businessSummaryInfo: 'A summary of appointment statistics',
        customers: 'Reporting on customer spending',
        outstandingAccounts: 'Outstanding Accounts',
        outstandingAccountsInfo: 'All open, unpaid sales',
        inventory: 'Current inventory levels and value',
        employeeTime: 'Employee Time',
        employeeTimeInfo: 'Employee working hours and services',
        loyaltyPoints: 'Customer loyalty points balances',
      },
    },
    resource: {
      edit: {
        id: '<span class="text-gray-700 dark:text-gray-200">Resource ID:</span> {id}',
        success: 'Resource details saved',
        successInfo: 'The resource details were successfully saved',
        createSuccess: 'Created new resource',
      },
    },
    resourceType: {
      edit: {
        id: '<span class="text-gray-700 dark:text-gray-200">Resource Group ID:</span> {id}',
        groupName: 'Resource group name',
        partOfGroup: 'What resources are part of this resource group',
        allowEmployees: 'Allow employees to be used as a resource in this group',
        addSecondaryEmployee: 'Add a secondary employee to a service',
        success: 'Resource group details saved',
        successInfo: 'The resource group details were successfully saved',
      },
    },
    role: {
      edit: {
        new: 'New role',
        label: 'Edit role: {name}',
        id: '<span class="text-gray-700">Role ID:</span> {id}',
        title: 'The role title',
        descriptionInfo: 'An internal label used to identify this role',
        permissions: 'Permissions',
        success: 'Role details saved',
        successInfo: 'The role details were successfully saved',
      },
    },
    roster: {
      print: {
        week: {
          title: 'Weekly Roster: {date}',
        },
      },
      index: {
        editNormalHours: 'Edit normal hours',
        datesSelected: '{count} dates selected',
      },
    },
    segment: {
      edit: {
        id: '<span class="text-gray-700 dark:text-gray-200">Segment ID:</span> {id}',
        label: 'The segment label',
        shortNameInfo: 'A label used to identify this segment',
        customerVisible: 'Should this segment be visible in the customers account on your minisite',
        colourInfo: 'Colour of the segment label tag on appointments',
        onlinePaymentCapture: 'Online Payment Capture Required',
        onlinePaymentCaptureInfo: 'Should customers in this segment require card capture when booking online',
        onlinePaymentDeposit: 'Online Payment Deposit Required',
        onlinePaymentDepositInfo: 'Percentage (%) deposit. Leave blank for system default, 0 for Not Required.',
        onlineBookingAllowed: 'Online Booking Allowed',
        onlineBookingAllowedInfo: 'Can customers in this segment book online',
        onlineBookingMessage: 'Online Booking Message',
        onlineBookingMessageInfo: 'Message displayed to customer when online booking not allowed.',
        success: 'Segment details saved',
        successInfo: 'The segment details were successfully saved',
      },
    },
    service: {
      edit: {
        id: '<span class="opacity-80">Service ID:</span> {id}',
        advancedTiming: 'Advanced timing',
        successInfo: 'The service details were successfully saved',
      },
    },
    serviceCategory: {
      edit: {
        id: '<span class="text-gray-700 dark:text-gray-200">Service Category ID:</span> {id}',
        name: 'The service name',
        services: 'Re-order services in this category',
        noServices: 'No services in this category',
        addServices: 'Add services by selecting a category in the Service Edit page',
        successInfo: 'The service category details were successfully saved',
      },
    },
    stockTake: {
      edit: {
        description: 'Stock take: {description}',
        importCSV: 'Import CSV',
        printForm: 'Print stock take form',
        new: 'Create a new stock take record to track the stock levels of your products.',
        systemInventory: 'System inventory',
        inventory: 'Stock take inventory',
        priceDifference: 'Price difference',
        totalDifference: 'Total price difference: {totalPriceDifference}',
        success: 'Stock take details saved',
        successInfo: 'The stock take details were successfully saved',
        errorParsing: 'Error parsing CSV:',
        csvSuccess: 'CSV file imported',
        someNotFound: 'Some products were not found',
        someNotFoundInfo: 'Please check the products in the CSV file and try again',
        productNotFound: 'Product not found:',
      },
    },
    supplier: {
      edit: {
        id: '<span class="text-gray-700 dark:text-gray-200">Supplier ID:</span> {id}',
        companyName: 'The supplier company name',
        fax: 'Fax',
        paymentTerms: 'Payment terms',
        success: 'Supplier details saved',
        successInfo: 'The supplier details were successfully saved',
      },
    },
    tax: {
      edit: {
        id: '<span class="text-gray-700 dark:text-gray-200">Tax ID:</span> {id}',
        amountInfo: 'Percentage, ie. for 7.5%, please enter 7.5',
        displayPrice: 'Include in display price',
        displayPriceInfo: 'Include the tax amount as part of the product/service price',
        default: 'Include by default',
        defaultInfo: 'Add tax amount to product/service by default',
        apply: 'Apply the {name} to your Products, Services and Passes in bulk',
        overrideCustomService: 'Override custom service tax settings',
        overrideCustomProduce: 'Override custom product tax settings',
        overrideCustomPass: 'Override custom pass tax settings',
        success: 'Tax details saved',
        successInfo: 'The tax details were successfully saved',
      },
    },
    template: {
      edit: {
        title: '{name} Template',
        sendPreview: 'Send a preview to your email address',
        complexity: 'Due to the complexity of this template, it can only be edited in code mode',
        subject: 'The email subject line',
        placeholders: 'Placeholders',
        placeholdersInfo: 'Placeholders available for this template',
        saveSuccess: 'Email template saved',
        saveSuccessInfo: 'The custom email template changes have been saved',
        previewSuccess: 'Preview sent',
        previewSuccessInfo: 'Email template preview has been sent to your email address',
      },
    },
    update: {
      index: {
        none: 'No updates...',
        markAsUnread: 'Mark as unread',
      },
    },
    waitlist: {
      id: {
        resend: 'Re-send email',
        requested: '{name} has requested the following service(s):',
        onTheseDates: 'on these date(s):',
        requestedServices: 'Requested services',
        firstAvailable: 'First available slot',
        more: '+ {label} more',
        booked: 'Booked appointment',
      },
      index: {
        noRequests: 'No waitlist requests',
      },
    },
    elements: {
      openToast: 'Open Toast',
      title: 'Message from Jarred Jenkinson',
      info: 'Hello there, can I move my appointment please?',
    },
    index: {
      hello: 'Hello',
      goodMorning: 'Good morning',
      goodAfternoon: 'Good afternoon',
      goodEvening: 'Good evening',
      greeting: '{greeting}, {name} 👋',
      appointmentsToday: 'You have <strong>{count} appointments</strong> today.',
      noAppointments: "You're all done for today! 🎉",
      noUnconfirmedReservations: 'No unconfirmed reservations',
      breaks: '{count} break | {count} breaks',
      todaysAppointments: "Today's appointments",
      showHideAppointments: 'Show/hide finished appointments',
      unconfirmedAppointments: 'Unconfirmed appointments',
      todaysRoster: "Today's roster",
      viewRoster: 'View roster',
    },
    unauthorised: {
      oops: 'Oops',
      noAccess: "It looks like you don't have access to that page",
      contactOwner: 'Contact the account owner if you need some help',
      takeMeHome: 'Take me home',
    },
  },
  plugins: {
    centrifuge: {
      messageFrom: 'Message from {label}',
      booked: '{name} booked an appointment',
    },
  },
  general: {
    ellipsesSlice: '{label}...',
    available: 'Available',
    filterByCategory: 'Filter by category',
    page: 'Page',
    post: 'Post',
    pre: 'Pre',
    listSeparator: ', ',
    system: 'System',
    percentage: 'Percentage',
    temporary: 'Temporary',
    suspended: 'Suspended',
    paymentError: 'Payment Error',
    trial: 'Trial',
    update: 'Update',
    sendPreview: 'Send preview',
    colonSeparator: '{label1}: {label2}', // 'needed' because languages have different usage of colons. label1 is where the word in english would go first, and label2 is the word that would follow - e.g. 'Name: John' / 'label1: label2'
    colon: '{label}:',
    reports: 'Reports',
    received: 'Received',
    didYouKnow: 'Did you know?',
    acceptLabel: 'Accept label',
    primary: 'Primary',
    expiryDays: 'Expiry days',
    preferences: 'Preferences',
    website: 'Website',
    useSearch: 'Use the search box to quickly find a particular page.',
    campaign: 'Campaign',
    sending: 'Sending',
    subject: 'Subject',
    rowLimit: 'Row limit',
    percentageValue: '{value}%',
    description: 'Description',
    businessName: 'Business name',
    guides: 'Unsure where to start? <a href="https://ovatu.com/au/help/guides" target="_blank">Check out our guides</a>.',
    currencySymbol: 'Currency symbol',
    enable: 'Enable',
    disable: 'Disable',
    settings: 'Settings',
    preview: 'Preview',
    publish: 'Publish',
    draft: 'Draft',
    resend: 'Resend',
    shortName: 'Short name',
    availability: 'Availability',
    tags: 'Tags',
    verify: 'Verify',
    next: 'Next',
    nope: 'Nope...',
    state: 'State',
    queued: 'Queued',
    complete: 'Complete',
    url: 'URL',
    employee: 'Employee',
    a4: 'A4',
    account: 'Account',
    actions: 'Actions',
    active: 'Active',
    activeFilters: 'Active filters',
    add: 'Add',
    address: 'Address',
    addressLine1: 'Address Line 1',
    addressLine2: 'Address Line 2',
    alerts: 'Alerts',
    all: 'All',
    allowed: 'Allowed',
    allowOnlineBooking: 'Allow online booking',
    amount: 'Amount',
    andMore: '{list} and {count} more',
    any: 'Any',
    apply: 'Apply',
    appointment: 'Appointment',
    appointments: 'Appointments',
    archived: 'Archived',
    assign: 'Assign',
    attach: 'Attach',
    attachment: 'Attachment',
    availableDays: 'Available days',
    back: 'Back',
    balance: 'Balance',
    bio: 'Bio',
    birthday: "It's {name}'s Birthday Today!",
    body: 'Body',
    bounced: 'Bounced',
    brand: 'Brand',
    call: 'Call',
    cancel: 'Cancel',
    cancelled: 'Cancelled',
    caption: 'Caption',
    category: 'Category',
    change: 'Change',
    checkIn: 'Check in',
    city: 'City',
    class: 'Class',
    clear: 'Clear',
    clearFilters: 'Clear filters',
    clicked: 'Clicked',
    close: 'Close',
    closed: 'Closed',
    closeWithoutSaving: 'Close without Saving',
    code: 'Code',
    colour: 'Colour',
    company: 'Company',
    complained: 'Complained',
    confirm: 'Confirm',
    confirmation: 'Confirmation',
    confirmDelete: 'Confirm delete',
    confirmReset: 'Confirm reset',
    contact: 'Contact',
    copyrightAndTrademark: '© {year} Ovatu Pty Ltd ABN 92 159 394 484 ATF Ovatu Trading Trust ABN 74 504 757 106 | OVATU is a registered trademark of Ovatu Pty Ltd.',
    country: 'Country',
    create: 'Create',
    created: 'Created',
    creditBalance: 'Credit balance',
    current: 'Current',
    custom: 'Custom',
    customer: 'Customer',
    customerAccount: 'Customer Account',
    customers: 'Customers',
    customerVisible: 'Customer visible',
    customFields: 'Custom fields',
    dashboard: 'Dashboard',
    dataType: 'Data type',
    date: 'Date',
    dateOfBirth: 'Date of birth',
    dates: 'Dates',
    datetime: 'Date & Time',
    dateTime: 'Date/Time',
    default: 'Default',
    defaultValue: 'Default value',
    delete: 'Delete',
    deleted: 'Deleted',
    delivered: 'Delivered',
    delivers: 'Delivers',
    deliveryDate: 'Delivery date',
    details: 'Details',
    disabled: 'Disabled',
    discount: 'Discount',
    discounts: 'Discounts',
    done: 'Done',
    download: 'Download',
    duplicate: 'Duplicate',
    duration: 'Duration',
    durationMinutes: 'Duration (minutes)',
    edit: 'Edit',
    editCustomer: 'Edit customer details',
    email: 'Email',
    emailBounced: 'Email bounced',
    employees: 'Employees',
    enabled: 'Enabled',
    error: 'Error',
    excl: 'excl.',
    expired: 'Expired',
    expires: 'Expires',
    expiresSoon: 'Expires soon',
    expiryDate: 'Expiry date',
    export: 'Export',
    filter: 'Filter',
    finishing: 'Finishing',
    firstName: 'First name',
    fixed: 'Fixed',
    fixError: 'Please fix the following error(s)',
    form: 'Form',
    forms: 'Forms',
    frequency: 'Frequency',
    from: 'From',
    fromColon: 'From:',
    fromName: 'From name',
    full: 'Full',
    gender: 'Gender',
    gift: 'Gift',
    giftCard: 'Gift card',
    giftCards: 'Gift Cards',
    group: 'Group',
    history: 'History',
    homePhone: 'Home phone',
    icon: 'Icon',
    image: 'Image',
    inactive: 'Inactive',
    incl: 'incl.',
    info: 'Info',
    information: 'Information',
    insert: 'Insert',
    integrations: 'Integrations',
    inventory: 'Inventory',
    invoice: 'Invoice',
    item: 'Item',
    label: 'Label',
    large: 'Large',
    lastMinute: 'Last Minute',
    lastName: 'Last name',
    link: 'Link',
    loading: 'Loading',
    loadingEllipses: 'Loading...',
    loadMore: 'Load more',
    location: 'Location',
    locked: 'Locked',
    logo: 'Logo',
    loyaltyEnabled: 'Loyalty enabled',
    loyaltyEnabledInfo: 'Customer earns loyalty points from this charge',
    loyaltyPoints: 'Loyalty points',
    manage: 'Manage',
    manual: 'Manual',
    marketing: 'Marketing',
    marketingConsent: 'Marketing consent',
    maxCustomers: 'Max customers',
    medium: 'Medium',
    message: 'Message',
    messages: 'Messages',
    messageSent: 'Message sent',
    mobile: 'Mobile',
    mobileCell: 'Mobile / Cell',
    name: 'Name',
    nameAndContact: 'Name and contact details',
    new: 'New',
    no: 'No',
    noCategory: 'No category',
    none: 'None',
    notAllowed: 'Not allowed',
    note: 'Note',
    notes: 'Notes',
    now: 'Now',
    number: 'Number',
    occupation: 'Occupation',
    online: 'Online',
    onlineBooking: 'Online booking',
    open: 'Open',
    opened: 'Opened',
    other: 'Other',
    override: 'Override',
    paid: 'Paid',
    paragraph: 'Paragraph',
    pass: 'Pass',
    passes: 'Passes',
    pay: 'Pay',
    payment: 'Payment',
    payments: 'Payments',
    percent: 'Percent',
    perMonth: 'p/m',
    personalInfo: 'Personal Information',
    phone: 'Phone',
    photo: 'Photo',
    photos: 'Photos',
    postcode: 'Postcode',
    preset: 'Preset',
    price: 'Price',
    print: 'Print',
    privacyConsent: 'Privacy consent',
    processing: 'Processing',
    product: 'Product',
    products: 'Products',
    promoCode: 'Promo code',
    purchase: 'Purchase',
    purchaseOrders: 'Purchase orders',
    purchaser: 'Purchaser',
    quantity: 'Quantity',
    readAndUnderstand: 'I have read and understand the above information',
    readMore: 'Read more',
    reason: 'Reason',
    receipt: 'Receipt',
    recipient: 'Recipient',
    recipientEmail: 'Recipient email',
    recipientName: 'Recipient name',
    refund: 'Refund',
    region: 'Region',
    remove: 'Remove',
    reorder: 'Reorder',
    request: 'Request',
    required: 'Required',
    reservation: 'Reservation',
    reservations: 'Reservations',
    resource: 'Resource',
    resourceGroups: 'Resource Groups',
    resources: 'Resources',
    restore: 'Restore',
    revenue: 'Revenue',
    revert: 'Revert',
    reviews: 'Reviews',
    role: 'Role',
    roster: 'Roster',
    sale: 'Sale',
    sales: 'Sales',
    save: 'Save',
    saveAndClose: 'Save & Close',
    saveChanges: 'Save changes',
    saved: 'Saved',
    schedule: 'Schedule',
    scheduled: 'Scheduled',
    search: 'Search',
    segments: 'Segments',
    selectAll: 'Select all',
    selectAnOption: 'Select an option',
    send: 'Send',
    sendEmail: 'Send email',
    senderName: 'Sender name',
    sendSMS: 'Send SMS',
    sent: 'Sent',
    sentEmail: 'Sent email',
    service: 'Service',
    services: 'Services',
    showLess: 'Show less',
    showMore: 'Show more',
    single: 'Single',
    small: 'Small',
    sms: 'SMS',
    start: 'Start',
    startTime: 'Start time',
    status: 'Status',
    stockTake: 'Stock take',
    subscribed: 'Subscribed',
    subtotal: 'Subtotal',
    suburb: 'Suburb',
    success: 'Success',
    summary: 'Summary',
    supplier: 'Supplier',
    tax: 'Tax',
    taxes: 'Taxes',
    theme: 'Theme',
    time: 'Time',
    timetable: 'Timetable',
    title: 'Title',
    to: 'To',
    todaysDate: 'Today\'s date',
    toEmail: 'To email',
    toName: 'To name',
    total: 'Total',
    transactions: 'Transactions',
    type: 'Type',
    undo: 'Undo',
    unsavedChanges: 'Unsaved Changes',
    unsavedChangesInfo: "Changes you've made so far will not be saved.",
    unsubscribed: 'Unsubscribed',
    updates: 'Updates',
    uploadAttachment: 'Upload attachment',
    uploading: 'Uploading',
    uploads: 'Uploads',
    user: 'User',
    uses: 'Uses',
    value: 'Value',
    values: 'Values',
    view: 'View',
    waitlist: 'Waitlist',
    warning: 'Warning',
    withName: 'with {name}',
    workPhone: 'Work phone',
    yes: 'Yes',
    reminders: 'Reminders',
    bookapp: 'BookApp'
  },
  i18n: {
    language: 'Language',
    description: 'Select site language',
  },
  datetime: {
    days: {
      monday: {
        initial: 'M',
        short: 'Mon',
        long: 'Monday',
        plural: 'Mondays',
      },
      tuesday: {
        initial: 'T',
        short: 'Tue',
        long: 'Tuesday',
        plural: 'Tuesdays',
      },
      wednesday: {
        initial: 'W',
        short: 'Wed',
        long: 'Wednesday',
        plural: 'Wednesdays',
      },
      thursday: {
        initial: 'T',
        short: 'Thu',
        long: 'Thursday',
        plural: 'Thursdays',
      },
      friday: {
        initial: 'F',
        short: 'Fri',
        long: 'Friday',
        plural: 'Fridays',
      },
      saturday: {
        initial: 'S',
        short: 'Sat',
        long: 'Saturday',
        plural: 'Saturdays',
      },
      sunday: {
        initial: 'S',
        short: 'Sun',
        long: 'Sunday',
        plural: 'Sundays',
      },
    },
    months: {
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'December',
    },
    labels: {
      hours: 'Hours',
      weeks: 'Weeks',
      today: 'Today',
      day: 'Day',
      week: 'Week',
      month: 'Month',
      quarter: 'Quarter',
      year: 'Year',
    },
    increments: {
      mins: '{mins} min | {mins} mins',
      minutes: '{minutes} minute | {minutes} minutes',
      hours: '{hours} hour | {hours} hours',
      days: '{days} day | {days} days',
      weeks: '{weeks} week | {weeks} weeks',
      months: '{months} month | {months} months',
      years: '{years} year | {years} years',
    },
    range: {
      daily: 'Daily',
      weekly: 'Weekly',
      fortnightly: 'Fortnightly',
      monthly: 'Monthly',
      quarterly: 'Quarterly',
      yearly: 'Yearly',
    },
    ordinals: {
      one: '{number}st',
      two: '{number}nd',
      few: '{number}rd',
      other: '{number}th',
    },
  },
  account: {
    users: 'Users',
    roles: 'Roles',
    owner: 'Owner',
    subscribe: 'Subscribe',
    inactive: 'Inactive account',
    inactiveInfo: 'Your account is currently inactive. To subscribe please click below',
    settings: 'Account settings',
    unableToSelectPlan: 'Unable to select this plan',
    pleaseSelectPlan: 'Please select a plan and payment method to continue using this account',
    selectPlan: 'Select a plan',
    pricePer: `
    First employee is {currency}{price}<br>
    Each additional employee is {currency}{pricePerEmployee}
    `,
    contactOwner: 'Please contact the account owner for assistance',
  },
  asset: {
    uploadImage: 'Upload image',
    changeImage: 'Change image',
    addedPhoto: 'Added photo',
    addPhoto: 'Add photo',
    addToLibrary: 'Add to Asset Library',
    addToLibraryInfo: 'Save this file to your asset library to easily use it again in future campaigns',
    adjustFilters: 'Try adjusting the filters above',
    deleteConfirm: 'Delete photo',
    deleteConfirm2: 'Delete existing photo',
    deleteErrorInfo: 'Error while deleting photo',
    deleteImage: 'Delete image',
    deleteInfo: 'Are you sure you want to delete this image?',
    deleteSuccess: 'Deleted photo',
    deleteSuccessInfo: 'Removed photo from account',
    deleteSuccessInfo2: 'Deleted image for {label}',
    downloadImage: 'Download image',
    fileName: 'File name',
    fileNameInfo: 'A label for this file in your asset library',
    files: 'Files',
    attachedFiles: 'Attached files',
    images: 'Images',
    insertImage: 'Insert Image',
    library: 'Asset library',
    noPhotos: 'No photos...',
    noUploads: 'No uploads found.',
    removeImage: 'Remove existing image',
    savedPhoto: 'Saved photo',
    search: 'Search assets',
    success: 'Uploaded image',
    successInfo: 'Updated profile photo for {name}',
    successInfo2: 'Updated image for {label}',
    upload: 'Upload',
    uploadGalleryImage: 'Upload gallery image',
    uploadAnImage: 'Upload an image',
    uploadInfo: 'Upload assets to your asset library to use them in your marketing campaigns.',
  },
  alerts: {
    delete: {
      title: 'Delete alert',
      success: 'Deleted alert',
    },
    deleteAll: {
      title: 'Delete all alerts',
      success: 'Alerts deleted',
      successInfo: 'All of your alerts have been deleted',
    },
  },
  auth: {
    password: {
      resetPassword: {
        title: 'Reset password',
        title2: 'Password Reset',
        for: 'Reset password for <strong>{name}</strong>',
        for2: `
          Reset password for: <br>
          <strong>{name}</strong> (#{id})<br>
        `,
        success: 'Password reset',
        successInfo: 'Successfully reset password for {name}',
        error: 'Error resetting',
        errorInfo: 'An error occured while attempting to reset password for customer: {name}',
        sendEmailToUser: 'Send an email to the user letting them know their new password',
        notifyCustomer: 'Notify the customer that their password has changed',
      },
      changePassword: {
        title: 'Change password',
        oldPassword: 'Old password',
        successInfo: 'Successfully changed your password',
      },
      label: 'Password',
      new: 'New password',
      confirm: 'Confirm password',
      suggest: 'Suggest a password',
      changeSuccess: 'Password changed',
      changeSuccessInfo: 'Successfully changed password for {name}',
      forgot: 'Forgot your password? {requestReset}',
      requestReset: 'Request password reset',
      set: 'Set your password',
    },
    pin: {
      resetPin: {
        info: `
          Reset PIN for: <br>
          <strong>{name}</strong> (#{id})<br>
        `,
        for: 'Reset PIN for <strong>{name}</strong>',
        successInfo: 'Successfully changed PIN for {name}',
      },
      label: 'PIN',
      new: 'New PIN',
      confirm: 'Confirm PIN',
      add: 'Add a PIN',
      remove: 'Remove PIN',
      reset: 'Reset PIN',
      enable: 'Enable PIN',
      change: 'Change PIN',
      changeDisable: 'Change/disable PIN',
      changeSuccess: 'PIN changed',
      enter: 'Enter your employee user PIN to unlock or {switchUser}',
      switchUser: 'switch user',
    },
    free: 'free',
    adminUnlock: 'Admin unlock',
    alreadyKnow: 'Already know the domain? {enterExisting}',
    attemptsRemaining: '{count} attempts remaining.',
    continue: 'Continue',
    dontKnow: "Don't know your Ovatu domain? {find}",
    email: 'Email address',
    enterExisting: 'Enter an existing domain',
    findAccount: 'Find your account',
    findDifferentDomain: 'Find a different domain',
    loginError: 'Error logging you in',
    resend: "Didn't receive a code? {resend}",
    signIn: 'Sign in',
    verifyCode: 'Verify your code',
    verifyCodeInfo: `
      We sent a 6-digit code to {email}<br>
      Enter the code below to show your associated accounts (expires after 5 minutes)
    `,
    welcome: 'Welcome!',
    wrongDomain: 'Wrong domain? {findDifferentDomain}',
    yourDomain: 'your-domain',
  },
  billing: {
    paypal: 'Paypal',
  },
  brand: {
    new: 'New brand',
  },
  cancellationReason: {
    displayMode: 'Display mode',
    includeInHighCancellation: 'Include in high cancellation',
    new: 'New cancellation reason',
  },
  changeEmail: {
    title: 'Change email',
    details: 'Change details',
    detailsInfo: 'Change the name and email for this user',
    confirm: 'Yes, change details',
    success: 'Details updated',
  },
  changeOwner: {
    title: 'Change owner',
    info: 'Transfer the ownership of this account',
    changeOwnership: 'Change ownership',
  },
  changeRole: {
    title: 'Change role',
    for: `
    Change role for: <br>
    <strong>{name}</strong> (#{id})<br>
    `,
    success: 'Role changed',
    successInfo: 'Successfully changed role for {name}',
  },
  confirmation: {
    confirmationCode: 'Confirmation code',
    requestCode: 'Request confirmation code',
    codeSentToEmail: "We've sent a code to your email address ({email})",
    resendCode: 'Resend code',
  },
  countries: {
    afghanistan: 'Afghanistan',
    aland: 'Åland',
    albania: 'Albania',
    algeria: 'Algeria',
    americanSamoa: 'American Samoa',
    andorra: 'Andorra',
    angola: 'Angola',
    anguilla: 'Anguilla',
    antarctica: 'Antarctica',
    antiguaAndBarbuda: 'Antigua and Barbuda',
    argentina: 'Argentina',
    armenia: 'Armenia',
    aruba: 'Aruba',
    australia: 'Australia',
    austria: 'Austria',
    azerbaijan: 'Azerbaijan',
    bahamas: 'Bahamas',
    bahrain: 'Bahrain',
    bangladesh: 'Bangladesh',
    barbados: 'Barbados',
    belarus: 'Belarus',
    belgium: 'Belgium',
    belize: 'Belize',
    benin: 'Benin',
    bermuda: 'Bermuda',
    bhutan: 'Bhutan',
    bolivia: 'Bolivia',
    bonaireSintEustatiusAndSaba: 'Bonaire, Sint Eustatius and Saba',
    bosniaAndHerzegovina: 'Bosnia and Herzegovina',
    botswana: 'Botswana',
    bouvetIsland: 'Bouvet Island',
    brazil: 'Brazil',
    britishIndianOceanTerritory: 'British Indian Ocean Territory',
    bruneiDarussalam: 'Brunei Darussalam',
    bulgaria: 'Bulgaria',
    burkinaFaso: 'Burkina Faso',
    burundi: 'Burundi',
    cambodia: 'Cambodia',
    cameroon: 'Cameroon',
    canada: 'Canada',
    capeVerde: 'Cape Verde',
    caymanIslands: 'Cayman Islands',
    centralAfricanRepublic: 'Central African Republic',
    chad: 'Chad',
    chile: 'Chile',
    china: 'China',
    christmasIsland: 'Christmas Island',
    cocosKeelingIslands: 'Cocos (Keeling) Islands',
    colombia: 'Colombia',
    comoros: 'Comoros',
    congoBrazzaville: 'Congo (Brazzaville)',
    congoKinshasa: 'Congo (Kinshasa)',
    cookIslands: 'Cook Islands',
    costaRica: 'Costa Rica',
    coteDIvoire: "Côte d'Ivoire",
    croatia: 'Croatia',
    cuba: 'Cuba',
    curacao: 'Curaçao',
    cyprus: 'Cyprus',
    czechRepublic: 'Czech Republic',
    denmark: 'Denmark',
    djibouti: 'Djibouti',
    dominica: 'Dominica',
    dominicanRepublic: 'Dominican Republic',
    ecuador: 'Ecuador',
    egypt: 'Egypt',
    elSalvador: 'El Salvador',
    equatorialGuinea: 'Equatorial Guinea',
    eritrea: 'Eritrea',
    estonia: 'Estonia',
    ethiopia: 'Ethiopia',
    falklandIslands: 'Falkland Islands',
    faroeIslands: 'Faroe Islands',
    fiji: 'Fiji',
    finland: 'Finland',
    france: 'France',
    frenchGuiana: 'French Guiana',
    frenchPolynesia: 'French Polynesia',
    frenchSouthernLands: 'French Southern Lands',
    gabon: 'Gabon',
    gambia: 'Gambia',
    georgia: 'Georgia',
    germany: 'Germany',
    ghana: 'Ghana',
    gibraltar: 'Gibraltar',
    greece: 'Greece',
    greenland: 'Greenland',
    grenada: 'Grenada',
    guadeloupe: 'Guadeloupe',
    guam: 'Guam',
    guatemala: 'Guatemala',
    guernsey: 'Guernsey',
    guinea: 'Guinea',
    guineaBissau: 'Guinea-Bissau',
    guyana: 'Guyana',
    haiti: 'Haiti',
    heardAndMcDonaldIslands: 'Heard and McDonald Islands',
    honduras: 'Honduras',
    hongKong: 'Hong Kong',
    hungary: 'Hungary',
    iceland: 'Iceland',
    india: 'India',
    indonesia: 'Indonesia',
    iran: 'Iran',
    iraq: 'Iraq',
    ireland: 'Ireland',
    isleOfMan: 'Isle of Man',
    israel: 'Israel',
    italy: 'Italy',
    jamaica: 'Jamaica',
    japan: 'Japan',
    jersey: 'Jersey',
    jordan: 'Jordan',
    kazakhstan: 'Kazakhstan',
    kenya: 'Kenya',
    kiribati: 'Kiribati',
    koreaNorth: 'Korea, North',
    koreaSouth: 'Korea, South',
    kuwait: 'Kuwait',
    kyrgyzstan: 'Kyrgyzstan',
    laos: 'Laos',
    latvia: 'Latvia',
    lebanon: 'Lebanon',
    lesotho: 'Lesotho',
    liberia: 'Liberia',
    libya: 'Libya',
    liechtenstein: 'Liechtenstein',
    lithuania: 'Lithuania',
    luxembourg: 'Luxembourg',
    macau: 'Macau',
    macedonia: 'Macedonia',
    madagascar: 'Madagascar',
    malawi: 'Malawi',
    malaysia: 'Malaysia',
    maldives: 'Maldives',
    mali: 'Mali',
    malta: 'Malta',
    marshallIslands: 'Marshall Islands',
    martinique: 'Martinique',
    mauritania: 'Mauritania',
    mauritius: 'Mauritius',
    mayotte: 'Mayotte',
    mexico: 'Mexico',
    micronesia: 'Micronesia',
    moldova: 'Moldova',
    monaco: 'Monaco',
    mongolia: 'Mongolia',
    montenegro: 'Montenegro',
    montserrat: 'Montserrat',
    morocco: 'Morocco',
    mozambique: 'Mozambique',
    myanmar: 'Myanmar',
    namibia: 'Namibia',
    nauru: 'Nauru',
    nepal: 'Nepal',
    netherlands: 'Netherlands',
    newCaledonia: 'New Caledonia',
    newZealand: 'New Zealand',
    nicaragua: 'Nicaragua',
    niger: 'Niger',
    nigeria: 'Nigeria',
    niue: 'Niue',
    norfolkIsland: 'Norfolk Island',
    northernMarianaIslands: 'Northern Mariana Islands',
    norway: 'Norway',
    oman: 'Oman',
    pakistan: 'Pakistan',
    palau: 'Palau',
    palestine: 'Palestine',
    panama: 'Panama',
    papuaNewGuinea: 'Papua New Guinea',
    paraguay: 'Paraguay',
    peru: 'Peru',
    philippines: 'Philippines',
    pitcairn: 'Pitcairn',
    poland: 'Poland',
    portugal: 'Portugal',
    puertoRico: 'Puerto Rico',
    qatar: 'Qatar',
    reunion: 'Reunion',
    romania: 'Romania',
    russianFederation: 'Russian Federation',
    rwanda: 'Rwanda',
    saintBarthelemy: 'Saint Barthélemy',
    saintHelena: 'Saint Helena',
    saintKittsAndNevis: 'Saint Kitts and Nevis',
    saintLucia: 'Saint Lucia',
    saintMartinFrenchPart: 'Saint Martin (French part)',
    saintPierreAndMiquelon: 'Saint Pierre and Miquelon',
    saintVincentAndGrenadines: 'Saint Vincent and the Grenadines',
    samoa: 'Samoa',
    sanMarino: 'San Marino',
    saoTomeAndPrincipe: 'Sao Tome and Principe',
    saudiArabia: 'Saudi Arabia',
    senegal: 'Senegal',
    serbia: 'Serbia',
    seychelles: 'Seychelles',
    singapore: 'Singapore',
    slovakia: 'Slovakia',
    slovenia: 'Slovenia',
    solomonIslands: 'Solomon Islands',
    southAfrica: 'South Africa',
    spain: 'Spain',
    sriLanka: 'Sri Lanka',
    sudan: 'Sudan',
    sweden: 'Sweden',
    switzerland: 'Switzerland',
    thailand: 'Thailand',
    turkey: 'Turkey',
    ukraine: 'Ukraine',
    unitedArabEmirates: 'United Arab Emirates',
    unitedKingdom: 'United Kingdom',
    unitedStates: 'United States of America',
    uruguay: 'Uruguay',
    venezuela: 'Venezuela',
    vietnam: 'Vietnam',
    yemen: 'Yemen',
    zambia: 'Zambia',
    zimbabwe: 'Zimbabwe',
  },
  customer: {
    gender: {
      none: 'Unspecified',
      female: 'Female',
      male: 'Male',
      nonBinary: 'Non-binary',
    },
    consent: {
      notObtained: 'Not obtained',
      accepted: 'Accepted',
      declined: 'Declined',
    },
    filters: {
      hasPurchasedGiftCard: 'Has purchased a gift card',
      hasPurchasedSelectedPasses: 'Has purchased the selected passes',
      allPasses: 'All Passes',
      emailPreferred: 'Email preferred',
      smsPreferred: 'SMS preferred',
      notDeleted: 'Not deleted',
      hasEmail: 'Has email',
      hasNoEmail: 'Has no email',
      phone: 'Phone #',
      hasPhone: 'Has phone',
      hasPhotoInfo: 'Show customers with photos',
      hasNoPhone: 'Has no phone',
      hasPhoto: 'Has photo',
      hasNoPhoto: 'Has no photo',
      photoInfo: 'Show customers with photos',
      createdInfo: 'When the customer record was created',
      inactiveInfo: 'Show customers without appointments in this range',
      noFutureAppointments: 'No future appointments',
      noPastAppointments: 'No past appointments',
      activeInfo: 'Show customers with appointments in this range',
      hasFutureAppointments: 'Has future appointments',
      hasPastAppointments: 'Has past appointments',
      noShows: 'No shows',
      noShowsInfo: 'Show customers with no show reservations in this range',
      cancellations: 'Cancellations',
      cancellationsInfo: 'Show customers with cancellations in this range',
      employeesInfo: 'Customers who have booked with the selected employees',
      with: 'With',
      allEmployees: 'All Employees',
      servicesInfo: 'Customers who have booked the selected services',
      allServices: 'All Services',
      invalidDate: 'The date you have entered is invalid. Please try again...',
      servicesWithEmployees: '{services} with {employees}',
      emailBouncedInfo: 'Customer has unsuccessful email attempts',
      duplicateInfo: 'Show customers that have more than one record',
      archivedInfo: 'Show archived customers',
      notArchived: 'Not archived',
      hasLoyaltyPoints: 'Has loyalty points',
      noLoyaltyPoints: 'No loyalty points',
      giftCardInfo: 'Show customers that have a gift card',
      valid: 'Valid',
      massEmailSubscription: 'Mass Email Subscription',
      massSMSSubscription: 'Mass SMS Subscription',
      formsFilled: 'Forms filled',
      formsFilledInfo: 'Show customers that have completed these forms',
      allForms: 'All Forms',
      formsRequested: 'Forms requested',
      formsRequestedInfo: 'Show customers that have had these forms requested',
      formsNotRequested: 'Forms not requested',
      formsNotRequestedInfo: 'Show customers that haven\'t completed these forms',
      productsInfo: 'Has purchased the selected products',
      personal: 'Personal',
    },
    expiringSoon: 'Expiring soon',
    add: 'Add customer',
    new: 'New customer',
    search: 'Search customers',
    loading: 'Loading Customers',
    createdSuccess: 'Created new customer',
    createdSuccessInfo: 'Successfully created new customer: {name}',
    createError: 'Error while creating new customer',
    customersSelected: '{count} Customer selected | {count} Customers selected',
    sendNotifications: 'Send notifications',
    highNoShow: 'High no show',
    highCancellation: 'High cancellation',
    averageValue: 'Avg. appointment value',
    call: 'Call customer',
    callInfo: 'Call customer using device default service',
    sendSMSInfo: 'Create & send a custom SMS',
    notify: 'Notify customer',
  },
  customPage: {
    create: 'Create new page',
    slug: 'Slug',
    published: 'Published',
    new: 'New {description} custom field',
  },
  demo: {
    start: 'Start demo',
  },
  discount: {
    select: 'Select a discount',
    edit: 'Edit discount',
    success: 'Applied discount',
    successInfo: 'Applied discount for {name} in reservation: {label}',
  },
  downloadOvatu: {
    downloadOnThe: 'Download on the',
    appStore: 'App Store',
    getItOn: 'GET IT ON',
    googlePlay: 'Google Play',
  },
  employee: {
    restricted: 'Restricted employee',
    hasPriceOverrides: 'Some services have price overrides. ',
    hasZeroPricesOverrides: 'Some services have 0 price overrides. ',
    hasTimingOverrides: 'Some services have timing overrides. ',
    withPreviousAppointments: 'With Previous Appointments',
    limited: 'Limited',
    allowAppAccessAndBookings: 'Allow app access and bookings with this employee',
    allowOnlineBookingInfo: 'Allow customers to book services with this employee online',
    loyaltyEnabledInfo: 'Allow customers to earn loyalty points with this employee',
    setPermissions: 'Set the employees permissions by selecting a role',
    limitService: 'Limit the days the employee can provide this service',
    select: 'Select an employee',
    all: 'All employees',
    revoke: 'Revoke employee sessions',
    successInfo: 'The employee details were successfully saved',
    forMoreDetails: 'For more details, visit',
    pricing: 'ovatu.com/pricing',
    new: 'New employee',
    edit: 'Edit employee',
  },
  export: {
    running: 'Running',
    requested: 'Export requested',
    processing: 'We are processing your data and will let you know when its ready on the exports page',
  },
  file: {
    fileType: 'File type',
    fileSize: 'File size',
    uploaded: 'Uploaded',
  },
  field: {
    text: 'Text',
    longText: 'Long Text',
    multiple: 'Select One (Dropdown/Radio List)',
    multiSelect: 'Select Multiple (Checkbox List)',
    selectOne: 'Select (One)',
    checkbox: 'Checkbox',
    number: 'Number',
    date: 'Date',
    signature: 'Signature',
    yesNo: 'Yes / No',
    selectMulti: 'Select (Multi)',
    annotation: 'Annotation',
    multipleValues: 'Multiple values',
  },
  form: {
    field: 'Field',
    radio: 'Radio',
    dropdown: 'Dropdown',
    secure: 'Secure',
    addItem: 'Add form item',
    content: 'Content',
    add: 'Add form',
    addNew: 'Add a new form',
    completed: 'Completed',
    consentAccepted: 'Consent accepted',
    delete: 'Delete form',
    deleteSuccess: 'Deleted form',
    deleteInfo: `
      Are you sure you want to delete form: <br>
      <strong>{title}</strong> (#{id})<br><br>
      The form will be removed permanently from your account.
    `,
    requestErrorInfo: 'Error while requesting form',
    fillInForm: 'Fill in form',
    new: 'New form',
    empty: 'No forms to show...',
    prefill: 'Prefill',
    prefillInfo: 'Use customers previous form information to prefill',
    reopened: 'Re-opened',
    requested: 'Requested',
    section: 'Section',
    updateRequested: 'Update requested',
    view: 'View form',
    select: 'Select a form',
    find: 'Find',
    frequencyInfo: 'How often the form will be required',
    requestMode: 'Request mode',
    requestModeInfo: 'When the form will be requested',
    scheduledTime: 'Scheduled time',
    scheduledTimeInfo: 'When the form is scheduled to be sent (in hours)',
    before: 'Before',
    after: 'After',
    edit: 'Edit Form',
    changeOptions: 'Change options for service form: <strong class="font-medium">{title}</strong>',
    success: 'Updated form',
    serviceFormSuccessInfo: 'Updated service form: {title}',
    error: 'Error updating form',
    errorInfo: 'Unable to update service form: {title}',
  },
  giftCard: {
    new: 'New gift card',
    edit: 'Edit gift card',
    delete: 'Delete gift card',
    customNumber: 'Custom number',
    neverExpires: 'Never expires',
    spent: 'Spent',
    undelivered: 'Undelivered',
    deliveryFailed: 'Delivery failed',
    claimed: 'Claimed',
    unclaimed: 'Unclaimed',
  },
  integration: {
    square: {
      label: 'Square',
      reconnectInfo: 'There is an issue with your Square account. Please re-connect in integration settings.',
      setupIncompleteInfo: 'Your Square integration setup is incomplete. Please finish setup in integration settings.',
      loading: 'Loading Square',
      error: 'Something went wrong while loading Square.',
    },
    stripe: {
      label: 'Stripe',
      reconnectInfo: 'There is an issue with your Stripe account. Please re-connect in integration settings.',
      setupIncompleteInfo: 'Your Stripe integration setup is incomplete. Please finish setup in integration settings.',
      loading: 'Loading Stripe',
    },
    label: 'Integration',
    connected: 'Connected',
    setupIncomplete: 'Setup incomplete',
    needsReconnecting: 'Needs re-connecting',
    paymentFailed: 'Payment failed',
    chargeCard: 'Charge card',
    connect: 'Connect {name} to your Ovatu account',
    disconnected: 'Disconected',
  },
  location: {
    appointmentsDate: 'Appointment\'s date',
    alphanum: 'Alpha-numeric (e.g. ae72ae4ead)',
    sequential: 'Sequential (e.g. 1234)',
    twelveHour: '12 Hour',
    twentyFourHour: '24 Hour',
  },
  manage: {
    pages: {
      activeVouchers: 'Active promo codes',
      brands: 'Brands',
      cancellationReasons: 'Cancellation reasons',
      customerGiftCards: 'Customer gift cards',
      customerPasses: 'Customer passes',
      expiredVouchers: 'Expired promo codes',
      groupCustomer: 'Group Customer',
      loyaltyProgram: 'Loyalty Program',
      loyaltyProgramSettings: 'Loyalty program settings',
      pointOfSale: 'Point of sale',
      productCategories: 'Product categories',
      promoCodes: 'Promo codes',
      remindersAndNotifications: 'Reminders & notifications',
      segments: 'Customer segments',
      serviceCategories: 'Service Categories',
      statusTypes: 'Appointment status types',
      suppliers: 'Suppliers',
      templates: 'Templates',
    },
    savedSuccessInfo: 'Saved location preferences',
    reorderSuccess: 'Successfully saved reorder',
    reorderError: 'Error saving reorder',
    reorderErrorInfo: 'There was an issue saving your reorder settings',
    overrides: 'Overrides',
  },
  marketing: {
    pages: {
      campaigns: 'Campaigns',
      sentSMSes: 'Sent SMSes',
      sentEmails: 'Sent Emails',
      customerReviews: 'Customer reviews',
    },
    automated: 'Automated',
    notToUnsubscribed: 'Not sent to unsubscribed customers',
    administrative: 'Administrative',
    scheduled: '(Scheduled: {date})',
    sendToAll: 'Sent to all customers (used for non-promotional emails only)',
    unopened: 'Un-opened',
    recipients: 'Recipients',
    uploadSuccess: 'Upload Success',
  },
  message: {
    empty: 'No messages...',
    lost: 'Your message will be lost',
    lostInfo: 'Your message has not been send. Your message will be lost.',
    close: 'Close without sending',
    emailSent: 'Email sent',
  },
  meta: {
    Account: 'Account',
    Users: 'Users',
    Inactiveaccount: 'Inactive account',
    Subscribe: 'Subscribe',
    Unsubscribe: 'Unsubscribe',
    Policy: 'Policy',
    Verify: 'Verify',
    Devices: 'Devices',
    Calendarfeeds: 'Calendar feeds',
    Exports: 'Exports',
    Integrations: 'Integrations',
    Privacy: 'Privacy',
    Referrals: 'Referrals',
    Security: 'Security',
    Sessions: 'Sessions',
    Sync: 'Sync',
    Roles: 'Roles',
    Manage: 'Manage',
    Appointmentstatustypes: 'Appointment status types',
    Brands: 'Brands',
    Cancellationreasons: 'Cancellation reasons',
    Charges: 'Charges',
    Giftcards: 'Gift cards',
    Customerpasses: 'Customer passes',
    Details: 'Details',
    Discounts: 'Discounts',
    Emailsettings: 'Email settings',
    Employees: 'Employees',
    Forms: 'Forms',
    Location: 'Location',
    Passes: 'Passes',
    Payments: 'Payments',
    Productcategories: 'Product categories',
    Products: 'Products',
    Promocodes: 'Promo codes',
    Purchaseorders: 'Purchase orders',
    Reminders: 'Reminders',
    ResourceGroups: 'Resource Groups',
    Resources: 'Resources',
    Schedule: 'Schedule',
    Segments: 'Segments',
    Servicecategories: 'Service categories',
    Services: 'Services',
    SMSsettings: 'SMS settings',
    Stocktakes: 'Stock takes',
    Suppliers: 'Suppliers',
    Taxes: 'Taxes',
    Emailtemplates: 'Email templates',
    Marketing: 'Marketing',
    AssetLibrary: 'Asset Library',
    Campaigns: 'Campaigns',
    Campaignreport: 'Campaign report',
    Messages: 'Messages',
    Reviews: 'Reviews',
    Minisite: 'Minisite',
    Theme: 'Theme',
    Contactinfo: 'Contact info',
    CustomPages: 'Custom Pages',
    CustomerAccount: 'Customer Account',
    Domain: 'Domain',
    Settings: 'Settings',
    Gallery: 'Gallery',
    Businesshours: 'Business hours',
    Menu: 'Menu',
    Minimisegaps: 'Minimise gaps',
    Newcustomers: 'New customers',
    Preferences: 'Preferences',
    CustomScript: 'Custom Script',
    SEO: 'SEO',
    Sitenotice: 'Site notice',
    Sociallinks: 'Social links',
    Terms: 'Terms',
    Reports: 'Reports',
    Report: 'Report',
  },
  minimiseGaps: {
    title: 'Minimise gaps',
    showFirstAvailable: 'Show first available appointment',
    showLastAvailable: 'Show last available appointment',
    showAllAvailability: 'Show all availability for empty day',
    showAvailabilityLeavingMinGapTime: 'Show availability leaving minimum gap time (minutes)',
  },
  minisite: {
    pages: {
      share: 'Share',
      embed: 'Embed',
      customer: 'Customer (Account)',
      siteNotice: 'Site Notice',
      businessHours: 'Business Hours',
      social: 'Social',
      minimiseGaps: 'Minimise Gaps',
      terms: 'Terms',
      pages: 'Pages',
      customPages: 'Custom pages',
      menu: 'Menu',
      businessInfo: 'Business info',
      advanced: 'Advanced',
    },
    preferences: {
      onlineBookingAllowCustomersAll: 'All customers unless disabled in segment',
      onlineBookingAllowCustomersNone: 'No customers unless enabled in segment',
      allowInternetSelectEmployeeAll: 'Not allowed (default First Available)',
      allowInternetSelectEmployeeNone: 'Allow Employee Selection',
      allowInternetSelectEmployeeExpanded: 'Expanded Employee Selection',
      waitlistModeNone: 'Manually notify customers (internal notification)',
      waitlistModeExpanded: 'Automatically notify customers',
    },
    customPage: 'Custom Page',
    advanced: 'This is an advanced feature. If you are not sure what you are doing, please do not use this feature.',
    onlineBookingDisabled: 'Online booking is disabled',
    businessHours: 'Business hours',
    label: 'Minisite',
    title: 'Minisite',
    hero: 'Hero',
    addMenuGroup: 'Add menu group',
    customDomain: 'Custom domain',
    booking: 'Booking',
    gallery: 'Gallery',
    newCustomers: 'New customers',
    domain: 'Domain',
    script: 'Custom Script',
    seo: 'SEO',
    siteNotice: 'Site notice',
    siteNoticeInfo: 'Notice/alert to show at the top of your book.app site',
    noticeContent: 'Notice content',
    noticeContentInfo: 'The text to show in the site notice',
    onlineBookingInfo: 'Allow customers to book appointments online',
    timetableInfo: 'Enable customers to book classes via the timetable',
    lastMinute: 'Last minute',
    lastMinuteInfo: 'Show a list of all availability in the coming 7 days',
    saved: 'Saved minisite settings',
    savedBookApp: 'Saved book.app business info',
    removeCustomDomain: 'Remove custom domain',
    waitingDNS: 'Waiting DNS',
    termsAgreement: 'Enable terms agreement',
    termsTitle: 'Terms title',
    termsContent: 'Terms content',
    termsAccept: 'Terms accept button',
    termsAcceptInfo: 'Label for the terms accept button',
  },
  note: {
    type: 'Note type',
    add: 'Add note',
    addInfo: 'Add note to: <strong>{name}</strong>',
    addSuccess: 'Note added',
    addSuccess2: 'Added note',
    addSuccessInfo: 'Added note to {name}',
  },
  notifications: {
    toggleAll: 'Toggle All',
    apps: 'Apps',
    push: 'Push',
    adminUser: {
      1: { // TYPE_CUSTOMER_NEW_RESERVATION: {
        name: 'Customer New Appointment',
        information: 'Sent when a customer makes a new online appointment via your minisite, booking widgets or Facebook.',
      },
      11: { // TYPE_CUSTOMER_MOVE_RESERVATION: {
        name: 'Customer Move Appointment',
        information: 'Sent when a customer moves their appointment via your minisite.',
      },
      2: { // TYPE_CUSTOMER_CANCEL_RESERVATION: {
        name: 'Customer Cancel Appointment',
        information: 'Sent when a customer cancels appointment via your minisite.',
      },
      3: { // TYPE_CUSTOMER_CHECKIN: {
        name: 'Customer Check-in',
        information: 'Sent when a customer checks in to an appointment.',
      },
      4: { // TYPE_CUSTOMER_ONLINE_PAYMENT: {
        name: 'Customer Payment',
        information: 'Sent when a customer makes a payment (appointment deposit, gift card, pass, etc) via your minisite, booking widgets or Facebook.',
      },
      20: { // TYPE_CUSTOMER_CONFIRM_RESERVATION: {
        name: 'Customer Appointment Confirmation',
        information: 'Sent when a customer confirms that they will be attending the appointment via an email or SMS reply.',
      },
      21: { // TYPE_CUSTOMER_FORM_RECEIVED: {j
        name: 'Customer Form Received',
        information: 'Sent when a customer fills in a form via your minisite.',
      },
      23: { // TYPE_CUSTOMER_EMAIL_BOUNCE: {
        name: 'Customer Email Bounced',
        information: 'Sent when an email is bounced for a customer.',
      },
      12: { // TYPE_INTERNAL_NEW_RESERVATION: {
        name: 'Internal New Appointment',
        information: 'Sent when a new appointment is made internally via our iOS, Android or web apps.',
      },
      13: { // TYPE_INTERNAL_MOVE_RESERVATION: {
        name: 'Internal Move Appointment',
        information: 'Sent when a appointment is moved internally via our iOS, Android or web apps.',
      },
      14: { // TYPE_INTERNAL_CANCEL_RESERVATION: {
        name: 'Internal Cancel Appointment',
        information: 'Sent when a appointment is cancelled internally via our iOS, Android or web apps.',
      },
      15: { // TYPE_INTERNAL_CHECKIN: {
        name: 'Internal Check-in',
        information: 'Sent when a customer is checked in to an appointment internally via our iOS, Android or web apps.',
      },
      22: { // TYPE_INTERNAL_NOSHOW: {
        name: 'Internal No-show',
        information: 'Sent when a customer is marked as a no-show for an appointment internally via our iOS, Android or web apps.',
      },
      7: { // TYPE_SUBSCRIPTION_PAYMENT_SUCCESS: {
        name: 'Payment Success',
        information: 'Sent when your monthly subscription payment has been received.',
      },
      8: { // TYPE_SUBSCRIPTION_PAYMENT_FAILED: {
        name: 'Payment Failure',
        information: 'Sent if there is a failure with your monthly subscription payment.',
      },
      18: { // TYPE_SUBSCRIPTION_PAYMENT_EXPIRING: {
        name: 'Payment method expiring',
        information: 'Sent if your payment method will be expiring soon.',
      },
      16: { // TYPE_SUBSCRIPTION_ACCOUNT_ACTIVATED: {
        name: 'Account Activated',
        information: 'Sent when your account subscription is activated.',
      },
      47: { // TYPE_SUBSCRIPTION_ACCOUNT_REACTIVATED: {
        name: 'Account Re-activated',
        information: 'Sent when your account subscription is re-activated.',
      },
      9: { // TYPE_SUBSCRIPTION_ACCOUNT_SUSPENDED: {
        name: 'Account Suspension',
        information: 'Sent if there have been multiple payment failures and your monthly subscription has been suspended.',
      },
      17: { // TYPE_SUBSCRIPTION_ACCOUNT_CANCELLED: {
        name: 'Account Cancellation',
        information: 'Sent when your account is cancelled.',
      },
      10: { // TYPE_REVIEW_RECEIVED: {
        name: 'Review Received',
        information: 'Sent when a customer leaves a review after their appointment.',
      },
      19: { // TYPE_SMS_REPLY_RECEIVED: {
        name: 'SMS Reply Received',
        information: 'Sent when a customer replies to an SMS from our system numbers.',
      },
      24: { // TYPE_MESSAGE_RECEIVED: {
        name: 'Message Received',
        information: 'Sent when a customer replies to a message sent from our system.',
      },
      25: { // TYPE_LOGIN_FROM_LINK: {
        name: 'Email Login Link Used',
        information: 'Sent when someone logs into your account using an email link.',
      },
      26: { // TYPE_WAITLIST_AVAILABLE: {
        name: 'Waitlisted request available',
        information: 'Sent when a request on the waitlist becomes available.',
      },
      27: { // TYPE_WAITLIST_CUSTOMER_ADD: {
        name: 'Customer request waitlisted',
        information: 'Sent when a customer request has been added to the waiting list.',
      },
      28: { // TYPE_REFERRAL_STARTED_TRIAL: {
        name: 'Referred Trial Started',
        information: 'Sent when a referred business begins their trial.',
      },
      29: { // TYPE_REFERRAL_SIGNED_UP: {
        name: 'Referred Sign-up',
        information: 'Sent when a referred business signs up.',
      },
      30: { // TYPE_REFERRAL_PAID_OUT: {
        name: 'Referral Payment made',
        information: 'Sent when a referral payment is made.',
      },
      31: { // TYPE_EMAIL_VERIFICATION_COMPLETE: {
        name: 'Email Verification Complete',
        information: 'Sent when the email is verified.',
      },
      32: { // TYPE_EMAIL_VERIFICATION_MANUAL_REQUIRED: {
        name: 'Manual Email Verification Required',
        information: 'Sent when manual email verification is required.',
      },
      6: { // TYPE_SMS_LOW: {
        name: 'SMS Credits Low',
        information: 'Sent when your SMS credits reach low levels and you may need to purchase additional credits to continue SMS service.',
      },
      33: { // TYPE_SMS_PLAN_LOW_50: {
        name: 'SMS Plan Credits Low - 50%',
        information: 'Sent when your included monthly SMS credits reach 50%. You may need to purchase additional credits to continue SMS service.',
      },
      34: { // TYPE_SMS_PLAN_LOW_75: {
        name: 'SMS Plan Credits Low - 75%',
        information: 'Sent when your included monthly SMS credits reach 75%. You may need to purchase additional credits to continue SMS service.',
      },
      35: { // TYPE_SMS_PLAN_SUSPENDED: {
        name: 'SMS Plan Credits expired',
        information: 'Sent when your included monthly SMS credits are required. You will need to purchase additional credits to continue SMS service.',
      },
      36: { // TYPE_SMS_USING_EXTRA: {
        name: 'SMS Plan Credits expired, using Extra SMS Credit',
        information: 'Sent when your included monthly SMS credits are required and the system will begin using your purchased Extra SMS credit.',
      },
      100: { // TYPE_SMS_EXTRA_LOW_100: {
        name: 'SMS Extra Credits Low',
        information: 'Sent when your purchased extra SMS credits are low. You may need to purchase additional credits to continue SMS service.',
      },
      38: { // TYPE_SMS_EXTRA_LOW_50: {
        name: 'Extra SMS Credits Very Low',
        information: 'Sent when your purchased extra SMS credits are very low. You may need to purchase additional credits to continue SMS service.',
      },
      39: { // TYPE_SMS_EXTRA_SUSPENDED: {
        name: 'Extra SMS Credits expired',
        information: 'Sent when your purchased extra SMS credits have been used. You will need to purchase additional credits to continue SMS service.',
      },
      40: { // TYPE_SMS_AUTO_TOPUP_PURCHASED: {
        name: 'Auto-Topup Purchased Extra SMS Credits',
        information: 'Sent when the SMS credit auto-topup system successfully purchases extra SMS credits.',
      },
      41: { // TYPE_SMS_AUTO_TOPUP_FAILED: {
        name: 'SMS Auto-Topup Purchase Failed',
        information: 'Sent when the SMS credit auto-topup system encounters a failue when attempting to purchases extra SMS credits.',
      },
      42: { // TYPE_SUBSTITUTION_REQUEST: {
        name: 'Appointment Substitution Request',
        information: 'Sent when a substitution is requested for a appointment.',
      },
      43: { // TYPE_SUBSTITUTION_REQUEST_DECLINED: {
        name: 'Appointment Substitution Request Declined',
        information: 'Sent when a substitution is declined.',
      },
      44: { // TYPE_SUBSTITUTION_REQUEST_ACCEPTED: {
        name: 'Appointment Substitution Request Accepted',
        information: 'Sent when a substitution is accepted.',
      },
      45: { // TYPE_SUBSTITUTION_REQUEST_CONFIRMED: {
        name: 'Appointment Substitution Request Confirmed',
        information: 'Sent when a substitution is confirmed.',
      },
      46: { // TYPE_ACCOUNT_VERIFICATION_PIN: {
        name: 'Account Verification PIN',
        information: 'Sent to verify a customer account.',
      },
      48: { // TYPE_INTEGRATION_REAUTHENTICATE: {
        name: 'App integration requires re-connecting',
        information: 'Sent when an app integration requires you to re-connect.',
      },
      49: { // TYPE_EXPORT_COMPLETE: {
        name: 'Export complete',
        information: 'Sent when an export report is available to download.',
      },
      50: { // TYPE_WAITLIST_CUSTOMER_CANCEL: {
        name: 'Waitlist request cancelled',
        information: 'Sent when a customer request for a waiting list is cancelled.',
      },
    },
    employee: {
      1: { // TYPE_CUSTOMER_NEW_RESERVATION: {
        name: 'Customer New Reservation',
        information: 'Sent when a customer makes a new online reservation via your minisite, booking widgets or Facebook.',
      },
      11: { // TYPE_CUSTOMER_MOVE_RESERVATION: {
        name: 'Customer Move Reservation',
        information: 'Sent when a customer moves their reservation via your minisite.',
      },
      2: { // TYPE_CUSTOMER_CANCEL_RESERVATION: {
        name: 'Customer Cancel Reservation',
        information: 'Sent when a customer cancels reservation via your minisite.',
      },
      3: { // TYPE_CUSTOMER_CHECKIN: {
        name: 'Customer Check-in',
        information: 'Sent when a customer checks in to an appointment.',
      },
      4: { // TYPE_CUSTOMER_ONLINE_PAYMENT: {
        name: 'Customer Payment',
        information: 'Sent when a customer makes a payment (reservation deposit, gift card, pass, etc) via your minisite, booking widgets or Facebook.',
      },
      20: { // TYPE_CUSTOMER_CONFIRM_RESERVATION: {
        name: 'Customer Reservation Confirmation',
        information: 'Sent when a customer confirms that they will be attending the appointment via an email or SMS reply.',
      },
      21: { // TYPE_CUSTOMER_FORM_RECEIVED: {
        name: 'Customer Form Received',
        information: 'Sent when a customer fills in a form via your minisite.',
      },
      23: { // TYPE_CUSTOMER_EMAIL_BOUNCE: {
        name: 'Customer Email Bounced',
        information: 'Sent when an email is bounced for a customer.',
      },
      12: { // TYPE_INTERNAL_NEW_RESERVATION: {
        name: 'Internal New Reservation',
        information: 'Sent when a new reservation is made internally via our iOS, Android or web apps.',
      },
      13: { // TYPE_INTERNAL_MOVE_RESERVATION: {
        name: 'Internal Move Reservation',
        information: 'Sent when a reservation is moved internally via our iOS, Android or web apps.',
      },
      14: { // TYPE_INTERNAL_CANCEL_RESERVATION: {
        name: 'Internal Cancel Reservation',
        information: 'Sent when a reservation is cancelled internally via our iOS, Android or web apps.',
      },
      15: { // TYPE_INTERNAL_CHECKIN: {
        name: 'Internal Check-in',
        information: 'Sent when a customer is checked in to an appointment internally via our iOS, Android or web apps.',
      },
      22: { // TYPE_INTERNAL_NOSHOW: {
        name: 'Internal No-show',
        information: 'Sent when a customer is marked as a no-show for an appointment internally via our iOS, Android or web apps.',
      },
      7: { // TYPE_SUBSCRIPTION_PAYMENT_SUCCESS: {
        name: 'Payment Success',
        information: 'Sent when your monthly subscription payment has been received.',
      },
      8: { // TYPE_SUBSCRIPTION_PAYMENT_FAILED: {
        name: 'Payment Failure',
        information: 'Sent if there is a failure with your monthly subscription payment.',
      },
      18: { // TYPE_SUBSCRIPTION_PAYMENT_EXPIRING: {
        name: 'Payment method expiring',
        information: 'Sent if your payment method will be expiring soon.',
      },
      16: { // TYPE_SUBSCRIPTION_ACCOUNT_ACTIVATED: {
        name: 'Account Activated',
        information: 'Sent when your account subscription is activated.',
      },
      47: { // TYPE_SUBSCRIPTION_ACCOUNT_REACTIVATED: {
        name: 'Account Re-activated',
        information: 'Sent when your account subscription is re-activated.',
      },
      9: { // TYPE_SUBSCRIPTION_ACCOUNT_SUSPENDED: {
        name: 'Account Suspension',
        information: 'Sent if there have been multiple payment failures and your monthly subscription has been suspended.',
      },
      17: { // TYPE_SUBSCRIPTION_ACCOUNT_CANCELLED: {
        name: 'Account Cancellation',
        information: 'Sent when your account is cancelled.',
      },
      10: { // TYPE_REVIEW_RECEIVED: {
        name: 'Review Received',
        information: 'Sent when a customer leaves a review after their reservation.',
      },
      19: { // TYPE_SMS_REPLY_RECEIVED: {
        name: 'SMS Reply Received',
        information: 'Sent when a customer replies to an SMS from our system numbers.',
      },
      24: { // TYPE_MESSAGE_RECEIVED: {
        name: 'Message Received',
        information: 'Sent when a customer replies to a message sent from our system.',
      },
      25: { // TYPE_LOGIN_FROM_LINK: {
        name: 'Email Login Link Used',
        information: 'Sent when someone logs into your account using an email link.',
      },
      26: { // TYPE_WAITLIST_AVAILABLE: {
        name: 'Waitlisted request available',
        information: 'Sent when a request on the waitlist becomes available.',
      },
      27: { // TYPE_WAITLIST_CUSTOMER_ADD: {
        name: 'Customer request waitlisted',
        information: 'Sent when a customer request has been added to the waiting list.',
      },
      28: { // TYPE_REFERRAL_STARTED_TRIAL: {
        name: 'Referred Trial Started',
        information: 'Sent when a referred business begins their trial.',
      },
      29: { // TYPE_REFERRAL_SIGNED_UP: {
        name: 'Referred Sign-up',
        information: 'Sent when a referred business signs up.',
      },
      30: { // TYPE_REFERRAL_PAID_OUT: {
        name: 'Referral Payment made',
        information: 'Sent when a referral payment is made.',
      },
      31: { // TYPE_EMAIL_VERIFICATION_COMPLETE: {
        name: 'Email Verification Complete',
        information: 'Sent when the email is verified.',
      },
      32: { // TYPE_EMAIL_VERIFICATION_MANUAL_REQUIRED: {
        name: 'Manual Email Verification Required',
        information: 'Sent when manual email verification is required.',
      },
      6: { // TYPE_SMS_LOW: {
        name: 'SMS Credits Low',
        information: 'Sent when your SMS credits reach low levels and you may need to purchase additional credits to continue SMS service.',
      },
      33: { // TYPE_SMS_PLAN_LOW_50: {
        name: 'SMS Plan Credits Low - 50%',
        information: 'Sent when your included monthly SMS credits reach 50%. You may need to purchase additional credits to continue SMS service.',
      },
      34: { // TYPE_SMS_PLAN_LOW_75: {
        name: 'SMS Plan Credits Low - 75%',
        information: 'Sent when your included monthly SMS credits reach 75%. You may need to purchase additional credits to continue SMS service.',
      },
      35: { // TYPE_SMS_PLAN_SUSPENDED: {
        name: 'SMS Plan Credits expired',
        information: 'Sent when your included monthly SMS credits are required. You will need to purchase additional credits to continue SMS service.',
      },
      36: { // TYPE_SMS_USING_EXTRA: {
        name: 'SMS Plan Credits expired, using Extra SMS Credit',
        information: 'Sent when your included monthly SMS credits are required and the system will begin using your purchased Extra SMS credit.',
      },
      100: { // TYPE_SMS_EXTRA_LOW_100: {
        name: 'SMS Extra Credits Low',
        information: 'Sent when your purchased extra SMS credits are low. You may need to purchase additional credits to continue SMS service.',
      },
      38: { // TYPE_SMS_EXTRA_LOW_50: {
        name: 'Extra SMS Credits Very Low',
        information: 'Sent when your purchased extra SMS credits are very low. You may need to purchase additional credits to continue SMS service.',
      },
      39: { // TYPE_SMS_EXTRA_SUSPENDED: {
        name: 'Extra SMS Credits expired',
        information: 'Sent when your purchased extra SMS credits have been used. You will need to purchase additional credits to continue SMS service.',
      },
      40: { // TYPE_SMS_AUTO_TOPUP_PURCHASED: {
        name: 'Auto-Topup Purchased Extra SMS Credits',
        information: 'Sent when the SMS credit auto-topup system successfully purchases extra SMS credits.',
      },
      41: { // TYPE_SMS_AUTO_TOPUP_FAILED: {
        name: 'SMS Auto-Topup Purchase Failed',
        information: 'Sent when the SMS credit auto-topup system encounters a failue when attempting to purchases extra SMS credits.',
      },
      42: { // TYPE_SUBSTITUTION_REQUEST: {
        name: 'Reservation Substitution Request',
        information: 'Sent when a substitution is requested for a reservation.',
      },
      43: { // TYPE_SUBSTITUTION_REQUEST_DECLINED: {
        name: 'Reservation Substitution Request Declined',
        information: 'Sent when a substitution is declined.',
      },
      44: { // TYPE_SUBSTITUTION_REQUEST_ACCEPTED: {
        name: 'Reservation Substitution Request Accepted',
        information: 'Sent when a substitution is accepted.',
      },
      45: { // TYPE_SUBSTITUTION_REQUEST_CONFIRMED: {
        name: 'Reservation Substitution Request Confirmed',
        information: 'Sent when a substitution is confirmed.',
      },
      46: { // TYPE_ACCOUNT_VERIFICATION_PIN: {
        name: 'Account Verification PIN',
        information: 'Sent to verify a customer account.',
      },
      48: { // TYPE_INTEGRATION_REAUTHENTICATE: {
        name: 'App integration requires re-connecting',
        information: 'Sent when an app integration requires you to re-connect.',
      },
      49: { // TYPE_EXPORT_COMPLETE: {
        name: 'Export complete',
        information: 'Sent when an export report is available to download.',
      },
      50: { // TYPE_WAITLIST_CUSTOMER_CANCEL: {
        name: 'Waitlist request cancelled',
        information: 'Sent when a customer request for a waiting list is cancelled.',
      },
    },
  },
  order: {
    filters: {
      invalidDate: 'Start and finish date must be provided',
      paymentStatus: 'Payment status',
      paidInFull: 'Paid in full',
      employees: 'Sales that include the selected employees',
      services: 'Sales that include the selected services',
      allServices: 'All services',
      products: 'Sales that include the selected products',
      paymentType: 'Payment type',
    },
    saleStatus: 'Sale status',
    addCharge: 'Add charge',
    addCustomerCredit: 'Add customer credit',
    addDiscount: 'Add discount',
    addGiftCards: 'Add gift cards',
    addPasses: 'Add passes',
    addPayment: 'Add payment',
    addProducts: 'Add products',
    cash: 'Cash',
    charges: 'Charges',
    close: 'Close sale',
    customerCredit: 'Customer credit',
    dateRange: 'Date range',
    edit: 'Edit sale',
    editDetails: 'Edit sale details',
    editTip: 'Edit tip',
    filterProducts: 'Filter products',
    loading: 'Loading Sales...',
    receiving: 'Receiving',
    refund: 'Refund order',
    remainder: 'Remainder',
    tip: 'Tip',
    overpaid: 'Overpaid',
    partiallyPaid: 'Partially paid',
    view: 'View sale',
    new: 'New sale',
    unpaid: 'Unpaid',
    partial: 'Partial',
  },
  pass: {
    expiryModeBooking: 'Booking must be made within validity period',
    expiryModeAppointment: 'Date of appointment must be within validity period',
    returnPassUsage: 'Return pass usage',
    leavePassUsage: 'Leave pass usage',
    edit: 'Edit pass',
    delete: 'Delete pass',
    deleteCustomer: 'Delete customer pass',
    emailCustomer: 'Email customer pass',
    newCustomer: 'New customer pass',
    restoreCustomer: 'Restore customer pass',
    expended: 'Expended',
    deletedSuccess: 'Deleted pass',
    new: 'New pass',
    select: 'Select a pass',
    search: 'Search customer passes',
    add: 'Add pass',
    usesRemaining: 'Uses remaining',
  },
  payment: {
    edit: 'Edit payment',
  },
  product: {
    filters: {
      search: 'Search customer product details',
      brand: 'Filter by brand',
      noBrand: 'No brand',
      supplier: 'Filter by supplier',
      noSupplier: 'No supplier',
    },
    all: 'All products',
    name: 'The product name',
    visibleInLists: 'Visible in product lists',
    new: 'New product',
    newCategory: 'New product category',
    addStock: 'Add Stock',
    returnStock: 'Return Stock',
    adjustment: 'Adjustment',
    writtenOff: 'Written Off',
    damaged: 'Damaged',
    outOfDate: 'Out of Date',
    internalUse: 'Internal use',
    undefined: 'Undefined',
  },
  purchaseOrder: {
    saved: 'Saved purchase order',
    new: 'New purchase order',
    title: 'Purchase Order: {name} (#{number})',
    title2: 'Purchase order: {label}',
    editProduct: 'Edit purchase order product',
    fromAddress: 'From Address',
    toAddress: 'To Address',
    shippingAddress: 'Shipping Address',
    markAsSent: 'Mark as sent',
  },
  reservation: {
    existingEmployeeRequested: 'Existing: Employee requested',
    newAnyEmployee: 'New: Any employee',
    newEmployeeRequested: 'New: Employee requested',
    existingAnyEmployee: 'Existing: Any employee',
    reminderEmail2: 'Reminder (Email)',
    reminderSMS2: 'Reminder (SMS)',
    afterNumberOfRepeats: 'After number of repeats',
    newStatusType: 'New status type',
    recurrence: 'Recurrence',
    moveAppointment: 'Move appointment',
    attendeeSavedSuccess: 'Customer details saved',
    attendeeSavedSuccessInfo: 'The customer details were successfully saved',
    addDetail: 'Add more detail to this reservation',
    allowOnline: 'Allow online',
    allowOnlineInfo: 'Allow customer to book online (book.app, widgets, bots)',
    appointmentConfirmation: 'Send appointment confirmation email',
    appointmentReceived: 'Send appointment received email',
    appointmentReminder: 'Send appointment reminder as an email',
    appointmentReminderSMS: 'Send appointment reminder as an SMS',
    attending: 'Attending',
    checkedIn: 'Checked in',
    checkInCustomer: 'Check in customer',
    checkIn: 'Check-in',
    checkout: 'Checkout',
    checkoutNewSale: 'Checkout / New sale',
    confirmCheckIn: 'Confirm check in',
    confirmCustomer: 'Confirm customer',
    confirmed: 'Confirmed',
    confirmSuccessInfo: 'Confirmed appointment for {name}',
    editRecurrence: 'Edit recurrence',
    makeRecurringReservation: 'Make recurring reservation',
    maxCustomersInfo: 'Maximum number of customers allowed to attend this class',
    moved: 'Moved appointment',
    new: 'New appointment',
    noShow: 'No show',
    noUpcoming: 'No upcoming appointments...',
    paymentDetailsCaptured: 'Payment details captured',
    rebook: 'Rebook',
    recurring: 'Recurring',
    reminderEmail: 'Reminder Email',
    reminderSMS: 'Reminder SMS',
    resetReminders: 'Reset reminders',
    resetRemindersInfo: 'Resend/reschedule all customer reminders related to this appointment',
    restore: 'Restore deleted appointment',
    restoredSuccess: 'Appointment restored',
    restoredSuccessInfo: 'The appointment was successfully restored',
    reviewRequest: 'Review request',
    reviewRequestInfo: 'Ask the customer to review their appointment',
    sendEmailInfo: 'Send an email to the customer letting them know the appointment has been confirmed',
    termsAgreed: 'Terms agreed',
    unconfirmed: 'Unconfirmed',
    undoCancel: 'Undo cancel',
    undoCheckIn: 'Undo Check-in',
    undoNoShow: 'Undo No show',
    move: 'Move',
  },
  resource: {
    select: 'Select a resource',
    delete: 'Delete resource',
    remove: 'Remove resource',
    retainOldAppointments: 'Retain old appointments',
    retainOldAppointmentsInfo: 'If enabled, old appointments (up until today) will still be shown in your schedule',
    rostered: 'Rostered',
    none: 'None (Optional)',
    firstAvailable: 'First Available (Automatic)',
    edit: 'Edit resource',
    new: 'New resource',
    newGroup: 'New resource group',
  },
  review: {
    approve: 'Approve',
    unapprove: 'Unapprove',
    feature: 'Feature',
    unfeature: 'Unfeature',
    reply: 'Reply',
    search: 'Search reviews',
    nameHidden: '(Name hidden from minisite)',
    rating: 'Rating',
    recommended: 'Recommended',
    approved: 'Approved',
    unapproved: 'Unapproved',
    replied: 'Replied',
    featured: 'Featured',
  },
  role: {
    new: 'New role',
    legacy: 'Legacy',
    admin: 'Admin',
  },
  roster: {
    normalHours: 'Normal hours',
    rosterInterval: 'Roster interval',
    week: 'Roster week:',
    starting: 'Starting {date}',
    finishTime: 'Finish time',
    breaks: 'Breaks',
    edit: 'Edit roster',
    noBreaks: 'No breaks...',
    addBreak: 'Add break',
    working: 'Working',
    notWorking: 'Not working',
    leave: 'Leave',
    sick: 'Sick',
    holiday: 'Holiday',
    breakCount: '{count} breaks',
  },
  segment: {
    new: 'New segment',
  },
  schedule: {
    createNewCustomerAppointment: 'Create a new customer appointment',
    break: 'Break',
    updatedBreak: 'Updated break',
    checkedIn: 'Checked In',
    createNewClass: 'Create a new class',
    breakTask: 'Break / Task',
    breakTaskInfo: 'Add an employee break',
    durationMinutesInfo: 'Initial service block',
  },
  service: {
    form: {
      frequency: {
        reservation: 'Each appointment',
        customer: 'Once per customer',
        service: 'Once per service',
      },
      requirement: {
        confirmation: 'When appointment is created',
        scheduled: 'At a scheduled time',
        internal: 'Internal prompt only',
      },
    },
    searchInfo: 'Search service details',
    allTimeModes: 'Duration + Processing + Finishing times',
    duration: 'Duration time only',
    durationProcessing: 'Duration + Processing times only',
    aggregate: 'Aggregrate all service buffer times',
    longest: 'Longest service buffer time applies',
    between: 'Apply buffer times between services',
    once: 'Once',
    everyAppointment: 'Every appointment',
    enableDisable: 'Enable/disable all services',
    firstAvailable: 'First available',
    add: 'Add a service',
    addService: 'Add service',
    filter: 'Filter services',
    edit: 'Edit service',
    priceModified: 'Price has been changed from its default value for this service',
    durationModified: 'Duration has been changed from its default value for this service',
    processingModified: 'Processing has been changed from its default value for this service',
    finishingModified: 'Finishing has been changed from its default value for this service',
    preBuffer: 'Pre buffer',
    preBufferModified: 'Pre Buffer has been changed from its default value for this service',
    postBufferModified: 'Post Buffer has been changed from its default value for this service',
    postBuffer: 'Post buffer',
    priceInfo: 'Default cost of the service to customers',
    type: 'Service type',
    typeInfo: 'Service is for single appointments or groups',
    infoInfo: 'Description of the service. Visible on your book.app site.',
    maxCustomersInfo: 'Maximum number of Customers that can book into this Group Service',
    newCategory: 'New service category',
    new: 'New service',
    savedSuccess: 'Service details saved',
    categoryDetails: 'Service category details',
    groupClass: 'Group / Class',
    hasAndHasTimingOverrides: 'Some employees have price overrides. Some employees have timing overrides.',
    hasZeroAndHasTimingOverrides: 'Some employees have 0 price overrides. Some employees have timing overrides.',
    hasTimingOverrides: 'Some employees have timing overrides.',
    hasZeroOverrides: 'Some employees have 0 price overrides.',
    hasOverrides: 'Some employees have price overrides.',
  },
  sms: {
    multipleSMSTooltip: 'Based on the text you have entered, this message will send more than one SMS. This estimate may vary based on the placeholders used. (1 SMS is 160 characters)',
    multipleSMS: 'Your message may use more than 1 SMS',
    specialTooltip: 'Please note: a single SMS contains 160 characters (special characters, such as emojis, reduce this to 70 characters)',
    special: 'Your message contains special characters: {special}',
    remainingMessages: `
      {remaining} characters remaining in this message.<br>
      This message will cost approximately {messages} messages.
    `,
    purchasePack: 'Purchase SMS Pack',
  },
  stockTake: {
    new: 'New stock take',
    applied: 'Applied',
  },
  supplier: {
    new: 'New supplier',
  },
  tax: {
    includeInItemCost: 'Include {name} ({amount}) in item cost',
    bulkSet: 'Bulk set tax',
    new: 'New tax',
  },
  theme: {
    light: 'Light',
    dark: 'Dark',
  },
  updatePrices: {
    title: 'Update service prices',
    updateFutureAppointments: 'Update all future appointments',
    updateFutureAppointmentsInfo: 'All future appointments with this service will be updated',
  },
  waitlist: {
    booked: 'Booked',
    request: 'Waitlist request',
    anyTime: 'Any time',
    afterAndBefore: '(After {startTime} & before {endTime})',
    after: '(After {startTime})',
    before: '(Before {endTime})',
    label: '{dateLabel} {timeLabel}',
    waiting: 'Waiting',
    alerted: 'Alerted',
    notified: 'Notified',
    offered: 'Offered',
  },
  xmas: {
    letItSnow: 'Let it Snow ❄️',
    imGettingCold: "I'm getting cold! 🥶",
    merryXmas: 'Merry Christmas',
    fromTheTeam: 'From the team at Ovatu',
    tryTheme: 'Try our Christmas theme',
    festiveEnough: 'Thats festive enough',
  },
}
