import Model, { Request } from '../model'
import APIObject from '../object'
import Enum from '../enums'

export default class Export extends Model {
  static modelName () {
    return 'export'
  }

  relations () {
    return {
      status: { type: ExportStatus },
    }
  }

  static exporters () {
    const url = this.modelBaseURL() + '/exporters'
    return this.requestData(Request.get(url))
  }

  static export ({ type, params = [] } = {}) {
    const url = this.modelBaseURL() + '/export'

    const data = {
      type,
      params,
    }

    return this.requestData(Request.post(url, JSON.stringify(data)), this)
  }

  rerun () {
    const url = this.constructor.modelBaseURL() + '/export'

    const data = {
      type: this.type,
      params: JSON.parse(this.params),
    }

    return this.constructor.requestData(Request.post(url, JSON.stringify(data)), this)
  }

  get isQueued () {
    return this.status === ExportStatus.queued
  }

  get isComplete () {
    return this.status === ExportStatus.complete
  }

  get isError () {
    return this.status === ExportStatus.error
  }

  get isRunning () {
    return this.status === ExportStatus.running
  }

  get isExpired () {
    return this.expiry < (Date.now() / 1000)
  }

  get isInvalid () {
    return this.result?.count === 0
  }
}

export const ExportStatus = new Enum({
  queued: { value: 0, description: 'general.queued' },
  running: { value: 1, description: 'export.running' },
  complete: { value: 2, description: 'general.complete' },
  error: { value: 3, description: 'general.error' },
})
