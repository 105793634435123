import Model, { Request } from '../model'
import Enum from '../enums'

import Location from './location'
import Minisite from './minisite'
import MinisiteMenu from './minisiteMenu'

export default class Settings extends Model {
  static modelName () {
    return 'settings'
  }

  static location () {
    const url = this.modelBaseURL() + '/location'
    return this.requestItem(Request.get(url), Location)
  }

  static privacy () {
    const url = this.modelBaseURL() + '/privacy'
    return this.requestItem(Request.get(url), Privacy)
  }

  static minisite () {
    const url = this.modelBaseURL() + '/minisite'
    return this.requestItem(Request.get(url), Minisite)
  }

  static setDomain ({ domain = '', confirm = false } = {}) {
    const url = this.modelBaseURL() + '/setDomain'
    const data = {
      domain,
      confirm,
    }

    return this.requestSuccess(Request.post(url, data))
  }

  static minisiteMenu () {
    const url = this.modelBaseURL() + '/minisiteMenu'
    return this.requestItem(Request.get(url), MinisiteMenu)
  }

  static emailSending () {
    const url = this.modelBaseURL() + '/emailSending'
    return this.requestItem(Request.get(url), EmailSending)
  }

  static autoTopupSettings () {
    const url = this.modelBaseURL() + '/autoTopupSettings'
    return this.requestItem(Request.get(url), AutoTopUp)
  }

  static smsCountry () {
    const url = this.modelBaseURL() + '/smsCountry'
    return this.requestItem(Request.get(url), SMSCountry)
  }

  static reminders () {
    const url = this.modelBaseURL() + '/reminders'
    return this.requestItem(Request.get(url), Reminders)
  }

  static sessions ({ page }) {
    const url = this.modelBaseURL() + '/authorisedSessions?page=' + page
    return this.requestList(Request.get(url), AuthorisedSession)
  }

  static revokeEmployeeSessions (employee) {
    const url = this.modelBaseURL() + '/revokeSession?idEmployee=' + employee?.idEmployee
    return this.requestSuccess(Request.get(url))
  }

  static policies () {
    const url = this.modelBaseURL() + '/viewPolicies'
    return this.requestData(Request.get(url))
  }

  static acceptPolicy ({ policy, termsAgreed }) {
    const url = this.modelBaseURL() + '/acceptPolicy'
    const data = {
      policy,
      termsAgreed,
    }

    return this.requestSuccess(Request.post(url, JSON.stringify(data)))
  }

  static setLogoImage (image, options) {
    const url = this.modelBaseURL() + '/logoImage'

    const formData = new FormData()
    formData.append('logo', image)

    const request = Request.post(url, formData)
    request.options = options

    return this.requestItem(request, this)
  }

  static removeLogoImage () {
    const url = this.modelBaseURL() + '/logoImage'
    return this.requestItem(Request.delete(url), this)
  }

  static checkCustomDomain () {
    const url = this.modelBaseURL() + '/checkCustomDomain'
    return this.requestData(Request.get(url))
  }

  static setCustomDomain ({ domain, enable = true }) {
    const url = this.modelBaseURL() + '/customDomain'
    const data = {
      domain,
      enable,
    }

    return this.requestSuccess(Request.post(url, data))
  }
}

export class EmailSending extends Model {
  static modelName () {
    return 'emailSending'
  }

  objectID () {
    return null
  }

  relations () {
    return {
    }
  }

  static modelBaseURL () {
    return this.apiBaseURL() + '/v' + this.modelApiVersion() + '/settings/' + this.modelName()
  }
}

export class AutoTopUp extends Model {
  static modelName () {
    return 'autoTopupSettings'
  }

  objectID () {
    return null
  }

  relations () {
    return {
    }
  }

  static modelBaseURL () {
    return this.apiBaseURL() + '/v' + this.modelApiVersion() + '/settings/' + this.modelName()
  }
}

export class SMSCountry extends Model {
  static modelName () {
    return 'smsCountry'
  }

  objectID () {
    return null
  }

  relations () {
    return {
    }
  }

  static modelBaseURL () {
    return this.apiBaseURL() + '/v' + this.modelApiVersion() + '/settings/' + this.modelName()
  }
}

export class Reminders extends Model {
  static modelName () {
    return 'reminders'
  }

  objectID () {
    return null
  }

  relations () {
    return {
    }
  }

  static modelBaseURL () {
    return this.apiBaseURL() + '/v' + this.modelApiVersion() + '/settings/' + this.modelName()
  }
}

export class Privacy extends Model {
  static modelName () {
    return 'privacy'
  }

  objectID () {
    return null
  }

  relations () {
    return {
    }
  }

  static modelBaseURL () {
    return this.apiBaseURL() + '/v' + this.modelApiVersion() + '/settings/' + this.modelName()
  }
}


export class AuthorisedSession extends Model {
  static modelName () {
    return 'authorisedSession'
  }

  objectID () {
    return this.session
  }

  relations () {
    return {
    }
  }

  static modelBaseURL () {
    return this.apiBaseURL() + '/v' + this.modelApiVersion() + '/settings/'
  }

  revoke () {
    const url = this.constructor.modelBaseURL() + '/revokeSession?session=' + this.session
    return this.constructor.requestSuccess(Request.get(url))
  }
}

export const CustomDomainStatus = new Enum({
  none: { value: 0, description: 'general.none' },
  waitingDNS: { value: 1, description: 'minisite.waitingDNS' },
  failing: { value: 2, description: 'api.models.settings.failing' },
  active: { value: 3, description: 'general.active' },
})
