<template>
  <div
    :class="[
      baseClass,
      { 'flex-col-reverse' : flip }
    ]"
  >
    <div
      v-if="!noHeader"
      :class="[
        headerClass,
        { 'cursor-pointer' : clickable && !disabled }
      ]"
      @click="handleHeaderClick()"
    >
      <slot name="header" :visible="show" :toggle="toggle" />
      <slot name="toggle" :visible="show" :toggle="toggle">
        <!--<o-icon v-if="showToggle" icon="accordion" :size="12" class="pr-1" />-->
        <o-button
          v-if="showToggle && !disabled"
          size="sm"
          flat
          icon="accordion"
          class="-mr-1"
        />
      </slot>
    </div>
    <transition
      name="expand"
      @enter="enter"
      @after-enter="afterEnter"
      @leave="leave"
    >
      <div v-if="show" :class="bodyClass">
        <div>
          <slot :active="show" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'OCollapse',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    showToggle: {
      type: Boolean,
      default: true,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    baseClass: {
      type: [String, Array],
      default: 'flex flex-col w-full',
    },
    headerClass: {
      type: [String, Array],
      default: '',
    },
    bodyClass: {
      type: [String, Array],
      default: '',
    },
    icon: {
      type: String,
      default () {
        return 'accordion'
      },
    },
    flip: {
      type: Boolean,
      default: false,
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      show: this.visible,
    }
  },
  watch: {
    show (value) {
      this.$emit('update:active', value)
      // this.$emit('toggle')
    },
  },
  methods: {
    toggle () {
      this.show = !this.show
    },
    open () {
      this.show = true
    },
    close () {
      this.show = false
    },
    afterEnter (el) {
      el.style.height = 'auto'
    },
    enter (el) {
      requestAnimationFrame(() => {
        el.style.height = el.scrollHeight + 'px'
      })
      this.$emit('toggle')
    },
    leave (el) {
      el.style.height = el.scrollHeight + 'px'
      requestAnimationFrame(() => {
        el.style.height = 0
      })
      this.$emit('toggle')
    },
    handleHeaderClick () {
      if (this.clickable && !this.disabled) {
        this.toggle()
      }
    },
  },
}
</script>

<style>
.expand-enter-active,
.expand-leave-active {
  transition: height 0.2s ease-in-out;
  overflow-y: hidden;
}
.expand-enter,
.expand-leave-to {
  height: 0;
}
</style>
