<template>
  <o-dropdown
    placement="left-start"
    :trigger="['hover', 'click']"
    :clickaway="false"
    :portal="false"
    :modifiers="modifiers"
    :arrow="false"
    class="w-full"
    @close="handleClose()"
  >
    <div class="w-full py-2 px-3 flex items-center justify-between transition duration-200 ease-in-out cursor-pointer rounded-md hover:bg-white/20">
      <div
        class="flex items-center"
        :class="{
          'py-2px' : !icon
        }"
      >
        <div v-if="icon" class="pr-3 leading-none ">
          <component :is="iconType" :icon="icon" :src="icon" :size="iconSize" class="max-w-4" />
        </div>
        <div class="text-left pr-2">
          <p class="text-sm leading-none">
            <slot>
              {{ name }}
            </slot>
          </p>
          <p v-if="description" class="text-sm opacity-60 leading-none mt-1">
            <slot name="description">
              {{ description }}
            </slot>
          </p>
        </div>
      </div>
      <o-icon icon="chevronRight" :size="12" class="opacity-60" />
    </div>

    <template #content>
      <slot name="content" />
    </template>
  </o-dropdown>
</template>

<script>
import ODropdown from './Dropdown'
import ODropdownItem from './DropdownItem'

import { icons } from '@/components/elements/Icon'

export default {
  components: {
    ODropdown,
    ODropdownItem,
  },
  inject: ['dropdown'],
  props: {
    name: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    icon: {
      type: [Array, String],
      default: null,
    },
    iconSize: {
      type: Number,
      default: 14,
    },
    autoHideParent: {
      type: Boolean,
      default: true,
    },
    modifiers: {
      type: Array,
      default: () => [
        {
          name: 'offset',
          options: {
            offset: [0, 0],
          },
        },
      ],
    },
  },
  computed: {
    iconType () {
      const icon = icons?.[this.icon]
      return icon ? 'o-icon' : 'img'
    },
  },
  methods: {
    handleClose () {
      this.hideParent()
    },
    hideParent () {
      if (this.autoHideParent) {
        this.dropdown.close()
      }
    },
  },
}
</script>
