import * as API from '~/api'

export const state = () => ({
  alerts: [],
  counts: [],
})

export const mutations = {
  setAlerts (state, alerts) {
    state.alerts = alerts
  },
  setCounts (state, counts) {
    state.counts = counts
  },
  markRead (state, index) {
    state.alerts[index].readForCurrentUser = true
    state.counts.unread--
  },
}

export const getters = {
  alerts (state) {
    return API.Alert.ensure(state.alerts)
  },
  counts (state) {
    return API.AlertCounts.ensure(state.counts)
  },
}

export const actions = {
  fetchAlerts ({ commit }) {
    return API.Alert.list(1).then((alerts) => {
      commit('setAlerts', alerts)
    })
  },
  fetchAlertCounts ({ commit }) {
    return API.Alert.counts().then((counts) => {
      commit('setCounts', counts)
    })
  },
  async markRead ({ commit, state }, index) {
    await state.alerts[index].markRead().then((success) => {
      if (success) {
        commit('markRead', index)
      }
    })
  },
}
