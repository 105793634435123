export default (context, inject) => {
  const plugin = {
    /**
     * Check if the current user has all the required grant/actions
     * @param {string} model - Model name for grant (e.g. 'customer')
     * @param {array} actions - A list of required grants (e.g. ['edit', 'list', 'view'])
     * @returns boolean
     */
    get ({ model, actions }) {
      const active = context.$auth.active
      const grants = this.list

      // Check if there is an active user and grants are available
      if (!active || !grants) {
        return false
      }

      // Check if the user has the required model
      if (!grants[model]) {
        return false
      }

      // Loop through actions requested
      for (const action of actions) {
        // Check if the user has the required action
        if (!grants[model].includes(action)) {
          return false
        }
      }

      return true
    },

    /**
     * Check if the current user has all the required grant/actions in object
     * @param {object} grants - A list of grants (model and actions))
     * @returns boolean
     */
    all (grants) {
      return Object.entries(grants)
        .every(([model, actions]) => {
          return this.get({ model, actions })
        })
    },

    /**
     * Check if the current user has any of the required grant/actions in object
     * @param {object} grants - A list of grants (model and actions))
     * @returns boolean
     */
    any (grants) {
      return Object.entries(grants)
        .some(([model, actions]) => {
          const active = context.$auth.active
          const grants = this.list

          // Check if there is an active user and grants are available
          if (!active || !grants) {
            return false
          }

          // Check if the user has the required model
          if (!grants[model]) {
            return false
          }

          // Loop through actions requested
          for (const action of actions) {
            // Check if the user has the required action
            if (grants[model].includes(action)) {
              return true
            }
          }

          return false
        })
    },

    /**
     * Return the required grants for a given path
     * @param {string} path - A path to check
     * @returns boolean
     */
    route (path) {
      if (!path) {
        return false
      }

      const resolved = context.app.router.resolve(path)
      const grants = resolved && resolved.route ? resolved.route.meta.grants : null

      return grants
    },

    /**
     * Check if current user has required grants for given path
     * @param {string} path - A path to check
     * @returns boolean
     */
    access (path) {
      if (!path) {
        return false
      }

      const resolved = context.app.router.resolve(path)
      const grants = resolved && resolved.route ? resolved.route.meta.grants : null

      if (!grants) {
        return true
      }

      return this.all(grants)
    },

    /**
     * Get the current active user role info
     */
    get role () {
      return context.$auth.active?.info?.employee?.role
    },

    /**
     * Get a list of the current users grants
     */
    get list () {
      return context.store.getters['auth/grants']
    },
  }

  inject('grants', plugin)
}
