<template>
  <div>
    <label
      :class="[
        optionClass,
        active ? optionClassActive : 'hover:bg-gray-200 dark:hover:bg-gray-800 transition-all duration-200'
      ]"
    >
      <input
        :id="id"
        ref="input"
        v-model="currentValue"
        :value="value"
        :autofocus="autofocus"
        :readonly="readonly"
        :disabled="disabled"
        :checked="checked"
        :name="name"
        :required="required"
        :class="currentClass"
        type="radio"
        @blur="onBlur"
        @focus="onFocus"
      >
      <!--<span v-if="label" class="ml-3 text-gray-800">
        {{ label }}
      </span>-->
      <slot :checked="checked">
        <label
          v-if="label"
          :class="labelClass"
          :for="id"
        >
          <div>
            {{ label }}
            <span v-if="info" class="block opacity-70 text-sm">
              {{ info }}
            </span>
          </div>
          <div v-html="control" />
        </label>
      </slot>
    </label>
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual'
import attributes from '@//mixins/fields/attributes.js'
import methods from '@//mixins/fields/methods.js'
import classes from '@//mixins/fields/classes.js'

export default {
  mixins: [attributes, methods, classes],
  model: {
    prop: 'model',
    event: 'input',
  },
  props: {
    value: {
      type: [String, Object, Number, Boolean],
      default: 'on',
    },
    label: {
      type: String,
      default: null,
    },
    info: {
      type: String,
      default: null,
    },
    control: {
      type: String,
      default: null,
    },
    checked: {
      type: [Boolean, String],
      default: false,
    },
    model: {
      type: [String, Object, Number, Boolean],
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    baseClass: {
      type: [String, Object, Array],
      default: 'o-radio appearance-none h-4 w-4 border border-gray-300 dark:border-gray-700 rounded-full checked:bg-primary-600 checked:border-primary-600 flex-shrink-0',
    },
    optionClass: {
      type: [String, Object, Array],
      default: 'p-1 flex items-center rounded transition duration-200',
    },
    optionClassActive: {
      type: [String, Object, Array],
      default: 'bg-primary-100/50 dark:bg-primary-800/20',
    },
    labelClass: {
      type: [String, Object, Array],
      default: 'w-full ml-2 opacity-80 flex justify-between items-center text-sm',
    },
  },
  data () {
    return {
      currentValue: this.checked ? this.value : this.model,
    }
  },
  computed: {
    active () {
      return this.currentValue === this.value
    },
  },
  watch: {
    model (model) {
      if (!isEqual(model, this.currentValue)) {
        this.currentValue = model
      }
    },
    checked (checked) {
      const currentValue = checked ? this.value : null
      if (!isEqual(currentValue, this.currentValue)) {
        this.currentValue = currentValue
      }
    },
    currentValue (currentValue, oldCurrentValue) {
      this.$emit('input', currentValue)
      this.$emit('change', currentValue)
    },
  },
  methods: {
    onBlur (e) {
      this.$emit('blur', e)
    },
    onFocus (e) {
      this.$emit('focus', e)
    },
  },
}
</script>

<style>
  .o-radio:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%22-4 -4 8 8%22%3E%3Ccircle r=%222%22 fill=%22%23fff%22/%3E%3C/svg%3E")
  }
</style>
