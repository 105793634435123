import MessageCount from './MessageCount.vue'

export default {
  primary: [
    {
      url: '/',
      name: 'general.dashboard',
      slug: 'dashboard',
      icon: 'menuDashboard',
    },
    {
      url: '/schedule',
      name: 'general.appointments',
      slug: 'schedule',
      icon: 'menuBookings',
      // badge: 4,
      match: '/schedule',
      grants: {
        reservation: ['view'],
      },
    },
    {
      url: '/order',
      name: 'general.sales',
      slug: 'sale',
      icon: 'menuSales',
      // badge: 12,
      grants: {
        order: ['view'],
      },
    },
    {
      url: '/customer',
      name: 'general.customers',
      slug: 'customer',
      icon: 'menuCustomers',
      match: '/customer',
      grants: {
        customer: ['profile'],
      },
    },
    {
      url: '/roster',
      name: 'general.roster',
      slug: 'roster',
      icon: 'menuRoster',
      grants: {
        roster: ['view'],
      },
    },
    {
      url: '/marketing',
      name: 'general.marketing',
      slug: 'marketing',
      icon: 'menuMarketing',
      grants: {
        marketing: ['view'],
      },
    },
    {
      url: '/message',
      name: 'general.messages',
      slug: 'message',
      icon: 'menuMessage',
      grants: {
        marketing: ['view'],
      },
      gates: [
        'messaging',
      ],
      slot: MessageCount,
    },
    {
      url: '/report',
      name: 'general.reports',
      icon: 'menuReports',
      match: '/report',
      grants: {
        report: ['view'],
      },
    },
    {
      url: '/manage',
      name: 'general.manage',
      icon: 'menuManage',
      match: '/manage',
    },
    {
      url: '/minisite',
      name: 'minisite.label',
      icon: 'menuOnlineBooking',
      match: '/minisite',
      grants: {
        account: ['onlineBooking'],
      },
    },
  ],
  secondary: [
    {
      url: '/account',
      name: 'general.account',
      icon: 'menuAccount',
      match: '/account',
      grants: {
        account: ['view'],
      },
    },
    {
      url: 'https://ovatu.com/help/next/guides',
      name: 'components.layout.help',
      icon: 'menuHelp',
      external: true,
    },
  ],
}
