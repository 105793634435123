import Model, { Request } from '../model'
import Enum from '../enums'

import Employee from './employee'

export default class CalendarFeed extends Model {
  static modelName () {
    return 'calendarFeed'
  }

  objectID () {
    return this.idLocationCalendarToken || this.idEmployeeCalendarToken
  }

  relations () {
    return {
      type: { type: CalendarFeedType },
      employee: { type: Employee },
    }
  }

  delete () {
    const url = this.constructor.modelBaseURL() + '/' + this.type.value + '/' + this.objectID()
    return this.constructor.requestSuccess(Request.delete(url), this)
  }

  static create ({ type, name, idEmployee }) {
    const url = this.modelBaseURL() + '/' + type
    const request = { name, idEmployee }

    return this.requestItem(Request.post(url, JSON.stringify(request)), this)
  }

  static location (page = 1) {
    const url = this.modelBaseURL() + '/location?page=' + page
    return this.requestList(Request.get(url), this)
  }

  static employee (page = 1) {
    const url = this.modelBaseURL() + '/employee?page=' + page
    return this.requestList(Request.get(url), this)
  }

  get isTypeLocation () {
    return this.type === CalendarFeedType.location
  }

  get isTypeEmployee () {
    return this.type === CalendarFeedType.employee
  }
}

export const CalendarFeedType = new Enum({
  location: { value: 'location', description: 'general.location' },
  employee: { value: 'employee', description: 'general.employee' },
})
