<template>
  <div class="o-step">
    <div class="flex items-center">
      <div>
        <div :class="circleClass">
          <template v-if="isComplete || isSuccess">
            <o-icon icon="check" :size="12" fill />
          </template>
          <template v-if="isActive">
            <div class="bg-primary-600 w-2 h-2 rounded-full dark:bg-primary-800" />
          </template>
        </div>
      </div>

      <div class="ml-4 w-full flex items-center justify-between">
        <div>
          <h3 v-if="name" :class="nameClass">
            {{ name }}
          </h3>
          <p v-if="info" class="text-gray-600 leading-none text-sm dark:text-gray-300">
            {{ info }}
          </p>
        </div>

        <div>
          <slot name="control" />
        </div>
      </div>
    </div>
    <div class="flex items-stretch">
      <div :class="lineClass" />
      <div class="ml-4 mt-4 mb-8 w-full">
        <slot v-bind="slot" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: null,
    },
    info: {
      type: String,
      default: null,
    },
    success: {
      type: Boolean,
      default: false,
    },
    danger: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      state: 'disabled',
    }
  },
  computed: {
    slot () {
      const { state, isActive, isDisabled, isComplete } = this
      return {
        state,
        isActive,
        isDisabled,
        isComplete,
      }
    },
    isActive () {
      return this.state === 'active'
    },
    isDisabled () {
      return this.state === 'disabled'
    },
    isComplete () {
      return this.state === 'complete'
    },
    isSuccess () {
      return this.success
    },
    circleClass () {
      const classes = ['w-6 h-6 md:w-8 md:h-8 rounded-full inline-flex items-center justify-center select-none']

      if (this.isComplete) {
        classes.push('border-2 border-primary-600 bg-primary-600 text-white dark:bg-primary-800 dark:border-primary-800')
      } else if (this.isActive) {
        classes.push('border-2 border-primary-600 text-primary-500 dark:text-primary-800 dark:border-primary-800')
      } else {
        classes.push('border-2 border-gray-200 text-gray-500 dark:text-gray-400 dark:border-gray-800')
      }

      return classes
    },
    nameClass () {
      const classes = ['font-semibold mb-1 text-sm uppercase leading-none']

      if (this.isComplete) {
        classes.push('text-gray-700 dark:text-gray-200')
      } else if (this.isActive) {
        classes.push('text-primary-600 dark:text-primary-700')
      } else {
        classes.push('text-gray-600 dark:text-gray-300')
      }

      return classes
    },
    lineClass () {
      const classes = ['o-step-line w-px mx-3 md:mx-4 border']

      if (this.isComplete) {
        classes.push('border-primary-500 dark:border-primary-800')
      } else {
        classes.push('border-dashed border-gray-200 dark:border-gray-800')
      }

      return classes
    },
  },
}
</script>

<style>
.o-step:last-child .o-step-line {
  opacity: 0;
}
</style>
