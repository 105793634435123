var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.allowed)?_c('li',{staticClass:"my-[2px]"},[_c('o-tooltip',{attrs:{"placement":"right","text":_vm.$t(_vm.item.name),"offset":"0, 10","trigger":_vm.collapse ? 'mouseover, mouseleave, mouseenter' : ''}},[_c(_vm.item.external ? 'a' : 'nuxt-link',{tag:"component",staticClass:"w-full flex flex-row flex-grow items-center h-8 rounded-lg relative hover:bg-white/10 transition-colors",class:[
        _vm.active && !_vm.isChristmas ? `bg-white/10 dark:bg-primary-${_vm.$auth.theme.dark.shade} dark:text-white/80 text-white` : '',
        _vm.active && _vm.isChristmas ? `bg-primary-700 dark:bg-primary-${_vm.$auth.theme.dark.shade} dark:text-white/80 text-white` : '',
        _vm.collapse ? 'px-2 text-white/80 dark:text-white/80' : 'px-3 text-white/70 dark:text-white/70',
      ],attrs:{"href":_vm.item.url || '/',"to":_vm.item.url || '/',"target":_vm.item.external ? '_blank' : ''},nativeOn:{"click":function($event){return (() => _vm.setMobile(false)).apply(null, arguments)}}},[_c('div',{staticClass:"w-full flex items-center"},[_c('span',{staticClass:"flex items-center justify-center text-lg",class:[
            _vm.collapse ? '-ml-px' : '',
            _vm.active ? '' : ''
          ]},[(_vm.item.icon)?_c('o-icon',{attrs:{"icon":_vm.item.icon,"size":18}}):_vm._e()],1),_vm._v(" "),_c('span',{staticClass:"ml-4 text-base font-medium",class:[ _vm.collapse? 'sm:hidden' : '']},[_vm._v(_vm._s(_vm.$t(_vm.item.name)))])]),_vm._v(" "),_c('div',{staticClass:"flex items-center space-x-1",class:[
          _vm.collapse ? 'absolute top-0 right-0' : '',
        ]},[(_vm.item.slot)?[_c(_vm.item.slot,{tag:"component"})]:(_vm.item.badge)?_c('div',{staticClass:"-mr-1 px-2 py-1 bg-primary-600 text-white rounded-lg text-xs"},[_vm._v("\n          "+_vm._s(_vm.item.badge)+"\n        ")]):_vm._e()],2)])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }