export const sections = {
  share: {
    label: 'minisite.pages.share',
    icon: 'share',
    info: 'components.minisite.pages.share',
    path: '/minisite/share',
    tags: ['share', 'link', 'url', 'website', 'minisite', 'minisite', 'online booking', 'booking', 'page', 'tab'],
  },
  preferences: {
    label: 'general.preferences',
    icon: 'preferences',
    info: 'components.minisite.pages.preferences',
    path: '/minisite/preferences',
    tags: ['waitlist', 'customer access', 'booking limits', 'settings', 'threshold', 'future', 'waiting', 'new customers', 'cancel', 'move', 'reschedule'],
  },
  theme: {
    label: 'general.theme',
    icon: 'preferences',
    info: 'components.minisite.pages.theme',
    path: '/minisite/theme',
    tags: ['colours', 'images', 'logos', 'design', 'style', 'website', 'minisite', 'minisite', 'background', 'colour', 'color', 'buttons', 'menu'],
  },
  widget: {
    label: 'minisite.pages.embed',
    icon: 'widget',
    info: 'components.minisite.pages.embed',
    path: '/minisite/embed',
    tags: ['embed', 'widget', 'ovatu bot', 'code', 'website', 'bot', 'iframe', 'integrate', 'script'],
  },
  booking: {
    label: 'minisite.booking',
    icon: 'calendar',
    info: 'components.minisite.pages.booking',
    path: '/minisite/booking',
    match: '/minisite/booking',
    tags: ['pages', 'tabs', 'booking', 'timetable', 'last', 'minute', 'schedule', 'calendar', 'online', 'online booking'],
  },
  giftCards: {
    label: 'general.giftCards',
    icon: 'giftCard',
    info: 'components.minisite.pages.giftCards',
    path: '/minisite/gift-cards',
    tags: ['page', 'tab', 'gift cards', 'giftcards', 'gift', 'cards', 'voucher', 'coupon', 'certificate'],
  },
  passes: {
    label: 'general.passes',
    icon: 'pass',
    info: 'components.minisite.pages.passes',
    path: '/minisite/passes',
    tags: ['page', 'tab', 'passes', 'pass', 'course'],
  },
  reviews: {
    label: 'general.reviews',
    icon: 'review',
    info: 'components.minisite.pages.reviews',
    path: '/minisite/reviews',
    tags: ['page', 'tab', 'reviews', 'review', 'feedback'],
  },
  customer: {
    label: 'minisite.pages.customer',
    icon: 'customer',
    info: 'components.minisite.pages.customer',
    path: '/minisite/customer',
    tags: ['page', 'tab', 'customer', 'account', 'login', 'manage', 'profile', 'dashboard', 'access'],
  },
  siteNotice: {
    label: 'minisite.pages.siteNotice',
    icon: 'warning',
    info: 'components.minisite.pages.siteNotice',
    path: '/minisite/site-notice',
    tags: ['alert', 'information', 'site notice', 'notice'],
  },
  gallery: {
    label: 'minisite.gallery',
    icon: 'gallery',
    info: 'components.minisite.pages.gallery',
    path: '/minisite/gallery',
    tags: ['gallery'],
  },
  contact: {
    label: 'general.contact',
    icon: 'product',
    info: 'components.minisite.pages.contact',
    path: '/minisite/contact',
    tags: ['contact', 'details', 'address', 'map'],
  },
  businessHours: {
    label: 'minisite.pages.businessHours',
    icon: 'businessHours',
    info: 'components.minisite.pages.businessHours',
    path: '/minisite/hours',
    tags: ['business hours', 'hours', 'holidays', 'opening hours', 'availability'],
  },
  social: {
    label: 'minisite.pages.social',
    icon: 'integrations',
    info: 'components.minisite.pages.social',
    path: '/minisite/social',
    tags: ['social', 'accounts', 'facebook', 'instagram', 'twitter', 'x', 'linkedin', 'pinterest', 'youtube', 'tiktok', 'tik tok', 'snapchat', 'whatsapp', 'telegram', 'links', 'media', 'meta', 'reddit', 'threads', 'linktree'],
  },
  payments: {
    label: 'general.payments',
    icon: 'payment',
    info: 'components.minisite.pages.payments',
    path: '/minisite/payments',
    tags: ['online payment', 'deposit options', 'pay', 'pay in full', 'deposit', 'pay later', 'invoicing', 'checkout', 'ecommerce'],
  },
  newCustomers: {
    label: 'minisite.newCustomers',
    icon: 'customFields',
    info: 'components.minisite.pages.newCustomers',
    path: '/minisite/new-customers',
    tags: ['fields', 'new customer', 'registrations', 'register', 'signup', 'intake'],
  },
  minimiseGaps: {
    label: 'minisite.pages.minimiseGaps',
    icon: 'forms',
    info: 'components.minisite.pages.minimiseGaps',
    path: '/minisite/minimise-gaps',
    tags: ['gaps', 'times', 'appointments'],
  },
  terms: {
    label: 'minisite.pages.terms',
    icon: 'giftCard',
    info: 'components.minisite.pages.terms',
    path: '/minisite/terms',
    tags: ['terms', 'conditions', 'policy', 'cancellation', 'no-show', 'agreement'],
  },
  domain: {
    label: 'minisite.domain',
    icon: 'menuOnlineBooking',
    info: 'components.minisite.pages.domain',
    path: '/minisite/domain',
    tags: ['domain', 'custom domain', 'custom url', 'url', 'website', 'redirect'],
  },
  script: {
    label: 'minisite.script',
    icon: 'schedule',
    info: 'components.minisite.pages.script',
    path: '/minisite/script',
    tags: ['javascript', 'css', 'google', 'conversion', 'code'],
  },
  seo: {
    label: 'minisite.seo',
    icon: 'search',
    info: 'components.minisite.pages.seo',
    path: '/minisite/seo',
    tags: ['seo', 'noindex', 'nofollow', 'meta', 'tags'],
  },
}

export const pages = [
  {
    label: 'minisite.pages.share',
    icon: 'share',
    info: 'components.minisite.pages.share',
    path: '/minisite/share',
    tabs: {
      share: sections.share,
    },
  },
  {
    label: 'general.preferences',
    icon: 'preferences',
    info: 'components.minisite.pages.preferences',
    path: '/minisite/preferences',
    tabs: {
      preferences: sections.preferences,
    },
  },
  {
    label: 'general.theme',
    icon: 'preferences',
    info: 'components.minisite.pages.theme',
    path: '/minisite/theme',
    tabs: {
      theme: sections.theme,
    },
  },
  {
    label: 'minisite.pages.embed',
    icon: 'widget',
    info: 'components.minisite.pages.embed',
    path: '/minisite/embed',
    tabs: {
      widget: sections.widget,
    },
  },
  {
    label: 'minisite.pages.pages',
    icon: 'services',
    info: 'components.minisite.pages.pages',
    path: '/minisite/booking',
    match: '/minisite/booking',
    tabs: {
      booking: sections.booking,
      giftCards: sections.giftCards,
      passes: sections.passes,
      reviews: sections.reviews,
      customer: sections.customer,
      siteNotice: sections.siteNotice,
      gallery: sections.gallery,
    },
  },
  {
    label: 'minisite.pages.customPages',
    icon: 'giftCard',
    info: 'components.minisite.pages.customPages',
    match: '/minisite/custom-pages',
    tabs: [
      {
        label: 'minisite.pages.customPages',
        icon: 'giftCard',
        info: 'components.minisite.pages.customPages',
        path: '/minisite/custom-pages',
        tags: ['custom', 'pages'],
      },
    ],
  },
  {
    label: 'minisite.pages.menu',
    icon: 'minisiteMenu',
    info: 'components.minisite.pages.menu',
    match: '/minisite/menu',
    tabs: [
      {
        label: 'minisite.pages.menu',
        icon: 'minisiteMenu',
        info: 'components.minisite.pages.menu',
        path: '/minisite/menu',
        tags: ['menu', 'order'],
      },
    ],
  },
  {
    label: 'minisite.pages.businessInfo',
    icon: 'product',
    info: 'components.minisite.pages.businessInfo',
    path: '/minisite/business-info/contact',
    match: '/minisite/business-info',
    tabs: {
      contact: sections.contact,
      businessHours: sections.businessHours,
      social: sections.social,
    },
  },
  {
    label: 'general.payments',
    icon: 'payment',
    info: 'components.minisite.pages.payments',
    path: '/minisite/payments',
    tags: ['online payment', 'deposit options'],
    tabs: {
      payments: sections.payments,
    },
  },
  {
    label: 'minisite.newCustomers',
    icon: 'customFields',
    info: 'components.minisite.pages.newCustomers',
    path: '/minisite/new-customers',
    tabs: {
      newCustomers: sections.newCustomers,
    },
  },
  {
    label: 'minisite.pages.minimiseGaps',
    icon: 'forms',
    info: 'components.minisite.pages.minimiseGaps',
    path: '/minisite/minimise-gaps',
    tabs: {
      minimiseGaps: sections.minimiseGaps,
    },
  },
  {
    label: 'minisite.pages.terms',
    icon: 'giftCard',
    info: 'components.minisite.pages.terms',
    path: '/minisite/terms',
    tabs: {
      terms: sections.terms,
    },
  },
  {
    label: 'minisite.pages.advanced',
    icon: 'warning',
    info: 'components.minisite.pages.advanced',
    path: '/minisite/domain',
    match: '/minisite/domain',
    tabs: {
      domain: sections.domain,
      script: sections.script,
      seo: sections.seo,
    },
  },
]
