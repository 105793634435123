import Model, { Request } from '../model'
import Enum from '../enums'

export default class CustomField extends Model {
  static modelName () {
    return 'customField'
  }

  objectID () {
    return this.idCustomField
  }

  relations () {
    return {
      dataType: { type: CustomFieldDataType },
      modelType: { type: CustomFieldModelType },
    }
  }

  editRow () {
    // TODO
    return false
  }

  populateValue (fromForm) {
    // TODO
    return false
  }

  hasChanged (fromForm) {
    // TODO
    return false
  }

  delete (showPastData) {
    const url = this.constructor.modelBaseURL() + '/' + this.objectID() + '?showPastData=' + (showPastData ? 1 : 0)
    return this.constructor.requestSuccess(Request.delete(url))
  }

  get isDataTypeText () {
    return this.dataType === CustomFieldDataType.text
  }

  get isDataTypeLongText () {
    return this.dataType === CustomFieldDataType.longtext
  }

  get isDataTypeMultiple () {
    return this.dataType === CustomFieldDataType.multiple
  }

  get isDataTypeBool () {
    return this.dataType === CustomFieldDataType.bool
  }

  get isDataTypeNumber () {
    return this.dataType === CustomFieldDataType.number
  }

  get isDataTypeDate () {
    return this.dataType === CustomFieldDataType.date
  }
}

export const CustomFieldModelType = new Enum({
  customer: {
    value: 0,
    description: 'general.customer',
  },
  reservation: {
    value: 1,
    description: 'general.appointment',
  },
  order: {
    value: 2,
    description: 'general.sale',
  },
  employee: {
    value: 3,
    description: 'general.employee',
  },
  service: {
    value: 4,
    description: 'general.service',
  },
  product: {
    value: 5,
    description: 'general.product',
  },
  reservationCustomer: {
    value: 6,
    description: 'api.models.customField.groupCustomer',
  },
})

export const CustomFieldDataType = new Enum({
  text: {
    value: 0,
    description: 'field.text',
  },
  longtext: {
    value: 1,
    description: 'field.longText',
  },
  multiple: {
    value: 2,
    description: 'field.multipleValues',
  },
  bool: {
    value: 3,
    description: 'field.yesNo',
  },
  number: {
    value: 4,
    description: 'field.number',
  },
  date: {
    value: 5,
    description: 'field.date',
  },
})
