import Model from '../model'
import Enum from '../enums'

import Form from './form'

export default class ServiceForm extends Model {
  static modelName () {
    return 'serviceForm'
  }

  objectID () {
    return this.idServiceForm
  }

  relations () {
    return {
      form: { type: Form },
      frequency: { type: ServiceFormFrequency },
      requirementMode: { type: ServiceFormRequirementMode },
    }
  }

  get scheduleOffsetMode () {
    return this.scheduleOffset > -1 ? 'after' : 'before'
  }

  set scheduleOffsetMode (value) {
    const isBefore = value === 'before'

    if (isBefore) {
      this.scheduleOffset = -Math.abs(this.scheduleOffset)
    } else {
      this.scheduleOffset = Math.abs(this.scheduleOffset)
    }
  }

  get scheduleOffsetValue () {
    return Math.abs(this.scheduleOffset)
  }

  set scheduleOffsetValue (value) {
    const isBefore = this.scheduleOffsetMode === 'before'

    if (isBefore) {
      this.scheduleOffset = -Math.abs(value)
    } else {
      this.scheduleOffset = Math.abs(value)
    }
  }

  get isRequirementModeScheduled () {
    return this.requirementMode === ServiceFormRequirementMode.scheduled
  }
}

export const ServiceFormFrequency = new Enum({
  reservation: { value: 0, description: 'service.form.frequency.reservation' },
  customer: { value: 1, description: 'service.form.frequency.customer' },
  service: { value: 2, description: 'service.form.frequency.service' },
})

export const ServiceFormRequirementMode = new Enum({
  confirmation: { value: 0, description: 'service.form.requirement.confirmation' },
  scheduled: { value: 1, description: 'service.form.requirement.scheduled' },
  internal: { value: 2, description: 'service.form.requirement.internal' },
})
