const rules = [
  // Customer
  { from: '^ovatu://customer/([0-9]+)/view', to: '/customer/$1' },

  // Customer form
  // { from: '^ovatu://customer/([0-9]+)/forms', to: '/customer/$1?tab=forms' },

  // Customer form
  { from: '^ovatu://customer/([0-9]+)/forms\\?idCustomerForm=([0-9]+)', to: '/customer/form/view?id=$2', component: 'o-panel-link' },

  // Customer message
  { from: '^ovatu://customer/([0-9]+)/message\\?idCustomerMessage=([0-9]+)', to: '/message/chat/$1', component: 'o-panel-link' },

  // Reservation
  { from: '^ovatu://reservation/([0-9]+)/view\\?date=([0-9]{6})', to: '/appointment/$1?date=$2', component: 'o-panel-link' },

  // Order
  { from: '^ovatu://order/([0-9]+)/view', to: '/order/$1', component: 'o-panel-link' },

  // Waitlist
  { from: '^ovatu://waitlist/([0-9]+)/view', to: '/waitlist/$1', component: 'o-panel-link' },

  // Reviews
  { from: '^ovatu://review/list', to: '/review' },

  // Review
  { from: '^ovatu://review/([0-9]+)/view', to: '/marketing/reviews/review?id=$1', component: 'o-panel-link' },

  // Messages
  { from: '^ovatu://messages/list', to: '/message' },

  // SMS settings
  { from: '^ovatu://settings/sms', to: '/manage/sms' },

  // Plan settings
  { from: '^ovatu://settings/plan', to: '/account/billing' },

  // Integration authentication
  { from: '^ovatu://integration/(\\w+)/authenticate', to: '/integration/$1', component: 'o-panel-link' },

  // Resend email verification
  { from: '^ovatu://settings/verify', to: '/account/verify' },

  // Password
  { from: '^ovatu://settings/password', to: '/account/users/users' },

  // Minisite settings
  { from: '^ovatu://settings/minisite', to: '/minisite' },

  // Policy settings
  { from: '^ovatu://settings/policy\\?policy=(\\w+)', to: '/account/policy?policy=$1' },

  // Reservation list
  { from: '^ovatu://reservation/list\\?ids=([&\\w]+)', to: '/appointment/list?ids=$1', component: 'o-panel-link' },

  // Order list
  { from: '^ovatu://order/list\\?ids=([&\\w]+)', to: '/order/list?ids=$1', component: 'o-panel-link' },

  // Cutomer list
  { from: '^ovatu://customer/list\\?ids=([&\\w]+)', to: '/customer/list?ids=$1', component: 'o-panel-link' },

  // Minisite home
  {
    from: '^ovatu://minisite/home',
    to: (url, context) => {
      const domain = context.$config.bookappDomain
      const slug = context.$auth.info.location.slug

      return 'https://' + slug + '.' + domain
    },
    component: 'a',
  },
]

export default (context, inject) => {
  const plugin = {
    match (url) {
      const found = this.rules.find(rule => rule.from.test(url))
      let path = '/'

      if (found) {
        if (found.to instanceof Function) {
          path = found.to(url, context)
        } else  {
          path = url.replace(found.from, found.to)
        }
      }

      const component = found?.component || 'nuxt-link'

      return {
        path,
        component,
      }
    },
    route (url) {
      const path = this.convert(url)
      context.router.go(path)
    },

    get rules () {
      return rules.map((rule) => {
        return {
          ...rule,
          from: new RegExp(rule.from),
        }
      })
    },
  }

  inject('redirect', plugin)
}
