import Fuse from 'fuse.js'
import axios from 'axios'
import Model from '../model'
import Result from '../result'
import Component from '@/components/search/elements/HelpGuide'

class HelpGuide extends Model {
  constructor (options = {}) {
    super(options)

    this.name = 'Help Guides'
    this.component = Component

    this.items = []
  }

  async init (context) {
    this.syncing = true

    try {
      const response = await axios.get('https://help.ovatu.com/api/help/menu?product=next')

      this.items = response.data.data.topics.map((topic) => {
        console.log(topic.iconCss)
        return topic.articles.map(article => ({
          label: article.name,
          info: topic.name,
          link: `https://help.ovatu.com/${topic.slug}/${article.slug}`,
          icon: topic.iconCss,
        }))
      }).flat()

      this.syncing = false
    } catch (error) {
      console.error('Error fetching help guides:', error)
      this.syncing = false
    }
  }

  search (query) {
    const fuse = new Fuse(this.items, {
      keys: ['label', 'info'],
    })

    const results = fuse.search(query)
    const filtered = results.map(({ item }) => new HelpGuideResult({ data: item }))

    // Limit results if needed
    filtered.length = Math.min(filtered.length, 5)

    this.results = filtered
  }
}

class HelpGuideResult extends Result {
  get path () {
    return this.data.link
  }
}

export default new HelpGuide()
