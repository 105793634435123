import localforage from 'localforage'
import * as API from '~/api'

export const state = () => ({
  forms: [],
  sync: {
    running: false,
    progress: null,
    date: null,
    error: false,
  },
  hydrated: false,
  count: null,
})

export const mutations = {
  setSync (state, { running, progress, date, error = null }) {
    if (!(running === undefined)) {
      state.sync.running = running
    }
    if (!(progress === undefined)) {
      state.sync.progress = progress
    }
    if (!(date === undefined)) {
      state.sync.date = date
    }

    state.sync.error = error
  },
  updateForms (state, forms) {
    state.forms = Object.freeze(API.Form.ensure(forms))
  },
  setHydrated (state, hydrated) {
    state.hydrated = hydrated
  },
  setCount (state, count) {
    state.count = count
  },
}

export const getters = {
  isSyncing (state) {
    return state.sync.running
  },
  syncProgress (state) {
    return state.sync.progress
  },
  syncDate (state) {
    return state.sync.date
  },
  all (state) {
    return state.forms
  },
  count (state) {
    return state.forms.length
  },
}

export const actions = {
  async sync ({ commit, state, rootState, rootGetters }, { force = false, change = false } = {}) {
    const guid = rootState.auth.active
    const info = rootGetters['auth/info']
    const features = info?.features

    // Check feature
    if (features && !features.includes('forms')) {
      return
    }

    // Check permission
    if (this.$grants && !this.$grants.all({ form: ['view'] })) {
      console.log('no form permissions')
      return
    }

    if (!state.hydrated || change) {
      const forms = await localforage.getItem(guid + '.forms.forms')
      const sync = await localforage.getItem(guid + '.forms.sync')

      if (forms) {
        commit('updateForms', forms)
      } else {
        commit('updateForms', [])
      }

      if (sync) {
        commit('setSync', sync)
      } else {
        commit('setSync', { date: null, progress: null, running: false })
      }

      commit('setHydrated', true)
    }

    const date = new Date()

    commit('setSync', { running: true, progress: 0 })

    const sinceDate = (force) ? null : state.sync.date

    const existing = {}
    if (!force) {
      for (let i = 0; i < state.forms.length; ++i) {
        const forms = state.forms[i]
        existing[forms.idForm] = forms
      }
    }

    const errors = []

    try {
      await API.Form.listAll(sinceDate, (page, pageCount, list) => {
        if (list.items) {
          for (let i = 0; i < list.items.length; ++i) {
            const forms = list.items[i]
            existing[forms.idForm] = forms
          }
        }

        if (list.deleted) {
          for (let i = 0; i < list.deleted.length; ++i) {
            const id = list.deleted[i]
            delete existing[id]
          }
        }

        commit('setSync', { running: true, progress: (page / pageCount) })
      })
    } catch (e) {
      if (e?.response?.status === 304) {
        console.log('Form data is up to date')
      } else {
        errors.push(e)
      }
    } finally {
      commit('updateForms', Object.values(existing))

      if (errors.length) {
        commit('setSync', { running: false, progress: null, date: null, error: true })
      } else {
        commit('setSync', { running: false, progress: null, date })
      }

      localforage.setItem(guid + '.forms.forms', state.forms)
      localforage.setItem(guid + '.forms.sync', state.sync)
    }
  },
}
