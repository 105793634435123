export default {
  props: {
    tagName: {
      type: String,
      default: 'a',
      validator (value) {
        return ['button', 'a', 'o-panel-link', 'o-button', 'nuxt-link'].includes(value)
      },
    },
  },
  computed: {
    isInertiaLinkComponentAvailable () {
      return !!this.$options.components.InertiaLink
    },
    isRouterLinkComponentAvailable () {
      return !!(this.$options.components.RouterLink || this.$options.components.NuxtLink)
    },
    isARouterLink () {
      return this.to !== undefined && this.isRouterLinkComponentAvailable
    },
    isAnIntertiaLink () {
      return this.href !== undefined && this.isInertiaLinkComponentAvailable
    },
    componentToRender () {
      if (this.isARouterLink) {
        return this.$options.components.NuxtLink || this.$options.components.RouterLink
      }
      if (this.isAnIntertiaLink) {
        return this.$options.components.InertiaLink
      }
      if (this.href) {
        return 'a'
      }
      return this.tagName
    },
  },
}
