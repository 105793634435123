export const pages = [
  // { label: 'Devices', icon: 'devices', info: 'Manage devices authorised to access your account', path: '/account/devices' },
  { label: 'components.account.pages.usersAndRoles.label', icon: 'employee', info: 'components.account.pages.usersAndRoles.info', path: '/account/users/users', match: '/account/users' },
  { label: 'components.account.pages.planAndBilling.label', icon: 'creditCard', info: 'components.account.pages.planAndBilling.info', path: '/account/billing' },
  { label: 'components.account.pages.referral.label', icon: 'info', info: 'components.account.pages.referral.info', path: '/account/referral' },
  { label: 'components.account.pages.security.label', icon: 'services', info: 'components.account.pages.security.info', path: '/account/security' },
  { label: 'components.account.pages.cachedData.label', icon: 'sync', info: 'components.account.pages.cachedData.info', path: '/account/storage' },
  { label: 'components.account.pages.privacy.label', icon: 'product', info: 'components.account.pages.privacy.info', path: '/account/privacy' },
  // { label: 'Roles', icon: 'permission', info: 'Create and edit groups of permissions (roles) that can be assigned to Employees', path: '/account/roles' },
  { label: 'components.account.pages.integrations.label', icon: 'menuIntegrations', info: 'components.account.pages.integrations.info', path: '/account/integrations' },
  { label: 'components.account.pages.sessions.label', icon: 'devices', info: 'components.account.pages.sessions.info', path: '/account/sessions' },
  { label: 'components.account.pages.calendarFeeds.label', icon: 'calendar', info: 'components.account.pages.calendarFeeds.info', path: '/account/calendar' },
  { label: 'components.account.pages.policy.label', icon: 'policy', info: 'components.account.pages.policy.info', path: '/account/policy' },
  { label: 'components.account.pages.exports.label', icon: 'export', info: 'components.account.pages.exports.info', path: '/account/exports' },
]
