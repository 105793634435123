import APIObject from '../object'
import Enum from '../enums'
import { DateYMD } from '../dates'

import { plugin as $date } from '../../plugins/date'

import Service from './service'
import Reservation from './reservation'
import Customer from './customer'
import { ConfirmationRequestService } from './booking'

export default class Recurrence extends APIObject {
  relations () {
    return {
      recurrence: { type: Recurrence },
      repeat: { type: RecurrenceRepeat },
      ending: { type: RecurrenceEnding },
      selectedDates: { type: RecurrenceSelectedDate },
      datesWithConflicts: { type: RecurrenceDateWithConflict },
      services: { type: ConfirmationRequestService },
    }
  }

  get label () {
    const frequency = this.repeat.frequency

    let interval
    let on
    let ending

    if (frequency > 1) {
      interval = window.$nuxt.$tc('datetime.increments.weeks', frequency, { weeks: frequency }).toLowerCase()
    } else {
      interval = window.$nuxt.$t(this.repeat.interval.description).toLowerCase()
    }

    if (this.isRecurrenceIntervalWeek) {
      const dayValue = Number(this.repeat.on.values[0])
      const day = window.$nuxt.$t(RecurrenceDays.symbolForValue(dayValue)?.description)
      on = window.$nuxt.$t('api.models.recurrence.onDay', { day })
    }

    if (this.isRecurrenceIntervalMonth) {
      if (this.isRecurrenceMonthTypeDay) {
        const week = this.repeat.on.options.weeksOfMonth[0]
        const ordinal = window.$nuxt.$t(week.description)
        const dayValue = this.repeat.on.values[0]
        const day = window.$nuxt.$t(RecurrenceDays.symbolForValue(dayValue)?.description)
        on = window.$nuxt.$t('api.models.recurrence.onOrdinalDay', { ordinal, day })
      }

      if (this.isRecurrenceMonthTypeDate) {
        const day = this.repeat.on.values[0]
        const ordinal = $date.getOrdinal(day)
        on = window.$nuxt.$t('api.models.recurrence.onOrdinalDayOfTheMonth', { ordinal })
      }

      if (this.isRecurrenceMonthTypeLast) {
        on = window.$nuxt.$t('api.models.recurrence.onLastDayOfTheMonth')
      }
    }

    if (this.isRecurrenceIntervalYear) {
      const date = $date.getOrdinal(this.repeat.on.values[0].date)
      const monthValue = this.repeat.on.values[0].month
      const month = window.$nuxt.$t(RecurrenceMonths.symbolForValue(monthValue).description)
      const ordinal = $date.getOrdinal(date)
      on = window.$nuxt.$t('api.models.recurrence.onMonthOrdinal', { month, ordinal })
    }

    if (this.isEndingOnDate) {
      const date = $date.convert(this.ending.date, undefined, $date.presets.long)
      ending = window.$nuxt.$t('api.models.recurrence.endingOnDate', { date })
    }

    if (this.isEndingAfterRepeats) {
      const repeats = this.ending.numberOfRepeats
      ending = window.$nuxt.$tc('api.models.recurrence.endingAfterRepeats', repeats, { repeats })
    }

    return window.$nuxt.$t('api.models.recurrence.label', { interval, on, ending })
  }

  get isEndingOnDate () {
    return !!this.ending.date?.value
  }

  get isEndingAfterRepeats () {
    return !!this.ending.numberOfRepeats
  }

  get isRecurrenceIntervalWeek () {
    return this.repeat.interval === RecurrenceIntervalType.week
  }

  get isRecurrenceIntervalMonth () {
    return this.repeat.interval === RecurrenceIntervalType.month
  }

  get isRecurrenceIntervalYear () {
    return this.repeat.interval === RecurrenceIntervalType.year
  }

  get isRecurrenceMonthTypeDate () {
    return !this.isRecurrenceMonthTypeDay && !this.isRecurrenceMonthTypeLast
  }

  get isRecurrenceMonthTypeDay () {
    return !!this.repeat.on.options?.weeksOfMonth
  }

  get isRecurrenceMonthTypeLast () {
    return !!this.repeat.on.options?.lastDayOfMonth
  }
}

export class RecurrenceRequest extends APIObject {
  relations () {
    return {
      reservation: { type: Reservation },
      services: { type: Service },
      recurrence: { type: Recurrence },
    }
  }

  toJSON () {
    const object = {
      recurrence: this.recurrence ? this.recurrence.toJSON() : null, // .toJSON(),
      services: (this.services) ? this.services.map(service => service.toJSON()) : null,
      idReservation: (this.reservation) ? this.reservation.idReservation : null,
    }

    return object
  }

  static create ({ recurrence, reservation, services, datesWithConflicts } = {}) {
    const request = new RecurrenceRequest()

    request.reservation = reservation
    request.services = services

    if (recurrence) {
      const object = {
        repeat: {},
        ending: {},
      }

      object.repeat.frequency = recurrence.repeat.interval === RecurrenceIntervalType.year ? 1 : recurrence.repeat.frequency

      if (recurrence.repeat.interval === RecurrenceIntervalType.week) {
        object.repeat.interval = RecurrenceIntervalType.week
        object.repeat.on = {
          values: recurrence.repeat.on.week.days,
        }
      }

      if (recurrence.repeat.interval === RecurrenceIntervalType.month) {
        object.repeat.interval = RecurrenceIntervalType.month

        if (recurrence.repeat.on.month.type === RecurrenceIntervalMonthType.day) {
          object.repeat.on = {
            options: {
              weeksOfMonth: recurrence.repeat.on.month.weeks
                .map(week => week.value),
            },
            values: recurrence.repeat.on.month.days,
          }
        }

        if (recurrence.repeat.on.month.type === RecurrenceIntervalMonthType.date) {
          object.repeat.on = {
            values: recurrence.repeat.on.month.dates,
          }
        }

        if (recurrence.repeat.on.month.type === RecurrenceIntervalMonthType.last) {
          object.repeat.on = {
            options: { lastDayOfMonth: true },
          }
        }
      }

      if (recurrence.repeat.interval === RecurrenceIntervalType.year) {
        object.repeat.interval = RecurrenceIntervalType.year
        const date = recurrence.repeat.on.year.date
        object.repeat.on = {
          values: [
            { date: date.date, month: date.month.value },
          ],
        }
      }

      if (recurrence.ending.type === RecurrenceEndingType.date) {
        object.ending.date = recurrence.ending.date
      }

      if (recurrence.ending.type === RecurrenceEndingType.repeats) {
        object.ending.numberOfRepeats = recurrence.ending.repeats
      }

      if (recurrence.datesWithConflicts) {
        object.datesWithConflicts = recurrence.datesWithConflicts
      }

      request.recurrence = new Recurrence(object)
    }

    return request
  }
}

export class RecurrenceRepeat extends APIObject {
  relations () {
    return {
      interval: { type: RecurrenceIntervalType },
      on: { type: RecurrenceRepeatOn },
    }
  }
}

export class RecurrenceRepeatOn extends APIObject {
  relations () {
    return {
      // days: { type: RecurrenceDays },
      options: { type: RecurrenceRepeatOptions },
    }
  }
}

export class RecurrenceRepeatOptions extends APIObject {
  relations () {
    return {
      // days: { type: RecurrenceDays },
      weeksOfMonth: { type: RecurrenceWeeks },
    }
  }
}

export class RecurrenceEnding extends APIObject {
  relations () {
    return {
      date: { type: DateYMD },
    }
  }
}

export class RecurrenceSelectedDate extends APIObject {
  relations () {
    return {
      options: { type: RecurrenceConflict },
      option: { type: RecurrenceConflict },
    }
  }

  get hasConflicts () {
    return !!this.options
  }

  get conflictOptions () {
    return this.options
      ?.map((option) => {
        return {
          value: option,
          text: window.$nuxt.$t(option.description),
        }
      })
  }
}

export class RecurrenceDateWithConflict extends APIObject {
  relations () {
    return {
      option: { type: RecurrenceConflict },
    }
  }
}

export const RecurrenceIntervalType = new Enum({
  week: { value: 'week', description: 'datetime.labels.week' },
  month: { value: 'month', description: 'datetime.labels.month'  },
  year: { value: 'year', description: 'datetime.labels.year'  },
})

export const RecurrenceDays = new Enum({
  monday: { value: 1, description: 'datetime.days.monday.long' },
  tuesday: { value: 2, description: 'datetime.days.tuesday.long' },
  wednesday: { value: 3, description: 'datetime.days.wednesday.long' },
  thursday: { value: 4, description: 'datetime.days.thursday.long' },
  friday: { value: 5, description: 'datetime.days.friday.long' },
  saturday: { value: 6, description: 'datetime.days.saturday.long' },
  sunday: { value: 7, description: 'datetime.days.sunday.long' },
})

export const RecurrenceMonths = new Enum({
  january: { value: 1, description: 'datetime.months.january' },
  february: { value: 2, description: 'datetime.months.february' },
  march: { value: 3, description: 'datetime.months.march' },
  april: { value: 4, description: 'datetime.months.april' },
  may: { value: 5, description: 'datetime.months.may' },
  june: { value: 6, description: 'datetime.months.june' },
  july: { value: 7, description: 'datetime.months.july' },
  august: { value: 8, description: 'datetime.months.august' },
  september: { value: 9, description: 'datetime.months.september' },
  october: { value: 10, description: 'datetime.months.october' },
  november: { value: 11, description: 'datetime.months.november' },
  december: { value: 12, description: 'datetime.months.december' },
})

export const RecurrenceIntervalMonthType = new Enum({
  day: { value: 'day', description: 'api.models.recurrence.dayOfWeek' },
  date: { value: 'date', description: 'api.models.recurrence.dayOfMonth' },
  last: { value: 'last', description: 'api.models.recurrence.lastDayOfMonth' },
})

export const RecurrenceEndingType = new Enum({
  date: { value: 'date', description: 'api.models.recurrence.onDate' },
  repeats: { value: 'repeats', description: 'reservation.afterNumberOfRepeats' },
})

export const RecurrenceWeeks = new Enum({
  1: { value: 1, description: 'api.models.recurrence.first' },
  2: { value: 2, description: 'api.models.recurrence.second' },
  3: { value: 3, description: 'api.models.recurrence.third' },
  4: { value: 4, description: 'api.models.recurrence.fourth' },
})

export const RecurrenceConflict = new Enum({
  skip: { value: 'skip', description: 'api.models.recurrence.skip' },
  doubleBook: { value: 'doubleBook', description: 'api.models.recurrence.doubleBook' },
  delete: { value: 'delete', description: 'general.delete' },
})

export const RecurrenceCustomerAction = new Enum({
  book: { value: 'book', description: 'api.models.recurrence.book' },
  cancel: { value: 'cancel', description: 'general.cancel' },
  delete: { value: 'delete', description: 'general.delete' },
})
