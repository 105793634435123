import Model, { Request } from '../model'
import APIObject from '../object'
import Enum from '../enums'

import Customer from './customer'
import Order from './order'
import Reservation from './reservation'
import Waitlist from './waitlist'

export default class CustomerMessage extends Model {
  static modelName () {
    return 'customerMessage'
  }

  objectID () {
    return this.idCustomerMessage
  }

  relations () {
    return {
      customer: { type: Customer },
      sender: { type: CustomerMessageSender },
      data: { type: CustomerMessageData },
      type: { type: CustomerMessageType },
      channelEmail: { type: CustomerMessageChannelStatus },
      channelSms: { type: CustomerMessageChannelStatus },
      channelApp: { type: CustomerMessageChannelStatus },
      attachments: { type: CustomerMessageAttachment },
    }
  }

  static counts () {
    const url = this.modelBaseURL() + '/counts'
    return this.requestData(Request.get(url))
  }

  static customer (customerID, { markAsRead = true, page = 1, limit = 50 } = {}) {
    const url = this.modelBaseURL() + `/customer?page=${page}&limit=${limit}&markAsRead=${markAsRead ? 1 : 0}`

    const data = {
      customer: customerID,
    }

    return this.requestList(Request.post(url, JSON.stringify(data)), this)
  }

  static send ({
    idCustomer,
    subject,
    body,
    attachments,
    sender = CustomerMessageSender.location,
    emailSend,
    smsSend,
  } = {}, uploads = {}) {
    const formData = new FormData()

    const data = {
      idCustomer,
      subject,
      body,
      attachments,
      sender,
      emailSend,
      smsSend,
    }

    formData.append('_request', JSON.stringify(data))
    formData.append('_method', 'POST')

    for (const key in uploads) {
      formData.append(key, uploads[key])
    }

    const url = this.modelBaseURL()
    const request = Request.jsonPost(url, formData)

    return this.requestItem(request, this)
  }

  get isSenderLocation () {
    return this.sender === CustomerMessageSender.location
  }

  get isSenderCustomer () {
    return this.sender === CustomerMessageSender.customer
  }

  get isSenderSystem () {
    return this.sender === CustomerMessageSender.system
  }

  get isSenderEmployee () {
    return this.sender === CustomerMessageSender.employee
  }

  get isChannelEmailDoNotSend () {
    return this.channelEmail === CustomerMessageChannelStatus.doNotSend
  }

  get isChannelEmailSend () {
    return this.channelEmail === CustomerMessageChannelStatus.send
  }

  get isChannelEmailSent () {
    return this.channelEmail === CustomerMessageChannelStatus.sent
  }

  get isChannelEmailFailed () {
    return this.channelEmail === CustomerMessageChannelStatus.failed
  }

  get isChannelEmailOpened () {
    return this.channelEmail === CustomerMessageChannelStatus.opened
  }

  get isChannelEmailOrigin () {
    return this.channelEmail === CustomerMessageChannelStatus.origin
  }

  get isChannelSMSDoNotSend () {
    return this.channelSms === CustomerMessageChannelStatus.doNotSend
  }

  get isChannelSMSSend () {
    return this.channelSms === CustomerMessageChannelStatus.send
  }

  get isChannelSMSSent () {
    return this.channelSms === CustomerMessageChannelStatus.sent
  }

  get isChannelSMSFailed () {
    return this.channelSms === CustomerMessageChannelStatus.failed
  }

  get isChannelSMSOpened () {
    return this.channelSms === CustomerMessageChannelStatus.opened
  }

  get isChannelSMSOrigin () {
    return this.channelSms === CustomerMessageChannelStatus.origin
  }

  get isChannelAppDoNotSend () {
    return this.channelApp === CustomerMessageChannelStatus.doNotSend
  }

  get isChannelAppSend () {
    return this.channelApp === CustomerMessageChannelStatus.send
  }

  get isChannelAppSent () {
    return this.channelApp === CustomerMessageChannelStatus.sent
  }

  get isChannelAppFailed () {
    return this.channelApp === CustomerMessageChannelStatus.failed
  }

  get isChannelAppOpened () {
    return this.channelApp === CustomerMessageChannelStatus.opened
  }

  get isChannelAppOrigin () {
    return this.channelApp === CustomerMessageChannelStatus.origin
  }

  get isTypeNewReservation () {
    return this.type === CustomerMessageType.newReservation
  }

  get isTypeNewReservationConfirmed () {
    return this.type === CustomerMessageType.newReservationConfirmed
  }

  get isTypeNewReservationRejected () {
    return this.type === CustomerMessageType.newReservationRejected
  }

  get isTypeNewReservationCancelled () {
    return this.type === CustomerMessageType.newReservationCancelled
  }

  get isTypeNewReservationReminder () {
    return this.type === CustomerMessageType.newReservationReminder
  }

  get isTypeReservationMoved () {
    return this.type === CustomerMessageType.reservationMoved
  }

  get isTypeReservationCancelled () {
    return this.type === CustomerMessageType.reservationCancelled
  }

  get isTypeReservationReminder () {
    return this.type === CustomerMessageType.reservationReminder
  }

  get isTypeNewSale () {
    return this.type === CustomerMessageType.newSale
  }

  get isTypePasswordReset () {
    return this.type === CustomerMessageType.passwordReset
  }

  get isTypeReceivedCampaign () {
    return this.type === CustomerMessageType.receivedCampaign
  }

  get isTypeGeneral () {
    return this.type === CustomerMessageType.general
  }

  get isTypeLoginCode () {
    return this.type === CustomerMessageType.loginCode
  }

  get isTypeNewCustomer () {
    return this.type === CustomerMessageType.newCustomer
  }

  get isTypeNewOrder () {
    return this.type === CustomerMessageType.newOrder
  }

  get isStatusQueued () {
    return this.status === CustomerMessageStatus.queued
  }

  get isStatusSending () {
    return this.status === CustomerMessageStatus.sending
  }

  get isStatusSent () {
    return this.status === CustomerMessageStatus.sent
  }

  get isStatusFailed () {
    return this.status === CustomerMessageStatus.failed
  }
}

export class CustomerMessageData extends APIObject {
  static modelName () {
    return 'customerMessageData'
  }

  relations () {
    return {
      customer: { type: Customer },
      order: { type: Order },
      reservation: { type: Reservation },
      waitlist: { type: Waitlist },
    }
  }
}


export class CustomerMessageAttachment extends APIObject {
  static modelName () {
    return 'customerMessageAttachment'
  }

  relations () {
    return {
      type: { type: CustomerMessageAttachmentType },
    }
  }

  get isTypeReservation () {
    return this.type === CustomerMessageAttachmentType.reservation
  }

  get isTypeOrder () {
    return this.type === CustomerMessageAttachmentType.order
  }

  get isTypeCustomerForm () {
    return this.type === CustomerMessageAttachmentType.customerForm
  }

  get isTypeUpload () {
    return this.type === CustomerMessageAttachmentType.upload
  }

  get isTypeCustomerCourse () {
    return this.type === CustomerMessageAttachmentType.customerCourse
  }

  get isTypeGiftCard () {
    return this.type === CustomerMessageAttachmentType.giftCard
  }

  get isTypeCustomerPass () {
    return this.type === CustomerMessageAttachmentType.customerPass
  }

  get isTypeWaitlist () {
    return this.type === CustomerMessageAttachmentType.waitlist
  }

  get isTypeCampaign () {
    return this.type === CustomerMessageAttachmentType.campaign
  }
}

export const CustomerMessageAttachmentType = new Enum({
  reservation: { value: 1, description: 'general.reservation' },
  order: { value: 2, description: 'api.models.customerMessage.order' },
  customerForm: { value: 3, description: 'api.models.customerMessage.customerForm' },
  upload: { value: 4, description: 'asset.upload' },
  customerCourse: { value: 5, description: 'api.models.customerMessage.customerCourse' },
  giftCard: { value: 6, description: 'general.giftCard' },
  customerPass: { value: 7, description: 'api.models.customerMessage.customerPass' },
  waitlist: { value: 8, description: 'general.waitlist' },
  campaign: { value: 9, description: 'general.campaign' },
})

export const CustomerMessageStatus = new Enum({
  queued: { value: 1, description: 'general.queued' },
  sending: { value: 2, description: 'general.sending' },
  sent: { value: 3, description: 'general.sent' },
  failed: { value: 4, description: 'api.models.customerMessage.failed' },
})

export const CustomerMessageSender = new Enum({
  location: { value: 1, description: 'general.location' },
  customer: { value: 2, description: 'general.customer' },
  system: { value: 3, description: 'general.system' },
  employee: { value: 4, description: 'general.employee' },
})

export const CustomerMessageChannelStatus = new Enum({
  doNotSend: { value: 1, description: 'api.models.customerMessage.doNotSend' },
  send: { value: 2, description: 'general.send' },
  sent: { value: 3, description: 'general.sent' },
  failed: { value: 4, description: 'api.models.customerMessage.failed' },
  opened: { value: 5, description: 'general.opened' },
  origin: { value: 6, description: 'api.models.customerMessage.origin' },
})

export const CustomerMessageType = new Enum({
  newReservation: { value: 1, description: 'api.models.customerMessage.newReservation' },
  newReservationConfirmed: { value: 2, description: 'api.models.customerMessage.newReservationConfirmed' },
  newReservationRejected: { value: 3, description: 'api.models.customerMessage.newReservationRejected' },
  newReservationCancelled: { value: 4, description: 'api.models.customerMessage.newReservationCancelled' },
  newReservationReminder: { value: 5, description: 'api.models.customerMessage.newReservationReminder' },
  reservationMoved: { value: 6, description: 'api.models.customerMessage.reservationMoved' },
  reservationCancelled: { value: 7, description: 'api.models.customerMessage.reservationCancelled' },
  reservationReminder: { value: 8, description: 'api.models.customerMessage.reservationReminder' },
  newSale: { value: 9, description: 'order.new' },
  passwordReset: { value: 10, description: 'api.models.customerMessage.passwordReset' },
  receivedCampaign: { value: 11, description: 'api.models.customerMessage.receivedCampaign' },
  general: { value: 12, description: 'api.models.customerMessage.general' },
  loginCode: { value: 13, description: 'api.models.customerMessage.loginCode' },
  newCustomer: { value: 14, description: 'customer.new' },
  newOrder: { value: 15, description: 'api.models.customerMessage.newOrder' },
})
