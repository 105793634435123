import Model from '../model'
import Enum from '../enums'
import Images from './images'

export default class Photo extends Model {
  static modelName () {
    return 'photo'
  }

  objectID () {
    return this.idPhoto
  }

  relations () {
    return {
      imageUrls: { type: Images },
      type: { type: PhotoType },
    }
  }
}

export const PhotoType = new Enum({
  customer: { value: 0, description: 'general.customer' },
})
