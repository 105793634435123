import { startOfMonth, endOfMonth, startOfDay, endOfDay, sub } from 'date-fns'

export default {
  today: {
    name: 'datetime.labels.today',
    value: {
      start: startOfDay(new Date()),
      finish: endOfDay(new Date()),
    },
  },
  oneWeek: {
    name: 'components.fields.dateRange.oneWeek',
    value: {
      start: startOfDay(sub(new Date(), { days: 7 })),
      finish: endOfDay(new Date()),
    },
  },
  twoWeeks: {
    name: 'components.fields.dateRange.twoWeeks',
    value: {
      start: startOfDay(sub(new Date(), { days: 14 })),
      finish: endOfDay(new Date()),
    },
  },
  threeWeeks: {
    name: 'components.fields.dateRange.threeWeeks',
    value: {
      start: startOfDay(sub(new Date(), { days: 21 })),
      finish: endOfDay(new Date()),
    },
  },
  thisMonth: {
    name: 'components.fields.dateRange.thisMonth',
    value: {
      start: startOfDay(startOfMonth(new Date())),
      finish: endOfDay(endOfMonth(new Date())),
    },
  },
  lastMonth: {
    name: 'components.fields.dateRange.lastMonth',
    value: {
      start: startOfDay(startOfMonth(sub(new Date(), { months: 1 }))),
      finish: endOfDay(endOfMonth(sub(new Date(), { months: 1 }))),
    },
  },
  future: {
    name: 'components.fields.dateRange.future',
    value: {
      start: new Date(),
      finish: null,
    },
  },
  past: {
    name: 'components.fields.dateRange.past',
    value: {
      start: null,
      finish: new Date(),
    },
  },
}
