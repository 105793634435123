import get from 'lodash/get'

export default (context, inject) => {
  const plugin = {
    csv (data, keys, title) {
      let csv = keys.map(key => key?.label || key).join(',') + '\n'

      for (const item of data) {
        const object = item.toJSON()

        for (const key of keys) {
          let value = get(object, key?.key || key)

          if (Array.isArray(value)) {
            value = value.join(', ')
          }

          csv += `"${value || ''}",`
        }

        csv += '\n'
      }

      const fileName = (title || 'export') + '.csv'
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })

      this.download(blob, fileName)

      return true
    },
    download (blob, fileName) {
      const link = document.createElement('a')
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob)

        link.setAttribute('href', url)
        link.setAttribute('download', fileName)

        link.style.visibility = 'hidden'

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
  }

  inject('export', plugin)
}
