<template>
  <container v-bind="container">
    <input
      ref="input"
      v-model="currentValue"
      type="text"
      :maxlength="maxlength"
      :minlength="minlength"
      :class="inputClass"
      v-bind="attributes"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      :autofocus="autofocus"
      v-on="handlers"
    >

    <template #leading>
      <slot name="leading" />
    </template>

    <template #trailing>
      <slot name="trailing" />
    </template>
  </container>
</template>

<script>
import Container from './Container'
import classes from '@/mixins/fields/classes.js'
import attributes from '@/mixins/fields/attributes.js'
import methods from '@/mixins/fields/methods.js'
import input from '@/mixins/fields/input.js'

export default {
  components: {
    Container,
  },
  mixins: [attributes, classes, methods, input],
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    autocomplete: {
      type: String,
      default: null,
    },
    maxlength: {
      type: [String, Number],
      default: null,
    },
    minlength: {
      type: [String, Number],
      default: null,
    },
    inputClass: {
      type: String,
      default: 'h-full w-full bg-transparent text-sm opacity-90',
    },
    placeholder: {
      type: String,
      default: null,
    },
  },
  computed: {
    currentValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
