<template>
  <div v-if="counts && counts.locationUnreadCount" class="-mr-1 px-2 py-1 bg-primary-600 text-white rounded-lg text-xs">
    {{ Number(counts.locationUnreadCount) }}
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      counts: 'customerMessage/counts',
    }),
  },
  mounted () {
    this.fetchCounts()
  },
  methods: {
    ...mapActions({
      fetchCounts: 'customerMessage/fetchCounts',
    }),
  },
}
</script>
