import { plugin as $date } from '../../plugins/date'

export default {
  YMD: {
    input: (value) => {
      if (value && !(value instanceof Date)) {
        const date = value.toString()

        if (date.length === 8) {
          const year = date.substring(0, 4)
          const month = date.substring(4, 6)
          const day = date.substring(6, 8)

          return new Date(year, month - 1, day, 0, 0, 0, 0)
        }
      }

      return value
    },
    output: (value) => {
      if (value && value instanceof Date) {
        const zeroPad = (num) => {
          if (num < 10) { return '0' + num.toString() }
          return num.toString()
        }

        return value.getFullYear().toString() + zeroPad(value.getMonth() + 1) + zeroPad(value.getDate())
      }

      return value
    },
  },
  timestamp: {
    input: (value) => {
      if (value && !(value instanceof Date)) { return new Date(value * 1000) }
      return value
    },
    output: (value) => {
      if (value && value instanceof Date) {
        return Math.round(value.getTime() / 1000)
      }
      return value
    },
  },
  minutes: {
    input: (value) => {
      if (value !== null && value !== undefined && !(value instanceof Date)) {
        return $date.minutes(value, false)
      }

      return value
    },
    output: (value) => {
      if (value !== null && value !== undefined && value instanceof Date) {
        return $date.getMinutes(value)
      }

      return value
    },
  },
}
