import Model, { Request } from '../model'

export default class Tax extends Model {
  static modelName () {
    return 'tax'
  }

  objectID () {
    return this.idTax
  }

  relations () {
    return {
    }
  }

  bulkSet (type, state) {
    const url = this.constructor.modelBaseURL() + '/bulkSet'

    const request = {
      idTax: this.idTax,
    }

    request[type] = state ? 'on' : 'off'

    return this.constructor.requestSuccess(Request.post(url, JSON.stringify(request)))
  }
}
