<template>
  <o-dropdown
    dropdown-class="bg-white dark:bg-gray-900 rounded-md shadow-md z-80000 overflow-hidden"
    :close-on-dropdown-click="false"
  >
    <text-field
      :value="displayValue"
      :disabled="disabled"
      icon="CalendarIcon"
    />

    <template #content>
      <div class="flex">
        <div class="p-2">
          <client-only>
            <v-date-picker
              v-model="range"
              :popover="{ visibility: (!disabled) ? 'click' : 'hidden' }"
              :show-popover="!disabled"
              :min-date="minDate"
              :columns="screens({ default: 1, lg: 2 })"
              :theme="theme"
              :first-day-of-week="firstDayOfWeek"
              is-inline
              mode="range"
              class="inline-block align-top"
              :is-dark="$colorMode.value === 'dark'"
              :locale="$i18n.locale"
              @input="change"
            />
          </client-only>
        </div>
        <div class="inline-block bg-gray-100 dark:bg-gray-800 p-3 align-top mt-3 md:mt-0 w-full md:w-auto">
          <text-field v-model="range.start" @click="range.start = null" />
          <text-field v-model="range.end" @click="range.end = null" />
          <!--<ul class="grid grid-cols-2 md:grid-cols-1">
            <li>
              <o-button :flat="selected != null" variant="dark" class="w-full mb-2 cursor-pointer">
                Custom
              </o-button>
            </li>
            <li v-for="(preset, index) in options" :key="index" @click="set(preset)">
              <o-button :flat="selected != preset" variant="dark" class="w-full mb-2 cursor-pointer">
                {{ presetOptions[preset].label }}
              </o-button>
            </li>
          </ul>-->
        </div>
      </div>
    </template>
  </o-dropdown>
</template>

<script>
import TextField from './Text'

import Augmenters from '@/mixins/fields/augmenters'
import ODropdown from '@/components/elements/dropdown/Dropdown'

export default {
  components: {
    ODropdown,
    TextField,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: null,
    },
    minDate: {
      type: Date,
      required: false,
      default: null,
    },
    augmenter: {
      type: Object,
      required: false,
      default: () => Augmenters.YMD,
    },
    options: {
      type: Array,
      required: false,
      default: null,
    },
  },
  data () {
    return {
      range: {
        start: null,
        end: null,
      },
      theme: {
        container:'vc-text-gray-900 vc-bg-white vc-rounded',
        dayContent:'vc-font-medium vc-text-sm vc-cursor-pointer focus:vc-font-bold rounded',
        highlightStartEndClass:'rounded',
        navPopoverContainer:'rounded-md vc-text-sm vc-font-semibold vc-text-white vc-bg-gray-800 vc-border vc-border-gray-700 vc-p-1 vc-shadow',
      },
      presetOptions: {
        allTime: {
          label: 'All time',
          value: 'allTime',
        },
        today: {
          label: this.$t('datetime.labels.today'),
          value: {
            start: new Date(),
            end: new Date(),
          },
        },
        oneWeek: {
          label: this.$t('components.fields.dateRange.oneWeek'),
          value: {
            start: this.$date.sub(new Date(), { days: 7 }),
            end: new Date(),
          },
        },
        twoWeeks: {
          label: this.$t('components.fields.dateRange.twoWeeks'),
          value: {
            start: this.$date.sub(new Date(), { days: 14 }),
            end: new Date(),
          },
        },
        threeWeeks: {
          label: this.$t('components.fields.dateRange.threeWeeks'),
          value: {
            start: this.$date.sub(new Date(), { days: 21 }),
            end: new Date(),
          },
        },
        thisMonth: {
          label: this.$t('components.fields.dateRange.thisMonth'),
          value: {
            start: this.$dateFns.startOfMonth(new Date()),
            end: this.$dateFns.endOfMonth(new Date()),
          },
        },
        lastMonth: {
          label: this.$t('components.fields.dateRange.lastMonth'),
          value: {
            start: this.$dateFns.startOfMonth(this.$date.sub(new Date(), { months: 1 })),
            end: this.$dateFns.endOfMonth(this.$date.sub(new Date(), { months: 1 })),
          },
        },
      },
    }
  },
  computed: {
    sanitizedValue () {
      if (this.date === '' || !this.date) { return null }

      if (this.augmenter && this.augmenter.input) {
        return `${this.augmenter.input(this.date.start)} to ${this.augmenter.input(this.date.end)}`
      } else {
        return this.date
      }
    },
    selected () {
      for (const [key, preset] of Object.entries(this.presetOptions)) {
        const value = this.mode === 'single' ? preset.value.end : preset.value
        if (value === this.date) {
          return key
        }
      }
      return null
    },
    displayValue () {
      if (this.selected) {
        return this.presetOptions[this.selected].label
      } else if (this.range.start && this.range.end) {
        return `${this.$date.format(this.range.start, this.$date.presets.short)} to ${this.$date.format(this.range.end, this.$date.presets.short)}`
      } else {
        return ''
      }
    },
    firstDayOfWeek () {
      const day = this.$auth?.info?.location?.startDayOfWeek?.value + 1

      return day === 8 ? 1 : day
    },
  },
  methods: {
    augment (value) {
      if (this.augmenter && this.augmenter.output) {
        return {
          start: this.augmenter.output(value.start),
          end: this.augmenter.output(value.end),
        }
      } else {
        return value
      }
    },
    change (value) {
      this.$emit('input', this.augment(value))
    },
    screens (args) {
      if (process.client) {
        return this.$screens(args)
      } else {
        return 2
      }
    },
  },
}
</script>
