import Model, { Request } from '../model'
import APIObject from '../object'
import Enum from '../enums'
import { DateYMD } from '../dates'

import { plugin as $date } from '../../plugins/date'

import Customer from './customer'
import CustomerPass from './customerPass'
import CustomField from './customField'
import Employee from './employee'
import EmployeeHour from './employeeHour'
import EmployeeBreak from './employeeBreak'
import OverviewBlock from './overviewBlock'
import Service, { ServiceCategorised, ServiceCustomerType } from './service'
import ServiceResource from './serviceResource'
import Reservation, { ReservationCustomerRequestType } from './reservation'
import ReservationCustomer from './reservationCustomer'
import Voucher from './voucher'
import Recurrence, { RecurrenceRequest } from './recurrence'

export default class Booking extends Model {
  static modelName () {
    return 'booking'
  }

  objectID () {
    return this.idBooking
  }

  relations () {
    return {
      date: { type: DateYMD },
      sections: { type: BookingSection },
    }
  }

  static day (date) {
    const url = this.modelBaseURL() + '/day?date=' + date
    return this.requestItem(Request.get(url), Booking)
  }

  static employee (employee, date) {
    const url = this.modelBaseURL() + '/employee?id=' + employee.idEmployee + '&date=' + date
    return this.requestItem(Request.get(url), Booking)
  }

  static reservations (dates, employees) {
    const url = this.modelBaseURL() + '/dates'
    const request = {
      dates,
      employees: employees.map((employee) => {
        return employee.idEmployee
      }),
    }

    return this.requestItem(Request.post(url, JSON.stringify(request)), BookingDates)
  }

  static timetable (dates, employees) {
    const url = this.modelBaseURL() + '/timetable'
    const request = {
      dates,
      employees: employees.map((employee) => {
        return employee.idEmployee
      }),
    }

    return this.requestItem(Request.post(url, JSON.stringify(request)), BookingDates)
  }

  static stats (dates, employees) {
    const url = this.modelBaseURL() + '/stats'
    const request = {
      dates,
      employees: employees.map((employee) => {
        return employee.idEmployee
      }),
    }

    return this.requestItem(Request.post(url, JSON.stringify(request)), BookingStats)
  }

  static availability (request) {
    const url = this.modelBaseURL() + '/availability'
    return this.requestItem(Request.post(url, request.toJSON()), AvailabilityResponse)
  }

  static confirm (request, { overrideTime = false, sendEmail = true }) {
    const url = this.modelBaseURL() + '/confirm?overrideTime=' + (overrideTime ? 1 : 0) + '&sendEmail=' + (sendEmail ? 1 : 0)
    return this.requestItem(Request.post(url, request.toJSON()), Reservation)
  }

  static confirmInfo (request) {
    const url = this.modelBaseURL() + '/confirmInfo'
    return this.requestItem(Request.post(url, request.toJSON()), BookingValidation)
  }

  static move (request, { overrideTime = false, sendEmail = true, resetReminder = true, future = false }) {
    const url = this.modelBaseURL() + '/move?future=' + (future ? 1 : 0) + '&overrideTime=' + (overrideTime ? 1 : 0) + '&sendEmail=' + (sendEmail ? 1 : 0) + '&resetReminder=' + (resetReminder ? 1 : 0)
    return this.requestItem(Request.post(url, request.toJSON()), Reservation)
  }

  static bookingDefaults () {
    const url = this.modelBaseURL() + '/bookingDefaults'
    return this.requestItem(Request.get(url), BookingDefaults)
  }

  static servicesEmployees (modifiedSince = null, customer = null) {
    let url = this.modelBaseURL()
    url += customer ? '/servicesEmployees?customer=' + customer.idCustomer : '/servicesEmployees'

    const headers = {}
    if (modifiedSince) {
      headers['If-Modified-Since'] = modifiedSince.toUTCString()
    }

    return this.requestItem(Request.get(url, headers), ServicesEmployees)
  }

  static services (modifiedSince = null) {
    const url = this.modelBaseURL() + '/services'

    const headers = {}
    if (modifiedSince) {
      headers['If-Modified-Since'] = modifiedSince.toUTCString()
    }

    return this.requestList(Request.get(url, headers), Service)
  }

  static recurrence (reservation, request) {
    const url = this.modelBaseURL() + '/recurrence?id=' + reservation.idReservation
    return this.requestItem(Request.post(url, request.toJSON()), Reservation)
  }

  static previewRecurrence (request) {
    const url = this.modelBaseURL() + '/previewRecurrence'
    return this.requestItem(Request.post(url, request.toJSON()), Recurrence)
  }

  static availableEmployees ({ employees = [], date, startTime, duration, processing, finishing, preBuffer, postBuffer, reservations = [], reservationCustomers = []  } = {}) {
    const url = this.modelBaseURL() + '/availableEmployees'
    const data = {
      employees: employees.map(employee => employee.idEmployee),
      date,
      startTime,
      duration,
      processing,
      finishing,
      preBuffer,
      postBuffer,
      excludeReservations: reservations.map(reservation => reservation.idReservation),
      excludeReservationCustomers: reservationCustomers.map(reservationCustomer => reservationCustomer.idReservationCustomer),
    }
    return this.requestList(Request.post(url, JSON.stringify(data)), Employee)
  }
}

export class BookingValidation extends APIObject {
  relations () {
    return {
      date: { type: DateYMD },
      services: { type: BookingValidationService },
    }
  }
}

export class BookingValidationService extends APIObject {
  relations () {
    return {
      services: { type: Service },
      passes: { type: CustomerPass },
      vouchers: { type: Voucher },
    }
  }
}

export class BookingSection extends APIObject {
  relations () {
    return {
      date: { type: DateYMD },
      employee: { type: Employee },
      hour: { type: EmployeeHour },
      breaks: { type: EmployeeBreak },
      blocks: { type: OverviewBlock },
    }
  }
}

export class BookingDates extends APIObject {
  relations () {
    return {
      dates: { type: BookingDatesInfo },
      services: { type: Service },
      employees: { type: Employee },
      breaks: { type: EmployeeBreak },
    }
  }

  fromJSON (object) {
    super.fromJSON(object)

    const dates = this.dates

    for (const dateInfo of dates) {
      if (dateInfo.rosters) {
        for (const roster of dateInfo.rosters) {
          roster.employee = this.employeeForID(roster.idEmployee)
        }
      }

      for (const reservation of dateInfo.reservations) {
        for (const reservationService of reservation.services) {
          reservationService.service = this.serviceForID(reservationService.idService)
          reservationService.employee = this.employeeForID(reservationService.idEmployee)

          for (const reservationServiceResource of reservationService.resources) {
            reservationServiceResource.resource = this.employeeForID(reservationServiceResource.idEmployee)
          }
        }

        if (reservation.attendees && reservation.attendees.resources) {
          for (const reservationCustomerResource of reservation.attendees.resources) {
            reservationCustomerResource.resource = this.employeeForID(reservationCustomerResource.idEmployee)
          }
        }
      }
    }
  }

  employeeForID (id) {
    return this.employees.find(employee => employee.idEmployee === parseInt(id))
  }

  serviceForID (id) {
    return this.services.find(service => service.idService === parseInt(id))
  }

  roster () {
    // TODO
  }
}

export class BookingDatesInfo extends APIObject {
  relations () {
    return {
      date: { type: DateYMD },
      reservations: { type: Reservation },
      rosters: { type: BookingDatesInfoRoster },
    }
  }
}

export class BookingDatesInfoRoster extends APIObject {
  relations () {
    return {
      employee: { type: Employee },
      hour: { type: EmployeeHour },
      breaks: { type: EmployeeBreak },
    }
  }
}

export class BookingStats extends APIObject {
  relations () {
    return {
      dates: { type: BookingStatsDateInfo },
    }
  }
}

export class BookingStatsDateInfo extends APIObject {
  relations () {
    return {
      date: { type: DateYMD },
      employees: { type: BookingStatsDateInfoEmployeeStat },
    }
  }
}

export class BookingStatsDateInfoEmployeeStat extends APIObject {
}

export const AvailabilityRequestDateMode = new Enum({
  all: { value: 0 },
  onePerDay: { value: 1 },
  onePerRequest: { value: 2 },
  none: { value: 3 },
})

export const AvailabilityRequestGroupMode = new Enum({
  reservation: { value: 0 },
  customer: { value: 1 },
})

export class AvailabilityRequest extends APIObject {
  constructor () {
    super()

    this.dateMode = AvailabilityRequestDateMode.all
    this.includeSlots = true
  }

  relations () {
    return {
      dates: { type: Date },
      dateMode: { type: AvailabilityRequestDateMode },
      services: { type: AvailabilityRequestService },
      excludeReservations: { type: Reservation },
      excludeReservationCustomers: { type: ReservationCustomer },
    }
  }

  toJSON () {
    const object = {
      dates: this.dates.map(date => parseInt(date.getTime() / 1000)),
      dateMode: (this.dateMode) ? this.dateMode.value : AvailabilityRequestDateMode.all.value,
      services: this.services.map(service => service.toJSON()),
      excludeReservations: this.excludeReservations.map(reservation => reservation.idReservation || reservation.id || 0),
      excludeReservationCustomers: this.excludeReservationCustomers.map(reservationCustomer => reservationCustomer.idReservationCustomer),
      includeSlots: this.includeSlots,
    }

    return object
  }

  static forReservation (reservation, dates, { includeEmployees = true, groupMode = AvailabilityRequestGroupMode.reservation, reservationCustomer } = {}) {
    const request = new AvailabilityRequest()
    const services = reservation.services ? reservation.services : [reservation]
    request.dates = dates
    request.excludeReservations = reservation ? [reservation] : []
    request.excludeReservationCustomers = reservationCustomer ? [reservationCustomer] : []

    request.services = []
    for (const reservationService of services) {
      const requestService = AvailabilityRequestService.forReservationService(reservationService, { includeEmployees, groupMode })

      request.services.push(requestService)
    }

    return request
  }
}

export class AvailabilityRequestService extends APIObject {
  relations () {
    return {
      employee: { type: Employee },
      service: { type: Service },
      resources: { type: AvailabilityRequestServiceResource },
    }
  }

  toJSON () {
    const compare = (key) => {
      const service = this.service
      const value = this[key]
      const base = service[key]

      return base !== value ? value : null
    }

    const object = {
      price: compare('price'),
      duration: compare('duration'),
      processing: compare('processing'),
      finishing: compare('finishing'),
      bufferTimePre: compare('bufferTimePre'),
      bufferTimePost: compare('bufferTimePost'),
      bufferTimeMode: compare('bufferTimeMode'),
      groupMode: this.groupMode.value,
    }

    if (this.service) {
      object.idService = this.service.idService
    }
    if (this.employee) {
      object.idEmployee = this.employee.idEmployee
    }

    if (this.resources) {
      object.resources = this.resources.map(resource => resource.toJSON())
    }

    return object
  }

  /*
    NOTE: should customerMax and allowInternet be included on availability services?
  */

  static forReservationService (reservationService, { includeEmployees = true, groupMode = AvailabilityRequestGroupMode.reservation }) {
    const requestService = new AvailabilityRequestService()
    if (includeEmployees) {
      requestService.employee = reservationService.employee
    }
    requestService.service = reservationService.service

    requestService.duration = reservationService.duration
    requestService.processing = reservationService.processing
    requestService.finishing = reservationService.finishing

    requestService.bufferTimePre = reservationService.bufferTimePre
    requestService.bufferTimePost = reservationService.bufferTimePost
    requestService.bufferTimeMode = (reservationService.bufferTimeMode) ? reservationService.bufferTimeMode.value : null

    requestService.groupMode = groupMode

    requestService.price = reservationService.price

    if (reservationService.resources != null && reservationService.resources.length > 0) {
      const resources = {}

      for (const reservationServiceResource of reservationService.resources) {
        const idServiceResource = reservationServiceResource.serviceResource.idServiceResource
        if (resources[idServiceResource]) {
          resources[idServiceResource].resources.push(reservationServiceResource.resource)
        } else {
          resources[idServiceResource] = new AvailabilityRequestServiceResource()
          resources[idServiceResource].serviceResource = reservationServiceResource.serviceResource
          resources[idServiceResource].resources = [reservationServiceResource.resource]
        }
      }

      requestService.resources = Object.values(resources)
    }

    return requestService
  }
}

export class AvailabilityRequestServiceResource extends APIObject {
  relations () {
    return {
      serviceResource: { type: ServiceResource },
      // resource: { type: Employee }
      resources: { type: Employee },
    }
  }

  toJSON () {
    const object = {
      // idServiceResource: this.serviceResource.idServiceResource,
      // idEmployee: this.resource?.idEmployee || this.resource
      serviceResource: this.serviceResource.idServiceResource,
      resources: this.resources?.map(resource => resource.idEmployee || resource),
    }

    return object
  }
}

export class AvailabilityResponse extends APIObject {
  relations () {
    return {
      dates: { type: AvailabilityDate },
    }
  }
}

class AvailabilityDate extends APIObject {
  relations () {
    return {
      date: { type: Date },
      slots: { type: AvailabilitySlot },
    }
  }
}

class AvailabilitySlot extends APIObject {
  relations () {
    return {
      date: { type: Date },
      services: { type: AvailabilitySlotService },
    }
  }

  get employeeLabel () {
    const services = this.services

    return services.map(service => service.employee.displayName).join(', ')
  }

  get hasResources () {
    const services = this.services

    return services.some(service => service.resources && service.resources.length)
  }
}

class AvailabilitySlotService extends APIObject {
  relations () {
    return {
      employee: { type: Employee },
      service: { type: Service },
      reservation: { type: Reservation },
      customerPass: { type: CustomerPass },
      voucher: { type: Voucher },
    }
  }
}

class AvailabilitySlotServiceResource extends APIObject {
  relations () {
    return {
      serviceResource: { type: ServiceResource },
      resources: { type: Employee },
    }
  }
}

class ResourceType extends APIObject {
}

export class ConfirmationRequest extends APIObject {
  constructor () {
    super()

    this.overrideTime = false
    this.sendEmail = true
  }

  relations () {
    return {
      date: { type: DateYMD },
      services: { type: ConfirmationRequestService },
      customFields: { type: CustomField },
      recurrence: { type: Recurrence },
      reservation: { type: Reservation },
      reservationCustomer: { type: ReservationCustomer },
      customer: { type: Customer },
      customerType: { type: ServiceCustomerType },
      customerRequestType: { type: ReservationCustomerRequestType },
    }
  }

  toJSON () {
    const object = {
      notes: this.notes,
      date: this.date.value,
      customerType: this.customerType.value,
      customerRequestType: this.customerRequestType.value,
      services: this.services.map(service => service.toJSON()),
      idCustomer: (this.customer) ? Number(this.customer.idCustomer) : null,
      idReservation: (this.reservation) ? this.reservation.idReservation : null,
      idReservationCustomer: (this.reservationCustomer) ? this.reservationCustomer.idReservationCustomer : null,
      recurrence: this.recurrence ? this.recurrence.toJSON() : null,
      customerRecurrence: this.customerRecurrence,
      allowInternet: this.allowInternet,
      customerMax: this.customerMax,
      idWaitlist: (this.waitlist) ? Number(this.waitlist.idWaitlist) : null,
      rebookIdReservation: (this.rebookReservation) ? Number(this.rebookReservation.idReservation) : null,
      rebookIdReservationCustomer: (this.rebookReservationCustomer) ? this.rebookReservationCustomer.idReservationCustomer : null,
    }

    if (this.customFields) {
      object.customFields = this.customFields.map((customField) => { return { idCustomField: customField.idCustomField, value: customField.value } })
    }

    return object
  }

  static fromReservation (reservation, {
    customFields = null,
    customer = null,
    reservationCustomer = null,
    customerType = ServiceCustomerType.single,
    customerRequestType = ReservationCustomerRequestType.none,
  } = {}) {
    const request = new ConfirmationRequest()
    request.reservation = reservation
    request.date = reservation.date
    request.allowInternet = reservation.allowInternet
    request.services = reservation.services.map(reservationService => ConfirmationRequestService.fromReservationService(reservation, reservationService))
    request.customFields = customFields
    request.customer = customer
    request.reservationCustomer = reservationCustomer
    request.customerType = customerType
    request.customerRequestType = customerRequestType

    return request
  }

  static fromGroupReservation (reservation, {
    customFields = null,
    customer = null,
    reservationCustomer = null,
    customerType = ServiceCustomerType.single,
    customerRequestType = ReservationCustomerRequestType.none,
    resources = null,
    customerRecurrence = null,
    notes = null,
  } = {}) {
    const request = new ConfirmationRequest()
    request.reservation = reservation
    request.date = reservation.date
    request.allowInternet = reservation.allowInternet
    request.services = reservation.services.map(reservationService => ConfirmationRequestService.fromGroupReservationService(reservation, reservationService, resources))
    request.customFields = customFields
    request.customer = customer
    request.reservationCustomer = reservationCustomer
    request.customerType = customerType
    request.customerRequestType = customerRequestType
    request.notes = notes

    if (customerRecurrence) {
      request.customerRecurrence = customerRecurrence
    }

    return request
  }

  static fromSlot (data, {
    customFields = null,
    customer = null,
    reservationCustomer = null,
    notes = null,
    move = null,
    customerType = ServiceCustomerType.single,
    customerRequestType = ReservationCustomerRequestType.none,
    overrideTime = false,
    allowInternet = true,
    customerMax = 0,
    sendEmail = true,
    recurrence = null,
    customerRecurrence = null,
    waitlist = null,
    rebookReservation = null,
    rebookReservationCustomer = null,
  } = {}) {
    const request = new ConfirmationRequest()
    request.date = DateYMD.fromDate(data.date)
    request.services = data.services.map(reservationService => ConfirmationRequestService.fromSlotService(reservationService))
    request.customFields = customFields
    request.customer = customer
    request.reservation = move
    request.reservationCustomer = reservationCustomer
    request.notes = notes
    request.customerType = customerType
    request.customerRequestType = customerRequestType
    request.overrideTime = overrideTime
    request.allowInternet = allowInternet
    request.customerMax = customerMax
    request.sendEmail = sendEmail
    request.waitlist = waitlist
    request.rebookReservation = rebookReservation
    request.rebookReservationCustomer = rebookReservationCustomer

    request.allowInternet = request.services[0].service.allowInternet

    if (recurrence && !move) {
      const recurrenceRequest = RecurrenceRequest.create({ recurrence })
      request.recurrence = recurrenceRequest.recurrence
    }

    if (customerRecurrence) {
      request.customerRecurrence = customerRecurrence
    }

    return request
  }
}

export class ConfirmationRequestService extends APIObject {
  relations () {
    return {
      employee: { type: Employee },
      service: { type: Service },
      reservation: { type: Reservation },
      resources: { type: ConfirmationRequestServiceResource },
      customerPass: { type: CustomerPass },
      voucher: { type: Voucher },
    }
  }

  toJSON () {
    const object = {
      slot: this.slot,
      startTime: this.startTime,
      duration: this.duration,
      processing: this.processing,
      finishing: this.finishing,
      price: this.price,
      bufferTimePre: this.bufferTimePre,
      bufferTimePost: this.bufferTimePost,
      bufferTimeMode: this.bufferTimeMode,
    }

    if (this.service) {
      object.idService = this.service.idService
    }
    if (this.employee) {
      object.idEmployee = this.employee.idEmployee
    }
    if (this.reservation) {
      object.idReservation = this.reservation.idReservation
    }
    if (this.resources) {
      object.resources = this.resources
        .filter(resource => resource?.resource)
        .map(resource => resource.toJSON())
    }
    if (this.customerPass?.idCustomerPass) {
      object.idCustomerPass = this.customerPass.idCustomerPass
    }
    if (this.voucher?.idVoucher) {
      object.idVoucher = this.voucher.idVoucher
    }
    if (this.groupMode) {
      object.groupMode = this.groupMode.value
    }

    return object
  }

  static fromSlotService (availabilitySlotService) {
    const requestService = new ConfirmationRequestService()
    requestService.employee = availabilitySlotService.employee
    requestService.service = availabilitySlotService.service

    requestService.reservation = availabilitySlotService.reservation

    requestService.startTime = availabilitySlotService.startTime
    requestService.slot = availabilitySlotService.slot

    requestService.duration = availabilitySlotService.duration
    requestService.processing = availabilitySlotService.processing
    requestService.finishing = availabilitySlotService.finishing

    requestService.bufferTimePre = availabilitySlotService.bufferTimePre
    requestService.bufferTimePost = availabilitySlotService.bufferTimePost
    requestService.bufferTimeMode = availabilitySlotService.bufferTimeMode

    requestService.price = availabilitySlotService.price

    requestService.customerPass = availabilitySlotService.customerPass
    requestService.voucher = availabilitySlotService.voucher

    if (availabilitySlotService.resources != null && availabilitySlotService.resources.length > 0) {
      requestService.resources = []

      for (const availabilitySlotServiceResource of availabilitySlotService.resources) {
        if (availabilitySlotServiceResource.resources) {
          for (const availabilitySlotServiceResourceEmployee of availabilitySlotServiceResource.resources) {
            const requestServiceResource = new ConfirmationRequestServiceResource()
            requestServiceResource.serviceResource = availabilitySlotServiceResource.serviceResource
            requestServiceResource.resource = availabilitySlotServiceResourceEmployee

            requestService.resources.push(requestServiceResource)
          }
        }  if (availabilitySlotServiceResource.resource) {
          const requestServiceResource = new ConfirmationRequestServiceResource()
          requestServiceResource.serviceResource = availabilitySlotServiceResource.serviceResource
          requestServiceResource.resource = availabilitySlotServiceResource.resource

          requestService.resources.push(requestServiceResource)
        }
      }
    }

    return requestService
  }

  static fromReservationService (reservation, reservationService) {
    const requestService = new ConfirmationRequestService()
    requestService.employee = reservationService.employee
    requestService.service = reservationService.service

    // requestService.reservation = reservation

    requestService.startTime = reservationService.startTime

    requestService.duration = reservationService.duration
    requestService.processing = reservationService.processing
    requestService.finishing = reservationService.finishing

    requestService.bufferTimePre = reservationService.bufferTimePre
    requestService.bufferTimePost = reservationService.bufferTimePost
    requestService.bufferTimeMode = (reservationService.bufferTimeMode) ? reservationService.bufferTimeMode.value : null

    requestService.price = reservationService.price

    const date = $date.parse(reservation.date.value)
    const minutes = reservationService.startTime

    const utc = $date.getStartOfDayUTC(date)
    const total = $date.add(utc, { minutes })
    const slot = $date.timestamp(total)

    requestService.slot = slot

    if (reservationService.resources != null && reservationService.resources.length > 0) {
      requestService.resources = []

      for (const reservationServiceResource of reservationService.resources) {
        if (reservationServiceResource.resources) {
          for (const reservationServiceResourceEmployee of reservationServiceResource.resources) {
            const requestServiceResource = new ConfirmationRequestServiceResource()
            requestServiceResource.serviceResource = reservationServiceResource.serviceResource
            requestServiceResource.resource = reservationServiceResourceEmployee

            requestService.resources.push(requestServiceResource)
          }
        }  if (reservationServiceResource.resource) {
          const requestServiceResource = new ConfirmationRequestServiceResource()
          requestServiceResource.serviceResource = reservationServiceResource.serviceResource
          requestServiceResource.resource = reservationServiceResource.resource

          requestService.resources.push(requestServiceResource)
        }
      }
    }

    return requestService
  }

  static fromGroupReservationService (reservation, reservationService, resources) {
    const requestService = new ConfirmationRequestService()
    requestService.employee = reservationService.employee
    requestService.service = reservationService.service

    requestService.reservation = reservation

    requestService.startTime = reservationService.startTime

    requestService.duration = reservationService.duration
    requestService.processing = reservationService.processing
    requestService.finishing = reservationService.finishing

    requestService.bufferTimePre = reservationService.bufferTimePre
    requestService.bufferTimePost = reservationService.bufferTimePost
    requestService.bufferTimeMode = (reservationService.bufferTimeMode) ? reservationService.bufferTimeMode.value : null

    requestService.price = reservationService.price

    requestService.groupMode = ConfirmationRequestGroupMode.customer

    const date = $date.parse(reservation.date.value)
    const minutes = reservationService.startTime

    const utc = $date.getStartOfDayUTC(date)
    const total = $date.add(utc, { minutes })
    const slot = $date.timestamp(total)

    requestService.slot = slot

    if (resources != null && resources.length > 0) {
      requestService.resources = []

      for (const reservationServiceResource of resources) {
        const requestServiceResource = new ConfirmationRequestServiceResource()
        requestServiceResource.serviceResource = reservationServiceResource.serviceResource
        requestServiceResource.resource = reservationServiceResource.resource || 0

        requestService.resources.push(requestServiceResource)
      }
    }

    return requestService
  }
}

export class ConfirmationRequestServiceResource extends APIObject {
  relations () {
    return {
      serviceResource: { type: ServiceResource },
      resource: { type: Employee },
    }
  }

  toJSON () {
    const object = {
      idServiceResource: this.serviceResource.idServiceResource,
      idEmployee: this.resource?.idEmployee || this.resource || null,
    }

    return object
  }
}

class BookingDefaults extends APIObject {
  relations () {
    return {
      reservationCustomFields: { type: CustomField },
      reservationCustomerCustomFields: { type: CustomField },
    }
  }
}

class ServicesEmployees extends APIObject {
  relations () {
    return {
      services: { type: ServiceCategorised },
      employees: { type: Employee },
      recents: { type: Reservation },
    }
  }

  get employeeForID () {
    // TODO
  }

  get serviceForID () {
    // TODO
  }
}

export const ConfirmationRequestGroupMode = new Enum({
  reservation: { value: 0 },
  customer: { value: 1 },
})

export const BookingRequestType = new Enum({
  serviceType: { value: 0 },
  classType: { value: 1 },
  timetableType: { value: 2 },
})


