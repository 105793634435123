<template>
  <container v-bind="container">
    <div class="h-full w-full flex items-center px-1">
      <v-date-picker
        v-model="currentValue"
        mode="time"
        :is24hr="is24hr"
        :popover="{
          placement: 'bottom-start',
          visibility: 'click'
        }"
        :locale="$i18n.locale"
        v-on="handlers"
      >
        <template #default="{ inputValue, inputEvents, togglePopover }">
          <slot v-bind="{ inputValue, inputEvents, togglePopover }">
            <input
              type="text"
              :value="inputValue"
              v-bind="attributes"
              class="h-full w-full bg-transparent"
              v-on="inputEvents"
              @click="togglePopover"
            >
          </slot>
        </template>
      </v-date-picker>
    </div>
  </container>
</template>

<script>
import Container from './Container'

import classes from '@/mixins/fields/classes.js'
import input from '@/mixins/fields/input.js'
import augmenters from '@/mixins/fields/augmenters'

import * as API from '~/api'

export default {
  components: {
    Container,
  },
  mixins: [classes, input],
  props: {
    value: {
      type: [String, Date, Number],
      default: null,
    },
    augmenter: {
      type: [Object, Boolean],
      required: false,
      default: () => augmenters.YMD,
    },
    defaultSizeClass: {
      type: [String, Object, Array],
      default: 'py-2px px-2px text-sm',
    },
    largeSizeClass: {
      type: [String, Object, Array],
      default: 'px-4 text-lg',
    },
    smallSizeClass: {
      type: [String, Object, Array],
      default: 'px-2 text-sm',
    },
    border: {
      type: [String, Object, Array],
      default: 'border',
    },
  },
  data () {
    return {
      date: null,
    }
  },
  computed: {
    currentValue: {
      get () {
        if (this.value === '') { return null }

        if (this.augmenter && this.augmenter.input) {
          return this.augmenter.input(this.value)
        } else {
          return this.value
        }
      },
      set (value) {
        this.$emit('input', this.augment(value))
        this.$emit('change')
      },
    },
    is24hr () {
      const format = this.$auth?.info?.location?.timeFormat

      return format && format === API.TimeFormat['24']
    },
  },
  methods: {
    augment (value) {
      if (this.augmenter && this.augmenter.output) {
        return this.augmenter.output(value)
      } else {
        return value
      }
    },
  },
}
</script>

<style lang="scss">

.vc-container {

  .vc-time-picker {

    .vc-date-time {
      margin-left: 0px !important;

      .vc-date {
        display: none !important;
      }
    }

    .vc-am-pm button {
      font-size: 0.7rem !important;
    }
  }

  .vc-select select {
    font-family: system-ui !important;
    font-size: 0.8rem !important;
  }

  .vc-time-icon {
    display: none !important
  }
}
/* .vc-container {
  width: 100%;
  border: none !important;

  .vc-time-picker {
    padding: 0px !important;

    .vc-date-time {
      margin-left: 0px !important;

      .vc-date {
        display: none !important;
      }
    }

    .vc-am-pm button {
      font-size: 0.7rem !important;
    }
  }

  .vc-select select {
    font-family: system-ui !important;
    font-size: 0.8rem !important;
  }

  .vc-time-icon {
    display: none !important
  }
} */
</style>
