import { DateYMD, TimeMinute } from './dates'
import APIObject from './object'
import Enum from './enums'

export default class Boxer {
  static unbox (value, type) {
    if (Array.isArray(value)) {
      const models = []

      for (let i = 0; i < value.length; i++) {
        models.push(Boxer.unbox(value[i], type))
      }

      return models
    }

    if (type) {
      if (type === Date) {
        return new Date(value * 1000)
      } else if (type instanceof Enum) {
        return type.symbolForValue(value)
      } else if (type === DateYMD) {
        return DateYMD.fromInteger(value)
      } else if (type === TimeMinute) {
        return TimeMinute.fromInteger(value)
      } else if (type.prototype instanceof APIObject) {
        return (value !== null) ? new type(value) : null
      }
    }

    return value
  }

  static box (value, type, isRequest = false) {
    if (Array.isArray(value)) {
      const models = []

      for (let i = 0; i < value.length; i++) {
        models.push(Boxer.box(value[i], type, isRequest))
      }

      return models
    }

    try {
      if (type) {
        if (type === Date) {
          return (value.getTime() / 1000)
        } else if (type instanceof Enum) {
          return value ? value.value : null
        } else if (type === DateYMD) {
          return value.integer
        } else if (type === TimeMinute) {
          return value.integer
        } else if (type.prototype instanceof APIObject) {
          if (value !== null && typeof value === 'object') {
            if (isRequest) {
              return value.requestJSON()
            } else {
              return value.toJSON()
            }
          } else {
            return null
          }
        }
      }
    } catch (error) {
      console.log({
        type,
        error,
        value,
      })
    }

    return value
  }
}
