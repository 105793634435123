import APIObject from '../object'
import Model from '../model'
import Enum from '../enums'

export default class FormItem extends APIObject {
  objectID () {
    return this.idFormItem
  }

  relations () {
    return {
      type: { type: FormItemType },
      dataType: { type: FormItemDataType },
      options: { type: FormItemOptions },
    }
  }

  get isTypeSection () {
    return this.type === FormItemType.section
  }

  get isTypeParagraph () {
    return this.type === FormItemType.paragraph
  }

  get isTypeField () {
    return this.type === FormItemType.field
  }

  get isDataTypeText () {
    return this.dataType === FormItemDataType.text
  }

  get isDataTypeLongText () {
    return this.dataType === FormItemDataType.longtext
  }

  get isDataTypeMultiple () {
    return this.dataType === FormItemDataType.multiple
  }

  get isDataTypeBool () {
    return this.dataType === FormItemDataType.bool
  }

  get isDataTypeNumber () {
    return this.dataType === FormItemDataType.number
  }

  get isDataTypeDate () {
    return this.dataType === FormItemDataType.date
  }

  get isDataTypeSignature () {
    return this.dataType === FormItemDataType.signature
  }

  get isDataTypeAnnotation () {
    return this.dataType === FormItemDataType.annotation
  }

  get isDataTypeMultiSelect () {
    return this.dataType === FormItemDataType.multiSelect
  }

  get isDisplayList () {
    return this.options?.display === FormItemDisplay.list
  }

  get isDisplayRadio () {
    return this.options?.display === FormItemDisplay.radio
  }
}

export class FormItemOptions extends Model {
  relations () {
    return {
      display: { type: FormItemDisplay },
    }
  }

  get isDisplayList () {
    return this.display === FormItemDisplay.list
  }

  get isDisplayRadio () {
    return this.display === FormItemDisplay.radio
  }
}

export const FormItemDisplay = new Enum({
  list: { value: 'list', description: 'form.dropdown' },
  radio: { value: 'radio', description: 'form.radio' },
})

export const FormItemType = new Enum({
  section: { value: 0, description: 'form.section' },
  paragraph: { value: 1, description: 'general.paragraph' },
  field: { value: 2, description: 'form.field' },
})

export const FormItemDataType = new Enum({
  text: { value: 0, description: 'field.text' },
  longtext: { value: 1, description: 'field.longText' },
  multiple: { value: 2, description: 'field.multiple' },
  multiSelect: { value: 8, description: 'field.multiSelect' },
  bool: { value: 3, description: 'field.checkbox' },
  yesNo: { value: 7, description: 'field.yesNo' },
  number: { value: 4, description: 'field.number' },
  date: { value: 5, description: 'field.date' },
  signature: { value: 6, description: 'field.signature' },
  annotation: { value: 9, description: 'field.annotation' },
})
