import Model, { Request } from '../model'
import Enum from '../enums'

export default class Referral extends Model {
  static modelName () {
    return 'referral'
  }

  objectID () {
    return this.idReferral
  }

  relations () {
    return {
      status: { type: ReferralStatus },
    }
  }

  static sendReferral (referrals) {
    const url = this.modelBaseURL() + '/send'
    const data = {
      referrals,
    }

    return this.requestSuccess(Request.post(url, JSON.stringify(data)))
  }

  get isStatusRequested () {
    return this.status === ReferralStatus.requested
  }

  get isStatusTrial () {
    return this.status === ReferralStatus.trial
  }

  get isStatusSignedUp () {
    return this.status === ReferralStatus.signedUp
  }

  get isStatusValidated () {
    return this.status === ReferralStatus.validated
  }

  get isStatusCancelled () {
    return this.status === ReferralStatus.cancelled
  }

  get isStatusPaid () {
    return this.status === ReferralStatus.paid
  }

  get isStatusInvalid () {
    return this.status === ReferralStatus.invalid
  }

  get isStatusPending () {
    return this.status === ReferralStatus.pending
  }
}

export const ReferralStatus = new Enum({
  requested: { value: 0, description: 'api.models.referral.invited' },
  trial: { value: 1, description: 'general.trial' },
  signedUp: { value: 2, description: 'api.models.referral.signedUp' },
  paid: { value: 3, description: 'api.models.referral.paid' },
  validated: { value: 4, description: 'api.models.referral.validated' },
  cancelled: { value: 5, description: 'general.cancelled' },
  invalid: { value: 6, description: 'api.models.referral.invalid' },
  pending: { value: 7, description: 'api.models.referral.pending' },
})
