import Model, { Request } from '../model'
import APIObject from '../object'
import Enum from '../enums'

export default class MinisiteMenu extends Model {
  static modelName () {
    return 'minisiteMenu'
  }

  objectID () {
    return null
  }

  relations () {
    return {
      menuItems: { type: MinisiteMenuCategory },
      options: { type: MinisiteMenuOptions },
    }
  }

  static modelBaseURL () {
    return this.apiBaseURL() + '/v' + this.modelApiVersion() + '/settings/' + this.modelName()
  }

  save () {
    const url = this.constructor.modelBaseURL()
    const request = Request.jsonPost(url, this.requestJSON())

    const that = this
    return this.constructor.requestItem(request, this.constructor)
      .catch((err) => {
        throw err
      }).then((item) => {
        that.saturated = new Date()

        for (const key in item) {
          that[key] = item[key]
        }

        return that
      })
  }
}


export class MinisiteMenuCategory extends APIObject {
  relations () {
    return {
      primary: { type: MinisiteMenuItem },
      secondary: { type: MinisiteMenuItem },
    }
  }
}

export class MinisiteMenuItem extends APIObject {
  relations () {
    return {
      type: { type: MinisiteMenuItemType },
      children: { type: MinisiteMenuItem },
    }
  }
}

export class MinisiteMenuOptions extends APIObject {
  relations () {
    return {
      customPages: { type: MinisiteMenuOption },
      tabs: { type: MinisiteMenuOption },

    }
  }
}

export class MinisiteMenuOption extends APIObject {
  relations () {
    return {
      type: { type: MinisiteMenuItemType },
    }
  }
}

export const MinisiteMenuItemType = new Enum({
  group: { value: 'group', label: 'general.group' },
  custom: { value: 'custom', label: 'minisite.customPage' },
  tab: { value: 'tab', label: 'general.page' },
})
