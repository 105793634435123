import Filter from './filter'

export default class RemoteFilter extends Filter {
  constructor (type, config) {
    super(config)
    this.type = type
    this.isLoading = false
  }

  request () {
    return {
      filter: this.type,
      params: this.params,
    }
  }

  get params () {
    if (this.config && this.config.getParams) {
      return this.config.getParams.apply(this)
    } else {
      return null
    }
  }

  set params (newParams) {
    if (this.config && this.config.setParams) {
      return this.config.setParams.apply(this, [newParams])
    } else {
      return null
    }
  }
}
