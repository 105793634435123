import APIObject from '../object'
import Enum from '../enums'

import Customer from './customer'
import Order from './order'

export default class ProductInventoryTransaction extends APIObject {
  relations () {
    return {
      customer: { type: Customer },
      order: { type: Order },
      type: { type: TransactionType },
    }
  }

  static addTypes () {
    const { refund, addStock, adjustment, other } = TransactionType
    return [
      refund,
      addStock,
      adjustment,
      other,
    ]
  }

  static removeTypes () {
    const { sale, returnStock, adjustment, writeoff, damaged, outOfDate, internal, other } = TransactionType
    return [
      sale,
      returnStock,
      adjustment,
      writeoff,
      damaged,
      outOfDate,
      internal,
      other,
    ]
  }
}

export const TransactionType = new Enum({
  sale: { value: 0, description: 'general.sale' },
  refund: { value: 1, description: 'general.refund' },
  addStock: { value: 2, description: 'product.addStock' },
  returnStock: { value: 3, description: 'product.returnStock' },
  adjustment: { value: 4, description: 'product.adjustment' },
  writeoff: { value: 5, description: 'product.writtenOff' },
  damaged: { value: 6, description: 'product.damaged' },
  outOfDate: { value: 7, description: 'product.outOfDate' },
  other: { value: 8, description: 'general.other' },
  internal: { value: 9, description: 'product.internalUse' },
  undefined: { value: 10, description: 'product.undefined' }, // NOTE: I was getting some 10s through for some reason
})

export const TransactionAction = new Enum({
  add: { value: 0, description: 'general.add' },
  remove: { value: 1, description: 'general.remove' },
})
