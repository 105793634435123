import Cookies from 'js-cookie'
import cookie from 'cookie'

export default (context, inject) => {
  const plugin = {
    get isApp () {
      const layout = process.server && context.req.headers.cookie
        ? cookie.parse(context.req.headers.cookie)['ovatu.layout']
        : Cookies.get('ovatu.layout')

      return layout === 'app'
    },
  }

  inject('app', plugin)
}
