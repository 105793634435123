var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-px"},[_c('o-panel-link',{staticClass:"w-full flex flex-row flex-grow items-center h-8 rounded-lg relative hover:bg-white/10 transition-colors",class:[
      _vm.active ? `bg-white/10 dark:bg-primary-${_vm.$auth.theme.dark.shade} dark:text-white/80` : '',
      _vm.collapse ? 'px-2 text-white/90 dark:text-white/90' : 'px-3 text-white/70 dark:text-white/70',
    ],attrs:{"path":"/alerts"}},[_c('div',{staticClass:"w-full flex items-center"},[(!_vm.collapse && _vm.active)?_c('span',{staticClass:"block h-full w-3 left-0 absolute p-1"},[_c('span',{staticClass:"block w-full h-full bg-primary rounded-full"})]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"flex items-center justify-center text-lg",class:[
          _vm.collapse ? '-ml-px' : '',
          _vm.active ? '' : ''
        ]},[_c('o-icon',{attrs:{"icon":"menuAlerts","size":18}})],1),_vm._v(" "),_c('span',{staticClass:"ml-4 text-base",class:[ _vm.collapse? 'sm:hidden' : '']},[_vm._v(_vm._s(_vm.$t('general.alerts')))])]),_vm._v(" "),_c('div',{staticClass:"flex items-center space-x-1",class:[
        _vm.collapse ? 'absolute top-0 right-0' : '',
      ]},[(_vm.counts && _vm.counts.unread)?_c('div',{staticClass:"-mr-1 px-2 py-1 bg-primary-600 text-white rounded-lg text-xs"},[_vm._v("\n        "+_vm._s(Number(_vm.counts.unread))+"\n      ")]):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }