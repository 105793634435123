import APIObject from '../object'
import Enum from '../enums'

import Employee from './employee'
import { ReservationStatus } from './reservation'
import Images from './images'

export default class OrderItem extends APIObject {
  objectID () {
    return this.idOrderItem
  }

  relations () {
    return {
      amountType: { type: ItemAmountType },
      customerPayment: { type: OrderItemCustomerPayment },
      employee: { type: Employee },
      paymentType: { type: ItemPaymentType },
      images: { type: Images },
      refundType: { type: ItemRefundType },
      type: { type: ItemType },
      discountType: { type: ItemDiscountType },
      discountTaxType: { type: ItemDiscountTaxType },
      status: { type: ReservationStatus },
    }
  }

  charge () {
    this.type = ItemType.charge
    this.amountType = ItemAmountType.fixed
    this.price = 0
    this.title = ''
    this.employee = null
    this.loyaltyEnabled = false
    this.taxIDs = []

    return this
  }

  discount () {
    this.type = ItemType.discount
    this.amountType = ItemAmountType.fixed
    this.discountTaxType = ItemDiscountTaxType.post
    this.price = 0
    this.title = ''

    return this
  }

  payment ({ itemName, price, tip, type, giftCard, customerPayment, title, idPaymentModel } = {}) {
    this.type = ItemType.payment
    this.itemName = itemName
    this.price = price
    this.tip = tip
    this.paymentType = type
    this.giftCard = giftCard
    this.customerPayment = customerPayment
    this.title = title
    this.idPaymentModel = idPaymentModel

    return this
  }

  get editAllow () {
    const type = this.type
    const { reservation, reservationCustomer, reservationService, service, product, charge, customerCredit, giftCard, customerPass, discount } = ItemType

    return (
      type === reservation ||
      type === reservationCustomer ||
      type === reservationService ||
      type === service ||
      type === product ||
      type === customerCredit ||
      type === giftCard ||
      type === customerPass ||
      type === discount ||
      type === charge
    )
  }

  get refundAllow () {
    const type = this.type
    const { payment } = ItemType

    return (
      type === payment &&
      this.canRefund
    )
  }

  get deleteAllow () {
    const type = this.type
    const { reservation, reservationCustomer, reservationService, service, product, charge, customerCredit, giftCard, customerPass, discount } = ItemType

    return (
      type === reservation ||
      type === reservationCustomer ||
      type === reservationService ||
      type === service ||
      type === product ||
      type === customerCredit ||
      type === giftCard ||
      type === customerPass ||
      type === discount ||
      type === charge
    )
  }

  get editShowQuantity () {
    const type = this.type
    const { product, charge } = ItemType

    return (
      type === product ||
      type === charge
    )
  }

  get editShowAmountType () {
    const type = this.type
    const { discount, charge } = ItemType

    return (
      type === discount ||
      type === charge
    )
  }

  get editShowLoyalty () {
    const type = this.type
    const { reservation, reservationCustomer, reservationService, service, product, charge } = ItemType

    return (
      type === reservation ||
      type === reservationCustomer ||
      type === reservationService ||
      type === service ||
      type === product ||
      type === charge
    )
  }

  get editShowDiscount () {
    const type = this.type
    const { reservation, reservationCustomer, reservationService, service, product, charge, customerPass, giftCard  } = ItemType

    return (
      type === reservation ||
      type === reservationCustomer ||
      type === reservationService ||
      type === service ||
      type === product ||
      type === charge ||
      type === customerPass ||
      type === giftCard
    )
  }

  get editShowTaxes () {
    const type = this.type
    const { reservation, reservationCustomer, reservationService, service, product, charge, customerPass } = ItemType

    return (
      type === reservation ||
      type === reservationCustomer ||
      type === reservationService ||
      type === service ||
      type === product ||
      type === charge ||
      type === customerPass
    )
  }

  get editShowEmployee () {
    const type = this.type
    const { service, product, charge, customerCredit, giftCard, customerPass } = ItemType

    return (
      type === customerCredit ||
      type === giftCard ||
      type === customerPass ||
      type === service ||
      type === product ||
      type === charge
    )
  }

  get discountAmount () {
    const price = this.isDiscountTaxTypePost ? this.priceWithIncludedTaxes : this.price
    const discount = this.discount

    if (discount) {
      if (this.isDiscountTypeFixed) {
        return discount
      }
      if (this.isDiscountTypePercentage) {
        return price * (discount / 100)
      }
    }

    return 0
  }

  get infoLabel () {
    switch (this.type) {
      case ItemType.customerPass:
        return '#' + this.idCustomerPass
      default:
        return null
    }
  }

  get discountedPrice () {
    return this.priceWithIncludedTaxes - this.discountAmount
  }

  get image () {
    if (this.hasImage) {
      return this.images.square
    }

    return null
  }

  get isProduct () {
    return this.type === ItemType.product
  }

  get isCustomerCredit () {
    return this.type === ItemType.customerCredit
  }

  get isGiftCard () {
    return this.type === ItemType.giftCard
  }

  get isCustomerPass () {
    return this.type === ItemType.customerPass
  }

  get isCharge () {
    return this.type === ItemType.charge
  }

  get isDiscountTypeFixed () {
    return this.discountType === ItemDiscountType.fixed
  }

  get isDiscountTypePercentage () {
    return this.discountType === ItemDiscountType.percentage
  }

  get isAmountTypeFixed () {
    return this.amountType === ItemAmountType.fixed
  }

  get isAmountTypePercentage () {
    return this.amountType === ItemAmountType.percentage
  }

  get isDiscountTaxTypePre () {
    return this.discountTaxType === ItemDiscountTaxType.pre
  }

  get isDiscountTaxTypePost () {
    return this.discountTaxType === ItemDiscountTaxType.post
  }

  get isRefund () {
    return this.refund
  }

  get isRefunded () {
    return this.refundedAmount > 0
  }

  get isReservationStatusConfirmed () {
    return this.status === ReservationStatus.confirmed
  }

  get isReservationStatusUnconfirmed () {
    return this.status === ReservationStatus.unconfirmed
  }

  get isReservationStatusCancelled () {
    return this.status === ReservationStatus.cancelled
  }
}

export class OrderItemCharge extends APIObject {
  relations () {
    return {
      amountType: { type: ItemAmountType },
    }
  }
}

export class OrderItemDiscount extends APIObject {
  relations () {
    return {
      amountType: { type: ItemAmountType },
    }
  }
}

export class OrderItemPayment extends APIObject {
  relations () {
    return {
      amountType: { type: ItemAmountType },
      customerPayment: { type: OrderItemCustomerPayment },
    }
  }
}

/* export class OrderItemPaymentType extends APIObject {
} */

export class OrderItemCustomerPayment extends APIObject {
  relations () {
    return {
      processor: { type: ItemCustomerPaymentProcessor },
    }
  }

  get isPaymentProcessorSquare () {
    return this.processor === ItemCustomerPaymentProcessor.square || this.integrationGuid === 'b0bca8b297674792a6f4489d272b8230' // 29bdebaccd9e452fac2be8963d3daba7'
  }

  get isPaymentProcessorStripe () {
    return this.processor === ItemCustomerPaymentProcessor.stripe || this.integrationGuid === '29bdebaccd9e452fac2be8963d3daba7'
  }

  get isPaymentProcessorSumUp () {
    return this.integrationGuid === '8fc197200f9644808e5d30468e92959b'
  }

  get isPaymentProcessorZettle () {
    return this.integrationGuid === '0d5a2db31a7a423aa29bd915c4eb4c03'
  }
}

export const ItemType = new Enum({
  reservation: { value: 0 },
  reservationCustomer: { value: 1 },
  reservationService: { value: 6 },
  service: { value: 9 },
  product: { value: 2 },
  charge: { value: 3 },
  discount: { value: 4 },
  payment: { value: 5 },
  customerCredit: { value: 7 },
  giftCard: { value: 8 },
  customerPass: { value: 10 },
})

export const ItemPaymentType = new Enum({
  custom: { value: 0, description: 'general.custom' },
  cash: { value: 1, description: 'order.cash' },
  customerCredit: { value: 2, description: 'order.customerCredit' },
  giftCard: { value: 3, description: 'general.giftCard' },
  loyaltyPoints: { value: 4, description: 'general.loyaltyPoints' },
  customerPayment: { value: 5, description: 'integration.label' },
})

export const ItemAmountType = new Enum({
  fixed: { value: 0 },
  percentage: { value: 1 },
})

export const ItemDiscountType = new Enum({
  fixed: { value: 0 },
  percentage: { value: 1 },
})

export const ItemRefundType = new Enum({
  none: { value: 0 },
  noInteraction: { value: 1 },
  serverHeadless: { value: 2 },
  serverWithUIInteration: { value: 3 },
  mobileNativeInteraction: { value: 4 },
})

export const ItemCustomerPaymentProcessor = new Enum({
  paypal: { value: 0 },
  stripe: { value: 1 },
  izettle: { value: 2 },
  paypalHere: { value: 3 },
  square: { value: 5 },
  sumup: { value: 6 },
})

export const ItemDiscountTaxType = new Enum({
  post: { value: 0, description: 'general.post' },
  pre: { value: 1, description: 'general.pre' },
})
