import Model from '../model'
import Enum from '../enums'

export default class CustomerNote extends Model {
  static modelName () {
    return 'customerNote'
  }

  static modelLabel () {
    return window.$nuxt.$t('api.models.customerNote.label')
  }

  objectID () {
    return this.idCustomerNote
  }

  relations () {
    return {
      type: { type: CustomerNoteType },
    }
  }

  get isTypeNote () {
    return this.type === CustomerNoteType.note
  }

  get isTypeForumla () {
    return this.type === CustomerNoteType.forumla
  }

  get isTypeAlert () {
    return this.type === CustomerNoteType.alert
  }
}

export const CustomerNoteType = new Enum({
  note: { value: 0, description: 'general.note' },
  formula: { value: 1, description: 'api.models.customerNote.formula' },
  alert: { value: 2, description: 'api.models.customerNote.alert' },
})
