import Model, { Request } from '../model'
import APIObject from '../object'
import Enum from '../enums'

export default class EmailTemplate extends Model {
  static modelName () {
    return 'emailTemplate'
  }

  objectID () {
    return this.idLocationEmailTemplate
  }

  relations () {
    return {

    }
  }

  static templates () {
    const url = this.modelBaseURL() + '/templates'
    return this.requestItem(Request.get(url), Templates)
  }

  enable () {
    const url = this.constructor.modelBaseURL() + '/' + this.objectID()

    const data = {
      enabled: true,
    }

    return this.constructor.requestItem(Request.put(url, JSON.stringify(data)), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  disable () {
    const url = this.constructor.modelBaseURL() + '/' + this.objectID()

    const data = {
      enabled: false,
    }

    return this.constructor.requestItem(Request.put(url, JSON.stringify(data)), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  sendPreview () {
    const url = this.constructor.modelBaseURL() + '/' + this.objectID() + '/preview'
    const { type, subject, htmlBody } = this
    const data = {
      type,
      subject,
      htmlBody,
    }

    return this.constructor.requestSuccess(Request.post(url, JSON.stringify(data)))
  }
}

export class Templates extends APIObject {
  relations () {
    return {
      masterTemplates: { type: MasterTemplate },
      emailTypes: { type: EmailTypes },
    }
  }
}

export class EmailTypes extends APIObject {
  relations () {
    return {
      type: { type: EmailTemplateType },
      status: { type: EmailTemplateStatus },
      template: { type: EmailTemplate },
    }
  }

  get isEmailTemplateStatusSystem () {
    return this.status === EmailTemplateStatus.system
  }

  get isEmailTemplateStatusOverride () {
    return this.status === EmailTemplateStatus.override
  }

  get isEmailTemplateStatusDisabled () {
    return this.status === EmailTemplateStatus.disabled
  }
}

export class MasterTemplate extends APIObject {
  relations () {
    return {

    }
  }
}

export class EmailTemplateType extends APIObject {
  relations () {
    return {

    }
  }
}

export const EmailTemplateStatus = new Enum({
  system: { value: 'system', description: 'general.system' },
  override: { value: 'override', description: 'general.override' },
  disabled: { value: 'disabled', description: 'general.disabled' },
})
