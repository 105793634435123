<template>
  <container v-bind="container">
    <draggable
      v-model="_value"
      draggable=".item"
      class="h-full flex items-center flex-wrap"
      @keydown.escape="handleClear()"
    >
      <div v-for="(item, index) in _value" :key="index" class="item py-1 pl-3 rounded-md bg-blue-100 dark:bg-blue-800/30 text-blue-600 dark:text-blue-500 text-sm mr-1 my-px flex items-center transition cursor-move">
        {{ item }}

        <o-icon icon="x" :size="12" class="bg-blue-200 dark:bg-blue-800/30 rounded-md h-5 w-5 flex items-center justify-center ml-3 mr-2 hover:bg-blue-300 dark:hover:bg-blue-700/50 transition cursor-pointer" @click="handleRemove(index)" />
      </div>

      <div
        slot="footer"
        key="footer"
        class="btn-group list-group-item flex-grow"
        role="group"
        :aria-label="$t('components.fields.list.basicExample')"
      >
        <input
          ref="input"
          v-model="inputValue"
          type="text"
          :placeholder="$t('components.fields.list.placeholder')"
          class="w-full h-8 px-2 bg-transparent text-sm mt-px"
          @blur="handleBlur"
          @keydown.enter="handleAdd"
          @keydown.backspace="handleBackspace"
        >
      </div>
    </draggable>
  </container>
</template>

<script>
import draggable from 'vuedraggable'

import Container from './Container'

import classes from '@/mixins/fields/classes.js'
import input from '@/mixins/fields/input.js'

export default {
  components: {
    draggable,
    Container,
  },
  mixins: [classes, input],
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    baseClass: {
      type: [String, Object, Array],
      default: 'h-auto w-full relative flex items-center',
    },
    defaultSizeClass: {
      type: String,
      default: 'p-2px',
    },
  },
  data () {
    return {
      inputValue: '',
      componentData: {
        props: {
          type: 'transition',
          name: 'flip-list',
        },
      },
    }
  },
  computed: {
    _value: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    handleAdd () {
      const inputValue = this.inputValue
      // this.$emit('input', inputValue)

      if (inputValue) {
        // allows multiple inputs split by comma
        const splitInput = inputValue.split(',')
        if (splitInput.length > 1) {
          const trimmedSplit = splitInput.map(input => input.trim())
          const filtered = trimmedSplit.filter(input => input)
          // this._value.push(...filtered)
          this._value = [...this._value, ...filtered]
        } else {
          // this._value.push(inputValue)
          this._value = [...this._value, inputValue]
        }

        this.inputValue = ''
        this.$nextTick(() => this.$refs.input?.$el?.focus())
        this.$emit('change', this._value)
      }
    },
    handleClear () {
      this.inputValue = ''
    },
    handleRemove (index) {
      // this._value.splice(index, 1)
      this._value = this._value.filter((_, i) => i !== index)

      this.$emit('blur', this._value)
      this.$emit('change', this._value)
    },
    handleBlur () {
      this.handleAdd()
      this.$emit('blur', this._value)
    },
    handleBackspace () {
      if (!this.inputValue) {
        this._value.pop()
      }
    },
  },
}
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}

.sortable-ghost {
  opacity: 0.4;
  border: 1px dashed gray
}
</style>
