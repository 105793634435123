import Model, { Request } from '../model'
import APIObject from '../object'
import Enum from '../enums'

export default class Billing extends Model {
  static modelName () {
    return 'billing'
  }

  static status () {
    const url = this.modelBaseURL() + '/status'
    return this.requestItem(Request.get(url), BillingStatus)
  }

  static plans () {
    const url = this.modelBaseURL() + '/plans'
    return this.requestList(Request.get(url), BillingPlan)
  }

  static methods () {
    const url = this.modelBaseURL() + '/methods'
    return this.requestList(Request.get(url), BillingMethod)
  }

  static token () {
    const url = this.modelBaseURL() + '/clientToken'
    return this.requestData(Request.get(url))
  }

  static cancel () {
    const url = this.modelBaseURL() + '/cancel'
    return this.requestSuccess(Request.get(url))
  }

  static payments (page = 1) {
    const url = this.modelBaseURL() + '/payments?page=' + page
    return this.requestList(Request.get(url), BillingPayments)
  }

  static cancelReasons ({ reasons = [], requestCallback = false, notes = '', callbackDetails = '' }) {
    const url = this.modelBaseURL() + '/cancelReasons'
    const data = {
      reasons,
      requestCallback,
      notes,
      callbackDetails,
    }

    return this.requestSuccess(Request.post(url, JSON.stringify(data)))
  }

  static create ({ nonce }) {
    const url = this.modelBaseURL() + '/methods'

    const data = {
      nonce,
    }

    return this.requestItem(Request.post(url, JSON.stringify(data)), BillingMethod)
  }

  static default ({ nonce, deviceData }) {
    const url = this.modelBaseURL() + '/setDefaultMethod'

    const data = {
      nonce,
      deviceData,
    }

    return this.requestItem(Request.post(url, JSON.stringify(data)), BillingMethod)
  }

  static delete ({ token }) {
    const url = this.modelBaseURL() + '/methods'

    const data = {
      token,
    }

    return this.requestSuccess(Request.delete(url, JSON.stringify(data)))
  }

  static subscribe ({ plan, token, nonce, deviceData }) {
    const url = this.modelBaseURL() + '/subscribe'

    const data = {
      plan,
      token,
      nonce,
      deviceData,
    }

    return this.requestSuccess(Request.post(url, JSON.stringify(data)))
  }

  static changePlan ({ plan }) {
    const url = this.modelBaseURL() + '/changePlan'

    const data = {
      plan,
    }

    return this.requestSuccess(Request.post(url, JSON.stringify(data)))
  }

  static purchaseSmsCredit (amount) {
    const url = this.modelBaseURL() + '/purchaseSmsCredit'

    const data = {
      amount,
    }

    return this.requestSuccess(Request.post(url, JSON.stringify(data)))
  }
}

export class BillingPlan extends APIObject {
  relations () {
    return {
      type: { type: BillingPlanType },
    }
  }

  get isPlanTypeClassic () {
    return this.type === BillingPlanType.classic
  }

  get isPlanTypeNext () {
    return this.type === BillingPlanType.next
  }
}

export class BillingPayments extends APIObject {
  relations () {
    return {
      payments: { type: BillingPayment },
    }
  }
}

export class BillingPayment extends APIObject {
  relations () {
    return {
      itemType: { type: BillingPaymentType },
      processor: { type: BillingPaymentProcessor },
    }
  }

  get processorLabel () {
    if (this.processor === BillingPaymentProcessor.braintree) {
      return 'api.models.billing.creditCard'
    }

    if (this.processor === BillingPaymentProcessor.paypal) {
      return 'billing.paypal'
    }

    return 'general.payment'
  }

  get net () {
    return ((100 * this.gross) / (100 + this.taxAmount)).toFixed(2)
  }

  get tax () {
    return (this.gross - this.net).toFixed(2)
  }

  get priceLabel () {
    return `${this.currency} ${Number(this.gross).toFixed(2)}`
  }

  get netLabel () {
    return `${this.currency} ${this.net}`
  }

  get taxLabel () {
    return `${this.currency} ${this.tax}`
  }

  get priceWithoutTaxLabel () {
    if (this.taxAmount) {
      return this.netLabel
    } else {
      return this.priceLabel
    }
  }
}


export class BillingMethod extends Model {
  relations () {
    return {
      type: { type: BillingMethodType },
    }
  }

  get isBillingMethodTypeCard () {
    return this.type === BillingMethodType.card
  }

  get isBillingMethodTypePaypal () {
    return this.type === BillingMethodType.paypal
  }
}

export const BillingMethodType = new Enum({
  card: { value: 'card', description: 'api.models.billing.card' },
  paypal: { value: 'paypal', description: 'billing.paypal' },
})


export class BillingStatus extends APIObject {
  relations () {
    return {
      state: { type: BillingSubscriptionState },
      plan: { type: BillingPlan },
      currentLocationPlan: { type: BillingCurrentPlan },
    }
  }

  get isBillingSubscriptionStateTrial () {
    return this.state === BillingSubscriptionState.trial
  }

  get isBillingSubscriptionStateActive () {
    return this.state === BillingSubscriptionState.active
  }

  get isBillingSubscriptionStateWarning () {
    return this.state === BillingSubscriptionState.warning
  }

  get isBillingSubscriptionStatePaymentError () {
    return this.state === BillingSubscriptionState.paymentError
  }

  get isBillingSubscriptionStateCancelled () {
    return this.state === BillingSubscriptionState.cancelled
  }

  get isBillingSubscriptionStateSuspended () {
    return this.state === BillingSubscriptionState.suspended
  }

  get isBillingSubscriptionStateInactive () {
    return this.state === BillingSubscriptionState.inactive
  }

  get isBillingSubscriptionStatePrepaid () {
    return this.state === BillingSubscriptionState.prepaid
  }

  get isBillingSubscriptionStateTemporary () {
    return this.state === BillingSubscriptionState.temporary
  }
}

export class BillingCurrentPlan extends Model {
  relations () {
    return {
      planDiscount: { type: BillingPlanDiscount },
    }
  }
}

export class BillingPlanDiscount extends Model {
  relations () {
    return {
      amountType: { type: BillingPlanDiscountType },
    }
  }

  get isAmountTypeFixed () {
    return this.amountType === BillingPlanDiscountType.fixed
  }

  get isAmountTypePercentage () {
    return this.amountType === BillingPlanDiscountType.percentage
  }
}

export const BillingSubscriptionState = new Enum({
  trial: { value: 'trial', description: 'general.trial' },
  active: { value: 'active', description: 'general.active' },
  warning: { value: 'warning', description: 'general.warning' },
  paymentError: { value: 'payment-error', description: 'general.paymentError' },
  cancelled: { value: 'cancelled', description: 'general.cancelled' },
  suspended: { value: 'suspended', description: 'general.suspended' },
  inactive: { value: 'inactive', description: 'general.inactive' },
  prepaid: { value: 'prepaid', description: 'api.models.billing.prepaid' },
  temporary: { value: 'temporary', description: 'general.temporary' },
})

export const BillingPaymentType = new Enum({
  plan: { value: 0, description: 'api.models.billing.subscriptionPayment' },
  credit: { value: 1, description: 'api.models.billing.smsCredit' },
  auto: { value: 2, description: 'api.models.billing.smsCreditAuto' },
})

export const BillingPaymentProcessor = new Enum({
  braintree: { value: 'braintree', description: 'api.models.billing.braintree' },
  paypal: { value: 'paypal', description: 'billing.paypal' },
})

export const BillingPlanType = new Enum({
  classic: { value: 0, description: 'api.models.billing.classic' },
  next: { value: 1, description: 'api.models.billing.next' },
})

export const BillingPlanDiscountType = new Enum({
  percentage: { value: 1, description: 'general.percentage' },
  fixed: { value: 2, description: 'general.fixed' },
})

