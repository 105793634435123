var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center",class:{ 'pointer-events-none opacity-50' : _vm.disabled }},[_c('div',{staticClass:"relative rounded-full transition duration-200 ease-linear",class:[
      _vm.isChecked ? `bg-${_vm.checkedColour}` : `bg-${_vm.uncheckedColour} dark:bg-gray-700`,
      { 'w-12 h-6': _vm.size === 'md' },
      { 'w-6 h-3': _vm.size === 'xs' },
    ]},[_c('label',{staticClass:"absolute left-0 bg-white dark:bg-gray-800 mb-2 rounded-full transition transform duration-100 ease-linear cursor-pointer",class:[
        _vm.isChecked ? ['translate-x-full', `border-${_vm.checkedColour}`] : ['translate-x-0', `border-${_vm.uncheckedColour} dark:border-gray-700`],
        { 'w-6 h-6 border-2': _vm.size === 'md' },
        { 'w-3 h-3 border': _vm.size === 'xs' },
      ],attrs:{"for":_vm.id}}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm._model),expression:"_model"}],staticClass:"appearance-none w-full h-full active:outline-none focus:outline-none",attrs:{"id":_vm.id,"true-value":_vm.value,"false-value":_vm.uncheckedValue,"type":"checkbox","name":"toggle"},domProps:{"value":_vm.value,"checked":Array.isArray(_vm._model)?_vm._i(_vm._model,_vm.value)>-1:_vm._q(_vm._model,_vm.value)},on:{"change":function($event){var $$a=_vm._model,$$el=$event.target,$$c=$$el.checked?(_vm.value):(_vm.uncheckedValue);if(Array.isArray($$a)){var $$v=_vm.value,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm._model=$$a.concat([$$v]))}else{$$i>-1&&(_vm._model=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm._model=$$c}}}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }