<template>
  <div class="relative">
    <textarea
      :id="id"
      ref="textarea"
      v-model="currentValue"
      :autofocus="autofocus"
      :disabled="disabled"
      :name="name"
      :placeholder="placeholder"
      :readonly="readonly"
      :required="required"
      :maxlength="maxlength"
      :rows="rows"
      :wrap="wrap"
      :class="[
        currentClass,
        counter && maxlength ? 'rounded-b-none border-b-0' : ''
      ]"
      @blur="onBlur"
      @focus="onFocus"
      @keyup="$emit('keyup', $event)"
      @keydown="$emit('keydown', $event)"
    />

    <div v-if="counter && maxlength" class="flex justify-end text-xs font-medium bg-gray-50 rounded-b-md py-1 px-2 border dark:bg-gray-900">
      {{ count }} / {{ maxlength }}
    </div>
  </div>
</template>

<script>

import classes from '@/mixins/fields/classes.js'
import attributes from '@/mixins/fields/attributes.js'
import methods from '@/mixins/fields/methods.js'

export default {
  mixins: [attributes, classes, methods],
  props: {
    value: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    maxlength: {
      type: [String, Number],
      default: null,
    },
    readonly: {
      type: Boolean,
      default: undefined,
    },
    rows: {
      type: [String, Number],
      default: 4,
    },
    wrap: {
      type: String,
      default: 'soft',
    },
    counter: {
      type: Boolean,
      default: false,
    },
    baseClass: {
      type: [String, Object, Array],
      default: 'w-full block bg-white dark:bg-gray-900 border border-gray-200 dark:border-gray-800 rounded-md focus-within:ring-2 rounded-md text-sm',
    },
    defaultStatusClass: {
      type: [String, Object, Array],
      default: 'bg-white dark:bg-gray-900',
    },
    warningStatusClass: {
      type: [String, Object, Array],
      default: 'border-yellow-400 bg-yellow-100 dark:bg-yellow-400/20',
    },
    errorStatusClass: {
      type: [String, Object, Array],
      default: 'border-red-300 bg-red-100 dark:bg-red-400/20',
    },
    successStatusClass: {
      type: [String, Object, Array],
      default: 'border-green-300 bg-green-100 dark:bg-green-400/20',
    },
    disabledClass: {
      type: [String, Object, Array],
      default: 'bg-gray-50 cursor-not-allowed opacity-75 dark:bg-gray-900',
    },
    defaultSizeClass: {
      type: [String, Object, Array],
      default: 'p-2',
    },
    largeSizeClass: {
      type: [String, Object, Array],
      default: 'p-4 text-lg',
    },
    smallSizeClass: {
      type: [String, Object, Array],
      default: 'p-2 text-sm',
    },
  },
  data () {
    return {
      currentValue: this.value,
      valueWhenFocus: null,
    }
  },
  computed: {
    count () {
      return this.currentValue?.length || 0
    },
  },
  watch: {
    value (value) {
      this.currentValue = value
    },
    currentValue (currentValue) {
      this.$emit('input', currentValue)
    },
  },
  mounted () {
    if (this.isSafari()) {
      this.attachResizeObserver()
    }
  },
  beforeDestroy () {
    if (this.isSafari()) {
      this.detachResizeObserver()
    }
  },
  methods: {
    onBlur (e) {
      this.$emit('blur', e)
      if (this.currentValue !== this.valueWhenFocus) {
        this.$emit('change', this.currentValue)
      }
    },
    onFocus (e) {
      this.$emit('focus', e)
      this.valueWhenFocus = this.currentValue
    },
    attachResizeObserver () {
      // Check if ResizeObserver is available in the browser
      if (typeof ResizeObserver !== 'undefined') {
        // Create a new ResizeObserver instance
        this.resizeObserver = new ResizeObserver((entries) => {
          for (const entry of entries) {
            /* Batch updates to the DOM outside of the ResizeObserver callback using
            requestAnimationFrame to defer changes until after the current frame */
            requestAnimationFrame(() => {
              // Handle the resize event
              this.handleSafariResize(entry.target)
            })
          }
        })

        // Observe the textarea element
        const textareaElement = this.$refs.textarea
        if (textareaElement) {
          this.resizeObserver.observe(textareaElement)
        }
      }
    },
    detachResizeObserver () {
      // Disconnect the observer when the component is destroyed or when it's no longer needed
      if (this.resizeObserver) {
        this.resizeObserver.disconnect()
      }
    },
    isSafari () {
      // Simple check for Safari browser
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    },
    handleSafariResize (element) {
      // Reset the overflowY value to trigger a reflow
      const ogValue = element.style.overflowY
      element.style.overflowY = ogValue === 'visible' ? 'auto' : 'visible'
      element.style.overflowY = ogValue
    },
  },
}
</script>
