import Model from '../model'
// import APIObject from '../object'
import Enum from '../enums'

export default class ReservationCancellationType extends Model {
  static modelName () {
    return 'ReservationCancellationType'
  }

  objectID () {
    return this.idReservationCancellationType
  }

  relations () {
    return {
      displayMode: { type: ReservationCancellationTypeDisplayMode },
    }
  }
}

export const ReservationCancellationTypeDisplayMode = new Enum({
  both: { value: 0, description: 'api.models.reservationCancellationType.both' },
  internal: { value: 1, description: 'api.models.reservationCancellationType.internal' },
  customer: { value: 2, description: 'general.customer' },
})
